import clsx from 'clsx';
import React from 'react';
import classes from './Privacy.module.scss';

export interface PrivacyProps {
  items: { title: string; content: string; }[];
  noShadow?: boolean;
}

export default function Privacy(props: PrivacyProps) {
  const { items, noShadow } = props;

  return <div className={clsx( classes['privacy-card'], noShadow && classes['no-shadow'])}>
    {
      items.map(item => <div className={classes['item']}>
        <div className={classes['title']}>{item.title}</div>
        <div className={classes['content']} dangerouslySetInnerHTML={{
          __html: item.content,
        }}></div>
      </div>)
    }
  </div>
}
