import clsx from 'clsx';
import useLocale from 'common/hooks/useLocale';
import { registerTouchableClassNames } from 'common/polyfills/touch-polyfill';
import React, { useState } from 'react';
import classes from "./MoreOptions.module.scss";

registerTouchableClassNames(
  classes['list-item'],
)

export interface MoreOptionsProps {
  label: string;
  openCollapse: boolean;
}

export default function MoreOptions({ label, openCollapse }: MoreOptionsProps) {
  const { lang } = useLocale();

  const [ showPopup, setShowPopup ] = useState(false);

  return <li className={clsx(["list-group-item", classes['list-item']])}>
    <div className={classes['search-item']} onClick={() => {return;}}>
      <div>{label}</div>
      <div className={classes['show-btn']}>
        <i className={clsx([ classes['icon'], openCollapse ? 'fa fa-minus' : 'fa fa-plus' ])}></i>
      </div>
    </div>
   
  </li>
}