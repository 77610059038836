import { currentStatusBarHeight } from 'common/hooks/useNativeStatusBarHeight';
import $ from 'jquery';
import { layoutActions } from "reducers/layout";
import { take } from "redux-saga/effects";
import toastr from 'toastr';
import 'toastr/build/toastr.css';
import { ActionType, getType } from "typesafe-actions";

const TOASTR_SEVERITY = [ 'error', 'success', 'info', 'warning' ];
toastr.options.timeOut = 6000;
toastr.options.toastClass = 'toastr'
toastr.options.hideDuration = 250;
toastr.options.showDuration = 250;
toastr.options.preventDuplicates = true;

export function *watchLayoutMessageAdded() {
  while (true) {
    $(`.toastr`).css('top', `${currentStatusBarHeight()}px`);
    const action: ActionType<typeof layoutActions['alert']> = yield take(getType(layoutActions.alert));
    if (TOASTR_SEVERITY.includes(action.payload.severity ?? '')) {
      toastr[action.payload.severity as 'error'](
        action.payload.message
      );
    } else {
      toastr.info(action.payload.message);
    }
  }
}

export const layoutWatchers = [ watchLayoutMessageAdded ];