import React from 'react';
import { useImage } from 'react-image';
import classes from './ProfileImage.module.css';


export interface ProfileImageProps {
  src?: string | absent;
  className?: string | absent;
  width: string;
}

export function ProfileImage(props: ProfileImageProps) {
  const { error } = useImage({
    srcList: [ props.src ?? '' ],
    useSuspense: false,
  });
  
  const noImage = <i className="fas fa-user" style={{ fontSize: `calc(${props.width} * 0.6)` }}></i>;
  if (!props.src || error) {
    return <div className={classes['image']} style={{ width: props.width, height: props.width }}>
      {noImage}
    </div>
  }
  return <div
    className={classes['image']}
    style={{ backgroundImage: `url("${props.src}")`, width: props.width, height: props.width }}
  />;
}