import { applyMiddleware, createStore, compose, combineReducers } from "redux";
import logger from "redux-logger";
import createSagaMiddleware from "redux-saga";
import { createBrowserHistory } from 'history';
import { reducerMap, IRootState, AllActions } from './reducers';
import { rootSaga } from './sagas';
import { saveState, loadState } from './utils/localStorage';
import { loginActions } from "reducers/login";

const middlewares = [];

const sagaMiddleware = createSagaMiddleware();

middlewares.push(sagaMiddleware);

export const browserHistory = createBrowserHistory({ basename: process.env.PUBLIC_URL });

if (process.env.NODE_ENV === 'development') {
  middlewares.push(logger);
}

const composeEnhancer =
  (process.env.NODE_ENV !== 'production' &&
    window && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

const persistedLoginState = loadState();

export const store = createStore<IRootState, AllActions, unknown, unknown>(    
    combineReducers(reducerMap),
    composeEnhancer(applyMiddleware(...middlewares))
);

if (persistedLoginState?.loggedIn) {
  store.dispatch(loginActions.success(persistedLoginState));
}

// Save Login
store.subscribe(() => {
	saveState(store.getState().login);
});

sagaMiddleware.run(rootSaga, { browserHistory });