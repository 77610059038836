import { BASE_URL } from 'api';
import useLocale from 'common/hooks/useLocale';
import { handleBlockDisplay, multiLang } from 'common/utils/utils';
import EnquiryDialog from 'components/overlay/EnquiryDialog';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { matchPath, useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import { IRootState } from 'reducers';
import { agentContactActions } from 'reducers/agent-contact';
import { agentListActions } from 'reducers/agent-list';
import { useCommonFormReducer } from 'reducers/common/form';
import { enquiryDialogActions, enquiryDialogValidations } from 'reducers/enquiry-dialog';

const EMPTY = {};

export default function GlobalEnquiryDialog() {
  const dispatch = useDispatch();
  const history = useHistory();
  
  const location = useLocation();
  const match = matchPath(location.pathname, {
    path: "/properties/:id",
    exact: true,
    strict: true
  });

  const { open, contents } = useSelector((state: IRootState) => state.enquiryDialog);
  const { loggedIn, email, phoneNumber, name } = useSelector((state: IRootState) => state.login);
  const { districts } = useSelector((state: IRootState) => state.customer.contents);
  const { entry } = useSelector((state: IRootState) => state.enquiryDialog);
  
  const {
    locale,
    districtHkiOptions,
    districtKltOptions,
    districtNtOptions,
    levelOptions,
    langEnquiryDialog,
  } = useLocale();

  const { currentPropertyStock } = useSelector((state: IRootState) => state.propertyDetail) ?? EMPTY;

  const districtOptions = { ...districtHkiOptions, ...districtKltOptions, ...districtNtOptions };
  
  const dialogPropertyInfoProps = ({
    id: currentPropertyStock.id,
    street: `${multiLang(locale, currentPropertyStock.streetZh, currentPropertyStock.streetEn, currentPropertyStock.streetSc) ?? ''}`,
    buildingName: `${multiLang(locale, currentPropertyStock.buildingNameZh, currentPropertyStock.buildingNameEn, currentPropertyStock.buildingNameSc) ?? ''}`,
    level: `${levelOptions[currentPropertyStock.level ?? ''] ?? ''}`,
    image: currentPropertyStock.photos.length > 0 ? `${BASE_URL}/files/${currentPropertyStock.photos?.[0]}` : '',
    net: currentPropertyStock.net,
    district: districtOptions[currentPropertyStock.district],
    gross: currentPropertyStock.gross,
    rent: currentPropertyStock.rent,
    price: currentPropertyStock.price
  });

  const form = useCommonFormReducer(
    (state: IRootState) => state.enquiryDialog,
    enquiryDialogActions.edit,
    enquiryDialogActions.addFormError,
    enquiryDialogValidations(langEnquiryDialog),
    { disableValidateOnBlur: true },
  );

  useEffect(() => {
    if (!open) return;
    dispatch(enquiryDialogActions.edit('tel', phoneNumber ?? ''));
    dispatch(enquiryDialogActions.edit('email', email ?? ''));
    dispatch(enquiryDialogActions.edit(locale === 'en' ? 'englishName' : 'chineseName', name));
    if (entry === 'D') dispatch(enquiryDialogActions.edit('districts', loggedIn ? districts ?? [] : []));
  }, [dispatch, email, phoneNumber, open, loggedIn, entry, districts, name, locale]);

  return <EnquiryDialog open={open} onHide={() => {
    dispatch(enquiryDialogActions.closeDialog());
    dispatch(enquiryDialogActions.reset());
  }}
    dialogPropertyInfoProps={dialogPropertyInfoProps}
    propertySpecific={Boolean(form.field('caPropertyStockId').value)} // || match?.isExact
    bindEmail={form.field('email')}
    bindContact={form.field('tel')}
    bindDistricts={form.field('districts')}
    bindName={locale === 'en' ? form.field('englishName') : form.field('chineseName')}
    regionBundles={{
      districtHkiOptions,
      districtKltOptions,
      districtNtOptions,
    }}
    onContactClick={async () => {
      const result = await form.validateAll();
      console.debug('result', result);
      if (result) {
        dispatch(enquiryDialogActions.doEnquiry());
      }
    }}
    onSameDistrictAgentClick={() => {
      // dispatch(enquiryDialogActions.closeDialog());
      // Copy fields to AgentContactDialog
      dispatch(agentContactActions.edit('contact', form.field('tel').value));
      dispatch(agentContactActions.edit('email', form.field('email').value));
      dispatch(agentContactActions.edit('name', locale === 'en' ? form.field('englishName').value : form.field('chineseName').value));
      // Reset
      dispatch(enquiryDialogActions.reset());
      if (contents.districts?.length) {
        dispatch(agentListActions.updateCriteria({ districts: contents.districts! }));
        dispatch(agentListActions.doFetchAgents({ isSwitchingPage: false }));
      } 
      if (history.location.pathname === '/agents') {
        dispatch(agentListActions.doFetchAgents({ isSwitchingPage: false }));
      } else {
        history.push('/agents');
      }
    }}
  />;
}