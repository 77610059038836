import clsx from 'clsx';
import { isMobileUI } from 'common/utils/utils';
import FloatingCaptionTile from 'components/data-display/FloatingCaptionTile';
import React from 'react';
import classes from './PropertyFeaturePanel.module.scss';

export interface PropertyFeaturePanelProps {
  features: { type: string, value: string }[];

  isDesktop?: boolean;
}

export default function PropertyFeaturePanel({ features, isDesktop }: PropertyFeaturePanelProps) {
  return <div className={classes['property-feature-panel']}>
    {
      features.map(feature => <div className={clsx({ 
        [classes['item']]: true, 
        [classes['desktop']]: !isMobileUI(), 
        [classes['mobile']]: isMobileUI() })
      }>
        <FloatingCaptionTile caption={feature.type} value={feature.value} isDesktop={isDesktop}/>
      </div>
      )
    }
  </div>
}