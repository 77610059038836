import useLocale from 'common/hooks/useLocale';
import breakpoints from 'common/theme/breakpoints.module.scss';
import PropertyCardDesktop, { PropertyCardDesktopProps } from 'components/display-cards/home/PropertyCardDesktop';
import ModalDialog from 'components/overlay/ModalDialog';
import React from 'react';
import Container from 'react-bootstrap/esm/Container';
import { useMediaQuery } from 'react-responsive';
import classes from './InlinePropertyListDesktop.module.scss';
import PropertyResultListItemDesktop, { PropertyResultListItemDesktopProps } from '../search/PropertyResultListItemDesktop';


export interface InlinePropertyListDesktopProps {
  open: boolean;
  onHide?: AnyFn;
  title: string;
  properties: PropertyResultListItemDesktopProps[];
}

export default function InlinePropertyListDesktop(props: InlinePropertyListDesktopProps) {
  const { properties, open, onHide, title } = props;
  const { lang, langProperty } = useLocale();

  const isSmallScreen = useMediaQuery({ maxWidth: breakpoints.lg });

  const notFoundView = () => <div className={classes['no-record-view']}>
    <div className={classes['msg']}>{langProperty.msgNoRecord}</div>
  </div>
  
  return <ModalDialog size={'xl'} title={title} 
    overrideClassName={{ overrideModal: !isSmallScreen ? classes['modal'] : undefined, overrideTitle: classes['title'] }} 
    show={open} onHide={onHide}
  >
    <div className={classes['root']}>
      {/* <hr className={clsx(classes['divider'], classes['bg-saffron'])}/> */}
      <Container>
        {
          properties.length > 0 ? <div className="row">
            <div className={`col-sm-12 col-md-12 col-lg-12 col-xl-12`}>
              <div className={`row ${classes['container']}`}>
                {console.log('property', properties)}
              {
                properties.map(property => <div className={`${classes['slot']} col-sm-12 col-md-12 col-lg-6 col-xl-6`}>
                  <PropertyResultListItemDesktop {...property} />
                </div>)
              }
              </div>
            </div>
          </div> : notFoundView()
        }
        
      </Container>
    </div>
  </ModalDialog>
}