import useLocale from 'common/hooks/useLocale';
import { handlePriceDisplay, handlePriceDisplayUnit, isNonEmpty, priceFromView, rangeFriendlyDisplay } from 'common/utils/utils';
import React from 'react';
import classes from './HistoryListItem.module.scss';

export interface HistoryListItemProps {
  id: number;
  buyOrRent: string;
  priceDisplay: number[];
  rent: number[];
  room: number;
  // For each item, only one of the below fields will have value:
  street?: string;
  buildingName?: string;
  district?: string;
}

export default function HistoryListItem(props: HistoryListItemProps) {
  const { buyOrRent, priceDisplay, rent, room, street, buildingName, 
    district } = props;
  
  const { lang, langHome, langProperty, locale, 
    districtHkiOptions, districtKltOptions, districtNtOptions,
  } = useLocale();

  const districtOptions = { ...districtHkiOptions, ...districtKltOptions, ...districtNtOptions };

  const buyOrRentDisplay= () => {
    return buyOrRent === 'BUY' ? langHome.actionBuy : langHome.actionRent;
  }

  const priceRangeDisplay = () => {
    return ` / ${rangeFriendlyDisplay(priceDisplay!, locale, num => `$${handlePriceDisplay(priceFromView(num, locale), locale)}${handlePriceDisplayUnit(priceFromView(num, locale), locale, lang)}`)}`;
    // if (priceDisplay[0] === 0 && priceDisplay[1] === Infinity) {
    //   return '';
    // } else if (priceDisplay[1] === Infinity) {
    //   return ` / $${handlePriceDisplay(priceFromView(priceDisplay[0], locale), locale)}${handlePriceDisplayUnit(priceFromView(priceDisplay[0], locale), locale, lang)} - ${lang.captionUnlimited}`;
    // } else {
    //   return ` / $${handlePriceDisplay(priceFromView(priceDisplay[0], locale), locale)} - ${handlePriceDisplay(priceFromView(priceDisplay[1], locale), locale)}` + `${handlePriceDisplayUnit(priceFromView(priceDisplay[1], locale), locale, lang)}`;
    // }
  }

  const rentRangeDisplay = () => {
    if (rent[0] === 0 && rent[1] === Infinity) {
      return '';
    } else if (rent[1] === Infinity) {
      return ` / $${rent[0]} - ${lang.captionUnlimited}`;
    } else {
      return ` / $${rent[0]} - ${rent[1]}`;
    }
  }

  const priceOrRentRangeDisplay = () => {
    return buyOrRent === 'BUY' ? priceRangeDisplay() : rentRangeDisplay();
    // return buyOrRent === 'BUY' ? `$${priceDisplay[0]} - ${priceDisplay[1]}` + 
    //   `${multiLang(locale, lang.u10k, lang.u1M)}`
    //   : `$${rent[0]} - ${rent[1]}`;
  }

  const roomNumberDisplay = () => {
    return ` / ${isNonEmpty(room) ? room : 0} ${langProperty.captionRooms}`;
  }

  let locationCaption = null;
  let locationValue = null;

  if (isNonEmpty(street)) {
    locationCaption = langProperty.captionStreet; 
    locationValue = street ?? '';
  } else if (isNonEmpty(buildingName)) {
    locationCaption = langProperty.captionBuilding; 
    locationValue = buildingName ?? '';
  } else if (isNonEmpty(district)) { 
    locationCaption = langProperty.captionDistrict; 
    locationValue = districtOptions[district ?? ''];
  } else {
    locationCaption = null;
    locationValue = null;
  }

  return <div className={classes['history-list-item']}>
    <div>
    {
      `${buyOrRentDisplay()}` + priceOrRentRangeDisplay() + `${roomNumberDisplay()}`
    }
    </div>
    { locationValue && <div className={classes['location-section']}>
      <div className={classes['caption']}>{`${locationCaption}:`}</div>
      <div>{`${locationValue}`}</div>
    </div>}
  </div>
};