// import DistrictFilter from './DistrictFilter';
import useLocale from 'common/hooks/useLocale';
import { registerTouchableClassNames } from 'common/polyfills/touch-polyfill';
import SectionTitleDesktop from 'components/home/SectionTitleDesktop';
import ScrollToTopButton from 'components/misc/ScrollToTopButton';
import PropertyListItemDesktop, { PropertyListItemDesktopProps } from 'components/property/PropertyListItemDesktop';
import MainLayoutDesktop from 'components/scaffold/MainLayoutDesktop';
import { BaseLayoutProps } from "components/scaffold/MainLayoutProps";
import React from 'react';
import Container from 'react-bootstrap/esm/Container';
import classes from './BookmarkListViewDesktop.module.scss';
import { PropertyResultListItemDemo, PropertyResultListItemDesktopDemo } from 'stories/3.DisplayCards.stories';
import PropertyResultListItemDesktop from 'components/property/search/PropertyResultListItemDesktop';
import { PropertyResultListItemDesktopProps } from '../search/PropertyResultListItemDesktop';


registerTouchableClassNames(
  classes['more-btn'], classes['back-to-top-button'],
)

export interface BookmarkListViewDesktopProps extends BaseLayoutProps {
  properties: PropertyResultListItemDesktopProps[];
  hasMore: boolean;
  onRequestNextPage?: () => void;
}

export default function BookmarkListViewDesktop(props: BookmarkListViewDesktopProps) {
  const { properties, hasMore, onRequestNextPage, ...rest } = props;

  const { langProperty  } = useLocale();

  const notFoundView = () => <div className={classes['no-record-view']}>
    <div className={classes['msg']}>{langProperty.msgNoRecord}</div>
  </div>

  return <MainLayoutDesktop {...rest}><Container style={{ padding: '0' }}>
    <div className={classes['bookmark-list-container']}>
      <div className={classes['inner']}>

        {/* --------- section header --------- */}
        <SectionTitleDesktop title={langProperty.titleBookmarkList} />

        {
          properties.length > 0 ? <div>
            <div className={`${classes['bookmark-list-section-container']} row`} >
              {
                properties.map(property => {
                  return <div key={property?.id} className={`${classes['bookmark-card-container']} col-sm-12 col-md-6 col-lg-4 col-xl-4`}>
                    <PropertyResultListItemDesktop 
                      {...property}
                    />
                  </div>
                })
              }
            </div>
    
            {/* --------- more button --------- */}
            {hasMore ? 
            <Container className={classes['more-btn-container']}>
              <div 
                className={classes['more-btn']}
                onClick={onRequestNextPage}
              >
                {langProperty.actionLoadMore}
                <i className="fas fa-arrow-down" />
              </div>
            </Container>
            : null}
          </div> : notFoundView()
        }
        
      </div>
    </div>
    <ScrollToTopButton />
  </Container></MainLayoutDesktop>;
}