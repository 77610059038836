import 'common/general-imports';
import useLocale from 'common/hooks/useLocale';
import React, { useMemo, useRef } from 'react';
import InfiniteScroll, { Props as InfiniteScrollProps } from 'react-infinite-scroll-component';
import loadingImg from './list-loading.svg';
import classes from './ListView.module.css';



export interface ListViewProps {
  children: React.ReactElement<ListViewItemProps>[];
  next?: AnyFn;
  hasMore?: boolean;
  infiniteScrollProps?: InfiniteScrollProps;
  refresh?: AnyFn;
}

const emptyFunction = (() => {});

export default function ListView(props: ListViewProps) {
  const { children } = props;
  const { lang } = useLocale();
  const randomId = useMemo(() => Math.random(), []);
  const scrollableTargetRef = useRef<HTMLDivElement>(null);
  return <div id={`list-view-${randomId}`} className={classes['list-view']} ref={scrollableTargetRef}>
    <InfiniteScroll
      next={props.next ?? emptyFunction}
      hasMore={props.hasMore ?? false}
      loader={<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '3.5rem' }}><img style={{ height: '100%' }} alt="Loading" src={loadingImg} /></div>}
      dataLength={children.length}
      scrollableTarget={`list-view-${randomId}`}
      scrollThreshold={'100px'}
      refreshFunction={props.refresh}
      pullDownToRefresh={Boolean(props.refresh)}
      pullDownToRefreshThreshold={80}
      pullDownToRefreshContent={
        <h6 style={{textAlign: 'center', color: 'var(--pas-gray)'}}>&#8595; {lang.msgPullDownToRefresh}</h6>
      }
      releaseToRefreshContent={
        <h6 style={{textAlign: 'center', color: 'var(--pas-gray)'}}>&#8593; {lang.msgReleaseToRefresh}</h6>
      }
      {...props.infiniteScrollProps ?? {}}
    >
      {children}
    </InfiniteScroll>
  </div>;
}

export interface ListViewItemProps {
  children: React.ReactChild;
  overrideClassName?: string;
}

export function ListViewItem(props: ListViewItemProps) {
  const { children, overrideClassName } = props;
  return <div className={overrideClassName ? overrideClassName : classes['item']}>
    {children}
  </div>;
}