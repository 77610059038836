import CAPropertyStockDetailDTO from 'common/dto/CAPropertyStockDetailDTO';
import { ActionType, createAction, createReducer } from 'typesafe-actions';

export interface PropertyStockDetailState {
  currentPropertyStock: CAPropertyStockDetailDTO;
  notFound?: boolean;
}

export const propertyStockDetailActions = {
  doFetch: createAction('PropertyStockDetail.FetchRequested')<{ propertyNo: string }>(),
  update: createAction('PropertyStockDetail.Updated')<{ propertyStock: CAPropertyStockDetailDTO | null }>(),
  reset: createAction('PropertyStockDetail.Reset')(),
  addBookmark: createAction('PropertyStockDetail.AddBookmark')<{ caPropertyStockId: number }>(),
  deleteBookmark: createAction('PropertyStockDetail.DeleteBookmark')<{ caPropertyStockId: number }>(),
};

export type PropertyStockDetailActions = ActionType<typeof propertyStockDetailActions>;

const initialState = (): PropertyStockDetailState => ({
  currentPropertyStock: {
    id: 0,
    propertyNo: '',
    dateModified: '',
    // Basic Info (Address) //
    // addressEn: string;
    // addressZh: string;
    district: '',
    streetZh: '',
    streetEn: '',
    streetSc: '',
    buildingNameZh: '',
    buildingNameEn: '',
    buildingNameSc: '',
    blockZh: '',
    blockEn: '',
    blockSc: '',
    level: '',
    // unit: '',

    // Basic Info (Others) //
    price: 0,
    rent: 0,
    gross: 0,
    net: 0,
    punchlineTC: '',
    punchlineSC: '',
    punchlineEN: '',
    latitude: 0,
    longitude: 0,
    photos: [],
    videoLink: '',
    floorPlans: [],
    bookmarked: false,

    // Features (Basic) //
    facing: '',
    deco: '',
    view: [],
    room: 0,
    suite: 0,
    // livingRoom: 0,
    helperRoom: 0,
    bathroom: 0,
    /** PropertyStock.balconySizes */
    balconySizes: [],
    gardenArea: 0,
    rooftopArea: 0,
    occupancyPermitAge: 0,


    // Features (More) //
    
    featuresAndFacilities: [],
    others: [],

    // Shcool Nets //
    primarySchoolNet: '',
    secondarySchoolNet: '',

    // Lead Agents //
    agents: [],

    // Recent Transactions //
    transactionList: [],
  },
  notFound: false,
});

export const propertyStockDetailReducer = createReducer<PropertyStockDetailState, PropertyStockDetailActions>(initialState())
  .handleAction(propertyStockDetailActions.update, (state, action) => 
    ({ ...state,
      currentPropertyStock: action.payload.propertyStock ?? initialState().currentPropertyStock,
      notFound: !action.payload.propertyStock,
    })
  )
  .handleAction(propertyStockDetailActions.doFetch, (state) =>
    ({ ...state, currentPropertyStock: initialState().currentPropertyStock, notFound: false }),
  )
  .handleAction(propertyStockDetailActions.addBookmark, (state, { payload }) => ({
    ...state, currentPropertyStock: {
      ...state.currentPropertyStock,
      bookmarked: true
    } 
  }))
  .handleAction(propertyStockDetailActions.deleteBookmark, (state, { payload }) => ({
    ...state, currentPropertyStock: {
      ...state.currentPropertyStock,
      bookmarked: false
    }
  }))
  .handleAction(propertyStockDetailActions.reset, (_) => 
    initialState()
  )
;