import useLocale from 'common/hooks/useLocale';
import React from 'react';
import { isNullOrUndefined } from 'util';
import classes from './FileUploadDialog.module.scss';
import ModalDialog, { ModalDialogProps } from './ModalDialog';
import ModalDialogActions, { ModalDialogActionButton } from './ModalDialogActions';


export interface FileUploadDialogProps {
  title: string;
  message?: string;
  handleUpload: (files: any) => void;
  fileAccept?: string;

  onConfirm?: AnyFn;

  size?: ModalDialogProps['size'];
  show?: boolean;
  onHide?: () => void;
}


export default function FileUploadDialog(props: FileUploadDialogProps) {
  const { title, message, size, show, onHide, onConfirm, handleUpload, fileAccept } = props;
  const { lang } = useLocale();

  return <ModalDialog title={title} size={size} show={show} onHide={onHide}>
    <div>
      <div className={classes['form-row']}>
        {message ? <div className={classes['message']}>{message}</div> : null}
      </div>
      <div className={classes['form-row']}>
        <input
            type="file"
            accept={fileAccept ?? ""}
            onChange={(ev) => {
              handleUpload(Array.from(ev.target.files ?? []));
              // resetFileInput(ev);
            }}
          />
      </div>
      {
        isNullOrUndefined(onConfirm) ?
          <ModalDialogActions>
            <ModalDialogActionButton onClick={onHide}>
              {lang.actionCancel}
            </ModalDialogActionButton>

          </ModalDialogActions>
          :
          <ModalDialogActions>
            <ModalDialogActionButton onClick={onHide}>
              {lang.actionCancel}
            </ModalDialogActionButton>
            <ModalDialogActionButton onClick={onConfirm}>
              {lang.actionConfirm}
            </ModalDialogActionButton>
          </ModalDialogActions>
      }

    </div>
  </ModalDialog>
}