import clsx from 'clsx';
import useLocale from 'common/hooks/useLocale';
import { registerTouchableClassNames } from 'common/polyfills/touch-polyfill';
import { FormBinder } from 'components/form-controls/IFormBinder';
import DistrictSelectDialog from 'components/overlay/DistrictSelectDialog';
import React, { useMemo, useState } from 'react';
import classes from "./SelectDistrictView.module.scss";

registerTouchableClassNames(
  classes['list-item'],
  classes['option'],
  classes['action-btn']
)

export interface SelectDistrictViewProps {
  label: string;
  options?: LocaleOptions;
  bind?: FormBinder<string[]>;
  districtHkiOptions: LocaleOptions;
	districtKltOptions: LocaleOptions;
	districtNtOptions: LocaleOptions;
}

export default function SelectDistrictView({ label, options, bind, districtHkiOptions, districtKltOptions, districtNtOptions }: SelectDistrictViewProps) {
  const { lang } = useLocale();

  const [ showPopup, setShowPopup ] = useState(false);
  const [ openDistrictDialog, setOpenistrictDialog ] = useState(false);
  const allDistrictOptions = useMemo(() => {

    return {
      ...districtHkiOptions,
      ...districtKltOptions,
      ...districtNtOptions,
    };
  }, [ districtHkiOptions, districtKltOptions, districtNtOptions]);

  return <li className={clsx(["list-group-item", classes['list-item']])}>
    <div className={classes['search-item']} onClick={() => {setOpenistrictDialog(true)}}>
      <div>{label}</div>
      <div className={classes['action']}>
        <div className={classes['selected-value-text']}>
          {bind?.value?.map(key=> allDistrictOptions[key])?.join(' / ')}
        </div>
        <div className={classes['show-btn']}>
          <i className={clsx([ classes['icon'], 'fa fa-angle-right' ])}></i>
        </div>
      </div>
    </div>
    <DistrictSelectDialog open={openDistrictDialog} onHide={() => setOpenistrictDialog(false) }
      districtHkiOptions={districtHkiOptions}
      districtKltOptions={districtKltOptions}
      districtNtOptions={districtNtOptions}
      districts={bind}
    />
  </li>
}