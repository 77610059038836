import CACustomerDTO from 'common/dto/CACustomerDTO';
import { checkValidEmail, checkValidPhoneNumber, isNonEmpty } from 'common/utils/utils';
import { ActionType, createAction, createReducer } from 'typesafe-actions';
import { commonFormInitialState, CommonFormState, createFormActions, formInitialReducerHandlers, FormValidator } from './common/form';

export interface CustomerState extends CommonFormState<CACustomerDTO> {

}

export type CustomerActions = ActionType<typeof customerActions>;

export const customerActions = {
  doFetchCustomer: createAction('Customer.FetchRequested')(),
  doUpdateCustomer: createAction('Customer.UpdateRequested')<CACustomerDTO>(),
  customerLoaded: createAction('Customer.Loaded')<CACustomerDTO>(),
  ...createFormActions('Customer.Edit', 'Customer.AddError')<CACustomerDTO>(),
};

const initialState = (): CustomerState => ({
  // ==> Form: extends the initial state formInitialState
  ...commonFormInitialState<CACustomerDTO>(),
});

export const customerReducer = createReducer<CustomerState, CustomerActions>(initialState(),
  // ==> Form: extends the reducer from FormInitialReducerHandler
  formInitialReducerHandlers('Customer.Edit', 'Customer.AddError')()
)
  .handleAction(customerActions.customerLoaded, (state, { payload }) => ({
    ...state,
    contents: { ...payload },
    originalContents: { ...payload },
  }))
  ;

export const customerValidations = (langClientProfile: LocaleOptions): FormValidator<CACustomerDTO>[] => [
  (values) => (!values.email || !checkValidEmail(values.email)) ? { email: langClientProfile.errEmail } : {},
  (values) => (isNonEmpty(values.phoneNumber) && !checkValidPhoneNumber(values.phoneNumber ?? '')) ? { phoneNumber: langClientProfile.errPhoneNumber } : {}
];
