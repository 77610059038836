import clsx from 'clsx';
import useLocale from 'common/hooks/useLocale';
import { useMobileLayoutHeight } from 'common/hooks/useMobileLayoutHeight';
import { registerTouchableClassNames } from 'common/polyfills/touch-polyfill';
import FormTextField from 'components/form-controls/FormTextField';
import SectionTitleDesktop from 'components/home/SectionTitleDesktop';
import ClientAvatar from 'components/navigation/ClientAvatar';
import DistrictSelectDialog from 'components/overlay/DistrictSelectDialog';
import MainLayoutDesktop from 'components/scaffold/MainLayoutDesktop';
import React, { useMemo, useState } from 'react';
import Button from 'react-bootstrap/esm/Button';
import Card from 'react-bootstrap/esm/Card';
import Form from 'react-bootstrap/esm/Form';
import { isNullOrUndefined } from 'util';
import classes from './CustomerProfileScreenLayoutMobile.module.scss';
import { CustomerProfileViewProps } from './CustomerProfileViewProps';



registerTouchableClassNames(
  classes['select-district-row'],
  classes['change-pw-button'],
  classes['logout-button'],
)

export default function CustomerProfileScreenLayoutDesktop(props: CustomerProfileViewProps) {
  const { layoutProps, regionBundles, userSalutationOptions,
    email, bindPhoneNumber, bindName, bindSalutation, bindDistrict, bindReceiveEmail, bindSubscribeNewsletter,
    onBackClick, onSaveClick, onChangePasswordClick, onLogoutClick, onConfirmUploadPhoto, onDeletePhoto, photoImgSrc, supportChangePassword, unsaved } = props;
  const mobileLayoutHeight = useMobileLayoutHeight();

  const allDistrictOptions = useMemo(() => {
    return {
      ...regionBundles.districtHkiOptions,
      ...regionBundles.districtKltOptions,
      ...regionBundles.districtNtOptions,
    };
  }, [regionBundles]);

  const { langClientProfile, lang, locale } = useLocale();
  const [districtDialogOpen, setDistrictDialogOpen] = useState(false);
  const handleDistrictDialogClose = () => setDistrictDialogOpen(false);
  return <MainLayoutDesktop {...layoutProps}>
    <DistrictSelectDialog {...regionBundles} open={districtDialogOpen} onHide={handleDistrictDialogClose} districts={bindDistrict} />
    <SectionTitleDesktop title={lang.actionUserProfile} />
    <div className={clsx([classes['client-profile-screen'], classes['desktop']])}>
      <Card
        className={classes['inner']}
      ><Card.Body>

          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            {/* <ClientScreenTopBar
            title={bindName?.value ?? ''}
            onBackClick={onBackClick}
            onSaveClick={onSaveClick}

            allowPhotoUpload={true}
            onConfirmUploadPhoto={onConfirmUploadPhoto}
            onDeletePhoto={onDeletePhoto}
            photoImgSrc={photoImgSrc}
          /> */}

            <ClientAvatar
              title={bindName?.value ?? ''}
              allowPhotoUpload={true}
              photoImgSrc={photoImgSrc}
              onConfirmUploadPhoto={onConfirmUploadPhoto}
              onDeletePhoto={onDeletePhoto}
              width="calc(150rem / 16)"
            />
          </div>

          <div className={classes['client-detail-container']} >

            <div className="row">
              <div className="col-md-6">
                {/* ------------ email ------------ */}
                <div className={classes['form-label']}>
                  {langClientProfile.captionEmail}
                </div>
                <div className={classes['email-container']}>
                  {email}
                </div>

                {/* ------------ phoneNumber ------------ */}
                <div className={classes['form-label']}>
                  {langClientProfile.captionPhoneNumber}
                </div>
                <FormTextField label={langClientProfile.captionPhoneNumber} bind={bindPhoneNumber} />

                {/* ------------ name ------------ */}
                <div className={classes['form-label']}>
                  {langClientProfile.captionName}
                </div>
                <FormTextField label={langClientProfile.captionName} bind={bindName} />

                {/* ------------ salutation ------------ */}
                <div className={classes['form-label']}>
                  {langClientProfile.captionSalutation}
                </div>
                <Form>
                  <div className={classes['salutation-container']} key={`salutations`} >
                    {Object.keys(userSalutationOptions).map(key => (

                      <Form.Check inline
                        label={userSalutationOptions[key]}
                        type='radio'
                        key={`salutation-${key}`}
                        id={`salutation-${key}`}
                        checked={bindSalutation?.value === key}
                        onChange={() => {
                          if (isNullOrUndefined(bindSalutation)) {
                            return;
                          } else {
                            bindSalutation.change(key)
                          }
                        }}
                      />
                    ))}
                    {/* <Form.Check inline label="先生" type='radio' id={`inline-radio-1`} />
                  <Form.Check inline label="小姐" type='radio' id={`inline-radio-2`} /> */}
                  </div>
                </Form>
              </div>

              <div className="col-md-6">
                {/* ------------ preferenceSetting ------------ */}
                <div className={classes['form-label']}>
                  {langClientProfile.captionPreferenceSetting}
                </div>

                <ul className="list-group preference-setting-container">
                  {/* ---------- district ---------*/}
                  <li
                    className="list-group-item preference-setting select-district-row"
                    onClick={() => { setDistrictDialogOpen(true) }}
                  >
                    <div className={classes['preference-setting-caption']} style={{ fontSize: locale === 'en' ? '0.875rem' : undefined }}>
                      {langClientProfile.captionSearchDistrict}
                    </div>

                    <div className={classes['preference-setting-action']}>
                      <div className={classes['selected-district-text']}>
                        {bindDistrict?.value?.map(key => allDistrictOptions[key])?.join(' / ')}
                      </div>
                      <div>
                        <i className={"fas fa-chevron-right " + classes['search-district-icon']}></i>
                      </div>
                    </div>

                  </li>
                  {/* ---------- receiveEmail ---------*/}
                  <li className="list-group-item preference-setting">
                    <div className={classes['preference-setting-caption']}>
                      {langClientProfile.captionReceiveEmail}
                    </div>
                    <div className={classes['preference-setting-action']}>
                      <Form>
                        <Form.Check
                          type="switch"
                          id="receive-email-switch"
                          key="receive-email-switch"
                          label=""
                          checked={bindReceiveEmail?.value ?? false}
                          onChange={() => {
                            if (isNullOrUndefined(bindReceiveEmail)) {
                              return;
                            } else {
                              bindReceiveEmail.change(!bindReceiveEmail?.value)
                            }
                          }}
                        />
                      </Form>
                    </div>
                  </li>
                  {/* ---------- subscribeNewsletter ---------*/}
                  <li className="list-group-item preference-setting">
                    <div className={classes['preference-setting-caption']}>
                      {langClientProfile.captionSubscribeNewsletter}
                    </div>
                    <div className={classes['preference-setting-action']}>
                      <Form>
                        <Form.Check
                          type="switch"
                          id="subscribe-news-letter-switch"
                          key="subscribe-news-letter-switch"
                          label=""
                          checked={bindSubscribeNewsletter?.value ?? false}
                          onChange={() => {
                            if (isNullOrUndefined(bindSubscribeNewsletter)) {
                              return;
                            } else {
                              bindSubscribeNewsletter.change(!bindSubscribeNewsletter?.value)
                            }
                          }}
                        />
                      </Form>
                    </div>
                  </li>
                </ul>

                {/* <div className={classes['form-label']}>
                &nbsp;
              </div> */}
                {/* ---------- change password ---------- */}
                <div className={classes['change-pw-button-container']}>
                  {supportChangePassword ? <Button className={classes['change-pw-button']} variant="outline-pas-darkblue" onClick={onChangePasswordClick}>
                    {langClientProfile.actionChangePassword}
                  </Button> : null}

                  <Button className={classes['logout-button']} variant="outline-danger" onClick={onLogoutClick}>
                    {lang.actionLogout}
                  </Button>
                </div>
              </div>
            </div>



            {/* <div className="row">
              <div className="col-md-6">
                
              </div>

              <div className="col-md-6">
              
            </div>
          </div> */}

            {/* <div className="row">
            <div className="col-md-6">
              
            </div>

            <div className="col-md-6">
              
            </div>
          </div> */}

            <div className={classes['desktop-save-button-container']}>
              <Button style={unsaved ? { color: '#FFBF00', fontWeight: 'bold' } : {}} variant="outline-pas-darkblue" onClick={onSaveClick}>
                {lang.actionSave + `${unsaved ? ' *' : ''}`}
              </Button>
            </div>
          </div>

        </Card.Body></Card>
    </div>
  </MainLayoutDesktop>
}