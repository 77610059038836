
export type ApiResult<T = any> = {
  error?: null | undefined;
  data: T;
} | {
  error: string;
  data?: null | undefined;
}

export interface Page<T> {
  content: T[];
  currentPage: number;
  totalPages: number;
  totalCount: number;
  totalElements: number;
}

export type ApiErrorCode =
  'ERR_SERVER_ERROR' |
  'ERR_VERSION_MISMATCHED' |
  'ERR_PERMISSION_DENIED' |
  'ERR_NOT_AUTHORIZED' |
  'ERR_NOT_FOUND' |
  'ERR_EMAIL_ALREADY_BEEN_REGISTERED' |
  'ERR_EMAIL_INPUT_REQUIRED'
  ;

export class ApiError extends Error {
  get code(): string { return this.message as string };

  static of(code: string) {
    return new ApiError(code);
  }

  static isApiError(o: any): o is ApiError {
    return o instanceof ApiError;
  }

  static isErrorCode(c: any, check: ApiErrorCode): c is ApiErrorCode {
    return c === check;
  }
}

export type ApiPageResult<T> = ApiResult<Page<T>>;

export const PUBLIC_URL = process.env['PUBLIC_URL'];
export const BASE_URL = process.env['REACT_APP_PAS_BASE_URL'];
export const SHARE_BASE_URL = process.env['REACT_APP_PAS_SHARE_BASE_URL'];
export const GOOGLE_MAP_API_KEY = process.env['REACT_APP_GOOGLE_MAP_API_KEY'] ?? '';
export const APP_PUBLIC_URL = process.env['REACT_APP_PAS_APP_PUBLIC_URL'];