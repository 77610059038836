import RatingStars from 'components/data-display/RatingStars';
import { ProfileImage } from 'components/misc/ProfileImage';
import PropTypes, { InferType } from 'prop-types';
import React, { useState } from 'react';
import classes from './DialogAgentView.module.scss';
import AgentDistrictDialog from './home/AgentDistrictDialog';


export const DialogAgentViewProps = PropTypes.shape({
  image: PropTypes.string,
  chineseName: PropTypes.string.isRequired,
  englishName: PropTypes.string.isRequired,
  rating: PropTypes.number.isRequired, // double
  maxRating: PropTypes.number.isRequired,
  district: PropTypes.string,
  // onClick: PropTypes.func as PropTypes.Requireable<AnyFn>,
}).isRequired;
export type DialogAgentViewProps = InferType<typeof DialogAgentViewProps>;

export default function DialogAgentView(props: DialogAgentViewProps) {
  const { image, chineseName, englishName, rating, maxRating, district } = props;
  // const { langAgent } = useLocale();

  const [showAllDistricts, setShowAllDistricts] = useState(false);
  const handleShowAllDistrictsClose = () => {
    setShowAllDistricts(false);
  }

  const handleDistrictOnClick = () => {
    setShowAllDistricts(true);
  }


  return <div className={classes['dialog-agent-view']}>
    <div className={classes['left']}>
      <ProfileImage src={image} width={'5rem'} />
    </div>
    <div className={classes['right']}>
      <div className={classes['agent-name']}><b>{englishName}</b></div>
      <div className={classes['agent-name']}><b>{chineseName}</b></div>
      <div className={classes['agent-rating']}>
        <RatingStars maxRating={maxRating} rating={rating} color={'#F96401'} />
      </div>
      <AgentDistrictDialog allDistricts={district ?? ""} open={showAllDistricts} onHide={handleShowAllDistrictsClose} />
      {district && district.trim().length && <div className={classes['agent-district']} onClick={handleDistrictOnClick}> {district} </div>}
    </div>
  </div>;
}

DialogAgentView.propTypes = DialogAgentViewProps;