import AlertConfirmDialog from 'components/overlay/AlertConfirmDialog';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from 'reducers';
import { alertConfirmDialogActions } from 'reducers/alert-confirm-dialog';

export default function GlobalAlertConfirmDialogs() {
  const dispatch = useDispatch();
  const { dialogs } = useSelector((state: IRootState) => state.alertConfirm);

  return <>
    {Object.values(dialogs).map(dialog => <AlertConfirmDialog
      key={dialog.id}
      title={dialog.title}
      message={dialog.message}
      show={dialog.show}
      actionConfirm={dialog.actionConfirm}
      actionCancel={dialog.actionCancel}
      
      onHide={(confirmed) => dispatch(alertConfirmDialogActions.hidden({ id: dialog.id, confirmed }))}
    />)}
  </>
}