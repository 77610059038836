import { ApiResult, BASE_URL } from 'api';

const PUBLIC_URL = process.env['REACT_APP_PAS_APP_PUBLIC_URL'] ?? '/';
export const forgetPasswordApi = {
  request: async (email: string): Promise<ApiResult<{ invalidUsernameOrEmail: boolean, emailSent: boolean }>> => {
    const payload = new FormData();

    payload.append('username', email);
    payload.append('contactEmail', email);
    payload.append('publicUrl', PUBLIC_URL);
    const res = fetch(`${BASE_URL}/ca/forgotPassword/request`, {
      method: 'POST',
      body: payload,
    });

    return (await res).json();
  }
}
export const resetPasswordApi = {

  verifyToken: async (token: string): Promise<ApiResult<any>> => {
    const payload = new FormData();

    payload.append('token', token);

    const res = fetch(`${BASE_URL}/ca/forgotPassword/verifyToken`, {
      method: 'POST',
      body: payload,
    });

    return (await res).json();
  },

  changePassword: async (token: string, newPassword: string): Promise<ApiResult<any>> => {
    const payload = new FormData();

    payload.append('token', token);
    payload.append('newPassword', newPassword);
    const res = fetch(`${BASE_URL}/ca/forgotPassword/changePassword`, {
      method: 'POST',
      body: payload,
    });

    return (await res).json();
  },

};

export default resetPasswordApi;