import useLocale from 'common/hooks/useLocale';
import { handlePriceDisplay, handlePriceDisplayUnit, isNonEmpty, numberWithCommas } from 'common/utils/utils';
import { ImageBg } from 'components/data-display/Image';
import React from 'react';
import { isNullOrUndefined } from 'util';
import classes from './DialogPropertyInfo.module.scss';

export interface DialogPropertyInfoProps {
	id: number;
	street: string;
	buildingName: string;
	level: string;
  image: string;
  net: number;
  gross?: number;
  district: string;
  price?: number;
  rent?: number;
}

export default function DialogPropertyInfo(props: DialogPropertyInfoProps) {
	const { street, buildingName, level, image, net, gross, district, price, rent } = props;

	const { lang: langCommon, langProperty, locale } = useLocale();

	const grossDisplay = () => {
		const grossDisplay = !(gross) ? '' : (langProperty.grossAbbr + String(gross) + langCommon.uFt);

		return isNonEmpty(grossDisplay) ? <div className={classes['gross']}>{grossDisplay}</div> : null;
	}

	const netDisplay = () => {
		const netDisplay = !(net) ? '' : (langProperty.netAbbr + String(net) + langCommon.uFt);
		
		return isNonEmpty(netDisplay) ? <div className={classes['net']}>{netDisplay}</div> : null;
	}

	const priceSection = () => {
		const priceDisplay = (isNullOrUndefined(price) || !isNullOrUndefined(price) && price === 0) ? '' : 
		numberWithCommas(handlePriceDisplay(price ?? 0, locale)) + handlePriceDisplayUnit(price, locale, langCommon);

		return isNonEmpty(priceDisplay) ? <div className={classes['price-section']}>
			<div className={classes['logo']}>{langProperty.sale}</div>
			<div className={classes['price']}>{`$${priceDisplay}`}</div>
		</div> : null ;
	}

	const rentSection = () => {
		const rentDisplay = (isNullOrUndefined(rent) || !isNullOrUndefined(rent) && rent === 0) ? '' : numberWithCommas(rent);

		return isNonEmpty(rentDisplay) ? <div className={classes['rent-section']}>
			<div className={classes['logo']}>{langProperty.rent}</div>
			<div className={classes['rent']}>{`$${rentDisplay}`}</div>
		</div> : null;
	}

	return <div className={classes['dialog-property-info']}>
		<div className={classes['property-image']}>
			<ImageBg src={image} overrideClassName={classes['image']}/>
		</div>
		<div className={classes['property-description']}>
			<div className={classes['general-description']}>
				<div className={classes['label']}>{street} </div>
				<div className={classes['label']}>{buildingName} {level}</div>
				<div className={classes['district']}>{district}</div>
				<div className={classes['area']}>
					{netDisplay()}
					{!!(net) && !!(gross) && <div className={classes['divider']}>/</div>}
					{grossDisplay()}
				</div>
				<div className={classes['price-rent-description']}>
					{rentSection()}
					{priceSection()}
				</div>
			</div>
		</div>
	</div>
}
