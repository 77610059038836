import useLocale from 'common/hooks/useLocale';
import { useMobileLayoutHeight } from 'common/hooks/useMobileLayoutHeight';
import useNativeStatusBarHeight from 'common/hooks/useNativeStatusBarHeight';
import { registerTouchableClassNames } from 'common/polyfills/touch-polyfill';
import zIndexes from 'common/theme/z-indexes.module.scss';
import ScreenTopBarMobile from 'components/navigation/ScreenTopBarMobile';
import ScreenTopBarMobileValues from 'components/navigation/ScreenTopBarMobile.module.scss';
import TopSearchBar from 'components/navigation/TopSearchBar';
import ListView, { ListViewItem } from 'components/scaffold/ListView';
import MainLayoutMobile from 'components/scaffold/MainLayoutMobile';
import React, { useLayoutEffect, useRef, useState } from 'react';
import Spinner from 'react-bootstrap/esm/Spinner';
import SwipeableViews from 'react-swipeable-views';
import PropertyMapView from './PropertyMapView';
import PropertyResultMapListItem from './PropertyResultMapListItem';
import classes from './PropertyResultMapScreenMobile.module.scss';
import PropertyResultMapScreenProps from './PropertyResultMapScreenProps';
import PropertyResultListItem from './PropertyResultListItem';



registerTouchableClassNames(
  classes['btn'],
  classes['collapse-btn'],
);

export default function PropertyResultMapScreenMobile(props: PropertyResultMapScreenProps) {
  const { layoutProps, center, properties, propertyPoints, filteredLoading,
    filteredNext, filteredHasMore,
    onBackClick, onSearchClick, onSearchListButtonClick, onMapClusterClick } = props;
  const { langHome } = useLocale();

  const mobileLayoutHeight = useMobileLayoutHeight();
  const listLayoutHeight = `calc(${mobileLayoutHeight} + ${useNativeStatusBarHeight()}px)`;

  // Swipeable State
  const [ swipeIndex, setSwipeIndex ] = useState(0);
  const handleSwipeIndex = (i: number) => {
    setSwipeIndex(i);
  //   if (i === 1) {
  //     // onShowContentSubPage?.();
  //   }
  };
  const toggleSwipeIndex = () => setSwipeIndex(swipeIndex === 1 ? 0 : 1);
  const pointerEvents = swipeIndex === 0 ? 'none' : 'none';
  const pointerEventsInner = 'all';

  const [ currentPidList, setCurrentPidList ] = useState<number[]>([]);

  const [ actualViewHeightPct, setActualViewHeightPct] = useState(0.4);
  const listRef = useRef<HTMLDivElement>(null);
  useLayoutEffect(() => {
    const listHeight = listRef.current?.offsetHeight ?? 0;
    setActualViewHeightPct(listHeight / window.document.body.offsetHeight);
  }, [ listRef.current?.offsetHeight ?? 0, window.document.body.offsetHeight ]);

  return <MainLayoutMobile {...layoutProps}>
    <div className={classes['root']}>
      <div style={{ height: mobileLayoutHeight }} className={classes['map-sub-page']}>
        <ScreenTopBarMobile shadow onBackClick={onBackClick}>
          <div className={classes['top-bar-right']}>
            <TopSearchBar onClick={onSearchClick} />
            <div className={classes['btn']} onClick={onSearchListButtonClick}>
              <i className={`fas fa-th-list ${classes['larger-icon']}`}></i>
            </div>
          </div>
        </ScreenTopBarMobile>
        <div style={{ height: `calc(${mobileLayoutHeight} - ${ScreenTopBarMobileValues.topBarHeight})` }}>
          <PropertyMapView
            isApp
            properties={propertyPoints}
            center={center}
            actualViewHeightPct={actualViewHeightPct}

            onClusterClick={(pidList) => {
              setCurrentPidList(pidList);
              setSwipeIndex(1);
              onMapClusterClick?.(pidList);
            }}
          />
        </div>
      </div>

      <div style={{ height: listLayoutHeight, width: '100%',
        position: 'absolute', top: 0, left: 0, zIndex: +zIndexes.homeContent,
        pointerEvents,
      }}>
        <SwipeableViews index={swipeIndex} style={{ pointerEvents }} containerStyle={{ height: listLayoutHeight, pointerEvents }} axis="y" disabled={false} onChangeIndex={handleSwipeIndex}>
          {/* Map */}
          <div className={classes['scroll-mask']} style={{ height: listLayoutHeight }}>

          </div>
          {/* Map Result */}
          <div className={classes['map-result']} style={{ height: listLayoutHeight, pointerEvents: pointerEventsInner }}>
            <div className={classes['collapse-btn']} onClick={toggleSwipeIndex}>
              <i className="fas fa-angle-double-up"></i>
            </div>
            <div ref={listRef} className={classes['list']} style={{ height: `calc(${listLayoutHeight} - calc(${classes.mapResultMarginTop} + ${classes.collapseBtnHeight}))` }}>
              {filteredLoading ? <div className={classes['loading']}>
                <Spinner animation="grow" variant="pas-darkblue" />
              </div> : <ListView next={() => filteredNext?.(currentPidList)} hasMore={filteredHasMore}>
                {properties.map((property, i) => <ListViewItem key={property.id} overrideClassName="-no-padding">
                  <PropertyResultListItem {...property} />
                </ListViewItem>)}
              </ListView>}
            </div>
          </div>
        </SwipeableViews>
      </div>

    </div>
  </MainLayoutMobile>
}