import CALoginStateDTO from 'common/dto/CALoginStateDTO';
import { IRootState } from 'reducers';
import { ActionType, createAction, createReducer } from 'typesafe-actions';

export interface LoginState extends Partial<CALoginStateDTO> {
  token?: string;
  loggedIn: boolean;
  isLoading?: boolean;
  loginFailed?: boolean;
  deviceReady: boolean;
  deviceId?: string | null;
}

export type LoginActions = ActionType<typeof loginActions>;

export const loginActions = {
  start: createAction('Login.LoginRequested', (username: string, password: string) => ({ username, password }))(),
  startGoogleLogin: createAction('Login.GoogleLoginRequested')(),
  startAppleLogin: createAction('Login.AppleLoginRequested')(),
  startFacebookLogin: createAction('Login.FacebookLoginRequested')(),
  success: createAction('Login.Success', (newState) => newState)(),
  fail: createAction('Login.Failed')(),
  logout: createAction('Login.LogoutRequested')(),
  _loggedOut: createAction('Login.LoggedOut')(),
  deviceReady: createAction('Login.DeviceReady', (deviceId: string | null) => ({ deviceId }))(),

  register: createAction('Login.RegisterRequested', (type: string, email: string, password: string, phoneNumber: string, name: string) => ({ type, email, password, phoneNumber, name }))(),
  updatePhotoFilename: createAction('Login.UpdatePhotoFilename', (photoFilename: string | null | undefined) => ({ photoFilename }))(),
};

export const loginReducer = createReducer<LoginState, LoginActions>({ loggedIn: false, deviceReady: false })
  .handleAction(loginActions.deviceReady,
    (state, action) => ({ ...state, deviceReady: true, deviceId: action.payload.deviceId }),
  )
  .handleAction(loginActions.start,
    (state, action) => ({ ...state, loggedIn: state.loggedIn, isLoading: true, loginFailed: false }),
  )
  .handleAction(loginActions.fail,
    (state, action) => ({ ...state, isLoading: false, loggedIn: false, loginFailed: true }),
  )
  .handleAction(loginActions._loggedOut,
    (state, action) => ({
      ...state, isLoading: false, loggedIn: false, loginFailed: false, token: undefined,
      id: undefined,
      username: undefined,
      email: undefined,
      phoneNumber: undefined,
      name: undefined,
      district: undefined,
      salutation: undefined,
      receiveNotification: undefined,
      receiveEmail: undefined,
      subscribeNewsletter: undefined,
      photoFilename: undefined,
    }),
  )
  .handleAction(loginActions.success,
    (state, action) => ({ ...state, isLoading: false, loggedIn: true, loginFailed: false, ...action.payload }),
  )
  .handleAction(loginActions.updatePhotoFilename,
    (state, action) => ({ ...state, photoFilename: action.payload.photoFilename ?? '' }),
  )
  ;

export const loginSelectors = {
  selectDeviceId: () => (state: IRootState) => state.login.deviceId,
  loggedIn: () => (state: IRootState) => state.login.loggedIn,
};