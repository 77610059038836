import { ApiPageResult, ApiResult } from 'api';
import CANewsDetailDTO from 'common/dto/CANewsDetailDTO';
import CANewsListItemDTO from 'common/dto/CANewsListItemDTO';
import { objectToQuery } from 'common/utils/utils';

const BASE_URL = process.env['REACT_APP_PAS_BASE_URL'];

export const newsApi = {

	getPage: async (criteria: object, token: string): Promise<ApiPageResult<CANewsListItemDTO>> => {
		try {
			const response = await fetch(`${BASE_URL}/ca/news?${objectToQuery(criteria)}`, {
				headers: { 'Content-Type': 'application/json' },
			});
			return await response.json();
		} catch (e) {
			return { error: String(e) };
		}  
	},

	getFooterList: async (criteria: object, token: string): Promise<ApiResult<CANewsListItemDTO[]>> => {
		try {
			const response = await fetch(`${BASE_URL}/ca/news/footer-list?${objectToQuery(criteria)}`, {
				headers: { 'Content-Type': 'application/json' },
			});
			return await response.json();
		} catch (e) {
			return { error: String(e) };
		}  
	},

	getDetail: async (id: number, token: string): Promise<ApiResult<CANewsDetailDTO>> => {
		try {
			const response = await fetch(`${BASE_URL}/ca/news/${id}`, {
				headers: { 'Content-Type': 'application/json' },
			});
			return await response.json();
		} catch (e) {
			return { error: String(e) };
		}  
	}, 
}

export default newsApi;