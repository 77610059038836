import clsx from 'clsx';
import useLocale from 'common/hooks/useLocale';
import ScreenTopBarMobile from 'components/navigation/ScreenTopBarMobile';
import ListView, { ListViewItem } from 'components/scaffold/ListView';
import MainLayoutMobile from 'components/scaffold/MainLayoutMobile';
import { BaseLayoutProps } from 'components/scaffold/MainLayoutProps';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { IRootState } from 'reducers';
import classes from './SettingScreenMobile.module.scss';
import SettingViewProps from './SettingViewProps';
import { App } from '@capacitor/app';


export interface SettingScreenMobileProps extends SettingViewProps {
  layoutProps: BaseLayoutProps;
  onBackClick?: AnyFn;
}

export default function SettingScreenMobile(props: SettingScreenMobileProps) {
  const { langSetting, locale } = useLocale();
  const languageOptions = useSelector((state: IRootState) => state.systemSettings.Language.LANGUAGE_OPTION);
  const [buildVersion, setBuildVersion] = useState<string>('');
  useEffect(() => { App.getInfo().then(info => setBuildVersion(info.version)) }, []);

  const items = [
    {
      caption: langSetting.captionAbout,
      icon: 'fas fa-info-circle',
      action: props.onAboutClick,
      chevron: true,
    },
    {
      caption: langSetting.captionLanguage,
      icon: 'fas fa-language',
      action: props.onLanguageClick,
      chevron: true,
      chevronText: languageOptions[locale],
    },
    {
      caption: langSetting.captionTermsAndConditions,
      icon: 'fas fa-list-ol',
      action: props.onTermsAndConditionsClick,
      chevron: true,
    },
    {
      caption: langSetting.captionPrivacy,
      icon: 'fas fa-lock',
      action: props.onPrivacyClick,
      chevron: true,
    },
    {
      caption: langSetting.captionVersion,
      icon: 'fas fa-cube',
      action: props.onVersionClick,
      chevron: false,
      chevronText: buildVersion,
    },
  ];

  return <MainLayoutMobile {...props.layoutProps}>
    <div className={classes['root']}>
      <ScreenTopBarMobile onBackClick={props.onBackClick} shadow>
        <div className={classes['title']}>{langSetting.titleSettings}</div>
      </ScreenTopBarMobile>

      <div className={classes['list-container']}>
        <ListView>
          {items.map(item => <ListViewItem key={item.icon} overrideClassName={classes['item-container']}>
            <div className={clsx(classes['item'])} onClick={item.action}>
              <div className={classes['icon']}>
                <i className={item.icon}></i>
                &nbsp;&nbsp;
                <span className={classes['caption']}>{item.caption}</span>
              </div>
              <div className={classes['right']}>
                <span className={clsx(classes['chevron-text'])}>{item.chevronText}</span>
                {item.chevron ? <i className={clsx(classes['chevron'], "fas fa-chevron-right")}></i> : null}
                {/* {item.actionBtn} */}
              </div>
            </div>
          </ListViewItem>)}
        </ListView>
      </div>
    </div>
  </MainLayoutMobile>
}