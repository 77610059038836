import 'common/general-imports';
import useLocale from 'common/hooks/useLocale';
import ModalDialog from 'components/overlay/ModalDialog';
import React from 'react';
import Button from 'react-bootstrap/esm/Button';
import RegisterLayout from './RegisterLayout';
import classes from './RegisterScreenDesktop.module.scss';
import { RegisterViewProps, RegistrationMoreInfoViewProps } from './RegisterViewProps';
import RegistrationMoreInfo from './RegistrationMoreInfo';

export interface RegisterScreenDesktopProps {
  open: boolean;
  onHide: AnyFn;
  step: number;
  registerViewProps: RegisterViewProps;
  registerMoreInfoViewProps: RegistrationMoreInfoViewProps;
}

export default function RegisterScreenDesktop(props: RegisterScreenDesktopProps) {
  const { open, onHide, step, registerViewProps, registerMoreInfoViewProps } = props;

  const { lang, langRegister } = useLocale();

  return <ModalDialog title={langRegister.title} show={open} onHide={onHide}>
    { step === 0 ? <div className={classes['register-layout']}>
        <RegisterLayout {...registerViewProps} /> 
      </div>
      : <div className={classes['register-more-info-layout']}>
        <RegistrationMoreInfo {...registerMoreInfoViewProps} />
        <div className={classes['skip-btn-container']}>
          <Button className={classes['skip-btn']} variant="link"  onClick={registerMoreInfoViewProps.onSkipClick}>
            {lang.actionSkip}
          </Button>
        </div>
      </div>
    }
  </ModalDialog>
}