import useLocale from 'common/hooks/useLocale';
import LoginScreenDesktop from 'components/login/LoginScreenDesktop';
import { BaseLayoutProps } from 'components/scaffold/MainLayoutProps';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from 'reducers';
import { useCommonFormReducer } from 'reducers/common/form';
import { forgotPasswordActions } from 'reducers/forgot-password';
import { loginActions } from 'reducers/login';
import { loginFormActions, loginFormValidations } from 'reducers/login-form';
import { registerDialogActions } from 'reducers/register-dialog';

export default function GlobalLoginDialog() {
  const dispatch = useDispatch();
  const { langLogin } = useLocale();
  const { dialogOpened } = useSelector((state: IRootState) => state.loginForm);
  const { loginFailed } = useSelector((state: IRootState) => state.login);

  useEffect(() => {
    if (loginFailed) {
      dispatch(loginFormActions.clearInput());
    }
  }, [loginFailed]);


  const form = useCommonFormReducer(
    (state: IRootState) => state.loginForm,
    loginFormActions.edit,
    loginFormActions.addFormError,
    loginFormValidations(langLogin)
  );

  return <LoginScreenDesktop 
    open={dialogOpened}
    onHide={() => dispatch(loginFormActions.reset())}
    loginViewProps={{
      layoutProps: {} as BaseLayoutProps,
      bindEmail: form.field('email'),
      bindPassword: form.field('password'),
      onForgotPasswordClick: () => {
        dispatch(loginFormActions.reset());
        dispatch(forgotPasswordActions.openDialog());
      },
      onFacebookLoginClick: () => dispatch(loginActions.startFacebookLogin()),
      onGoogleLoginClick: () => dispatch(loginActions.startGoogleLogin()),
      onLoginClick: async () => {
        const result = await form.validateAll();
        if (result) {
          dispatch(loginActions.start(form.field('email')?.value ?? '', form.field('password')?.value ?? ''));
        } else {
          dispatch(loginFormActions.clearInput());
        }
      },
      onRegisterClick: () => {
        dispatch(loginFormActions.reset());
        dispatch(registerDialogActions.open());
      },
      onAppleLoginClick: () => {
        dispatch(loginActions.startAppleLogin());
      },
    }}
  />
}