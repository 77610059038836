import CANotificationDTO from 'common/dto/CANotificationDTO';
import { ActionType, createAction, createReducer } from 'typesafe-actions';

export interface NotificationState {
	totalCount: number;
	notifications: CANotificationDTO[],
	hasMore: boolean;
	
	criteria: {
		limit: number;
		page: number;
	}

	//Desktop only
	overlayViewOpened: boolean;
};

const notificationInitialState = (): NotificationState => ({
	totalCount: 0,
	notifications: [],
	hasMore: false,
	criteria: {
    limit: 10,
    page: 0,
	},
	overlayViewOpened: false
});

export const notificationActions = {
	fetch: createAction('NotificationList.FetchRequested')<{ isSwitchingPage: boolean }>(),
	update: createAction('NotificationList.Updated')<{ notifications: CANotificationDTO[], hasMore: boolean}>(),
	appendNextPage: createAction('NotificationList.AppendNextPage')<{ notifications: CANotificationDTO[], hasMore: boolean }>(),
	updateCriteria: createAction('NotificationList.CriteriaUpdated')<Partial<NotificationState['criteria']>>(),
	updateTotalCount: createAction('NotificationList.TotalCount.Updated')<{ totalCount: number }>(),
	read: createAction('Notification.ReadRequested')<{ id: number }>(),
	openOverlayView: createAction('NotificationList.OverlayView.Open')(),
	closeOverlayView: createAction('NotificationList.OverlayView.Close')(),
	confirmClientClaimProcess: createAction('Notification.ConfirmClientClaimProcess')<{ nid: number, cid: number }>(),
	_onNotificationReceiveEvent: createAction('Notification.NotificationReceiveEvent')(),
};

export type NotificationActions = ActionType<typeof notificationActions>;

export const notificationReducer = createReducer<NotificationState, NotificationActions>(notificationInitialState())
  .handleAction(notificationActions.fetch, (state, { payload }) => ({
    ...state,
    notifications: payload.isSwitchingPage ? state.notifications : [],
    hasMore: payload.isSwitchingPage ? state.hasMore : true,
	}))
	.handleAction(notificationActions.appendNextPage, (state, { payload }) => ({
    ...state, notifications: [ ...state.notifications, ...payload.notifications ], hasMore: payload.hasMore,
  }))
  .handleAction(notificationActions.update, (state, { payload }) => ({
    ...state, notifications: payload.notifications, hasMore: payload.hasMore,
	}))
	.handleAction(notificationActions.updateCriteria, (state, { payload }) => ({
    ...state, criteria: { ...state.criteria, ...payload },
	}))
	.handleAction(notificationActions.updateTotalCount, (state, { payload }) => ({
		...state, totalCount: payload.totalCount
	}))
	.handleAction(notificationActions.read, (state, { payload }) => ({
		...state, 
		notifications: state.notifications.map(n => n.id === payload.id ? { ...n, isRead: true } : n),
		totalCount: state.totalCount > 0 ? state.totalCount - 1 : 0
	}))
	.handleAction(notificationActions.openOverlayView, (state, _) => ({
		...state, overlayViewOpened: true
	}))
	.handleAction(notificationActions.closeOverlayView, (state, _) => ({
		...state, overlayViewOpened: false
	}))
;