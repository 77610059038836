import clsx from 'clsx';
import useLayoutProps from 'common/hooks/useLayoutProps';
import useLocale from 'common/hooks/useLocale';
import { useMobileLayoutHeight } from 'common/hooks/useMobileLayoutHeight';
import { registerTouchableClassNames } from 'common/polyfills/touch-polyfill';
import { isMobileUI, printPasswordComplexityRequirement, useQuery } from 'common/utils/utils';
import React, { useEffect } from 'react';
import Button from 'react-bootstrap/esm/Button';
import Container from 'react-bootstrap/esm/Container';
import Form from 'react-bootstrap/esm/Form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { IRootState } from 'reducers';
import { useCommonFormReducer } from 'reducers/common/form';
import { layoutActions } from 'reducers/layout';
import { resetPasswordActions, resetPasswordSelectors } from 'reducers/reset-password';
import { resetPasswordFormActions, resetPasswordFormValidations } from 'reducers/reset-password-form';
import FormTextField from '../../components/form-controls/FormTextField';
import ClientScreenTopBar from '../../components/navigation/ClientScreenTopBar';
import MainLayoutMobile from '../../components/scaffold/MainLayoutMobile';
import classes from './ResetPasswordLayout.module.scss';

const EMPTY = '';

registerTouchableClassNames(
	classes['cofirm-btn']
)

const ResetPasswordPage = () => {
  const dispatch = useDispatch();
  const layoutProps = useLayoutProps();
	const history = useHistory();
	const location = useLocation();
	const { lang, langLogin, langRegister } = useLocale();
	const stage: string = useSelector(resetPasswordSelectors.selectStage());
  const { MIN_LENGTH, MIN_LENGTH_UPPER, MIN_LENGTH_LOWER, MIN_LENGTH_NUM, MIN_LENGTH_SPECIAL } = useSelector((state: IRootState) =>
    state.systemSettings?.ClientApp?.PASSWORD) ?? {};

	const token = useQuery(location).get('key') ?? EMPTY;

	const mobileLayoutHeight = useMobileLayoutHeight();

	const pwRequirement = printPasswordComplexityRequirement(langRegister, MIN_LENGTH, MIN_LENGTH_UPPER, MIN_LENGTH_LOWER, MIN_LENGTH_NUM, MIN_LENGTH_SPECIAL);
  
	useEffect(() => {
		dispatch(resetPasswordActions.verifyTokenRequested(token));
	}, []);

	useEffect(() => {
		if (stage === 'linkExpired') {
			dispatch(layoutActions.alert(langLogin.msgResetPasswordTokenExpiry, 'severity'));
			history.replace('/home');
		}
	}, [ stage ]);

	const form = useCommonFormReducer(
    (state: IRootState) => state.resetPasswordForm,
    resetPasswordFormActions.edit,
    resetPasswordFormActions.addFormError,
    resetPasswordFormValidations(langLogin)
	);

	const formLayout = () => {
		return <Container className={clsx({ [classes['inner']]: true, [classes['desktop-inner']]: false })}>
			<FormTextField label={langLogin.captionPassword} bind={form.field('password')} type={'password'}/>
			<FormTextField label={langLogin.captionRePassword} bind={form.field('passwordConfirm')} type={'password'}/>
			<Form.Text>{pwRequirement}</Form.Text>
			<div className={classes['confirm-btn-container']}>
				<Button 
					className={classes['confirm-btn']} 
					variant="outline-pas-darkblue" 
					onClick={
						async () => {
							const result = await form.validateAll();
							if (result) {
								dispatch(resetPasswordActions.doRequest(token, form.field('password').value ?? ''))	
							} else {
							  dispatch(resetPasswordFormActions.clearInput());
							}
						}}
						
				>
					{lang.actionConfirm}
				</Button>
			</div>
		</Container>
	}

	return isMobileUI() ? <MainLayoutMobile {...layoutProps}> 
		<div className={classes['reset-password']} style={{ height: mobileLayoutHeight }}>
		<ClientScreenTopBar title={langLogin.titleResetPassword} hideBackBtn={true}/>
			{formLayout()}
		</div>
	</MainLayoutMobile> :
	<div className={classes['reset-password']}>
		<div className={classes['title-container']}>
			<div className={classes['title']}>{langLogin.titleResetPassword}</div>
		</div>
		{formLayout()}
	</div>
}

export default ResetPasswordPage;