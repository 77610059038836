import clsx from 'clsx';
import React from 'react';
import classes from './TermsAndConditions.module.scss';

export interface TermsAndConditionsProps {
  items: { title: string; content: string; }[];
  noShadow?: boolean;
}

export default function TermsAndConditions(props: TermsAndConditionsProps) {
  const { items, noShadow } = props;

  return <div className={clsx( classes['tc-card'], noShadow && classes['no-shadow'])} >
    {
      items.map(item => <div className={classes['item']}>
        <div className={classes['title']}>{item.title}</div>
        <div className={classes['content']} dangerouslySetInnerHTML={{
          __html: item.content
        }}></div>
      </div>)
    }
  </div>
}
