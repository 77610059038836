import 'common/general-imports';
import React from 'react';
import { FormBinder } from './IFormBinder';
import classes from './RatingStarsField.module.scss';

export interface RatingStarsFieldProps {
	bind: FormBinder<number>;
	maxRating: number;
	minRating: number;
}

export default function RatingStarsField({ bind, maxRating, minRating }: RatingStarsFieldProps) {

	return <div className={classes['rating-stars-field']}>
		<div className={classes['rating-stars']}>
		{
			[...Array(maxRating).keys()].map((v: number, i: number) => <button onClick={() => bind.change(i + 1)}
			>
				{(bind?.value ?? 0) > i ? <i className={"fas fa-star"}></i> : <i className={"far fa-star"}></i>}
			</button>)
		}
		</div>
		<div className="text-danger">{bind.errorMessage}</div>
	</div>
}