import clsx from 'clsx';
import useLocale from 'common/hooks/useLocale';
import { isNonEmpty } from 'common/utils/utils';
import { ProfileImage } from 'components/misc/ProfileImage';
import React from 'react';
import classes from './AgentCommentItem.module.scss';


export interface AgentCommentItemProps {
  name?: string;
  image?: string;
  comment: string;
  smallScreen?: boolean;
}

export default function AgentCommentItem(props: AgentCommentItemProps) {
  const { name, image, comment, smallScreen } = props;

  const { langAgent } = useLocale();

  const sizeClass = smallScreen ? classes['full-width'] : classes['small-size'];


  return <div className={classes['customer-comment-item']}>
    <div className={classes['image']}>
      <ProfileImage src={image} width={'5rem'} />
    </div> 
    <div className={classes['comment-section']}>
      <div className={clsx([classes['comment'], sizeClass,])}>{comment}</div>
      <div className={clsx([classes['name'], sizeClass])}>-{isNonEmpty(name) ? ` ${langAgent.captionCustomer} ${name}` : ` ${langAgent.captionAnonymousCustomer}`}</div>
    </div>
  </div>
}