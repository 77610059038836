import useLocale from 'common/hooks/useLocale';
import ModalDialog from 'components/overlay/ModalDialog';
import { useMediaQuery } from 'react-responsive';
import breakpoints from 'common/theme/breakpoints.module.scss';
import classes from './AgentDistrictDialog.module.scss';
import React from 'react';


interface AgentDistrictDialogProps {
    allDistricts: string;
    open?: boolean;
    onHide?: () => void;
}

export default function AgentDistrictDialog(props: AgentDistrictDialogProps) {
    const { allDistricts, open, onHide } = props;
    const { langHome } = useLocale();
    const isMobile = useMediaQuery({ maxWidth: breakpoints.sm });
    const modalSize = isMobile ? 'sm' : undefined;

    return <ModalDialog size={modalSize} title={langHome.titleAgentDistrict}
        show={open} onHide={onHide}>
        <div style={{ padding: '24px' }}>{allDistricts}</div>
    </ModalDialog>

}