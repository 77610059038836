import { registerTouchableClassNames } from 'common/polyfills/touch-polyfill';
import Swipeout from 'rc-swipeout';
import 'rc-swipeout/assets/index.css';
import React from 'react';
import classes from './SavedSearchListItem.module.scss';


export interface SavedSearchListItemProps {
  name: string;
  date: string;

  onDelete?: AnyFn;
  onClick?: AnyFn;
}

registerTouchableClassNames(
  classes['root'],
);

export default function SavedSearchListItem(props: SavedSearchListItemProps) {
  const { name, date, onDelete, onClick } = props;

  return <Swipeout right={[
      {
        text: <i className="fas fa-trash-alt" style={{ padding: '0 1.5rem' }}></i>,
        onPress: onDelete,
        style: { color: 'var(--white)', backgroundColor: 'var(--danger)' },
      }
    ]}
    autoClose
    // onOpen={() => console.log('open')}
    // onClose={() => console.log('close')}
  >
    <div className={classes['root']} onClick={onClick}>
      <div className={classes['name']}>{name}</div>
      <div className={classes['date']}>{date}</div>    
    </div>
  </Swipeout> ;
}