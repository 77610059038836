import useLocale from 'common/hooks/useLocale';
import AgentCard from 'components/display-cards/AgentCard';
import ListView, { ListViewItem } from 'components/scaffold/ListView';
import MainLayoutMobile from 'components/scaffold/MainLayoutMobile';
import React from 'react';
import classes from './AgentListViewMobile.module.css';
import { AgentListViewProps } from './AgentListViewProps';
import DistrictFilter from './DistrictFilter';


export default function AgentListViewMobile(props: AgentListViewProps) {
  const { regionBundles, agents, bindDistricts, onRequestNextPage, onRefresh, hasMore, onBackClick,
    doDisplayDetail, doDisplayContact, doDisplayRatingForm,
  ...rest } = props;

  const { langAgent, locale } = useLocale();
  
  return <MainLayoutMobile {...rest}>
    <div className={classes['agent-list-container']}>
      {/* {searchBar} */}
      <DistrictFilter bindDistricts={bindDistricts} regionBundles={regionBundles} onBackClick={onBackClick} />
      {
        agents.length > 0 ? 
        <ListView next={onRequestNextPage} hasMore={hasMore} refresh={onRefresh}>
        {
          agents.map(agent => {
            return <ListViewItem key={agent?.id ?? ''}>
              <AgentCard
                id={agent?.id ?? 0}
                image={agent?.photoFilename ? `${agent.photoFilename}` : ''}
                // By design, display both languages here.
                chineseName={`${agent?.chineseName ?? ''}`}
                englishName={agent?.englishName ?? ''}
                // name={multiLang(locale, agent?.chineseName, agent?.englishName) ?? ''}
                maxRating={agent?.maxRating ?? 0}
                rating={agent?.rating ?? 0}
                comment={agent?.lastComment}
                onClickMore={() => doDisplayDetail?.(agent?.id ?? 0)}
                onClickContact={() => doDisplayContact?.(agent?.id ?? 0)}
                onClickRating={() => doDisplayRatingForm?.(agent?.id ?? 0)}
              />
            </ListViewItem>
          })
        }
        </ListView> : <div className={classes['empty-msg']}>
          <div className={classes['msg']}>{langAgent.msgNoRecord}</div>
        </div>
      }
    </div>
  </MainLayoutMobile>;
}