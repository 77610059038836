import clsx from 'clsx';
import useLocale from 'common/hooks/useLocale';
import ScreenTopBarMobile from 'components/navigation/ScreenTopBarMobile';
import ListView, { ListViewItem } from 'components/scaffold/ListView';
import MainLayoutMobile from 'components/scaffold/MainLayoutMobile';
import { BaseLayoutProps } from 'components/scaffold/MainLayoutProps';
import React from 'react';
import classes from './LanguageScreenMobile.module.scss';

export interface LanguageScreenMobileProps {
  layoutProps: BaseLayoutProps;
  languageItems: { 
    key: string;
    caption: string;
    onClick?: AnyFn;
  } [];
  currentLanguage: string;
  onBackClick?: AnyFn;
}

export default function LanguageScreenMobile(props: LanguageScreenMobileProps) {
  const { layoutProps, languageItems, currentLanguage, onBackClick } = props;
  const { langSetting } = useLocale();

  return <MainLayoutMobile {...layoutProps}>
    <div className={classes['root']}>
      <ScreenTopBarMobile onBackClick={onBackClick} shadow>
        <div className={classes['title']}>{langSetting.captionLanguage}</div>
      </ScreenTopBarMobile>

      <div className={classes['list-container']}>
        <ListView>
          {languageItems.map(item => <ListViewItem key={item.key} overrideClassName={classes['item-container']}>
            <div className={clsx(classes['item'])} onClick={item.onClick}>
              <div className={clsx({ [classes['caption']]: true, [classes['active']]: currentLanguage === item.key })}>{item.caption}</div>
              <div className={classes['icon-container']}>
                {currentLanguage === item.key && <i className="fas fa-check-circle" />}
              </div>
            </div>
          </ListViewItem>)}
        </ListView>
      </div>
    </div>
  </MainLayoutMobile>
}