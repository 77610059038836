import SystemSettingDTO, { SystemSettingMap } from 'common/dto/SystemSettingDTO';

export type SystemSettingActions =
  { type: 'SystemSetting.FetchRequested' } | 
  { type: 'SystemSetting.Loaded', payload: { data: SystemSettingDTO[] } };

export function systemSettingReducer(state: SystemSettingMap = {}, action: SystemSettingActions): SystemSettingMap {
  switch (action.type) {
    // case 'Global.ClearCacheRequested':
    //   return {};
    case 'SystemSetting.Loaded':
      const newMap: SystemSettingMap = {};
      action.payload.data.forEach(masterData => {
        const moduleName = masterData.module ?? '';
        const typeName = masterData.type ?? '';
        const key = masterData.key ?? '';
        const value = masterData.value ?? '';
        if (!newMap[moduleName]) {
          newMap[moduleName] = {};
        }
        if (!newMap[moduleName][typeName]) {
          newMap[moduleName][typeName] = {};
        }
        newMap[moduleName][typeName][key] = value;

        if (masterData.seq) newMap[moduleName][typeName]['seq'] = masterData.seq.toString();
      });
      return newMap;
    default:
      return state;
  }
}