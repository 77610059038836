import { useMobileLayoutHeight } from 'common/hooks/useMobileLayoutHeight';
import ScreenTopBarMobile from 'components/navigation/ScreenTopBarMobile';
import React from 'react';
import classes from './InfoScreenMobile.module.scss';


export interface InfoScreenMobileProps {
  title: string;
  children: { [key: string]: React.ReactNode };
  onBackClick?: AnyFn;
}

export default function InfoScreenMobile(props: InfoScreenMobileProps) {
  const { title, children, onBackClick } = props;

  const mobileLayoutHeight = useMobileLayoutHeight();

  return <div className={classes['root']} style={{ height: mobileLayoutHeight }}>
    <ScreenTopBarMobile shadow onBackClick={onBackClick}>
      <div className={classes['title']}>{title}</div>
    </ScreenTopBarMobile>

    <div className={classes['body']}>
      {
        Object.entries(children).map(([key, value]) => <div key={key} className={classes['item']}>{value}</div>)
      }
    </div>
  </div>
}