import { createContext, useEffect, useMemo, useState } from 'react';

export const ScrollContext = createContext({
  scrollTop: 0,
});

export const useCreateScrollContext = (scrollRef: React.MutableRefObject<HTMLElement | null>) => {
  const [ context, setContext ] = useState({ scrollTop: 0 });
  useEffect(() => {
    if (!scrollRef.current) {
      return;
    }

    scrollRef.current!.onscroll = () => {
      setContext({ scrollTop: scrollRef.current!.scrollTop });
    };
  });

  return context;
}

export const useWindowScrollContext = () => {
  const [ context, setContext ] = useState({ scrollTop: 0 });
  const onScroll = useMemo(() => () => {
    setContext({ scrollTop: window.scrollY });
  }, [ setContext ]);

  useEffect(() => {
    window.addEventListener('scroll', onScroll);
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, [ onScroll ]);

  return context;
}
  