import { Capacitor } from '@capacitor/core';
import { useLayoutEffect, useMemo, useState } from 'react';
import StatusBarIOSPlugin from 'StatusBarIOSPlugin';


const statusBarHeightChange = 'status-bar-height-change';

let _lastHeight = 0;

export const currentStatusBarHeight = () => _lastHeight;

async function pollStatusBarHeight() {
  const { height } = await StatusBarIOSPlugin.getStatusBarHeight();
  if (height !== _lastHeight) {
    window.dispatchEvent(new CustomEvent(statusBarHeightChange, { detail: height }));
    _lastHeight = Number(height);
  }
  requestAnimationFrame(pollStatusBarHeight);
}

if (Capacitor.isNativePlatform() && StatusBarIOSPlugin) {
  requestAnimationFrame(pollStatusBarHeight);
}

export default function useNativeStatusBarHeight() {

  const [ height, setHeight ] = useState(_lastHeight);

  const listener = useMemo(() => (event: CustomEvent) => {
    setHeight(event.detail as number);
    _lastHeight = Number(event.detail);
  }, [ setHeight ]);

  useLayoutEffect(() => {
    (window as any).addEventListener(statusBarHeightChange, listener);

    return () => {
      (window as any).removeEventListener(statusBarHeightChange, listener);
    };
  }, [ listener ]);

  return height;
}