import useLocale from 'common/hooks/useLocale';
import { registerTouchableClassNames } from 'common/polyfills/touch-polyfill';
import FormTextField from 'components/form-controls/FormTextField';
import DistrictSelectDialog from 'components/overlay/DistrictSelectDialog';
import React, { useMemo, useState } from 'react';
import Button from 'react-bootstrap/esm/Button';
import Form from 'react-bootstrap/esm/Form';
import { isNullOrUndefined } from 'util';
import { RegistrationMoreInfoViewProps } from './RegisterViewProps';
import classes from './RegistrationMoreInfo.module.scss';


registerTouchableClassNames(
	classes['select-district-row'],
	classes['save-btn'],
)
export interface RegistrationMoreInfoProps extends RegistrationMoreInfoViewProps {}

export default function RegistrationMoreInfo(props: RegistrationMoreInfoProps) {
  const { bindDistrict, bindPhoneNumber, bindReceiveEmail, bindSubscribeNewsletter,
    onSaveClick, onSkipClick } = props;
  
  const { lang, langClientProfile, locale } = useLocale();
  const regionBundles = useLocale();


  const allDistrictOptions = useMemo(() => {
    return {
      ...regionBundles.districtHkiOptions,
      ...regionBundles.districtKltOptions,
      ...regionBundles.districtNtOptions,
    };
  }, [ regionBundles ]);

  const [ districtDialogOpen, setDistrictDialogOpen ] = useState(false);

  return <div className={classes['main-container']} >
    <DistrictSelectDialog {...regionBundles} open={districtDialogOpen} onHide={() => setDistrictDialogOpen(false)} districts={bindDistrict} />
    {/* ------------ phoneNumber ------------ */}
    <div className={classes['form-label']}>
      {langClientProfile.captionPhoneNumber}
    </div>
    <FormTextField label={langClientProfile.captionPhoneNumber} bind={bindPhoneNumber}/>

    {/* ------------ preferenceSetting ------------ */}
    <div className={classes['form-label']}>
      {langClientProfile.captionPreferenceSetting}
    </div>

    <ul className="list-group preference-setting-container">
      {/* ---------- district ---------*/}
      <li 
        className="list-group-item preference-setting select-district-row"
        onClick={()=>{setDistrictDialogOpen(true)}}
      >
        <div className={classes['preference-setting-caption']} style={{ fontSize: locale === 'en' ? '0.85rem' : undefined }}>
          {langClientProfile.captionSearchDistrict}
        </div>
        
          <div className={classes['preference-setting-action']}>
            <div className={classes['selected-district-text']}>
              {bindDistrict?.value?.map(key=> allDistrictOptions[key])?.join(' / ')}
            </div>
            <div>
              <i className={"fas fa-chevron-right " + classes['search-district-icon']}></i>
            </div>
          </div>
          
      </li>
      {/* ---------- receiveEmail ---------*/}
      <li className="list-group-item preference-setting">
        <div className={classes['preference-setting-caption']}>
          {langClientProfile.captionReceiveEmail}
        </div>
        <div className={classes['preference-setting-action']}>
          <Form>
            <Form.Check
              type="switch"
              id="receive-email-switch"
              key="receive-email-switch"
              label=""
              checked={bindReceiveEmail?.value ?? false}
              onChange={() => {
                if (isNullOrUndefined(bindReceiveEmail)) {
                  return;
                } else {
                  bindReceiveEmail.change(!bindReceiveEmail?.value)
                }
              }}
            />
          </Form>
        </div>
      </li>
      {/* ---------- subscribeNewsletter ---------*/}
      <li className="list-group-item preference-setting">
        <div className={classes['preference-setting-caption']}>
          {langClientProfile.captionSubscribeNewsletter}
        </div>
        <div className={classes['preference-setting-action']}>
          <Form>
            <Form.Check
              type="switch"
              id="subscribe-news-letter-switch"
              key="subscribe-news-letter-switch"
              label=""
              checked={bindSubscribeNewsletter?.value ?? false}
              onChange={() => {
                if (isNullOrUndefined(bindSubscribeNewsletter)) {
                  return;
                } else {
                  bindSubscribeNewsletter.change(!bindSubscribeNewsletter?.value)
                }
              }}
            />
          </Form>
        </div>
      </li>
    </ul>

    <div className={classes['save-btn-container']}>
      <Button className={classes['save-btn']} variant="outline-pas-darkblue" onClick={onSaveClick}>
        {lang.actionSave}
      </Button>
    </div>

  </div>
}