import { ApiResult, BASE_URL } from "api";

export const fileApi = {
  async addFile(file: File, token: string, addWatermark = false): Promise<ApiResult<string>> {
    return fetch(`${BASE_URL}/files/CA/${file.name}?addWatermark=${addWatermark}`, {
      method: 'POST',
      body: file,
      headers: {
        'Content-Type': 'application/octet-stream',
        'Authorization': token,
      },
    })
      .then(res => res.json())
      .catch(err => ({ error: String(err) }));
  },
};

export default fileApi;