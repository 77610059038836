import clsx from 'clsx';
import useLocale from 'common/hooks/useLocale';
import { useMobileLayoutHeight } from 'common/hooks/useMobileLayoutHeight';
import { registerTouchableClassNames } from 'common/polyfills/touch-polyfill';
import FormTextField from 'components/form-controls/FormTextField';
import ClientScreenTopBar from 'components/navigation/ClientScreenTopBar';
import DistrictSelectDialog from 'components/overlay/DistrictSelectDialog';
import MainLayoutMobile from 'components/scaffold/MainLayoutMobile';
import { ScrollContext, useCreateScrollContext } from 'components/scaffold/ScrollContext';
import React, { useMemo, useRef, useState } from 'react';
import Button from 'react-bootstrap/esm/Button';
import Form from 'react-bootstrap/esm/Form';
import { useHistory } from 'react-router';
import { isNullOrUndefined } from 'util';
import classes from './CustomerProfileScreenLayoutMobile.module.scss';
import { CustomerProfileViewProps } from './CustomerProfileViewProps';



registerTouchableClassNames(
  classes['select-district-row'],
  classes['change-pw-button'],
  classes['logout-button'],
  classes['noti-btn-container']
)

export default function CustomerProfileScreenLayoutMobile(props: CustomerProfileViewProps) {
  const { layoutProps, regionBundles, userSalutationOptions,
    email, bindPhoneNumber, bindName, bindSalutation, bindDistrict, bindReceiveEmail, bindSubscribeNewsletter,
    onBackClick, onSaveClick, onChangePasswordClick, onLogoutClick, onConfirmUploadPhoto, onDeletePhoto, photoImgSrc,
    notificationTotalCount, supportChangePassword, unsaved
  } = props;
  const mobileLayoutHeight = useMobileLayoutHeight();

  const history = useHistory();
  const allDistrictOptions = useMemo(() => {
    return {
      ...regionBundles.districtHkiOptions,
      ...regionBundles.districtKltOptions,
      ...regionBundles.districtNtOptions,
    };
  }, [regionBundles]);

  const { locale, langClientProfile, lang } = useLocale();
  const [districtDialogOpen, setDistrictDialogOpen] = useState(false);
  const handleDistrictDialogClose = () => setDistrictDialogOpen(false);

  // Scroll Context
  const scrollRef = useRef<HTMLDivElement>(null);
  const scrollContext = useCreateScrollContext(scrollRef);

  return <MainLayoutMobile {...layoutProps}>
    <DistrictSelectDialog {...regionBundles} open={districtDialogOpen} onHide={handleDistrictDialogClose} districts={bindDistrict} />
    <ScrollContext.Provider value={scrollContext}><div className={classes['client-profile-screen']} ref={scrollRef}>
      <div
        className={classes['inner']} style={{ height: mobileLayoutHeight }}
      >

        <div>
          <ClientScreenTopBar
            title={bindName?.value ?? ''}
            onBackClick={onBackClick}
            onSaveClick={onSaveClick}
            unsaved={unsaved}
            allowPhotoUpload={true}
            onConfirmUploadPhoto={onConfirmUploadPhoto}
            onDeletePhoto={onDeletePhoto}
            photoImgSrc={photoImgSrc}
          />
        </div>

        <div className={classes['client-detail-container']} >
          <div className={classes['noti-section']}>
            <div className={classes['noti-btn-container']} onClick={() => history.push('/notifications')}>
              <div className={classes['noti-icon']}>
                <i className="far fa-envelope" />
              </div>
              {notificationTotalCount > 0 && <div className={classes['noti-reminder']}>{notificationTotalCount > 99 ? "99+" : notificationTotalCount}</div>}
            </div>
          </div>

          <div>
            {/* ------------ email ------------ */}
            <div className={classes['form-label']}>
              {langClientProfile.captionEmail}
            </div>
            <div className={classes['email-container']}>
              {email}

            </div>
          </div>




          {/* ------------ phoneNumber ------------ */}
          <div className={classes['form-label']}>
            {langClientProfile.captionPhoneNumber}
          </div>
          <FormTextField label={langClientProfile.captionPhoneNumber} bind={bindPhoneNumber} />

          {/* ------------ name ------------ */}
          <div className={classes['form-label']}>
            {langClientProfile.captionName}
          </div>
          <FormTextField label={langClientProfile.captionName} bind={bindName} />

          <div className={clsx(classes['flex-container'], classes['vertical-center'])}>
            <div>
              {/* ------------ salutation ------------ */}
              <div className={classes['form-label']}>
                {langClientProfile.captionSalutation}
              </div>
              <Form>
                <div className={classes['salutation-container']} key={`salutations`} >
                  {Object.keys(userSalutationOptions).map(key => (

                    <Form.Check inline
                      label={userSalutationOptions[key]}
                      type='radio'
                      key={`salutation-${key}`}
                      id={`salutation-${key}`}
                      checked={bindSalutation?.value === key}
                      onChange={() => {
                        if (isNullOrUndefined(bindSalutation)) {
                          return;
                        } else {
                          bindSalutation.change(key)
                        }
                      }}
                    />
                  ))}
                  {/* <Form.Check inline label="先生" type='radio' id={`inline-radio-1`} />
                  <Form.Check inline label="小姐" type='radio' id={`inline-radio-2`} /> */}
                </div>
              </Form>
            </div>

            {supportChangePassword ? <Button className={classes['change-pw-button']} variant="outline-pas-darkblue" onClick={onChangePasswordClick} style={{ fontSize: locale === 'en' ? '0.8rem' : undefined }}>
              {langClientProfile.actionChangePassword}
            </Button> : null}
          </div>

          {/* ------------ preferenceSetting ------------ */}
          <div className={classes['form-label']}>
            {langClientProfile.captionPreferenceSetting}
          </div>

          <ul className="list-group preference-setting-container">
            {/* ---------- district ---------*/}
            <li
              className="list-group-item preference-setting select-district-row"
              onClick={() => { setDistrictDialogOpen(true) }}
            >
              <div className={classes['preference-setting-caption']} style={{ fontSize: locale === 'en' ? '0.85rem' : undefined }}>
                {langClientProfile.captionSearchDistrict}
              </div>

              <div className={classes['preference-setting-action']}>
                <div className={classes['selected-district-text']}>
                  {bindDistrict?.value?.map(key => allDistrictOptions[key])?.join(' / ')}
                </div>
                <div>
                  <i className={"fas fa-chevron-right " + classes['search-district-icon']}></i>
                </div>
              </div>

            </li>
            {/* ---------- receiveEmail ---------*/}
            <li className="list-group-item preference-setting">
              <div className={classes['preference-setting-caption']}>
                {langClientProfile.captionReceiveEmail}
              </div>
              <div className={classes['preference-setting-action']}>
                <Form>
                  <Form.Check
                    type="switch"
                    id="receive-email-switch"
                    key="receive-email-switch"
                    label=""
                    checked={bindReceiveEmail?.value ?? false}
                    onChange={() => {
                      if (isNullOrUndefined(bindReceiveEmail)) {
                        return;
                      } else {
                        bindReceiveEmail.change(!bindReceiveEmail?.value)
                      }
                    }}
                  />
                </Form>
              </div>
            </li>
            {/* ---------- subscribeNewsletter ---------*/}
            <li className="list-group-item preference-setting">
              <div className={classes['preference-setting-caption']}>
                {langClientProfile.captionSubscribeNewsletter}
              </div>
              <div className={classes['preference-setting-action']}>
                <Form>
                  <Form.Check
                    type="switch"
                    id="subscribe-news-letter-switch"
                    key="subscribe-news-letter-switch"
                    label=""
                    checked={bindSubscribeNewsletter?.value ?? false}
                    onChange={() => {
                      if (isNullOrUndefined(bindSubscribeNewsletter)) {
                        return;
                      } else {
                        bindSubscribeNewsletter.change(!bindSubscribeNewsletter?.value)
                      }
                    }}
                  />
                </Form>
              </div>
            </li>

          </ul>


        </div>

        {/* ---------- change password ---------- */}
        <div className={classes['change-pw-button-container']}>

          <Button className={classes['logout-button']} variant="outline-danger" onClick={onLogoutClick}>
            {lang.actionLogout}
          </Button>
        </div>

        {/* <div className={classes['change-pw-button-container']}>
          
        </div> */}

      </div>
    </div></ScrollContext.Provider>
  </MainLayoutMobile>
}