import { BASE_URL, SHARE_BASE_URL } from 'api';
import useComponentDidMount from 'common/hooks/useComponentDidMount';
import useLayoutProps from 'common/hooks/useLayoutProps';
import useLocale from 'common/hooks/useLocale';
import usePageTitle from 'common/hooks/usePageTitle';
import { jumpers, useJump } from 'common/theme/jumper';
import { systemShare, systemShareAvailable } from 'common/utils/share-api';
import { isMobileUI, isNonEmpty, multiLang } from 'common/utils/utils';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { IRootState } from 'reducers';
import { agentContactActions } from 'reducers/agent-contact';
import { bookmarkListActions } from 'reducers/bookmark-list';
import { enquiryDialogActions } from 'reducers/enquiry-dialog';
import { fullTransactionListActions } from 'reducers/full-transaction-list';
import { loginSelectors } from 'reducers/login';
import { propertyStockDetailActions } from 'reducers/property-detail';
import { format } from 'util';
import PropertyDetailScreenDesktop from '../../components/property/detail/PropertyDetailScreenDesktop';
import PropertyDetailScreenMobile from '../../components/property/detail/PropertyDetailScreenMobile';
import GlobalInlinePropertyListDialog from './GlobalInlinePropertyListingDialog';

const EMPTY = {};

const PropertyDetailPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const jump = useJump();
  const { propertyNo } = useParams<any>();
  const layoutProps = useLayoutProps();
  const { districtHkiOptions, districtKltOptions, districtNtOptions,
    levelOptions, locale, clubHouseFacilitiesOptions, otherFeatureOptions,
    otherOptions, primarySchoolNetOptions, secondarySchoolNetOptions,
    facingOptions, decoOptions, viewOptions,
    lang, langProperty } = useLocale();

  const districtOptions = { ...districtHkiOptions, ...districtKltOptions, ...districtNtOptions };

  const { currentPropertyStock, notFound } = useSelector((state: IRootState) => state.propertyDetail) ?? EMPTY;
  const loggedIn = useSelector(loginSelectors.loggedIn());
  const { email, name, phoneNumber } = useSelector((state: IRootState) => state.login);

  const DISPLAY_DATE_FORMAT = useSelector((state: IRootState) => state.systemSettings.System?.SYSTEM_PARAM?.DISPLAY_DATE_FORMAT);
  const SITECORE_GOAL_PROPERTY_VISIT = useSelector((state: IRootState) => state.systemSettings.ClientApp?.SITECORE_GOAL?.PROPERTY_VISIT) ?? 'Hit';
  const SITECORE_GOAL_KEY_PROPERTY_ID = useSelector((state: IRootState) => state.systemSettings.ClientApp?.SITECORE_GOAL_KEY?.PROPERTY_ID) ?? 'propertyId';

  const fullTransactionList = useSelector((state: IRootState) => state.fullTransactionList);

  useEffect(() => {

    dispatch(propertyStockDetailActions.doFetch({ propertyNo: propertyNo }));

  }, []);

  useEffect(() => {
    if (loggedIn && propertyNo && currentPropertyStock.propertyNo) {
      dispatch(fullTransactionListActions.reset());
      dispatch(fullTransactionListActions.doFetchTransactions({
        propertyNo, page: 0, sort: 'transactionDate', direction: 'DESC',
      }));
    }
  }, [currentPropertyStock, propertyNo, loggedIn]);

  useEffect(() => {
    if (Number(currentPropertyStock.id) !== Number(0)) {
      dispatch(bookmarkListActions.visitBookmark({ caPropertyStockId: currentPropertyStock.id }));
      // window.SCBeacon?.trackGoal(SITECORE_GOAL_PROPERTY_VISIT, {
      //   data: currentPropertyStock.propertyNo,
      //   dataKey: SITECORE_GOAL_KEY_PROPERTY_ID,
      //   xDistrict: currentPropertyStock.district,
      //   xPrice: currentPropertyStock.price,
      //   xRent: currentPropertyStock.rent,
      //   xSize: currentPropertyStock.net,
      // });
    }
  }, [currentPropertyStock.id ?? Number(0)]);

  useComponentDidMount(() => {
    document.documentElement.scrollTo({ top: 0 });
  });

  const roomSuiteValDisplay = (room: number, suite: number) => {
    let roomDisplay = '';

    switch (room) {
      case 0:
      case null:
        roomDisplay = langProperty.captionOpenType;
        break;
      case 1:
        roomDisplay = room + ' ' + langProperty.captionRoom;
        break;
      default:
        roomDisplay = room + ' ' + langProperty.captionRooms;
        break;
    }

    let suiteDisplay = '';

    switch (suite) {
      case 0:
      case null:
        break;
      case 1:
        suiteDisplay = '(' + suite + ' ' + langProperty.captionSuite + ')';
        break;
      default:
        suiteDisplay = '(' + suite + ' ' + langProperty.captionSuites + ')';
        break;
    }

    return roomDisplay + ' ' + suiteDisplay;
  }

  const mainFeatureMap = [
    {
      show: isNonEmpty(currentPropertyStock.facing),
      type: langProperty.captionFacing,
      value: facingOptions[currentPropertyStock?.facing!] ?? 'N/A',
    },
    {
      show: isNonEmpty(currentPropertyStock.deco),
      type: langProperty.captionDeco,
      value: decoOptions[currentPropertyStock?.deco!] ?? 'N/A'
    },
    ...currentPropertyStock.view.map((v: string, i: number) => ({
      show: true,
      type: `${langProperty.captionView} ${currentPropertyStock.view.length > 1 ? i + 1 : ''}`,
      value: viewOptions[v] ?? 'N/A'
    })),
    {
      show: true,
      type: langProperty.captionRoom,
      value: roomSuiteValDisplay(currentPropertyStock?.room ?? 0, currentPropertyStock?.suite ?? 0)
    },
    {
      show: currentPropertyStock.bathroom > 0,
      type: langProperty.captionBathroom,
      value: currentPropertyStock?.bathroom?.toString() ?? '0'
    },
    {
      show: currentPropertyStock.helperRoom > 0,
      type: langProperty.captionHelperRoom,
      value: currentPropertyStock?.helperRoom?.toString() ?? '0'
    },
    ...currentPropertyStock.balconySizes.map((v: number, i: number) => ({
      show: true,
      type: `${langProperty.captionBalcony} ${currentPropertyStock.balconySizes.length > 1 ? i + 1 : ''}`,
      value: `${v}${lang.uFt}`
    })),
    {
      show: isNonEmpty(currentPropertyStock.gardenArea),
      type: langProperty.captionGarden,
      value: currentPropertyStock?.gardenArea ? `${currentPropertyStock?.gardenArea}${lang.uFt}` : 'N/A'
    },
    {
      show: isNonEmpty(currentPropertyStock.rooftopArea),
      type: langProperty.captionRooftop,
      value: currentPropertyStock?.rooftopArea ? `${currentPropertyStock?.rooftopArea ?? 0}${lang.uFt}` : 'N/A'
    },
    {
      show: isNonEmpty(currentPropertyStock.occupancyPermitAge),
      type: langProperty.captionOccupancyPermitAge,
      value: currentPropertyStock?.occupancyPermitAge ? `${currentPropertyStock?.occupancyPermitAge - 1 ?? 0}` : 'N/A'
    },


  ];

  let schoolNetMap = [];
  if (isNonEmpty(currentPropertyStock?.primarySchoolNet)) {
    schoolNetMap.push(`${langProperty.captionPrimarySchool}: ${primarySchoolNetOptions[currentPropertyStock?.primarySchoolNet ?? '']}`);
  }
  if (isNonEmpty(currentPropertyStock?.secondarySchoolNet)) {
    schoolNetMap.push(`${langProperty.captionSecondarySchool}: ${secondarySchoolNetOptions[currentPropertyStock?.secondarySchoolNet ?? '']}`);
  }

  const title = `${multiLang(locale, currentPropertyStock?.streetZh, currentPropertyStock?.streetEn, currentPropertyStock?.streetSc) ?? ''} ${multiLang(locale, currentPropertyStock?.buildingNameZh, currentPropertyStock?.buildingNameEn, currentPropertyStock?.buildingNameSc) ?? ''} ${levelOptions[currentPropertyStock?.level] ?? ''}`;
  // const title = `${multiLang(locale, currentPropertyStock?.streetZh, currentPropertyStock?.streetEn) ?? ''} ${multiLang(locale, currentPropertyStock?.buildingNameZh, currentPropertyStock?.buildingNameEn) ?? ''} ${handleBlockDisplay(locale, multiLang(locale, currentPropertyStock?.blockZh, currentPropertyStock?.blockEn) ?? '')} ${levelOptions[currentPropertyStock?.level ?? ''] ?? ''}`;
  const shareText = useMemo(() => format(
    langProperty.msgSharingTemplate,
    title,
  ), [title, window.location.href]);

  const shareUrl = SHARE_BASE_URL + propertyNo;
  const shareTextWithUrl = encodeURIComponent(shareText + ' ' + shareUrl);

  usePageTitle(title);

  const PropertyDetailScreen = isMobileUI() ? PropertyDetailScreenMobile : PropertyDetailScreenDesktop;
  // if (!currentPropertyStock.id) {
  //   return <div />;
  // }

  // Inline Property Dialog
  const [inlinePropertyDialogProps, setInlinePropertyDialogProps] = useState({
    open: false,
    propertyNo: '',
    type: '',
    district: '',
  });


  // const randomUrl = 'https://qwert.propertymavin.com/pas-client-uat/properties/' + currentPropertyStock.id;
  return <>
    <GlobalInlinePropertyListDialog
      {...inlinePropertyDialogProps}
      onHide={() => {
        setInlinePropertyDialogProps(prev => ({
          ...prev, open: false,
        }));
      }}
    />
    <PropertyDetailScreen
      loggedIn={loggedIn}
      layoutProps={layoutProps || { activeNavigation: '/detail' }}
      photos={currentPropertyStock?.photos?.map(p => `${BASE_URL}/files/${p}`) ?? []}
      videoLink={currentPropertyStock?.videoLink}
      floorPlans={currentPropertyStock?.floorPlans?.map(p => `${BASE_URL}/files/${p}`) ?? [] ?? []}
      sharePanelProps={{
        onFacebookClick: () => {
          // window.open(`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}&t=${shareText}`);
          window.open(`https://www.facebook.com/sharer/sharer.php?u=${shareUrl}&quote=${shareTextWithUrl}`);
        },
        onWhatsAppClick: () => {
          window.open(`https://wa.me/?text=${shareTextWithUrl}`);
        },
        // onWeChatClick: () => {},
        // onInstagramClick: () => {},
        onTwitterClick: () => {
          window.open(`https://twitter.com/intent/tweet?text=${shareTextWithUrl}`);
        },
        // onSinaClick: () => {},
        onEmailClick: () => {
          window.open(`mailto:?body=${shareTextWithUrl}`);
        },
        onMoreClick: systemShareAvailable ? () => {

          systemShare(shareText, shareUrl);
        } : undefined,
      }}
      propertyDetailBasicInfo={{
        title: `${multiLang(locale, currentPropertyStock?.buildingNameZh, currentPropertyStock?.buildingNameEn, currentPropertyStock?.buildingNameSc) ?? ''} ${levelOptions[currentPropertyStock?.level] ?? ''}`,
        address: `${districtOptions[currentPropertyStock?.district ?? '']} ${multiLang(locale, currentPropertyStock?.streetZh, currentPropertyStock?.streetEn, currentPropertyStock?.streetSc) ?? ''}`,
        price: currentPropertyStock?.price,
        rent: currentPropertyStock?.rent,
        net: currentPropertyStock?.net,
        gross: currentPropertyStock?.gross,
        punchline: multiLang(locale, currentPropertyStock?.punchlineTC, currentPropertyStock?.punchlineEN, currentPropertyStock?.punchlineSC),
        propertyNo: currentPropertyStock.propertyNo,
        dateModified: currentPropertyStock.dateModified ? moment(currentPropertyStock.dateModified).format(DISPLAY_DATE_FORMAT) : '',
        bookmarked: currentPropertyStock?.bookmarked ?? false,
        onBookmarkClick: () => {
          if (!loggedIn) {
            jump(jumpers.toLogin());
            return;
          }
          currentPropertyStock?.bookmarked ?
            dispatch(propertyStockDetailActions.deleteBookmark({ caPropertyStockId: currentPropertyStock.id })) :
            dispatch(propertyStockDetailActions.addBookmark({ caPropertyStockId: currentPropertyStock.id }))
            ;
        }
      }}
      agents={currentPropertyStock?.agents?.map(a => ({ ...a, photoFilename: `${BASE_URL}/files/${a.photoFilename}` })) ?? []}
      lat={currentPropertyStock?.latitude ?? 0}
      lng={currentPropertyStock?.longitude ?? 0}
      mainFeatures={mainFeatureMap.filter(f => f.show)}
      features={currentPropertyStock?.featuresAndFacilities?.map(f => clubHouseFacilitiesOptions[f.value] || otherFeatureOptions[f.value]) ?? []}
      otherInfo={currentPropertyStock?.others?.map(f => otherOptions[f.value]) ?? []}
      schoolNet={schoolNetMap}
      transactions={currentPropertyStock?.transactionList?.map(t => ({
        ...t,
        address: `${multiLang(locale, t.fullAddressTC, t.fullAddressEN, t.fullAddressSC) ?? ''}`,
        date: t.transactionDate ? moment(t.transactionDate).format(DISPLAY_DATE_FORMAT) : ''
      })
      ) ?? []}
      transactionList={{
        transactions: fullTransactionList.transactions.map(t => ({
          ...t,
          address: `${multiLang(locale, t.fullAddressTC, t.fullAddressEN, t.fullAddressSC) ?? ''}`,
          date: t.transactionDate ? moment(t.transactionDate).format(DISPLAY_DATE_FORMAT) : ''
        })
        ),
        sorting: fullTransactionList.sort,
        direction: fullTransactionList.direction,
        onSortSelect: (sort, direction) => {
          dispatch(fullTransactionListActions.doFetchTransactions({ propertyNo, page: 0, sort, direction }));
        },
        currentPage: fullTransactionList.currentPage + 1,
        totalPages: fullTransactionList.totalPages,
        onNext: () => {
          dispatch(fullTransactionListActions.doFetchTransactions({
            propertyNo,
            page: Math.min(fullTransactionList.totalPages - 1, fullTransactionList.currentPage + 1),
            sort: fullTransactionList.sort,
            direction: fullTransactionList.direction
          }));
        },
        onPrev: () => {
          dispatch(fullTransactionListActions.doFetchTransactions({
            propertyNo,
            page: Math.max(0, fullTransactionList.currentPage - 1),
            sort: fullTransactionList.sort,
            direction: fullTransactionList.direction
          }));
        }
      }}
      onContactLeadAgentClick={() => {
        dispatch(enquiryDialogActions.edit('caPropertyStockId', currentPropertyStock.id));
        dispatch(enquiryDialogActions.edit('districts', [currentPropertyStock.district]));
        dispatch(enquiryDialogActions.openDialogForProperty());
      }}
      onAgentClick={(agentUserId) => {
        // dispatch(enquiryDialogActions.edit('agentUserId', agentUserId));
        const agent = currentPropertyStock?.agents?.filter(a => a.id === agentUserId)[0]!;
        dispatch(agentContactActions.edit('caPropertyStockId', currentPropertyStock.id));
        dispatch(agentContactActions.edit('email', email));
        dispatch(agentContactActions.edit('name', name));
        dispatch(agentContactActions.edit('contact', phoneNumber));
        dispatch(agentContactActions.openDialog({
          id: agent.id,
          chineseName: agent.chineseName,
          englishName: agent.englishName,
          maxRating: agent.maxRating || 5,
          rating: agent.rating || 0,
          image: agent.photoFilename ? `${BASE_URL}/files/${agent.photoFilename}` : '',
          entry: 'P',
        }));
      }}
      onSameBuildingSearchClick={() => {
        isMobileUI() ? history.push(`/properties/${currentPropertyStock.propertyNo}/relatives/building`) :
          setInlinePropertyDialogProps({
            open: true,
            type: 'building',
            propertyNo: currentPropertyStock.propertyNo,
            district: '',
          });
      }}
      onSameDistrictSearchClick={() => {
        isMobileUI() ? history.push(`/properties/${currentPropertyStock.propertyNo}/relatives/district/${currentPropertyStock.district}`) :
          setInlinePropertyDialogProps({
            open: true,
            type: 'district',
            propertyNo: currentPropertyStock.propertyNo,
            district: currentPropertyStock.district,
          });
      }}
      onBackClick={() => history.goBack()}
      loading={!notFound && !currentPropertyStock.id}
      notFound={notFound}
    /></>
}

export default PropertyDetailPage;