import useLocale from 'common/hooks/useLocale';
import FormTextField from 'components/form-controls/FormTextField';
import { FormBinder } from 'components/form-controls/IFormBinder';
import React from 'react';
import ModalDialog, { ModalDialogProps } from './ModalDialog';
import ModalDialogActions, { ModalDialogActionButton } from './ModalDialogActions';
import classes from './SingleTextConfirmDialog.module.scss';


export interface SingleTextConfirmDialogProps {
  title: string;
  message?: string;
  bindText?: FormBinder<string>;
  placeholder?: string;
  onConfirm?: AnyFn;

  size?: ModalDialogProps['size'];
  show?: boolean;
  onHide?: () => void;
}

export default function SingleTextConfirmDialog(props: SingleTextConfirmDialogProps) {
  const { title, message, placeholder, bindText, size, show, onHide, onConfirm } = props;
  const { lang } = useLocale();

  return <ModalDialog title={title} size={size} show={show} onHide={onHide}>
    <div>
      <div className={classes['form-row']}>
        {message ? <div className={classes['message']}>{message}</div> : null}
        <FormTextField label={placeholder ?? ''} bind={bindText} />
      </div>

      <ModalDialogActions>
        <ModalDialogActionButton onClick={onConfirm}>
          {lang.actionConfirm}
        </ModalDialogActionButton>
        <ModalDialogActionButton onClick={onHide}>
          {lang.actionCancel}
        </ModalDialogActionButton>
      </ModalDialogActions>
    </div>
  </ModalDialog>
}