import AlertConfirmDialog from "components/overlay/AlertConfirmDialog";
import React from "react";
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router';
import NavigationPrompt from "react-router-navigation-prompt";
import { IRootState } from "reducers";
interface NavigationBlockerDialogProps {
    unsaved: boolean;
    goBack?: boolean;
    matchesUrl?: string;
}

export default function NavigationBlockerDialog({ unsaved, goBack, matchesUrl }: NavigationBlockerDialogProps) {
    const env = process.env['PUBLIC_URL'];
    const { lang } = useSelector((state: IRootState) => state.locale);
    const match = useRouteMatch(matchesUrl ?? '');
    const isLoggedIn = useSelector((state: IRootState) => state.login.loggedIn);

    //bypass NavigationBlocer when logout is triggered or enter customer profile without login
    if ((matchesUrl && (!match || !match.isExact)) || !isLoggedIn) {
        return <></>;
    }

    return <NavigationPrompt
        when={unsaved
        }

        allowGoBack={goBack ?? false}
    >
        {({ onConfirm, onCancel }) => (
            <AlertConfirmDialog show={true} onHide={(confirm: boolean) => confirm ? onConfirm() : onCancel()}
                title={""} message={lang.msgUnsavedChanges} actionConfirm={lang.actionConfirm} actionCancel={lang.actionCancel}></AlertConfirmDialog>
        )}
    </NavigationPrompt>
}