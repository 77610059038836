import 'common/general-imports';
import useNativeStatusBarHeight from 'common/hooks/useNativeStatusBarHeight';
import TabBar, { tabBarHeight, TabBarIcon } from 'components/navigation/TabBar';
import React from 'react';
import classes from './MainLayoutMobile.module.css';
import MainLayoutProps, { NavigationKeys } from './MainLayoutProps';



export default function MainLayoutMobile(props: MainLayoutProps) {
  const bindActiveState = (key: NavigationKeys) => ({
    onClick: () => props.onNavigate?.(key),
    active: key === props.activeNavigation,
  });

  const statusBarHeight = useNativeStatusBarHeight();

  return <div className={classes['main-layout-mobile']} style={{ paddingTop: `0px` }}>

    <div style={{ backgroundColor: 'var(--pas-darkblue)', height: `${statusBarHeight}px`, flexShrink: 0 }}></div>

    <div style={{ height: !props.navigationHidden ? `calc(100% - ${statusBarHeight}px - ${tabBarHeight()})` : `calc(100% - ${statusBarHeight}px)` }}>
      {props.children}
    </div>

    {!props.navigationHidden && <TabBar>
      <TabBarIcon iconClassName="fas fa-cog" {...bindActiveState('/settings')} />
      <TabBarIcon iconClassName="far fa-bookmark" {...bindActiveState('/bookmarks')} />
      <TabBarIcon iconClassName={`far fa-comment-dots ${props.tabBarIconExtraClasses?.['enquiry'] ?? ''}`} {...bindActiveState('EnquiryDialog.Open')} />
      <TabBarIcon iconClassName="fas fa-home" {...bindActiveState('/home')} />
    </TabBar>}
  </div>;
}