import useLayoutProps from 'common/hooks/useLayoutProps';
// import { loginActions } from 'reducers/login';
// import { registerFormActions, registerFormValidations } from 'reducers/register-form';
import SettingScreenMobile from 'components/setting/SettingScreenMobile';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

// import { printPasswordComplexityRequirement } from 'common/utils/utils';

const SettingPage = () => {
  const dispatch = useDispatch();
  const layoutProps = useLayoutProps();
  const history = useHistory();

  return <SettingScreenMobile
    layoutProps={layoutProps}
    onAboutClick={() => history.push('/settings/about')}
    onLanguageClick={() => history.push('/settings/language')}
    onTermsAndConditionsClick={() => history.push('settings/T&C')}
    onPrivacyClick={() => history.push('/settings/privacy')}
    onQAClick={() => history.push('/settings/Q&A')}
    onBackClick={() => history.goBack()}
  />

}

export default SettingPage;