import { IRootState } from 'reducers';
import { ActionType, createAction, createReducer } from 'typesafe-actions';

export interface ResetPasswordState {
	stage: 'index' | 'success' | 'pendingForUserInput' | 'processing' | 'linkExpired';
	token?: string;
}

export type ResetPasswordActions = ActionType<typeof resetPasswordActions>;

export const resetPasswordActions = {
	doRequest: createAction('ResetPassword.ResetRequested', (token: string, newPassword: string) => ({ token, newPassword }))(),
	verifyTokenRequested: createAction('ResetPassword.VerifyTokenRequested', (token: string) => ({ token }))(),
	pendingForUserInput: createAction('ResetPassword.PendingForUserInput')(),
	success: createAction('ResetPassword.Success')(),
	processing: createAction('ResetPassword.Processing')(),
	linkExpired: createAction('ResetPassword.LinkExpired')()
}

export const resetPasswordReducer = createReducer<ResetPasswordState, ResetPasswordActions>({ stage: 'index', token: ''})
	.handleAction(resetPasswordActions.success,
		(state, _) => ({ ...state, stage: 'success' })
	)
	.handleAction(resetPasswordActions.pendingForUserInput,
		(state, _) => ({ ...state, stage: 'pendingForUserInput' })
	)
	.handleAction(resetPasswordActions.processing, 
		(state, _) => ({ ...state, stage: 'processing' })
	)
	.handleAction(resetPasswordActions.linkExpired, 
		(state, _) => ({ ...state, stage: 'linkExpired' })
	)
;

export const resetPasswordSelectors = {
	selectStage: () => (state: IRootState) => state.resetPassword.stage
};