import { checkValidEmail, checkValidPhoneNumber } from 'common/utils/utils';
import { ActionType, createAction, createReducer } from 'typesafe-actions';
import { CommonFormState, createFormSpec } from './common/form';

export interface GetInTouchForm {
  name: string;
  phone: string;
  email: string;
  districts: string[];
  message: string;
}

export interface GetInTouchState extends CommonFormState<GetInTouchForm> {

}

export const getInTouchFormSpec = createFormSpec(
  "GetInTouch.Edit",
  "GetInTouch.AddError"
)<GetInTouchForm>((langEnquiryDialog: LocaleOptions) => [
  (values) => (!values.name ? { name: langEnquiryDialog.errName } : {}),
  (values) =>
    !values.districts || !(values.districts?.length > 0)
      ? { districts: langEnquiryDialog.errDistrict }
      : {},
  (values) =>
    values.phone || values.email
      ? {}
      : {
          phone: langEnquiryDialog.errAtLeastTelOrEmail,
          email: langEnquiryDialog.errAtLeastTelOrEmail,
        },
  (values) =>
    values.phone && !checkValidPhoneNumber(values.phone)
      ? { phone: langEnquiryDialog.errContact }
      : {},
  (values) =>
    values.email && !checkValidEmail(values.email)
      ? { email: langEnquiryDialog.errEmail }
      : {},
]);

export const getInTouchActions = {
  ...getInTouchFormSpec.actions,
  reset: createAction('GetInTouch.Reset')(),
  doRequest: createAction('GetInTouch.DoRequest')(),
};

export type GetInTouchActions = ActionType<typeof getInTouchActions>;

export const initialState = (): GetInTouchState => ({
  ...getInTouchFormSpec.initialState,
  contents: {
    districts: [],
  },
});

export const getInTouchReducer = createReducer<GetInTouchState, GetInTouchActions>(initialState(), getInTouchFormSpec.reducer)
  .handleAction(getInTouchActions.reset, (state, _) => ({ 
    ...initialState(), 
    contents: {
      ...initialState().contents,
      name: state.contents.name,
      email: state.contents.email,
      phone: state.contents.phone
    } 
  }))
;