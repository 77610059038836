
import $ from 'jquery';

let currentHeight = -1;

export const getCurrentHeight = () => currentHeight;
export const subscribeHeightUpdate = (fn: (updated: number) => any) => {
  const listener = (_: Event, updated: number) => {
    fn(updated);
  };
  $('#root').on('height-update' as any, listener);

  // unsubscribe
  return () => {
    $('#root').off('height-update' as any, listener as any);
  };
};

const updateHeight = () => {
  const height = $(window).height()!;

  if (height !== currentHeight) {
    $('#root').css('height', `${height}px`);
    currentHeight = height;
    $('#root').trigger('height-update', [currentHeight]);
  }

  requestAnimationFrame(updateHeight);
};

requestAnimationFrame(updateHeight);
