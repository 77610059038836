import clsx from 'clsx';
import { registerTouchableClassNames } from 'common/polyfills/touch-polyfill';
import { FormBinder } from 'components/form-controls/IFormBinder';
import React, { useState } from 'react';
import Collapse from 'react-bootstrap/esm/Collapse';
import classes from './MultiSelectChips.module.scss';

registerTouchableClassNames(
	classes['chip'],
)

export interface MultiSelectChipsProps {
  label: string;
  options: LocaleOptions;
  bind?: FormBinder<string[]>;
  collapsible?: boolean;
  initialExpand?: boolean;
}

export default function MultiSelectChips({ label, options, bind, collapsible, initialExpand }: MultiSelectChipsProps) {
  const [ isCollapsed, setCollapsed ] = useState(!initialExpand);

  return  <div id='multi-select-chips' className={classes['multi-select-chips']}>
    <div className={classes['label']}
      style={{ cursor: collapsible ? 'pointer' : undefined }}
      onClick={collapsible ? (() => setCollapsed(!isCollapsed)) : undefined}
    >
      {label} {collapsible ? <i className={clsx("fas fa-chevron-down", isCollapsed && classes['collapsed-icon'])}></i> : null}
    </div>
    <Collapse in={collapsible ? !isCollapsed : true} unmountOnExit><div className={classes['chips-container']}>
    {
      Object.keys(options)?.map((key: string) => <div>
        <button
          className={clsx({ [classes['chip']]: true, [classes['selected']]: bind?.value?.includes(key)})}
          onClick={() => {
            const currValue = bind?.value ?? [];
            if (currValue.includes(key)) {
              bind?.change(currValue.filter(v => v !== key));
            } else {
              bind?.change([ ...currValue, key]);
            }
          }}
        >
        {options[key]}
      </button></div>)
    }
    </div></Collapse>
  </div>
}