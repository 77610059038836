import clsx from 'clsx';
import useLocale from 'common/hooks/useLocale';
import { registerTouchableClassNames } from 'common/polyfills/touch-polyfill';
import breakpoints from 'common/theme/breakpoints.module.scss';
import { handlePriceDisplay, handlePriceDisplayUnit, handlePriceLength, isNonEmpty, numberWithCommas } from 'common/utils/utils';
import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { isNullOrUndefined } from 'util';
import classes from './PropertyDetailBasicInfo.module.scss';

registerTouchableClassNames(
  classes['bookmark'],
)

export interface PropertyDetailBasicInfoProps {
  title: string;
  address: string;
  /** Note: Raw price, NOT price display */
  price?: number;
  rent?: number;
  gross?: number;
  net?: number;
  punchline?: string;
  propertyNo?: string;
  dateModified?: string;

  bookmarked?: boolean;
  onBookmarkClick?: AnyFn;

  isDesktop?: boolean;
}

export default function PropertyDetailBasicInfo(props: PropertyDetailBasicInfoProps) {
  const { title, address, price, rent, gross, net, punchline, propertyNo, dateModified,
    bookmarked, onBookmarkClick, isDesktop } = props;

  const { lang: langCommon, langProperty, locale } = useLocale();

  const isSmScreen = useMediaQuery({ maxWidth: breakpoints.sm })

  const grossDisplay = () => {
    const grossDisplay = !(gross) ? '' : (langProperty.grossAbbr + '     ' + String(gross) + langCommon.uFt);

    return isNonEmpty(grossDisplay) ? <div className={classes['gross']}>{grossDisplay}</div> : null;
  }

  const netDisplay = () => {
    const netDisplay = !(net) ? '' : (langProperty.netAbbr + '     ' + String(net) + langCommon.uFt);

    return isNonEmpty(netDisplay) ? <div className={classes['net']}>{netDisplay}</div> : null;
  }

  const displayType = !isDesktop ? classes['app-view'] : isSmScreen ? classes['small-desktop-view'] : classes['large-desktop-view'];

  const priceSection = () => {
    const priceDisplay = (isNullOrUndefined(price) || !isNullOrUndefined(price) && price === 0) ? '' :
      numberWithCommas(handlePriceLength(handlePriceDisplay(price ?? 0, locale))) + handlePriceDisplayUnit(price, locale, langCommon);

    const area = net || gross || NaN;
    const unit = net ? langProperty.captionPerNet : langProperty.captionPerGross;
    const priceAreaDisplay = ((price || NaN) / area).toFixed(0);

    return isNonEmpty(priceDisplay) ? <div>
      <div className={classes['price-section']}>
        <div className={classes['logo']} >{langProperty.sale}</div>
        <div className={classes['price']}>{`${langCommon.uCurr}${priceDisplay}`}</div>
      </div>
      {priceAreaDisplay ? <div className={clsx(classes['per-square-display'], displayType)}>@ {langCommon.uCurr}{numberWithCommas(priceAreaDisplay)} / {unit}</div> : null}
    </div> : null;
  }

  const rentSection = () => {
    const rentDisplay = (isNullOrUndefined(rent) || !isNullOrUndefined(rent) && rent === 0) ? '' : numberWithCommas(rent);

    const area = net || gross || NaN;
    const unit = net ? langProperty.captionPerNet : langProperty.captionPerGross;
    const netAreaDisplay = ((rent || 0) / area).toFixed(0);

    return isNonEmpty(rentDisplay) ? <div>
      <div className={classes['rent-section']}>
        <div className={classes['logo']}>{langProperty.rent}</div>
        <div className={classes['rent']}>{`${langCommon.uCurr}${rentDisplay}`}</div>
      </div>
      {netAreaDisplay ? <div className={clsx(classes['per-square-display'], displayType)}>@ {langCommon.uCurr}{numberWithCommas(netAreaDisplay)} / {unit}</div> : null}
    </div> : null;
  }

  // const tmpPunchline = "haha haha haha haha haha haha haha haha haha haha haha haha haha haha haha haha haha haha haha haha haha haha haha haha ";



  return <div className={classes['property-detail-basic-info']}>
    <div className={classes['main']}>
      <div className={classes['title']}>{title}</div>
      <div className={classes['address']}>
        <i className="fas fa-map-marker-alt" />
        <div style={{ paddingLeft: '3px' }}>{address}</div>
      </div>
      <div className={classes['price-rent']}>
        {priceSection()}
        {rentSection()}
      </div>
      <div className={classes['area']}>
        {netDisplay()}
        {grossDisplay()}
      </div>
      <div className={classes['punchline']} dangerouslySetInnerHTML={{
        __html: punchline?.replace('\n', '<br>') ?? '',
      }}></div>
      <div className={clsx(classes['other-info-container'], isDesktop && classes['desktop'])}>
        <div className={clsx(classes['info-item'], displayType)}>{`${langProperty.captionPropertyNo}: ${propertyNo}`}</div>
        <div className={clsx(classes['info-item'], displayType)}>{`${langProperty.captionDateModified}: ${dateModified}`}</div>
      </div>
    </div>
    <div className={classes['bookmark']}>
      {
        <img
          src={bookmarked ? "images/bookmark-active.png" : "images/bookmark-inactive.png"}
          onClick={(evt) => {
            evt.stopPropagation();
            onBookmarkClick?.()
          }
          }
        // src={"images/Capture.png"}
        />
      }
    </div>
  </div>
}