import { ApiPageResult, ApiResult, BASE_URL } from 'api';
import CABuildingListItemDTO, { CABuildingSearchDTO } from 'common/dto/CABuildingListItemDTO';
import CAHistoryListItemDTO from 'common/dto/CAHistoryListItemDTO';
import CAHomeDTO from 'common/dto/CAHomeDTO';
import { objectToQuery } from 'common/utils/utils';

export const homeApi = {

  getHome: async (displayLanguage: string, token: string): Promise<ApiPageResult<CAHomeDTO>> => {
    try {
      const response = await fetch(`${BASE_URL}/ca/home?displayLanguage=${displayLanguage}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        }
      });
      return await response.json();
    } catch (e) {
      return { error: String(e) };
    }
  },

  getBuildings: async (criteria: CABuildingSearchDTO): Promise<ApiResult<CABuildingListItemDTO[]>> => {
    try {
      const response = await fetch(`${BASE_URL}/ca/home/buildings?${objectToQuery(criteria)}`, {
        method: 'GET',
        headers: {
        //   'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        }
      });
      return await response.json();
    } catch (e) {
      return { error: String(e) };
    }
  },

  getSearchHistory: async (token: string): Promise<ApiResult<CAHistoryListItemDTO[]>> => {
    try {
      const response = await fetch(`${BASE_URL}/ca/home/search-history`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        }
      });
      return await response.json();
    } catch (e) {
      return { error: String(e) };
    }
  },
};