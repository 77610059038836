import useLocale from 'common/hooks/useLocale';
import FormTextField from 'components/form-controls/FormTextField';
import { FormBinder } from 'components/form-controls/IFormBinder';
import ModalDialog from 'components/overlay/ModalDialog';
import ModalDialogActions, { ModalDialogActionButton } from 'components/overlay/ModalDialogActions';
import React from 'react';
import classes from './SaveSearchDialog.module.scss';


export interface SaveSearchDialogProps {
  bindTitle?: FormBinder<string>;
  onSave?: AnyFn;

  // size?: ModalDialogProps['size'];
  show?: boolean;
  onHide?: () => void;
}

export default function SaveSearchDialog(props: SaveSearchDialogProps) {
  const { bindTitle, onSave, show, onHide } = props;
  const { lang, langProperty } = useLocale();

  return <ModalDialog title={langProperty.titleSaveSearch} size='sm' show={show} onHide={onHide}>
    <div>
      <div className={classes['message']}>{langProperty.msgPleaseEnterTitle}</div>
      <div className={classes['form-row']}>
        <FormTextField label={langProperty.captionTitle} bind={bindTitle} />
      </div>

      <ModalDialogActions>
        <ModalDialogActionButton onClick={() => {
          onSave?.();
        }}>
          {lang.actionSave}
        </ModalDialogActionButton>
      </ModalDialogActions>
    </div>
  </ModalDialog>
}