import { FacebookLogin } from '@capacitor-community/facebook-login';
import { Capacitor, registerPlugin } from '@capacitor/core';
import { PushNotifications } from '@capacitor/push-notifications';
import { StatusBar, Style as StatusBarStyle } from '@capacitor/status-bar';
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { notificationActions } from 'reducers/notification';
import StatusBarIOSPlugin from 'StatusBarIOSPlugin';
import { browserHistory, store } from 'store';
import App from './App';
import './index.css';
import * as serviceWorker from './serviceWorker';

GoogleAuth.initialize();
const BASE_URL = process.env['REACT_APP_PAS_BASE_URL'];
if (Capacitor.isNativePlatform()) {
  StatusBarIOSPlugin.disableScale?.();
  PushNotifications.requestPermissions().then(result => {
    if (result.receive === 'granted') {
      // Register with Apple / Google to receive push via APNS/FCM
      PushNotifications.register();
      PushNotifications.createChannel({
        id: 'default',
        name: 'Default',
        importance: 3,
        vibration: true,
      });
    } else {
      // Show some error
      console.error("Error: cannot register to FCM")
    }
  });

  PushNotifications.addListener('registration', (token) => {
    //console.info('pnToken', token.value);
  });

  PushNotifications.addListener('pushNotificationReceived', () => {
    store.dispatch(notificationActions._onNotificationReceiveEvent());
  });

  PushNotifications.addListener('pushNotificationActionPerformed', (payload) => {
    // console.info('pushNotificationActionPerformed', payload);
    if (payload.actionId === 'tap') {
      // Handle tap
      const module = payload.notification.data['com.propertymavin.client.app.MODULE'];
      const link = payload.notification.data['com.propertymavin.client.app.LINK'];

      switch (module) {
        case 'NEW_PROPERTY':
          browserHistory.push(`/properties/${link}`);
          break;
        default:
          browserHistory.push(`/notifications`);
          break;
      }
    }
  });
} else {
  const appleScript = document.createElement('script');
  document.body.appendChild(appleScript);
  appleScript.onload = () => {
    window.AppleID?.auth.init({
      clientId: 'com.propertymavin.client',
      scope: 'name email',
      redirectURI: `${BASE_URL}/ca/apple-signin-return`,
      state: 'init',
      nonce: 'property mavin nonce',
      usePopup: true //or false defaults to false
    });
  };
  appleScript.src = 'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js';
  appleScript.type = 'application/javascript';

}

console.log('signin3', `'${BASE_URL}/ca/apple-signin-return'`)
// Scroll handling
window.history.scrollRestoration = 'manual';

// Facebook Init
//registerWebPlugin(FacebookLogin as any);
registerPlugin('FacebookLogin', FacebookLogin);

// Status Bar Settings
if (Capacitor.isNativePlatform()) {
  StatusBar.setStyle({ style: StatusBarStyle.Dark });
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
