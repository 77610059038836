import clsx from 'clsx';
import useLocale from 'common/hooks/useLocale';
import { registerTouchableClassNames } from 'common/polyfills/touch-polyfill';
import { FormBinder } from 'components/form-controls/IFormBinder';
import React from 'react';
import Button from 'react-bootstrap/esm/Button';
import ButtonGroup from 'react-bootstrap/esm/ButtonGroup';
import Form from 'react-bootstrap/Form';
import classes from "./YesNoOptions.module.scss";

registerTouchableClassNames(
  classes['yes-no-option-btn'],
)

export interface YesNoOptionsProps {
  label: string;
  yesLabel: string;
  noLabel: string;
  bind?: FormBinder<boolean>;
  bindEnabled?: FormBinder<boolean>;
}

export default function YesNoOptions({ label, yesLabel, noLabel, bind, bindEnabled}: YesNoOptionsProps) {
  const { lang } = useLocale();

  return <div className={classes['container']} onClick={() => {return;}}>
    {/* <div>{label}</div> */}
    <Form.Group controlId="formBasicCheckbox">
      <Form.Check>
        <Form.Check.Input
          checked={bindEnabled?.value ?? false}
          onChange={()=>{
            bindEnabled?.change(!bindEnabled?.value);
          }}
        />
        <Form.Check.Label>{label}</Form.Check.Label>
      </Form.Check>
    </Form.Group>
    <ButtonGroup className={classes['yes-no-option-group-btn']}>
      <Button
        className={clsx(classes['yes-no-option-btn'], classes['left'])}
        variant={bind?.value === true ? "pas-gray" : "outline-pas-gray"}
        onClick={() => {  
          bindEnabled?.change(true)
          bind?.change(true)
        }}> {yesLabel}
      </Button>
      <Button
        className={clsx(classes['yes-no-option-btn'], classes['right'])}
        variant={bind?.value === false ? "pas-gray" : "outline-pas-gray"}
        onClick={() => {
          bindEnabled?.change(true)
          bind?.change(false)
        }}>{noLabel}
      </Button>

    </ButtonGroup>
  </div>
   
}