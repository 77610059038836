import useLocale from 'common/hooks/useLocale';
import { FormBinder } from 'components/form-controls/IFormBinder';
import RangeInput from 'components/form-controls/RangeInput';
import React from 'react';
import Form from 'react-bootstrap/Form';
import classes from "./LargeSlider.module.scss";

export interface LargeSliderProps {
  label: string;
  unit: string;
  min: number;
  max: number;
  step: number;
  bind?: FormBinder<number[]>;
  valueMapping?: number[];
}

export default function LargeSlider(props: LargeSliderProps) {
  const { label, min, max, step, bind, unit, valueMapping } = props;
  
  const { lang } = useLocale();

  return <div className={classes['large-slider']}>
    <div className={classes['label']}>{label}</div>
    <div className={classes['range-display']}>
      <Form.Control disabled className={classes['price-display']} value={bind?.value?.[0]}/>
      {/* <div className={classes['price-display']}>{bind?.value?.[0]}</div> */}
      <div><i className="fas fa-minus" style={{ fontSize: '0.8rem' }}></i></div>
      <Form.Control disabled className={classes['price-display']} value={bind?.value?.[1] !== Infinity ? bind?.value?.[1] : lang.captionUnlimited}/>
      {/* <div className={classes['price-display']}>{bind?.value?.[1]}</div> */}
      <div>{unit}</div>
    </div>
    <div className={classes['range-slider-container']}>
      <div className={classes['range-slider']}>
        <RangeInput bind={bind} min={min} max={max} step={step} unlimited valueMapping={valueMapping}></RangeInput>
      </div>
    </div>
  </div>
}