import { ApiPageResult, ApiResult } from 'api';
import CANotificationDTO from 'common/dto/CANotificationDTO';
import { objectToQuery } from 'common/utils/utils';

const BASE_URL = process.env['REACT_APP_PAS_BASE_URL'];

export const notificationApi = {
	getTotalCount: async (token: string): Promise<ApiResult<number>> => {
		try {
			const response = await fetch(`${BASE_URL}/ca/notifications/total-count`, {
				headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
			});
			return await response.json();
		} catch (e) {
			return { error: String(e) };
		}  
	},

	getList: async (criteria: object, token: string): Promise<ApiPageResult<CANotificationDTO>> => {
		try {
			const response = await fetch(`${BASE_URL}/ca/notifications/list?${objectToQuery(criteria)}`, {
				headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
			});
			return await response.json();
		} catch (e) {
			return { error: String(e) };
		}  
	},

	read: async (id: number, token: string): Promise<ApiResult<number>> => {
		try {
			const response = await fetch(`${BASE_URL}/ca/notifications/${id}/read`, {
				method: 'PATCH',
				headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
			});
			return await response.json();
		} catch (e) {
			return { error: String(e) };
		}  
	},

	confirmClientClaimProcess: async (clientId: number | string, token: string): Promise<ApiResult<number>> => {
		try {
			const response = await fetch(`${BASE_URL}/ca/agents/confirm-claim-process?cid=${clientId}`, {
				method: 'POST',
				headers: { 'Authorization': `Bearer ${token}` },
			});
			return await response.json();
		} catch (e) {
			return { error: String(e) };
		}  
	},
	// getPage: async (criteria: any = {}, token: string): Promise<ApiPageResult<CANotificationDTO>> => {
	// 	try {
	// 		const response = await fetch(`${BASE_URL}/notifications?${objectToQuery(criteria)}`, {
	// 			headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
	// 		});
	// 		return await response.json();
	// 	} catch (e) {
	// 		return { error: String(e) };
	// 	}  
	// },

	// doneAction: async (notification: CANotificationDTO, token: string): Promise<ApiResult<any>> => {
	// 	try {
	// 		const response = await fetch(`${BASE_URL}/notifications/done`, {
	// 			method: 'PUT', body: JSON.stringify(notification),
	// 			headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
	// 		});
	// 		return await response.json();
	// 	} catch (e) {
	// 		return { error: String(e) };
	// 	}  
	// },

	// deleteAction: async (notification: CANotificationDTO, token: string): Promise<ApiResult<any>> => {
	// 	try {
	// 		const response = await fetch(`${BASE_URL}/notifications/delete`, {
	// 			method: 'PUT', body: JSON.stringify(notification),
	// 			headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
	// 		});
	// 		return await response.json();
	// 	} catch (e) {
	// 		return { error: String(e) };
	// 	}  
	// }, 

}

export default notificationApi;