import useLocale from 'common/hooks/useLocale';
import { lineBreakString, isNonEmpty } from 'common/utils/utils';
import RatingStars from 'components/data-display/RatingStars';
import DialogAgentView, { DialogAgentViewProps } from 'components/display-cards/DialogAgentView';
import ListView, { ListViewItem } from 'components/scaffold/ListView';
import React from 'react';
import Button from 'react-bootstrap/esm/Button';
import classes from './AgentDetailDialog.module.scss';
import ModalDialog from './ModalDialog';
import { useMediaQuery } from 'react-responsive';
import breakpoints from 'common/theme/breakpoints.module.scss';



export interface AgentDetailDialogProps {
  open: boolean;
  onHide?: AnyFn;
  agentViewProps: DialogAgentViewProps;
  comments: { id: number | string, comment: string, rating: number, name: string, isAnonymous: boolean }[];

  onContactAgentClick?: AnyFn;
}

export default function AgentDetailDialog(props: AgentDetailDialogProps) {
  const { open, onHide, agentViewProps, comments, onContactAgentClick } = props;
  const { langAgent } = useLocale();

  const isMobile = useMediaQuery({ maxWidth: breakpoints.sm });
  const modalSize = isMobile ? 'sm' : undefined;

  return <ModalDialog size={modalSize} title={langAgent.titleAgentDetail} show={open} onHide={onHide}>
    <div className={classes['agent-detail-dialog']}>
      <DialogAgentView {...agentViewProps} />

      <div className={classes['comment-title-bar']}>
        <div className={classes['title']}>
          {langAgent.titleAgentComments}
        </div>
        <Button onClick={onContactAgentClick} variant="pas-green">
          {langAgent.actionContactAgent}
        </Button>
      </div>
      <div className={classes['comment-list']}>
        {
          comments.length > 0 ?
          <ListView>
            {comments.map(comment => 
              <ListViewItem key={comment.id} overrideClassName={classes['comment-container']}>
                <div>
                  <div className={classes['name-rating']}>
                    <div className={classes['name']}>
                      {`${(comment.isAnonymous || !isNonEmpty(comment.name)) ? langAgent.captionAnonymousCustomer : langAgent.captionCustomer + comment.name}`}
                    </div>
                    <div className={classes['rating']}>
                      <RatingStars maxRating={agentViewProps.maxRating} rating={comment.rating} color={'#FCB004'} />
                    </div>
                  </div>
                  <div className={classes['comment']}>{lineBreakString(comment.comment)}</div>
                  {/* <div className={classes['comment']}>{lineBreakString(comment.comment)}</div>
                  <div className={classes['name']}>
                    {`${comment.isAnonymous ? langAgent.captionAnonymousCustomer : langAgent.captionCustomer} ${comment.name}`}
                  </div> */}
                </div>
              </ListViewItem>
            )}
          </ListView> : 
          <div className={classes['empty-msg']}>
            <div className={classes['msg']}>{langAgent.msgNoComment}</div>
          </div>
        }
       
      </div>
    </div>
  </ModalDialog>
}