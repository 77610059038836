import CACustomerDTO from 'common/dto/CACustomerDTO';
import useLayoutProps from 'common/hooks/useLayoutProps';
import useLocale from 'common/hooks/useLocale';
import RegistrationMoreInfoScreenMobile from 'components/register/RegistrationMoreInfoScreenMobile';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { IRootState } from 'reducers';
import { useCommonFormReducer } from 'reducers/common/form';
import { customerActions, customerValidations } from 'reducers/customer';


const RegisterMoreInfoPage = () => {
  const dispatch = useDispatch();
  const layoutProps = useLayoutProps();
  const history = useHistory();
  const { langClientProfile } = useLocale();

  useEffect(() => {
    dispatch(customerActions.doFetchCustomer());
  }, [])

  const form = useCommonFormReducer(
    (state: IRootState) => state.customer,
    customerActions.edit,
    customerActions.addFormError,
    customerValidations(langClientProfile)
  );

  const customerProfileFormValues = useSelector((state: IRootState) => state.customer.contents);
  
  return <RegistrationMoreInfoScreenMobile
    layoutProps={layoutProps}
    name={form.field('name').value ?? ''}
    bindPhoneNumber={form.field('phoneNumber')}
    bindDistrict={form.field('districts')}
    bindReceiveEmail={form.field('receiveEmail')}
    bindSubscribeNewsletter={form.field('subscribeNewsletter')}
    onSaveClick={() => {
      dispatch(customerActions.doUpdateCustomer({
        ...customerProfileFormValues,
      } as CACustomerDTO));
      history.replace('/home');
    }}
    onSkipClick={() => history.replace('/home')}
  />

}

export default RegisterMoreInfoPage;