import CAAgentRatingCommentDTO from 'common/dto/CAAgentRatingCommentDTO';
import { checkValidEmail, checkValidPhoneNumber } from 'common/utils/utils';
import { ActionType, createAction, createReducer } from 'typesafe-actions';
import { CommonFormState, createFormSpec } from './common/form';

export interface AgentContactInfo {
  id: number;
  image: string;
  chineseName: string;
  englishName: string;
  maxRating: number;
  rating: number;
  entry: 'D' | 'P';
  districts?: string;
}

export interface ContactForm {
  name: string;
  contact: string;
  email: string;
  caPropertyStockId?: number;
}

export interface AgentContactState extends CommonFormState<ContactForm> {
  open: boolean;
  comments: CAAgentRatingCommentDTO[];
  currentAgent: AgentContactInfo;
}

export const agentContactFormSpec = createFormSpec(
  "AgentContact.Edit",
  "AgentContact.AddFormError",
  { disableValidateOnBlur: true }
)<ContactForm>((langEnquiry: LocaleOptions) => [
  (values) =>
    values.contact && !checkValidPhoneNumber(values.contact)
      ? { contact: langEnquiry.errContact }
      : {},
  (values) =>
    values.email && !checkValidEmail(values.email)
      ? { email: langEnquiry.errEmail }
      : {},
  (values) =>
    values.contact || values.email
      ? {}
      : {
          contact: langEnquiry.errAtLeastTelOrEmail,
          email: langEnquiry.errAtLeastTelOrEmail,
        },
  (values) => (!values.name ? { name: langEnquiry.errName } : {}),
]);

export const agentContactActions = {
  ...agentContactFormSpec.actions,
  openDialog: createAction('AgentContact.Open')<AgentContactInfo>(),
  closeDialog: createAction('AgentContact.Close')(),
  reset: createAction('AgentContact.Reset')(),
  doContact: createAction('AgentContact.DoContact')(),
};

export type AgentContactActions = ActionType<typeof agentContactActions>;

const initialState = (): AgentContactState => ({
  ...agentContactFormSpec.initialState,
  open: false,
  comments: [],
  currentAgent: {
    id: 0,
    image: '',
    chineseName: '',
    englishName: '',
    rating: 0,
    maxRating: 0,
    entry: 'D',
    districts: '',
  }
});


export const agentContactReducer = createReducer<AgentContactState, AgentContactActions>(initialState(), agentContactFormSpec.reducer)
  .handleAction(agentContactActions.openDialog, (state, action) => 
    ({ ...state, open: true, currentAgent: action.payload })
  )
  .handleAction(agentContactActions.closeDialog, (state) => 
    ({ ...state, open: false })
  )
  .handleAction(agentContactActions.reset, (_) => 
    initialState()
  )
;
