import useLocale from 'common/hooks/useLocale';
import { registerTouchableClassNames } from 'common/polyfills/touch-polyfill';
import ScreenTopBarMobile from 'components/navigation/ScreenTopBarMobile';
import { ScrollContext } from 'components/scaffold/ScrollContext';
import React, { useContext, useRef, useState } from 'react';
import ClientAvatar from './ClientAvatar';
import classes from './ClientScreenTopBar.module.scss';

export interface ClientScreenTopBarProps {
	title: string;

	/** Note: Do not display save button when onSave is null. */
	onSaveClick?: AnyFn;
	onSkipClick?: AnyFn;
	onBackClick?: AnyFn;
	hideBackBtn?: boolean;

	//** for handling customer profile */
	allowPhotoUpload?: boolean; //use to show profile picture and the photo upload actions
	photoImgSrc?: string;
	onConfirmUploadPhoto?: (output: Blob) => void; //onDone action in the image cropper
	onDeletePhoto?: AnyFn;
	unsaved?: boolean;
}

registerTouchableClassNames(
	classes['save-btn'],
	classes['photo-buttons'],
);

export default function ClientScreenTopBar(props: ClientScreenTopBarProps) {
	const { title, onSaveClick, onSkipClick, onBackClick, hideBackBtn, allowPhotoUpload, onConfirmUploadPhoto, photoImgSrc, onDeletePhoto, unsaved } = props;

	const { lang, langClientProfile } = useLocale();

	const layoutContext = useContext(ScrollContext);
	const shouldShowTopBarBg = layoutContext.scrollTop > 120;

	//for customer profile picture
	const [showPhotoActions, setShowPhotoActions] = useState<boolean>(false);
	const [target, setTarget] = useState<any>(null);
	const ref = useRef(null);

	const [showUploadDialog, setShowUploadDialog] = useState<boolean>(false);
	const [inputImgFile, setInputImgFile] = useState<File | undefined>();

	const handleUpload = (files: File[]) => {
		setInputImgFile(files[0]);
		setShowUploadDialog(false);
		setShowImageCropper(true);
	}

	const [showImageCropper, setShowImageCropper] = useState<boolean>(false);
	const [photoLightBoxOpened, setPhotoLightBoxOpened] = useState(false);

	return <div className={classes['client-screen-top-container']}>
		<ScreenTopBarMobile sticky onBackClick={onBackClick} hideBackBtn={hideBackBtn} shadow={shouldShowTopBarBg} background={shouldShowTopBarBg}>
			<div className={classes['save-btn-container']}>
				{
					onSaveClick ? <button
						style={unsaved ? { color: '#FFBF00', fontWeight: 'bold' } : {}}
						className={classes['save-btn']}
						onClick={onSaveClick}
					>{lang.actionSave + `${unsaved ? ' *' : ''}`}</button> :
						onSkipClick ? <button
							className={classes['save-btn']}
							onClick={onSkipClick}
						>{lang.actionSkip}</button> : null
				}
			</div>
		</ScreenTopBarMobile>

		<ClientAvatar
			title={title}
			allowPhotoUpload={allowPhotoUpload}
			photoImgSrc={photoImgSrc}
			onConfirmUploadPhoto={onConfirmUploadPhoto}
			onDeletePhoto={onDeletePhoto}
		/>

	</div>
}