import CAPropertyStockListItemDTO from 'common/dto/CAPropertyStockListItemDTO';
import { CAPropertyStockSearchObjectCommons } from 'common/dto/CAPropertyStockSearchDTO';
import { SortKey } from 'components/property/search/PropertySearchResultViewProps';
import { ActionType, createAction, createReducer } from 'typesafe-actions';
import { CommonFormState, createFormSpec } from './common/form';

export const priceRangeMapping10k = [
  0, 200, 500, 800, 1000, 1400, 2000, 2500, 3000, 4000, 5000, 6500, 8000, 10000, 15000, Infinity,
];

export const rentRangeMapping = [
  Infinity, 150_000, 130_000, 100_000, 90_000, 80_000, 70_000, 60_000, 50_000, 40_000, 30_000, 20_000, 10_000, 8_000, 5_000, 0
].sort((a, b) => a > b ? 1 : -1);

export const areaRangeMapping = [
  Infinity, 6000, 4500, 3000, 2500, 2000, 1500, 1000, 900, 800, 700, 600, 500, 400, 300, 0,
].sort((a, b) => a > b ? 1 : -1);

export interface PropertyListState extends CommonFormState<PropertyStockSearchForm> {
  /** Current fetched properties */
  properties: CAPropertyStockListItemDTO[];

  /** sold/leased properties */
  soldLeasedProperties: CAPropertyStockListItemDTO[];

  /** Whether having more properties to fetch (mobile only) */
  hasMore: boolean;
  totalCount: number;
}

export const propertySearchFormSpec = createFormSpec(
  'PropertyList.CriteriaUpdated',
  'PropertyList.CriteriaErrorAdded'
)<PropertyStockSearchForm>(
  () => [] // no validation required
);

export interface PropertyStockSearchForm extends CAPropertyStockSearchObjectCommons {
  priceDisplay: number[];
  roomEnabled: boolean;
  suiteEnabled: boolean;
  bathroomEnabled: boolean;
  balconyEnabled: boolean;
  helperRoomEnabled: boolean;
  sortKey: SortKey,
  direction: 'DESC' | 'ASC';
  toggledFieldDisplay: string;
}

export interface PropertyStockSearchFormForHome {
  // Autocomplete. Only one of the below will be enabled at a time.
  buildingName: PropertyStockSearchForm['buildingName'];
  street: PropertyStockSearchForm['street'];
  primarySchoolNet: PropertyStockSearchForm['primarySchoolNet'];
  secondarySchoolNet: PropertyStockSearchForm['secondarySchoolNet'];
  district: PropertyStockSearchForm['district'];

  // Price & Room. To be retained in each home search, other fields will be reset.
  room: PropertyStockSearchForm['room'];
  roomEnabled: PropertyStockSearchForm['roomEnabled'];
  priceDisplay: PropertyStockSearchForm['priceDisplay'];
  rent: PropertyStockSearchForm['rent'];
  buyOrRent: PropertyStockSearchForm['buyOrRent'];
  limit: PropertyStockSearchForm['limit'];

  saveSearchCriteria: PropertyStockSearchForm['saveSearchCriteria'];
  toggledFieldDisplay: PropertyStockSearchForm['toggledFieldDisplay'];
  freeTextSearch: PropertyStockSearchForm['freeTextSearch'];
}

export const fieldsToBeIncludedForSaveSearchTitle: Array<keyof PropertyStockSearchForm> = [
  'status',
  'freeTextSearch',
  'buyOrRent',
  'district',
  'usage',
  
  // 'gross',
  'net',
  'priceDisplay',
  'rent',
  
  'buildingName',
  'street',

  'room',
  'suite',
  'bathroom',
  'balcony',
  'hasHelperRoom',
  
  'facing',
  'deco',
  'view',
  'otherFeatures',
  'primarySchoolNet',
  'secondarySchoolNet',
  'clubHouseFacilities',
  'others'
]

const fieldsToBeToggled: Array<keyof PropertyStockSearchFormForHome> = [
  'buildingName',
  'street',
  'primarySchoolNet',
  'secondarySchoolNet',
  'district',
  'freeTextSearch',
]

const fieldsForHomeSearch: Array<keyof PropertyStockSearchFormForHome> = [
  ...fieldsToBeToggled,
  'room',
  'roomEnabled',
  'priceDisplay',
  'rent',
  'buyOrRent',
  'saveSearchCriteria',
  'toggledFieldDisplay',

  'limit',
];

const toggledHomeSearchCriteria = (obj: Partial<PropertyStockSearchFormForHome>) => Object.keys(obj).some(k => fieldsToBeToggled.includes(k as any)) ? {
  buildingName: '',
  street: '',
  primarySchoolNet: [],
  secondarySchoolNet: [],
  district: [],
  freeTextSearch: '',
  ...obj as object,
} : obj;

export const propertyListInitialState = (): PropertyListState => ({
  ...propertySearchFormSpec.initialState,
  properties: [],
  soldLeasedProperties: [],
  hasMore: true,
  totalCount: 0,
  contents: {
    limit: 20,
    page: 0,
    sortKey: null,
    direction: 'DESC',
    
    status: [],
    freeTextSearch: '', // Free Text Search Keyword
    buyOrRent: 'BUY', // BUY or RENT (CACustomerSearch.BuyOrRentOptions)
    district: [], // Districts (Multi-value)
    usage: [], // Usages (Multi-value)
    
    // gross: [ 0, Infinity ],
    net: [ 0, Infinity ],
    priceDisplay: [ 0, Infinity ],
    rent: [ 0, Infinity ],
    
    buildingName: '',
    street: '',

    room: 0,
    suite: 0,
    bathroom: 0,
    balcony: 0,
    hasHelperRoom: false,
    
    facing: [],
    deco: [],
    view: [],
    otherFeatures: [],
    primarySchoolNet: [],
    secondarySchoolNet: [],
    
    saveSearchCriteria: false,

    //----- for search draft display -----//
    toggledFieldDisplay: '',

    //-------- for keyword search--------//
    clubHouseFacilities: [],
    others: [],
  },
});

export const propertyListActions = {
  ...propertySearchFormSpec.actions,
  reset: createAction('PropertyList.Reset')(),
  fetch: createAction('PropertyList.FetchRequested')<{ isSwitchingPage: boolean }>(),
  refresh: createAction('PropertyList.Updated')<{ properties: CAPropertyStockListItemDTO[], hasMore: boolean }>(),
  refreshSoldLeased: createAction('PropertyList.SoldLeased.Updated')<{ soldLeasedProperties: CAPropertyStockListItemDTO[] }>(),
  appendNextPage: createAction('PropertyList.AppendNextPage')<{ properties: CAPropertyStockListItemDTO[], hasMore: boolean }>(),
  // updateCriteria: createAction('PropertyList.CriteriaUpdated')<Partial<PropertyListState['contents']>>(),
  addBookmark: createAction('PropertyList.AddBookmark')<{ caPropertyStockId: number }>(),
  deleteBookmark: createAction('PropertyList.DeleteBookmark')<{ caPropertyStockId: number }>(),
  editHomeSearch: createAction('PropertyList.EditHomeSearch',
    (key: keyof PropertyStockSearchFormForHome, value: any): Partial<PropertyStockSearchFormForHome> => ({ [key]: value })
  )(),
  updateCurrentCriteria: createAction('PropertyList.SaveSearch.CriteriaUpdated')<{ savedCriteria: PropertyStockSearchForm }>(),
};

export type PropertyListActions = ActionType<typeof propertyListActions>;

export const propertyListReducer = createReducer<PropertyListState, PropertyListActions>(propertyListInitialState(), propertySearchFormSpec.reducer)
  .handleAction(propertyListActions.reset, () => propertyListInitialState())
  .handleAction(propertyListActions.fetch, (state, { payload }) => ({
    ...state,
    properties: payload.isSwitchingPage ? state.properties : [],
    hasMore: payload.isSwitchingPage ? state.hasMore : true,
  }))
  .handleAction(propertyListActions.appendNextPage, (state, { payload }) => ({
    ...state, properties: [ ...state.properties, ...payload.properties ], hasMore: payload.hasMore,
  }))
  .handleAction(propertyListActions.refresh, (state, { payload }) => ({
    ...state, properties: payload.properties, hasMore: payload.hasMore,
  }))
  .handleAction(propertyListActions.refreshSoldLeased, (state, { payload }) => ({
    ...state, soldLeasedProperties: payload.soldLeasedProperties,
  }))
  .handleAction(propertyListActions.addBookmark, (state, { payload }) => ({
    ...state, properties: state.properties.map(p => p.caPropertyStockId === payload.caPropertyStockId ?
      { ...p, isBookmarked: true } : p,
    )
  }))
  .handleAction(propertyListActions.deleteBookmark, (state, { payload }) => ({
    ...state, properties: state.properties.map(p => p.caPropertyStockId === payload.caPropertyStockId ?
      { ...p, isBookmarked: false } : p,
    )
  }))
  // .handleAction(propertyListActions.editHomeSearch, (state, { payload }) => ({
  //   ...state, contents: {
  //     // 1. Reset all search criteria
  //     ...propertyListInitialState().contents,
  //     // 2. Copy fields to be retained from current criteria
  //     ...fieldsForHomeSearch
  //       .map(k => ({ [k]: state.contents[k] }))
  //       .reduce((a, b) => ({ ...a, ...b }) , {}),
  //     // 3. Perform Edit
  //     ...toggledHomeSearchCriteria(payload),
  //   }
  // }))
  // Same as propertyListActions.edit()
  .handleAction(propertyListActions.editHomeSearch, (state, { payload }) => ({
    ...state,
    contents: {
      ...state.contents,
      ...payload,
    },
  }))
  .handleAction(propertyListActions.updateCurrentCriteria,
    (state, action) => ({ ...state, contents: {
      ...propertyListInitialState().contents,
      ...action.payload.savedCriteria
    }})
  )
  // .handleAction(propertyListActions.updateCriteria, (state, { payload }) => ({
  //   ...state, contents: { ...state.contents, ...payload },
  // }))
;