import { ApiError, ApiResult } from 'api';
import resetPasswordApi, { forgetPasswordApi } from 'api/resetPasswordApi';
import { selectLocale } from 'common/hooks/useLocale';
import { isMobileUI } from 'common/utils/utils';
import { forgotPasswordActions } from 'reducers/forgot-password';
import { layoutActions } from 'reducers/layout';
import { resetPasswordActions } from 'reducers/reset-password';
import { call, put, select, takeEvery, takeLeading } from 'redux-saga/effects';
import { PASSagaContext } from 'sagas';
import { ActionType, getType } from 'typesafe-actions';
import { apiTaskWrapper } from './saga-commons';
import { resetPasswordFormActions } from 'reducers/reset-password-form';


const BASE_URL = process.env['REACT_APP_PAS_BASE_URL'];
const PUBLIC_URL = window.location.origin + process.env['PUBLIC_URL'];

export default [ watchForgotPasswordRequest, watchVerifyTokenRequested, watchResetPasswordRequested ];

export function* watchForgotPasswordRequest(context: PASSagaContext) {
	yield takeLeading(
		getType(forgotPasswordActions.doRequest),
		apiTaskWrapper(forgotPasswordRequest),
		context
	)
}

export function* forgotPasswordRequest(_context: PASSagaContext, action: ActionType<typeof forgotPasswordActions['doRequest']>) {
	const { data, error }: ApiResult<{ invalidUsernameOrEmail: boolean, emailSent: boolean }> = yield call(forgetPasswordApi.request, action.payload );

	const { langLogin } = yield select(selectLocale());

	if (error) {
		throw ApiError.of(error);
	} else {
		if (data?.invalidUsernameOrEmail) {
			yield put(forgotPasswordActions.updateEmailError(langLogin.errInvalidEmail));
			yield put(forgotPasswordActions.clearInput());
		} else {
			if (data?.emailSent) {
				yield put(forgotPasswordActions.success());
				yield put(forgotPasswordActions.reset());
				yield put(forgotPasswordActions.closeDialog());
				yield put(layoutActions.alert(langLogin.msgForgetPasswordRequestSuccess, 'success'));
			}
		}
	}
}

///////////////////////////////////////////////////////////////////
///////////////////// Verify token and status  ////////////////////////
///////////////////////////////////////////////////////////////////

export function* watchVerifyTokenRequested(context: PASSagaContext) {
	yield takeEvery(
		getType(resetPasswordActions.verifyTokenRequested),
		apiTaskWrapper(verifyTokenRequest),
		context,
	)
}

export function* verifyTokenRequest(_context: PASSagaContext, action: ActionType<typeof resetPasswordActions['verifyTokenRequested']>) {
	const { token } = action.payload;

	const { data, error }: ApiResult<any> = yield call(resetPasswordApi.verifyToken, token);

	const { langLogin } = yield select(selectLocale());

	if (error) {
		switch(error) {
			case 'ERR_INVALID_TOKEN':
			case 'ERR_TOKEN_EXPIRED': 
				yield put(resetPasswordActions.linkExpired());
				// yield put(layoutActions.alert(langLogin.errInvalidToken, 'severity'));
				break;
			default: 			
				// yield call(_context.browserHistory.replace as any, '/login');
				throw ApiError.of(error);
		}
	} else {
		yield put(resetPasswordActions.pendingForUserInput());
	}
}

///////////////////////////////////////////////////////////////////
///////////////////// Reset Password Request  /////////////////////
///////////////////////////////////////////////////////////////////

export function* watchResetPasswordRequested(context: PASSagaContext) {
	yield takeEvery(
		getType(resetPasswordActions.doRequest),
		apiTaskWrapper(resetPasswordRequest),
		context,
	)
}

export function* resetPasswordRequest(_context: PASSagaContext, action: ActionType<typeof resetPasswordActions['doRequest']>) {
	const { token, newPassword } = action.payload;
	const { data, error }: ApiResult<any> = yield call(resetPasswordApi.changePassword, token, newPassword);

	const { langLogin } = yield select(selectLocale());

	if (error) {
		switch(error) {
			case 'ERR_PW_FAIL_COMPLEXITY_REQUIREMENT':
				yield put(layoutActions.alert(langLogin.msgPasswordFailComplexityRequirement, 'severity'));
				break;
			case 'ERR_PREVIOUS_PW_USED':
				yield put(layoutActions.alert(langLogin.msgPreviousPasswordUsed, "error"));
				break;
			case 'ERR_INVALID_TOKEN':
				// yield put(layoutActions.alert(langLogin.errInvalidToken, 'severity'));
				// yield call(_context.browserHistory.replace as any, '/login');
				// break;
			case 'ERR_TOKEN_EXPIRED': 
				yield put(resetPasswordActions.linkExpired());
				// yield put(layoutActions.alert(langLogin.msgResetPasswordTokenExpiry, 'severity'));
				// yield call(_context.browserHistory.replace as any, '/login');
				break;
			default: 			
				// yield call(_context.browserHistory.replace as any, '/login');
				throw ApiError.of(error);
		}
		yield put(resetPasswordFormActions.clearInput());
	} else {
		yield put(resetPasswordActions.success());
		if (isMobileUI()) {
			yield call(_context.browserHistory.replace as any, '/login');
		} else {
			yield call(_context.browserHistory.replace as any, '/home');
		}
		yield put(layoutActions.alert(langLogin.msgResetPasswordSuccess, 'success'));
	}
}