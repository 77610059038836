import { registerTouchableClassNames } from 'common/polyfills/touch-polyfill';
import React from 'react';
import classes from './SavedSearchListItemDesktop.module.scss';


export interface SavedSearchListItemDesktopProps {
  name: string;
  date: string;

  onDelete?: AnyFn;
  onClick?: AnyFn;
}

registerTouchableClassNames(
  classes['content'], classes['append-btn']
);

export default function SavedSearchListItemDesktop(props: SavedSearchListItemDesktopProps) {
  const { name, date, onDelete, onClick } = props;

  return <div className={classes['root']}>
    <div className={classes['content']} onClick={onClick}>
      <div className={classes['name']}>{name}</div>
      <div className={classes['date']}>{date}</div> 
    </div>   
    <div className={classes['append-btn']} onClick={onDelete}>
      <i className={`far fa-trash-alt ${classes['delete']}`} />
    </div>
  </div>
  ;
}