import { AgentContactActions, agentContactReducer } from './agent-contact';
import { AgentDetailActions, agentDetailReducer } from './agent-detail';
import { AgentListActions, agentListReducer } from './agent-list';
import { AgentRatingCommentActions, agentRatingCommentReducer } from './agent-rating-comment';
import { alertConfirmDialogReducer } from './alert-confirm-dialog';
import { BookmarkListActions, bookmarkListReducer } from './bookmark-list';
import { ChangePasswordFormActions, changePasswordFormReducer } from "./change-pw";
import { CustomerActions, customerReducer } from "./customer";
import { EnquiryDialogActions, enquiryDialogReducer } from './enquiry-dialog';
import { facebookEmailReducer } from './facebook-email';
import { ForgotPasswordActions, forgotPasswordReducer } from './forgot-password';
import { FullTransactionListActions, fullTransactionListReducer } from './full-transaction-list';
import { GetInTouchActions, getInTouchReducer } from './get-in-touch';
import { HomeActions, homeReducer } from './home';
import { InlinePropertyListActions, inlinePropertyListReducer } from './inline-property-list';
import { LayoutActions, layoutReducer } from './layout';
import { LocaleActions, localeReducer } from "./locale";
import { LoginActions, loginReducer } from "./login";
import { LoginFormActions, loginFormReducer } from './login-form';
import { NewsActions, newsReducer } from './news';
import { NotificationActions, notificationReducer } from './notification';
import { PropertyStockDetailActions, propertyStockDetailReducer } from './property-detail';
import { propertyListReducer } from './property-list';
import { PropertyMapActions, propertyMapReducer } from './property-map';
import { RegisterDialogActions, registerDialogReducer } from './register-dialog';
import { RegisterFormActions, registerFormReducer } from "./register-form";
import { ResetPasswordActions, resetPasswordReducer } from './reset-password';
import { ResetPasswordFormActions, resetPasswordFormReducer } from './reset-password-form';
import { SaveSearchActions, saveSearchReducer } from './save-search';
import { sellerEnquiryReducer } from './seller-enquiry';
import { SystemSettingActions, systemSettingReducer } from './system-setting';

// Compose Interfaces
export const reducerMap = {
  systemSettings: systemSettingReducer,
  layout: layoutReducer,
  alertConfirm: alertConfirmDialogReducer,
  locale: localeReducer,
  login: loginReducer,
  loginForm: loginFormReducer,
  registerForm: registerFormReducer,
  registerDialog: registerDialogReducer,
  agentList: agentListReducer,
  enquiryDialog: enquiryDialogReducer,
  sellerEnquiry: sellerEnquiryReducer,
  agentDetail: agentDetailReducer,
  agentContact: agentContactReducer,
  getInTouch: getInTouchReducer,
  agentRatingComment: agentRatingCommentReducer,
  home: homeReducer,
  customer: customerReducer,
  changePwForm: changePasswordFormReducer,
  facebookEmail: facebookEmailReducer,
  forgotPassword: forgotPasswordReducer,
  propertyList: propertyListReducer,
  propertyMap: propertyMapReducer,
  propertyDetail: propertyStockDetailReducer,
  inlinePropertyList: inlinePropertyListReducer,
  bookmarkList: bookmarkListReducer,
  resetPassword: resetPasswordReducer,
  resetPasswordForm: resetPasswordFormReducer,
  saveSearch: saveSearchReducer,
  notification: notificationReducer,
  news: newsReducer,
  fullTransactionList: fullTransactionListReducer,
};

export type IRootState = { [ K in keyof typeof reducerMap ]: ReturnType<typeof reducerMap[K]> };
export type AllActions = LayoutActions | SystemSettingActions | LocaleActions |
  LoginActions | LoginFormActions | RegisterFormActions | RegisterDialogActions | AgentListActions | 
  EnquiryDialogActions | AgentDetailActions | AgentContactActions | AgentRatingCommentActions | 
  HomeActions | ChangePasswordFormActions | CustomerActions | ForgotPasswordActions | 
  ResetPasswordActions | ResetPasswordFormActions | PropertyMapActions | PropertyStockDetailActions | 
  InlinePropertyListActions | BookmarkListActions | SaveSearchActions | GetInTouchActions |
  NotificationActions | NewsActions | FullTransactionListActions
;