import clsx from 'clsx';
import 'common/general-imports';
import useLocale from 'common/hooks/useLocale';
import { registerTouchableClassNames } from 'common/polyfills/touch-polyfill';
import { handlePriceDisplay, handlePriceDisplayUnit, handlePriceLength, isNonEmpty, multiLang, numberWithCommas } from 'common/utils/utils';
import Image from 'components/data-display/Image';
import React, { useMemo } from 'react';
import { isNullOrUndefined } from 'util';
import classes from './PropertyCard.module.scss';



export interface PropertyCardProps {
	id: number | string;
	image: string;
	propertyNo: string;
	titleEn: string;
	titleZh: string;
	street: string;
	buildingName: string;
	level: string;
	gross: number;
	net: number;
	district: string;
	price?: number;
	rent?: number;
	onClick?: () => any;
	/** If set, the component width should be 100% */
	fullWidth?: boolean;
	noShadow?: boolean;
	punchline?: string;
}

registerTouchableClassNames(
	classes['property-card'],
);

export default function PropertyCard(props: PropertyCardProps) {
	const { id, image, street, buildingName, level, gross, net, district, price, rent, onClick, fullWidth, noShadow } = props;

	const { lang: langCommon, langProperty, locale } = useLocale();

	const regionBundles = useLocale();

	const allDistrictOptions = useMemo(() => {
		return {
			...regionBundles.districtHkiOptions,
			...regionBundles.districtKltOptions,
			...regionBundles.districtNtOptions,
		};
	}, [regionBundles]);

	const priceRentDisplay = () => {
		const priceDisplay = (isNullOrUndefined(price) || !isNullOrUndefined(price) && price === 0) ? '' :
			numberWithCommas(handlePriceLength(handlePriceDisplay(price ?? 0, locale))) + handlePriceDisplayUnit(price, locale, langCommon);
		const rentDisplay = (isNullOrUndefined(rent) || !isNullOrUndefined(rent) && rent === 0) ? '' : numberWithCommas(rent);

		return `$${isNonEmpty(priceDisplay) ? priceDisplay : rentDisplay}`;
	}

	const grossDisplay = () => {
		const grossDisplay = !(gross) ? '' : (langProperty.grossAbbr + String(gross) + langCommon.uFt);

		return isNonEmpty(grossDisplay) ? <div className={classes['gross']}>{grossDisplay}</div> : null;
	}

	const netDisplay = () => {
		const netDisplay = !(net) ? '' : (langProperty.netAbbr + String(net) + langCommon.uFt);

		return isNonEmpty(netDisplay) ? <div className={classes['net']}>{netDisplay}</div> : null;
	}

	return <div id={`property-card-${id}`} className={clsx(
		classes['property-card'],
		(fullWidth ? classes['property-card-desktop'] : classes['property-card-mobile']),
		noShadow && classes['no-shadow'],
	)} onClick={onClick} >
		<Image
			className={classes['image']}
			// alt="Property" 
			src={image}
			ratio={4 / 3}
		// width={800}
		// height={600}
		// style={{ backgroundImage: `url("${image}")` }}
		/>
		<div style={{ height: '58px' }}>
			{street.trim().length ? <div className={classes['title']}>{street}</div> : null}
			<div className={classes['title']}>{buildingName} {level}</div>
		</div>
		<div className={classes['area']}>
			{netDisplay()}
			{!!(net) && !!(gross) && <div className={classes['divider']}>/</div>}
			{grossDisplay()}
		</div>
		<div className={classes['last-section']}>
			<div className={classes['district']}>{allDistrictOptions[district] ?? district}</div>
			<div className={classes['price']}>{priceRentDisplay()}</div>
		</div>
	</div>
}
