import useLocale from 'common/hooks/useLocale';
import { registerTouchableClassNames } from 'common/polyfills/touch-polyfill';
import AgentCard from 'components/display-cards/AgentCard';
import SectionTitleDesktop from 'components/home/SectionTitleDesktop';
import ScrollToTopButton from 'components/misc/ScrollToTopButton';
import MainLayoutDesktop from 'components/scaffold/MainLayoutDesktop';
import { useWindowScrollContext } from 'components/scaffold/ScrollContext';
import React from 'react';
import Container from 'react-bootstrap/esm/Container';
import classes from './AgentListViewDesktop.module.scss';
import { AgentListViewProps } from './AgentListViewProps';
import DistrictFilterDesktop from './DistrictFilterDesktop';

registerTouchableClassNames(
  classes['more-btn'], classes['back-to-top-button'],
)

export default function AgentListViewDesktop(props: AgentListViewProps) {
  const { regionBundles, agents, bindDistricts, onRequestNextPage, onRefresh, hasMore, onBackClick,
    doDisplayDetail, doDisplayContact, doDisplayRatingForm, 
  ...rest } = props;

  const { langAgent  } = useLocale();

  const windowScroll = useWindowScrollContext();

  const notFoundView = () => <div className={classes['no-record-view']}>
    <div className={classes['msg']}>{langAgent.msgNoRecord}</div>
  </div>

  return <MainLayoutDesktop {...rest}><Container style={{ padding: '0' }}>
    <div className={classes['agent-list-container']}>
      <div className={classes['inner']}>

        {/* --------- section header --------- */}
        <SectionTitleDesktop title={langAgent.titleOurAgents} />

        {/* --------- selected district --------- */}
        {/* {isSmScreen && false ? */}
          {/* <DistrictFilter bindDistricts={bindDistricts} regionBundles={regionBundles} onBackClick={onBackClick} /> */}
          {/* :   */}
          <DistrictFilterDesktop bindDistricts={bindDistricts} regionBundles={regionBundles} onBackClick={onBackClick} />
        {/* } */}
        {/* --------- agent list --------- */}
        {
          agents.length ? <div>
            <div className={`${classes['agen-list-section-container']} row`} >
              {
                agents.map(agent => {
                  return <div key={agent?.id} className={`${classes['agent-card-container']} col-sm-12 col-md-6 col-lg-6 col-xl-6`}>
                    <AgentCard
                      size="lg"
                      id={agent?.id ?? 0}
                      image={agent?.photoFilename ? `${agent.photoFilename}` : ''}
                      // By design, display both languages here.
                      chineseName={`${agent?.chineseName ?? ''}`}
                      englishName={agent?.englishName ?? ''}
                      // name={multiLang(locale, agent?.chineseName, agent?.englishName) ?? ''}
                      maxRating={agent?.maxRating ?? 0}
                      rating={agent?.rating ?? 0}
                      comment={agent?.lastComment}
                      onClickMore={() => doDisplayDetail?.(agent?.id ?? 0)}
                      onClickContact={() => doDisplayContact?.(agent?.id ?? 0)}
                      onClickRating={() => doDisplayRatingForm?.(agent?.id ?? 0)}
                    />
                  </div>
                })
              }
              {/* {windowScroll.scrollTop > 100 &&
                <div className={`${classes['back-to-top-button-container']}`} >
                  <Button variant="pas-orange"
                    className={classes['back-to-top-button']}
                    onClick={() => {
                    document.querySelector(`[data-key="TOP"]`)?.scrollIntoView({ behavior: 'smooth'});
                    }}
                  >
                    <i className="fas fa-chevron-up" />
                  </Button>
                </div>
              } */}
              <ScrollToTopButton />
            </div> 
            {/* --------- more button --------- */}
            {hasMore ? 
            <Container className={classes['more-btn-container']}>
              <div 
                className={classes['more-btn']}
                onClick={onRequestNextPage}
              >
                {langAgent.actionLoadMoreAgents}
                <i className="fas fa-arrow-down" />
              </div>
            </Container>
            : null}
          </div>
          : notFoundView()
        }

      </div>
    </div>
  </Container></MainLayoutDesktop>;
}