import { isMobileUI } from 'common/utils/utils';
import $ from 'jquery';

const usePassiveEvent = true;

/**
 * Register Touchable Classnames.
 * 
 * When using this function, the hover selector should be defined as:
 * 
 * `&:active, &:not([data-no-hover]):hover`
 */
export function registerTouchableClassNames(...classnames: string[]) {
  addTouchableHoveringSupport(`${classnames.map(c => '.' + c).join(', ')}`, []);
}

export function addTouchableHoveringSupport(elementSelector: string, _deprecated_hoverEffectClassName?: string | string[], noHoverClassName?: string) {
  if (noHoverClassName) {
    console.error('[!!] addTouchableHoveringSupport(): noHoverClassName is deprecated and will be removed in next version. Please migrate to use :not([data-no-hover]):hover selector.');
  } else {
    noHoverClassName = 'nohover';
  }

  if (isMobileUI()) {
    const onTouchStart = (ev: Event) => {
      const target = ev.target as HTMLElement | null;
      if (!$(target!).is(elementSelector)) {
        return;
      }
      target!.dataset.noHover = '1';
      $(target!).addClass(noHoverClassName!);
    }
    document.body.addEventListener('touchstart', onTouchStart, { passive: usePassiveEvent });
    document.body.addEventListener('mousedown', onTouchStart, { passive: usePassiveEvent });

    const onTouchEnd = (ev: Event) => {
      const target = ev.target as HTMLElement | null;
      if (!$(target!).is(elementSelector)) {
        return;
      }
      target!.dataset.noHover = '1';
      $(target!).addClass(noHoverClassName!);
      $(target!).trigger('blur')
    };
    document.body.addEventListener('touchcancel', onTouchEnd, { passive: usePassiveEvent });
    document.body.addEventListener('touchend', onTouchEnd, { passive: usePassiveEvent });
    document.body.addEventListener('mouseup', onTouchEnd, { passive: usePassiveEvent });

    const onTouchMove = (ev: TouchEvent) => {
      // ev.preventDefault();
      const target = ev.target as HTMLElement | null;
      if (!$(target!).is(elementSelector)) {
        return;
      }
      target!.dataset.noHover = '1';
      const hoveringEl = document.elementFromPoint(ev.touches[0].clientX, ev.touches[0].clientY);
      if (!hoveringEl) {
        $(target!).trigger('touchcancel');
        return;
      }

      if (hoveringEl !== target && !$(hoveringEl).parents(elementSelector).toArray().includes(target!)) {
        $(target!).trigger('touchcancel');
      }
    }
    document.body.addEventListener('touchmove', onTouchMove, { passive: usePassiveEvent });

  }
}

export function jsxTouchableProps(): { 'data-no-hover'?: string | undefined } {
  return isMobileUI() ? { 'data-no-hover': '1' } : {};
}

addTouchableHoveringSupport('.btn', []);