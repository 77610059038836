import { TransactionDTO } from 'common/dto/CAPropertyStockDetailDTO';
import { ActionType, createAction, createReducer } from 'typesafe-actions';

export interface FullTransactionListState {
  /** Current fetched transactions */
  transactions: TransactionDTO[];
  totalPages: number;
  currentPage: number;
  sort: string;
  direction: 'DESC' | 'ASC';
}

const fullTransactionListInitialState = (): FullTransactionListState => ({
  transactions: [],
  totalPages: 0,
  currentPage: 0,
  sort: 'transactionDate',
  direction: 'DESC',
});

export const fullTransactionListActions = {
  reset: createAction('FullTransactionList.Reset')(),
  doFetchTransactions: createAction('FullTransactionList.FetchRequested')<{ propertyNo: string, page: number, sort: string, direction: 'DESC' | 'ASC' }>(),
  update: createAction('FullTransactionList.Updated')<{ transactions: TransactionDTO[], totalPages: number, currentPage: number }>(),
  // updateCriteria: createAction('FullTransactionList.CriteriaUpdated')<Partial<FullTransactionListState['criteria']>>(),
};

export type FullTransactionListActions = ActionType<typeof fullTransactionListActions>;

export const fullTransactionListReducer = createReducer<FullTransactionListState, FullTransactionListActions>(fullTransactionListInitialState())
  .handleAction(fullTransactionListActions.reset, () => fullTransactionListInitialState())
  .handleAction(fullTransactionListActions.doFetchTransactions, (state, { payload }) => ({
    ...state,
    sort: payload.sort,
    direction: payload.direction,
  }))
  .handleAction(fullTransactionListActions.update, (state, { payload }) => ({
    ...state,
    transactions: payload.transactions,
    currentPage: payload.currentPage,
    totalPages: payload.totalPages,
  }))
;