import useLocale from 'common/hooks/useLocale';
import MainLayoutMobile from 'components/scaffold/MainLayoutMobile';
import { BaseLayoutProps } from 'components/scaffold/MainLayoutProps';
import React from 'react';
import { AboutContact, AboutIntro } from './About';
import InfoScreenMobile from './InfoScreenMobile';

export interface AboutScreenMobileProps {
  layoutProps: BaseLayoutProps;
  intro: string;
  email: string;
  tel: string;
  // fax: string;
  address: string;
  onBackClick?: AnyFn;
}

export default function AboutScreenMobile(props: AboutScreenMobileProps) {
  const { layoutProps, intro, email, tel, address, onBackClick } = props;

  const { langSetting } = useLocale();

  return <MainLayoutMobile {...layoutProps}>
    <InfoScreenMobile title={langSetting.captionAbout}
      children={{
        aboutIntro: <AboutIntro 
          content={intro}
        />,
        aboutContact: <AboutContact
          email={email}
          tel={tel}
          address={address}
        />
      }}
      onBackClick={onBackClick}
    />
  </MainLayoutMobile>
}