import useLayoutProps from 'common/hooks/useLayoutProps';
import useLocale from 'common/hooks/useLocale';
import { multiLang } from 'common/utils/utils';
import QAScreenMobile from 'components/setting/QAScreenMobile';
import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { IRootState } from 'reducers';


const QAPage = () => {
  const history = useHistory();
  const qaItems = useSelector((state: IRootState) => state.systemSettings['Q&A']);
  const layoutProps = useLayoutProps();
  const { locale } = useLocale();

  return <QAScreenMobile
    layoutProps={layoutProps}
    qaItems={Object.entries(qaItems).sort((a, b) => parseInt(a[1]['seq'] ?? 0) - parseInt(b[1]['seq'] ?? 0))
    .map(([_, item], index) => ({ 
      question: multiLang(locale, item['QUESTION_TC'], item['QUESTION_EN'])!, 
      answer: multiLang(locale, item['ANSWER_TC'], item['ANSWER_EN'])!, 
      index: index
    }))}
    onBackClick={() => history.goBack()}
  />

}

export default QAPage;