import useSimpleFormBinder from 'common/hooks/useFormBinder';
import useLocale from 'common/hooks/useLocale';
import SingleTextConfirmDialog from 'components/overlay/SingleTextConfirmDialog';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from 'reducers';
import { forgotPasswordActions } from 'reducers/forgot-password';

export default function GlobalForgotPasswordDialog() {
  const dispatch = useDispatch();
	const { langLogin } = useLocale();
	const { email, emailErrorMessage, open } = useSelector((state: IRootState) => state.forgotPassword);

	const bindEmail = useSimpleFormBinder(
		email,
		(updated) => dispatch(forgotPasswordActions.updateEmail(updated ?? '')),
		() => {},
		emailErrorMessage
	);

	return <SingleTextConfirmDialog
		show={open}
		title={langLogin.actionForgetPassword}
		message={langLogin.msgForgetPasswordEmailRequired}
		bindText={bindEmail}
		onConfirm={() => dispatch(forgotPasswordActions.doRequest(email ?? ''))}
		onHide={() => dispatch(forgotPasswordActions.reset())}
	/>;
}