import CAClientContactDTO from 'common/dto/CAClientContactDTO';
// import CAEnquiryRequestDTO from 'common/dto/CAEnquiryRequestDTO';
import { checkValidEmail, checkValidPhoneNumber } from 'common/utils/utils';
import { ActionType, createAction, createReducer } from 'typesafe-actions';
import { commonFormInitialState, CommonFormState, createFormActions, formInitialReducerHandlers, FormValidator } from './common/form';

// ==> Form: extends the state CommonFormState
export interface EnquiryDialogState extends CommonFormState<CAClientContactDTO> {
  open: boolean;
  entry: 'D' | 'P';
}

export const enquiryDialogActions = {
  // ==> Form: extends the actions from FormActions
  ...createFormActions('EnquiryDialog.Edit', 'EnquiryDialog.AddError')<CAClientContactDTO>(),
  openDialog: createAction('EnquiryDialog.Open')(),
  openDialogForProperty: createAction('EnquiryDialog.OpenForProperty')(),
  closeDialog: createAction('EnquiryDialog.Close')(),
  reset: createAction('EnquiryDialog.Reset')(),
  doEnquiry: createAction('EnquiryDialog.EnquiryRequested')(),
};

export type EnquiryDialogActions = ActionType<typeof enquiryDialogActions>;

const initialState = (): EnquiryDialogState => ({
  // ==> Form: extends the initial state formInitialState
  ...commonFormInitialState<CAClientContactDTO>(),
  open: false,
  contents: {
    districts: [],
  },
  entry: 'D',
});


export const enquiryDialogReducer = createReducer<EnquiryDialogState, EnquiryDialogActions>(initialState(),
    // ==> Form: extends the reducer from FormInitialReducerHandler
    formInitialReducerHandlers('EnquiryDialog.Edit', 'EnquiryDialog.AddError')()
  )
  .handleAction(enquiryDialogActions.openDialog, (state) => 
    ({ ...state, open: true, entry: 'D' })
  )
  .handleAction(enquiryDialogActions.openDialogForProperty, (state) => 
    ({ ...state, open: true, entry: 'P' })
  )
  .handleAction(enquiryDialogActions.closeDialog, (state) => 
    ({ ...state, open: false })
  )
  .handleAction(enquiryDialogActions.reset, (_) => 
    initialState()
  )
;

export const enquiryDialogValidations = (
  langEnquiry: LocaleOptions
): FormValidator<CAClientContactDTO>[] => [
  (values) =>
    values.tel && !checkValidPhoneNumber(values.tel)
      ? { tel: langEnquiry.errContact }
      : {},
  (values) =>
    values.email && !checkValidEmail(values.email)
      ? { email: langEnquiry.errEmail }
      : {},
  (values) =>
    values.tel || values.email
      ? {}
      : {
          tel: langEnquiry.errAtLeastTelOrEmail,
          email: langEnquiry.errAtLeastTelOrEmail,
        },
  (values) =>
    !values.chineseName && !values.englishName
      ? { chineseName: langEnquiry.errName, englishName: langEnquiry.errName }
      : {},
  (values) =>
    !values.districts?.length ? { districts: langEnquiry.errDistrict } : {},
];