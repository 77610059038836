import clsx from 'clsx';
import { registerTouchableClassNames } from 'common/polyfills/touch-polyfill';
import React from 'react';
import Button from 'react-bootstrap/esm/Button';
import classes from './NotificationListItem.module.scss';


export interface NotificationListItemProps {
	id: number;
	module: string;
  title: string;
  content: string;
  date?: string;
	isRead: boolean;
  onNavigate?: AnyFn;
  onPrimaryAction?: {
		caption: string;
		onClick: AnyFn;
	}
	onSecondaryAction?: {
		caption: string;
		onClick: AnyFn;
	}
	finished?: boolean;
}

registerTouchableClassNames(
	classes['nav-item'],
	classes['primary-action-btn'],
	classes['secondary-action-btn']
);

export default function NotificationListItem(props: NotificationListItemProps) {
  const { id, module, title, content, date, isRead, onNavigate, onPrimaryAction, onSecondaryAction } = props;

	const titleColorByModule: { [key: string]: string } = {
		PROPERTY_RECOMMENDATION: 'var(--green)',
		SERVICE_REMINDER: 'var(--yellow)',
		CLIENT_CLAIM_CONFIRM: 'var(--yellow)',
		CLIENT_CLAIM_FINISHED: 'var(--yellow)',
		NEWS_MARKET_UPDATE: 'var(--blue)',
	}

	const iconByModule: { [key: string]: JSX.Element } = {
		NEW_PROPERTY: <i className="fas fa-building" style={{ color: 'var(--dark)' }} />,
		PROPERTY_RECOMMENDATION: <i className="fas fa-building" style={{ color: 'var(--dark)' }} />,
		SERVICE_REMINDER: <i className="fas fa-exclamation-circle" style={{ color: 'var(--yellow)' }} />,
		CLIENT_CLAIM_CONFIRM: <i className="fas fa-exclamation-circle" style={{ color: 'var(--yellow)' }} />,
		CLIENT_CLAIM_FINISHED: <i className="fas fa-exclamation-circle" style={{ color: 'var(--yellow)' }} />,
		NEWS_MARKET_UPDATE: <i className="fas fa-newspaper" style={{ color: 'var(--blue)' }} />,
	}

  return <div className={clsx(classes['root'], classes['nav-item'], props.finished && classes['finished'])} onClick={onNavigate}>
    <div className={classes['main-container']}>
			<div className={classes['reminder-badge-container']}>
			{ !isRead && <div className={classes['reminder-badge']} /> }
			</div>
			<div className={classes['icon-container']}>
				<div className={classes['icon']}>
					{iconByModule[module]}
				</div>
			</div>
			<div className={classes['notification-content']}>
				<div className={classes['title']} style={{ color: titleColorByModule[module]}}>{title}</div>
				<div className={classes['content']}>{content}</div>
				<div className={classes['date']}>{date}</div>
			</div>
			<div className={classes['append-container']}>
				{ onNavigate && <i className={clsx([ classes['icon'], 'fa fa-angle-right' ])}></i> }
			</div>
    </div>
    {
      (onPrimaryAction || onSecondaryAction) && <div className={classes['actions-container']}>
				{
					onPrimaryAction && 
					<div className={classes['btn-container']}>
						<Button className={classes['primary-action-btn']} variant="outline-dark"
							onClick={onPrimaryAction.onClick}>{onPrimaryAction.caption}</Button>
					</div>
				}
				{
					onSecondaryAction && 
					<div className={classes['btn-container']}>
						<Button className={classes['secondary-action-btn']} variant="outline-pas-gray"
							onClick={onSecondaryAction.onClick}>{onSecondaryAction.caption}</Button>
					</div>
				}
    	</div>
    }
  </div>
}