import languages from "common/languages";
import { ActionType, createAction, createReducer } from 'typesafe-actions';

export interface LocaleState extends LocaleStateLocal, LocaleStateRemote {
  _bundle: { [key: string]: LocaleStateLocal & LocaleStateRemote }; 
}

export interface LocaleStateRemote { 
  // Insert remote locale bundles here
  districtHkiOptions: LocaleOptions;
  districtKltOptions: LocaleOptions;
  districtNtOptions: LocaleOptions;
  clientAppUserSalutationOptions: LocaleOptions;
  clubHouseFacilitiesOptions: LocaleOptions;
  usageOptions: LocaleOptions;
  facingOptions: LocaleOptions;
  decoOptions: LocaleOptions;
  viewOptions: LocaleOptions;
  otherFeatureOptions: LocaleOptions;
  otherOptions: LocaleOptions;
  primarySchoolNetOptions: LocaleOptions;
  secondarySchoolNetOptions: LocaleOptions;
  levelOptions: LocaleOptions;
  propertyStockStatusOptions: LocaleOptions;
}

export interface LocaleStateLocal {
  locale: string;
  /** Common Language Bundle */
  lang: LocaleOptions;
  // Agent
  langAgent: LocaleOptions;
  // Home Screen
  langHome: LocaleOptions;
  // Enquiry Dialog
  langEnquiryDialog: LocaleOptions;
  // district Select Dialog
  langDistrictSelectDialog: LocaleOptions;
  // register page
  langRegister: LocaleOptions;
  // client profile
  langClientProfile: LocaleOptions;
  //Property
  langProperty: LocaleOptions;
  //Login
  langLogin: LocaleOptions;
  // Setting
  langSetting: LocaleOptions;
  // Notification
  langNotification: LocaleOptions;
  // News
  langNews: LocaleOptions;
}

const localeInitials: LocaleState = {
  _bundle: {
    'zh_HK': languages.zh_HK as any,
    'zh_CN': (languages as any).zh_CN ?? {},
    'en': languages.en as any,
  },
  ...(languages.zh_HK as any),
};


// Actions
export const localeActions = {
  switchLocale: createAction('Locale.Switch',
    (locale: string) => ({ locale })
  )(),
  loadRemoteBundle: createAction('Locale.RemoteBundleLoaded',
    (locale: string, bundle: LocaleStateRemote) => ({ [locale]: bundle })
  )(),
};


export type LocaleActions = ActionType<typeof localeActions>;

// Reducer (Model Manager)
export const localeReducer = createReducer<LocaleState, LocaleActions>(localeInitials)
  .handleAction(localeActions.switchLocale, (state, action) => ({
    _bundle: state._bundle,
    ...state._bundle[action.payload.locale],
    locale: action.payload.locale,
  }))
  .handleAction(localeActions.loadRemoteBundle, (state, action) => {
    const newBundle: { [locale: string]: LocaleStateLocal & LocaleStateRemote } = {
      'zh_HK': { ...state._bundle['zh_HK'], ...action.payload['zh_HK'] },
      'zh_CN': { ...state._bundle['zh_CN'], ...action.payload['zh_CN'] },
      'en': { ...state._bundle['en'], ...action.payload['en'] },
    };
    return {
      _bundle: newBundle,
      ...newBundle[state.locale]
    };
  })
;