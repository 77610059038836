import useLayoutProps from 'common/hooks/useLayoutProps';
import useLocale from 'common/hooks/useLocale';
import { printPasswordComplexityRequirement } from 'common/utils/utils';
import RegisterScreenLayoutMobile from 'components/register/RegisterScreenLayoutMobile';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { IRootState } from 'reducers';
import { useCommonFormReducer } from 'reducers/common/form';
import { loginActions } from 'reducers/login';
import { registerFormActions, registerFormValidations } from 'reducers/register-form';


const RegisterPage = () => {
  const dispatch = useDispatch();
  const layoutProps = useLayoutProps();
  const history = useHistory();
  const { langRegister } = useLocale();

  useEffect(() => {
    dispatch(registerFormActions.reset());
  }, [dispatch]);

  const form = useCommonFormReducer(
    (state: IRootState) => state.registerForm,
    registerFormActions.edit,
    registerFormActions.addFormError,
    registerFormValidations(langRegister)
  );

  const { MIN_LENGTH, MIN_LENGTH_UPPER, MIN_LENGTH_LOWER, MIN_LENGTH_NUM, MIN_LENGTH_SPECIAL } = useSelector((state: IRootState) =>
    state.systemSettings?.ClientApp?.PASSWORD) ?? {};

  const pwRequirement = printPasswordComplexityRequirement(langRegister, MIN_LENGTH, MIN_LENGTH_UPPER, MIN_LENGTH_LOWER, MIN_LENGTH_NUM, MIN_LENGTH_SPECIAL);
  
  const { showPasswordComplexityRequirement }= useSelector((state: IRootState) => state.registerForm);
  
  return <RegisterScreenLayoutMobile
    layoutProps={layoutProps}
    bindEmail={form.field('email')}
    bindPassword={form.field('password')}
    bindConfirmPassword={form.field('confirmPassword')}
    bindPhoneNumber={form.field('phoneNumber')}
    bindName={form.field('name')}
    onRegisterClick={async ()=>{
      const result = await form.validateAll();
      if (result){
        dispatch(loginActions.register('normal', form.field('email')?.value ?? '', form.field('password')?.value ?? '', form.field('phoneNumber')?.value ?? '', form.field('name')?.value ?? ''));
      } else {
        dispatch(registerFormActions.clearInput());
      }
    }}
    onGoToLoginClick={()=>{
      history.push('/login');
    }}
    onBackClick={() => {
      history.goBack();
    }}

    passwordComplexityRequirement={pwRequirement}
    showPasswordComplexityRequirement={showPasswordComplexityRequirement}
  />

}

export default RegisterPage;