import clsx from 'clsx';
import { registerTouchableClassNames } from 'common/polyfills/touch-polyfill';
import React from 'react';
import classes from './ScreenTopBarMobile.module.scss';

export interface ScreenTopBarMobileProps {
  children: React.ReactNode;
  onBackClick?: AnyFn;
  background?: boolean;
  shadow?: boolean;
  sticky?: boolean;
  hideBackBtn?: boolean;
  style?: React.CSSProperties;
}

registerTouchableClassNames(
  classes['back-btn'],
);

export default function ScreenTopBarMobile(props: ScreenTopBarMobileProps) {
  const { children, shadow, sticky, background, onBackClick, hideBackBtn, style } = props;
  return <>
    {sticky ? <div className={clsx([ classes['top-bar'] ])} /> : null}
    <div className={clsx([
      classes['top-bar'],
      shadow && classes['shadow'],
      sticky && classes['sticky'],
      background && classes['background'],
    ])}>
      
      { !hideBackBtn ? <div style={{ width: '3.125rem', height: '3.125rem'}}>
        <div className={classes['back-btn']} onClick={onBackClick}>
          <i className={clsx([ classes['icon'], 'fa fa-angle-left' ])}></i>
        </div></div> : null }
      
      <div className={classes['right-node']} style={style}>
        {children}
      </div>
    </div>
  </>;
}