import clsx from 'clsx';
import useLocale from 'common/hooks/useLocale';
import { registerTouchableClassNames } from 'common/polyfills/touch-polyfill';
import FormTextField from 'components/form-controls/FormTextField';
import { FormBinder } from 'components/form-controls/IFormBinder';
import React from 'react';
import Form from 'react-bootstrap/esm/Form';
import classes from './ChangePasswordDialog.module.scss';
import ModalDialog from './ModalDialog';


export interface ChangePasswordDialogProps {
  open: boolean;
  onHide?: AnyFn;

  bindOldPassword?: FormBinder<string>;
  bindNewPassword?: FormBinder<string>;
  bindConfirmPassword?: FormBinder<string>;

  onSubmitClick?: AnyFn;

  passwordComplexityRequirement?: string;
  socialLogin?: boolean;
}

registerTouchableClassNames(
	classes['submit-button'],
)

export default function ChangePasswordDialog(props: ChangePasswordDialogProps) {
  const { open, onHide,
    bindOldPassword, bindNewPassword, bindConfirmPassword,
    onSubmitClick, passwordComplexityRequirement, socialLogin,
  } = props;
  const { lang, langClientProfile} = useLocale();

  return <ModalDialog size={'sm'} title={langClientProfile.actionChangePassword} show={open} onHide={onHide}>
    <div className={classes['change-pw-dialog']}>
     
      <div className={classes['form']}>
        
        {/* Forms */}
        {!socialLogin &&
          <div className={classes['form-row']}>
            <FormTextField label={langClientProfile.captionOldPassword} bind={bindOldPassword} type={'password'} />
          </div>
        }
        <div className={classes['form-row']}>
          <FormTextField label={langClientProfile.captionNewPassword} bind={bindNewPassword} type={'password'}/>
        </div>
        <div className={classes['form-row']}>
          <FormTextField label={langClientProfile.captionConfirmPassword} bind={bindConfirmPassword} type={'password'}/>
        </div>

        <Form.Text className={clsx({
            'text-danger': false,
          })}>{passwordComplexityRequirement}</Form.Text>
      </div>

      {/* <ModalDialogActions>
        <ModalDialogActionButton onClick={onSubmitClick} className={'submit-button'}>
          {lang.actionSubmit}
        </ModalDialogActionButton>
      </ModalDialogActions> */}
      <div className={classes['change-pw-dialog-actions']}>
        <div className={clsx(classes['submit-button'])} onClick={onSubmitClick}>
          {lang.actionSubmit}
        </div>
      </div>
      
    </div>
  </ModalDialog>
}