import { useSelector } from 'react-redux';
import { IRootState } from 'reducers';

const useLocale = () => {
  const locale = useSelector(selectLocale());
  return locale;
};

export const selectLocale = () => (state: IRootState) => state.locale; 

export default useLocale;