import clsx from 'clsx';
import 'common/general-imports';
import React from 'react';
import Modal, { ModalProps } from 'react-bootstrap/Modal';
import './ModalDialog.css';


export interface ModalDialogProps {
  title: string;
  children: React.ReactChild;
  headerChildren?: React.ReactChild | ((header: React.ReactChild) => React.ReactChild);
  bodyPadding?: boolean;

  size?: ModalProps['size'];
  hasStyle?: React.CSSProperties;

  overrideClassName?: {
    overrideModal?: string;
    overrideTitle?: string;
  };

  closeButtonWhenCustomizedHeader?: boolean;

  // delegate to bootstrap
  show?: boolean;
  onHide?: () => void;

  isMobile?: boolean;
  backdrop?: boolean;
}

export default function ModalDialog(props: ModalDialogProps) {
  const { title, children, headerChildren, bodyPadding, size, overrideClassName, show, onHide, isMobile, hasStyle, backdrop } = props;

  // useOverlayNavigationBlocking(show, onHide);

  return <Modal
    size={size}
    style={hasStyle}
    className={clsx(["pm-modal-dialog", !bodyPadding && "pm-modal-no-body-padding", isMobile && "pm-modal-dialog-mobile"])}
    dialogClassName={overrideClassName?.overrideModal}
    backdrop={backdrop ? backdrop : "static"} centered show={show} onHide={onHide}
  >
    <Modal.Header closeButton={headerChildren === undefined || props.closeButtonWhenCustomizedHeader}>
      {typeof headerChildren === 'function' ?
        headerChildren(<Modal.Title className={clsx(overrideClassName?.overrideTitle, 'modal-title-custom')}>{title}</Modal.Title>)
        :
        <>
          <Modal.Title className={overrideClassName?.overrideTitle} >{title}</Modal.Title>
          {headerChildren}
        </>
      }

    </Modal.Header>

    <Modal.Body>
      {children}
    </Modal.Body>

    {/* <Modal.Footer>
      <Button variant="secondary">Close</Button>
      <Button variant="primary">Save changes</Button>
    </Modal.Footer> */}
  </Modal>;
} 