import { BASE_URL } from 'api';
import useLayoutProps from 'common/hooks/useLayoutProps';
import useLocale from 'common/hooks/useLocale';
import { jumpers, useJump } from 'common/theme/jumper';
import { isNonEmpty, multiLang, priceToView } from 'common/utils/utils';
import { PropertyCardDesktopProps } from 'components/display-cards/home/PropertyCardDesktop';
import InlinePropertyListDesktop from 'components/property/detail/InlinePropertyListDesktop';
import { PropertyResultListItemDesktopProps } from 'components/property/search/PropertyResultListItemDesktop';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { IRootState } from 'reducers';
import { inlinePropertyListActions } from 'reducers/inline-property-list';
import { loginSelectors } from 'reducers/login';

export interface DesktopInlinePropertyListContainerProps {
  propertyNo: string;
  type: string;
  district: string;
  open: boolean;
  onHide?: AnyFn;
}

export default function GlobalInlinePropertyListDialog(props: DesktopInlinePropertyListContainerProps) {
  const layoutProps = useLayoutProps();
  const history = useHistory();
  const { propertyNo, type, district, open } = props;
  const dispatch = useDispatch();
  const { properties, contents: searchForm } = useSelector((state: IRootState) => state.inlinePropertyList);
  const { currentPropertyStock } = useSelector((state: IRootState) => state.propertyDetail);
  const { locale, districtHkiOptions, districtKltOptions, districtNtOptions, levelOptions, langProperty } = useLocale();
  const districtOptions = { ...districtHkiOptions, ...districtKltOptions, ...districtNtOptions };
  const SAME_DISTRICT_PROPERTIES_PRICE_RANGE_PERCENTAGE = useSelector((state: IRootState) => state.systemSettings.ClientApp.PROPERTY_STOCK.SAME_DISTRICT_PROPERTIES_PRICE_RANGE_PERCENTAGE);
  const SAME_DISTRICT_PROPERTIES_RENT_RANGE_PERCENTAGE = useSelector((state: IRootState) => state.systemSettings.ClientApp.PROPERTY_STOCK.SAME_DISTRICT_PROPERTIES_RENT_RANGE_PERCENTAGE);

  const jump = useJump();
  const loggedIn = useSelector(loginSelectors.loggedIn());

  // useEffect(() => {
  //   if (open) {
  //     dispatch(propertyStockDetailActions.doFetch({ propertyNo: propertyNo }));
  //   }
  // }, [ open, dispatch ]);

  useEffect(() => {
    if (!open) {
      return;
    }
    dispatch(inlinePropertyListActions.reset());
    switch (type) {
      case 'district':
        if (isNonEmpty(currentPropertyStock.price) && isNonEmpty(SAME_DISTRICT_PROPERTIES_PRICE_RANGE_PERCENTAGE)) {
          dispatch(inlinePropertyListActions.edit('priceDisplay', [
            priceToView(currentPropertyStock.price - parseInt(SAME_DISTRICT_PROPERTIES_PRICE_RANGE_PERCENTAGE) * currentPropertyStock.price / 100, locale),
            priceToView(currentPropertyStock.price + parseInt(SAME_DISTRICT_PROPERTIES_PRICE_RANGE_PERCENTAGE) * currentPropertyStock.price / 100, locale)
          ]));
        } else {
          dispatch(inlinePropertyListActions.edit('priceDisplay', [-1, -1]));

        }
        if (isNonEmpty(currentPropertyStock.rent) && isNonEmpty(SAME_DISTRICT_PROPERTIES_RENT_RANGE_PERCENTAGE)) {
          dispatch(inlinePropertyListActions.edit('rent', [
            currentPropertyStock.rent - parseInt(SAME_DISTRICT_PROPERTIES_RENT_RANGE_PERCENTAGE) * currentPropertyStock.rent / 100,
            currentPropertyStock.rent + parseInt(SAME_DISTRICT_PROPERTIES_RENT_RANGE_PERCENTAGE) * currentPropertyStock.rent / 100
          ]))
        }
        else {
          dispatch(inlinePropertyListActions.edit('rent', [-1, -1]))

        }
        dispatch(inlinePropertyListActions.edit('status', ['SALES', 'RENT']));
        dispatch(inlinePropertyListActions.edit('district', [district]));
        break;
      case 'building':
        dispatch(inlinePropertyListActions.edit('sameBuildingOfPropertyStockId', currentPropertyStock.id));
        break;
    }
    dispatch(inlinePropertyListActions.fetch({ isSwitchingPage: false }));
  }, [open, dispatch, propertyNo, type, district, currentPropertyStock, SAME_DISTRICT_PROPERTIES_PRICE_RANGE_PERCENTAGE, SAME_DISTRICT_PROPERTIES_RENT_RANGE_PERCENTAGE]);

  const propertiesProps: PropertyResultListItemDesktopProps[] = properties.filter(property => property.propertyNo !== propertyNo).map(property => ({
    id: property.caPropertyStockId,
    propertyNo: property.propertyNo,
    street: multiLang(locale, property.streetZh, property.streetEn, property.streetSc) ?? '',
    buildingName: multiLang(locale, property.buildingNameZh, property.buildingNameEn, property.buildingNameSc) ?? '',
    level: levelOptions[property.level] ?? '',
    titleZh: `${multiLang(locale, property.streetZh, property.streetEn, property.streetSc) ?? ''} ${multiLang(locale, property.buildingNameZh, property.buildingNameEn, property.buildingNameSc) ?? ''} ${levelOptions[property.level] ?? ''}`, // ?
    titleEn: `${multiLang(locale, property.streetZh, property.streetEn, property.streetSc) ?? ''} ${multiLang(locale, property.buildingNameZh, property.buildingNameEn, property.buildingNameSc) ?? ''} ${levelOptions[property.level] ?? ''}`, // ?
    image: property.photoFilename ? `${BASE_URL}/files/${property.photoFilename}` : '', // property.image, 
    net: property.net,
    gross: property.gross,
    district: districtOptions[property.district], //
    price: property.price,
    rent: property.rent,

    bookmarked: property.isBookmarked,
    isHotpick: false,
    punchline: multiLang(locale, property.punchlineTC, property.punchlineEN, property.punchlineSC),

    onBookmarkClick: () => {
      if (!loggedIn) {
        jump(jumpers.toLogin());
        return;
      }
      property.isBookmarked ?
        dispatch(inlinePropertyListActions.deleteBookmark({ caPropertyStockId: property.caPropertyStockId })) :
        dispatch(inlinePropertyListActions.addBookmark({ caPropertyStockId: property.caPropertyStockId }))
        ;
    },

    onClick: () => {
      history.push(`/properties/${property.propertyNo}`);
      props.onHide?.();
    },
  }));

  return <InlinePropertyListDesktop
    title={type === 'district' ? langProperty.titlePropertySameDistrict : langProperty.titlePropertySameBuilding}
    properties={propertiesProps}
    open={open}
    onHide={props.onHide}
  // sortKey={searchForm.sortKey ?? null}
  // direction={searchForm.direction ?? 'DESC'}

  // onSortSelect={(newSort, newDirection) => {
  //   dispatch(inlinePropertyListActions.edit('sortKey', newSort));
  //   dispatch(inlinePropertyListActions.edit('direction', newDirection));
  //   dispatch(inlinePropertyListActions.fetch({ isSwitchingPage: false }));
  // }}
  // onBackClick={() => history.goBack()}
  // onRequestNextPage={() => dispatch(inlinePropertyListActions.fetch({ isSwitchingPage: true }))}
  />
}