import { BASE_URL } from 'api';
import useComponentDidMount from 'common/hooks/useComponentDidMount';
import useLayoutProps from 'common/hooks/useLayoutProps';
import useLocale from 'common/hooks/useLocale';
import { multiLang } from 'common/utils/utils';
import PropertyResultMapScreenMobile from 'components/property/search/PropertyResultMapScreenMobile';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { IRootState } from 'reducers';
import { propertyMapActions } from 'reducers/property-map';
import { jumpers, useJump } from 'common/theme/jumper';
import { loginSelectors } from 'reducers/login';
import { propertyListActions } from 'reducers/property-list';


const DEFAULT_CENTER = { lat: 22.302711, lng: 114.177216 };
const KLN_CENTER = { lat: 22.346967, lng: 114.179379 };
const NT_CENTER = { lat: 22.458957, lng: 114.092938 };
const HKI_CENTER = { lat: 22.264033, lng: 114.193466 };

export default function PropertyMapPage() {
  const layoutProps = useLayoutProps();

  const propertyPoints = useSelector((state: IRootState) => state.propertyMap.points);
  const properties = useSelector((state: IRootState) => state.propertyMap.filtered);
  const { filteredLoading, filteredHasMore } = useSelector((state: IRootState) => state.propertyMap);

  const history = useHistory();
  const dispatch = useDispatch();
  const jump = useJump();
  const loggedIn = useSelector(loginSelectors.loggedIn());

  
  useComponentDidMount(() => {
    dispatch(propertyMapActions.fetchPoints());
  });


  const { locale, levelOptions,
    districtHkiOptions,
    districtKltOptions,
    districtNtOptions,
  } = useLocale();
  const districtOptions = { ...districtHkiOptions, ...districtKltOptions, ...districtNtOptions };

  return <PropertyResultMapScreenMobile
    layoutProps={layoutProps}
    properties={properties.map(property => ({
      id: property.caPropertyStockId,
      street: `${multiLang(locale, property.streetZh, property.streetEn, property.streetSc) ?? ''}`,
      buildingName: `${multiLang(locale, property.buildingNameZh, property.buildingNameEn, property.buildingNameSc) ?? ''}`,
      level: `${levelOptions[property.level] ?? ''}`,
      image: property.photoFilename ? `${BASE_URL}/files/${property.photoFilename}` : '' ,
      net: property.net,
      gross: property.gross,
      district: districtOptions[property.district], //
      price: property.price,
      rent: property.rent,

      bookmarked: property.isBookmarked,
      isHotpick: property.isHotPick,

      onBookmarkClick: () => {

        if (!loggedIn) {
          jump(jumpers.toLogin());
          return;
        }
        property.isBookmarked ?
          dispatch(propertyListActions.deleteBookmark({ caPropertyStockId: property.caPropertyStockId })) :
          dispatch(propertyListActions.addBookmark({ caPropertyStockId: property.caPropertyStockId }))
          ;
      },
      onClick: () => history.push(`/properties/${property.propertyNo}`),
    }))}
    center={DEFAULT_CENTER}
    propertyPoints={propertyPoints.filter(p => p.lat && p.lng).map(p => ({
      id: p.caPropertyStockId,
      lat: p.lat,
      lng: p.lng,
      district: p.district,
    }))}

    filteredLoading={filteredLoading}
    filteredHasMore={filteredHasMore}
    filteredNext={(pidList) => dispatch(propertyMapActions.fetchFiltered({ pidList, isSwitchingPage: true }))}

    onSearchListButtonClick={() => history.replace('/properties')}
    onMapClusterClick={(pidList) => dispatch(propertyMapActions.fetchFiltered({ pidList, isSwitchingPage: false }))}

    onBackClick={() => history.goBack()}
    onSearchClick={() => history.push('/advanced-search')}
  />
}