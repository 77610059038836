import { GOOGLE_MAP_API_KEY } from 'api';
import clsx from 'clsx';
import useLocale from 'common/hooks/useLocale';
import { isMobileUI } from 'common/utils/utils';
import PopupOverlay from 'components/overlay/PopupOverlay';
import GoogleMapReact from 'google-map-react';
import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import classes from './PropertyMiniMap.module.scss';

export interface PropertyMiniMapProps {
  lat: number;
  lng: number;
  label?: string;
  defaultZoom?: number;
  height?: string;

  fullscreen?: boolean;
  onHideFullscreen?: AnyFn;
}

const Marker = (props: { lat: number, lng: number, children: any }) => props.children;

const defaultCenter = {
  // Hong Kong's lat & lng
  lat: 22.302711,
  lng: 114.177216
}

const fullscreenMapEl = document.createElement('div');
document.body.appendChild(fullscreenMapEl);

export default function PropertyMiniMap(props: PropertyMiniMapProps) {
  const { lat, lng, label, defaultZoom, height, fullscreen, onHideFullscreen } = props;
  const mapRef = useRef<google.maps.Map | null>(null);
  const { langProperty } = useLocale();
  const [ bounds, setBounds ] = useState<[number, number, number, number]>([0, 0, 0, 0]);
  const [ zoom, setZoom ] = useState(defaultZoom ?? 16);
  const [ center, setCenter ] = useState<{lat: number, lng: number }>(defaultCenter);

  useEffect(() => {
    if (lat && lng) {
      setCenter({ lat: lat, lng: lng })
    } else {
      setCenter(defaultCenter);
    }
  }, [ lat, lng ]);

  const [ showFullScreenMapIOS, setShowFullScreenMapIOS ] = useState(false);
  const fullScreenMapIOS = fullscreen ? null : ReactDOM.createPortal(<PopupOverlay
    show={showFullScreenMapIOS}
    onHide={() => setShowFullScreenMapIOS(false)}
    noIOSInsect
  >
      <PropertyMiniMap {...props} fullscreen onHideFullscreen={() => setShowFullScreenMapIOS(false)} />
    </PopupOverlay>,
    fullscreenMapEl,
  );

  return <div className={clsx({ 
      [classes['property-mini-map']]: true, 
      [classes['mobile']]: !fullscreen && isMobileUI(), 
      [classes['desktop']]: !fullscreen && !isMobileUI(),
      [classes['fullscreen']]: fullscreen,
    })}
    style={{ height: height}}
  >
    {fullScreenMapIOS}
    <GoogleMapReact
      bootstrapURLKeys={{
        key: GOOGLE_MAP_API_KEY,
        language: 'en'
      }}
      yesIWantToUseGoogleMapApiInternals
      center={center}      
      zoom={zoom}
      // distanceToMouse={() => {}}
      onGoogleApiLoaded={({ map }) => {
        mapRef.current = map;
        // Customer control on map:
        // https://developers.google.com/maps/documentation/javascript/examples/control-custom
        // https://spectrum.chat/react-google-maps/general/adding-a-custom-control-to-your-map~9b99eeee-5c2a-422f-b8d5-8f8cd02038ec
        // Create the DIV to hold the control
        const centerControlDiv = document.createElement("div");
        ReactDOM.render(<button className={classes['center-btn']} onClick={() => mapRef.current?.setCenter(center)}>{langProperty.actionCenterMap}</button>, centerControlDiv);
        mapRef.current?.controls[google.maps.ControlPosition.TOP_LEFT].push(centerControlDiv);

        if (!window.document.body.requestFullscreen) {
          const fullscreenControlIOSDiv = document.createElement("div");
          ReactDOM.render(<button className={classes['full-screen-btn']} onClick={() => !fullscreen ? setShowFullScreenMapIOS(true) : onHideFullscreen?.()}>
            <i className={clsx('fas', !fullscreen ? 'fa-expand' : 'fa-compress')}></i>  
          </button>, fullscreenControlIOSDiv);
          mapRef.current?.controls[google.maps.ControlPosition.TOP_RIGHT].push(fullscreenControlIOSDiv);
        }
      }}
      onChange={({ zoom, bounds }) => { 
        setZoom(zoom);
        setBounds([
          bounds.nw.lng,
          bounds.se.lat,
          bounds.se.lng,
          bounds.nw.lat
        ]);
      }}
    >
      <Marker
        key={label}
        lat={lat}
        lng={lng}
      >
        <div className={classes['marker']} onClick={() => {
          mapRef.current?.setCenter(center);
        }}>
          {/* <i className="fas fa-map-marker-alt"></i> */}
          <img src="images/map-marker.png" />
          <div className={classes['label']}>{label}</div>
        </div>
      </Marker>
    </GoogleMapReact>
  </div>
}