import clsx from 'clsx';
import { isMobileUI } from 'common/utils/utils';
import React from 'react';
import classes from './PropertyFeaturePanelSmall.module.scss';

export interface PropertyFeaturePanelSmallProps {
  features: string[];
}

export default function PropertyFeaturePanelSmall({ features }: PropertyFeaturePanelSmallProps) {
  return <div className={classes['property-feature-panel-small']}>
    {
      features.map(f => 
      <div className={clsx({ 
        [classes['tile']]: true, 
        [classes['desktop']]: !isMobileUI(), 
        [classes['mobile']]: isMobileUI() })
      }>
        <div className={clsx(classes['inner'], !isMobileUI() && classes['desktop'])}>{f}</div>
      </div>
      )
    }
  </div>
}