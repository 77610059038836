import clsx from 'clsx';
import 'common/general-imports';
import useLocale from 'common/hooks/useLocale';
import { registerTouchableClassNames } from 'common/polyfills/touch-polyfill';
import { FormBinder } from 'components/form-controls/IFormBinder';
import React from 'react';
import { useDispatch } from 'react-redux';
import { homeActions } from 'reducers/home';
import { propertyListActions } from 'reducers/property-list';
import HistoryListItem, { HistoryListItemProps } from './HistoryListItem';
import classes from './HomeSearchAutocompletePopup.module.scss';

registerTouchableClassNames(
	classes['clickable-item'],
)

export interface HomeSearchAutocompletePopupProps {
  mode: 'history' | 'suggestion';
  
  // History List
  histories: HistoryListItemProps[];
  
  // Suggestion List
  streets: string[];
  buildingNames: string[];
  districts: { key: string, display: string }[];
  
  bindSearch?: FormBinder<string>;
}

export default function HomeSearchAutocompletePopup(props: HomeSearchAutocompletePopupProps) {
  const { mode, histories, streets, buildingNames, 
    districts, bindSearch } = props;
  
  const { lang, langProperty, districtHkiOptions, districtKltOptions, districtNtOptions } = useLocale();

  const districtOptions = { ...districtHkiOptions, ...districtKltOptions, ...districtNtOptions };

  const dispatch = useDispatch();

  const historyIsEmpty = histories.length === 0;
  const suggestionIsEmpty = streets.length === 0 && buildingNames.length === 0 
    && districts.length === 0;

  const isEmpty = mode === 'history' && historyIsEmpty 
    || mode === 'suggestion' && suggestionIsEmpty;

  const history = () => {
    return <div>
      <div className={classes['list-item']}>
        <div className={classes['list-item-header']}>
          <i className="fas fa-history"></i>
          <div>{lang.captionSearchHistory}</div>
        </div>
      </div>
      {
        historyIsEmpty && <div className={classes['list-item']}>
          <div className={classes['empty-message']}>
            <div>{lang.captionNoSearchResults}</div>
          </div>
        </div>
      }
      {
        histories.map((history, i) => <div key={history.id} className={clsx(classes['list-item'], classes['clickable-item'])}
          onClick={() => {
            dispatch(propertyListActions.reset());
            dispatch(homeActions.updateSearchType(history.buyOrRent as ('BUY' | 'RENT')));
            dispatch(propertyListActions.editHomeSearch('buyOrRent', history.buyOrRent));
            dispatch(propertyListActions.editHomeSearch('priceDisplay', history.priceDisplay));
            dispatch(propertyListActions.editHomeSearch('rent', history.rent));
            dispatch(propertyListActions.editHomeSearch('room', history.room));
            if (history.district) {
              dispatch(propertyListActions.editHomeSearch('district', [ history.district ]));
              bindSearch?.change(districtOptions[history.district]);
            } else if (history.buildingName) {
              dispatch(propertyListActions.editHomeSearch('buildingName', history.buildingName));
              bindSearch?.change(history.buildingName);
            } else if (history.street) {
              dispatch(propertyListActions.editHomeSearch('street', history.street));
              bindSearch?.change(history.street);
            } 
          }}
        ><HistoryListItem
          id={history.id}
          buyOrRent={history.buyOrRent}
          priceDisplay={history.priceDisplay}
          rent={history.rent}
          room={history.room}
          district={history.district}
          buildingName={history.buildingName}
            street={history.street}
        /></div>)
      }
    </div>
  }

  const suggestion = () => {
    return <div>
      {
        suggestionIsEmpty && <div className={classes['list-item']}>
          <div className={classes['empty-message']}>
            <div>{lang.captionNoSearchResults}</div>
          </div>
        </div>
      }
      { districts.length > 0 && <div className={classes['list-item']}>
          <div className={classes['list-item-header']}>
            <div>{langProperty.captionDistrict}</div>
          </div>
        </div>
      }
      {
        districts.map(district => <div key={`district-${district.key}`} className={clsx(classes['list-item'], classes['clickable-item'])}
          onClick={() => {
            dispatch(propertyListActions.editHomeSearch('district', [ district.key ]));
            bindSearch?.change(district.display);
          }}
        >
          {district.display}
        </div>)
      }
      { buildingNames.length > 0 && <div className={classes['list-item']}>
          <div className={classes['list-item-header']}>
            <div>{langProperty.captionBuilding}</div>
          </div>
        </div>
      }
      {
        buildingNames.map(building => <div key={`building-${building}`} className={clsx(classes['list-item'], classes['clickable-item'])}
          onClick={() => {
            dispatch(propertyListActions.editHomeSearch('buildingName', building));
            bindSearch?.change(building);
          }}
        >
          {building}
        </div>)
      }
      { streets.length > 0 && <div className={classes['list-item']}>
          <div className={classes['list-item-header']}>
            <div>{langProperty.captionStreet}</div>
          </div>
        </div>
      }
      {
        streets.map(street => <div key={`street-${street}`} className={clsx(classes['list-item'], classes['clickable-item'])}
          onClick={() => {
            dispatch(propertyListActions.editHomeSearch('street', street));
            bindSearch?.change(street);
          }}
        >
          {street}
        </div>)
      }
    </div>
  }

  return <div className={clsx({ [classes['main']]: true,
    [classes['empty-result']]: isEmpty})
  }>
    {mode === 'history' ? history() : suggestion()}
  </div>
}