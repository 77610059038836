import { ActionType, createAction, createReducer } from 'typesafe-actions';

export interface RegisterDialogState {
  open: boolean;
  currentStep: number;
}

export type RegisterDialogActions = ActionType<typeof registerDialogActions>;

export const registerDialogActions = {
  open: createAction('RegisterDialog.Open')(),
  close: createAction('RegisterDialog.Close')(),
  setCurrentStep: createAction('RegisterDialog.SetCurrentStep', (step: number) => ({ step }))()
};

export const registerDialogReducer = createReducer<RegisterDialogState, RegisterDialogActions>({ open: false, currentStep: 0 })
  .handleAction(registerDialogActions.open,
    (state, _) => ({ ...state, open: true })
  )
  .handleAction(registerDialogActions.close,
    (state, _) => ({ ...state, open: false })
  )
  .handleAction(registerDialogActions.setCurrentStep,
    (state, action) => ({ ...state, currentStep: action.payload.step })
  )
;
