import useLocale from 'common/hooks/useLocale';
import { addTouchableHoveringSupport } from 'common/polyfills/touch-polyfill';
import DistrictSelectDialog from 'components/overlay/DistrictSelectDialog';
import React, { useMemo, useState } from 'react';
import { DistrictFilterProps } from './DistrictFilter';
import classes from './DistrictFilterDesktop.module.scss';


addTouchableHoveringSupport(
  `.${classes['filter-btn']}`, [], classes['no-hover'],
);

export default function DistrictFilterDesktop(props: DistrictFilterProps) {
  const { bindDistricts, regionBundles, onBackClick } = props;
  const [ districtDialogOpen, setDistrictDialogOpen ] = useState(false);
  const handleDistrictDialogClose = () => setDistrictDialogOpen(false);

  const allDistrictOptions = useMemo(() => {
    return {
      ...regionBundles.districtHkiOptions,
      ...regionBundles.districtKltOptions,
      ...regionBundles.districtNtOptions,
    };
  }, [ regionBundles ]);

  const { lang } = useLocale();

  const numberOfAllDistrict = useMemo(() => Object.keys(allDistrictOptions).length, [allDistrictOptions]);


  const selectedDistrictText = useMemo(() => {
    const selectedDistricts = bindDistricts?.value ?? [];
    if (selectedDistricts.length === 0) return lang.captionAllDistricts;
    if (selectedDistricts.length === numberOfAllDistrict) return lang.captionAllDistricts;
    return selectedDistricts?.map(n => allDistrictOptions[n])?.join(', ')
  }, [bindDistricts, lang, allDistrictOptions]);

  return <div className={classes['district-filter-desktop']}>
      <DistrictSelectDialog confirmRequired {...regionBundles} open={districtDialogOpen} onHide={handleDistrictDialogClose} districts={bindDistricts} />
      {/* <VerticalScrollView>
        <div className={classes['tag-pane']}>
          {bindDistricts?.value?.length ? null : <div className={classes['tag']} key="ALL">{lang.captionAllDistricts}</div>}
          {bindDistricts?.value?.map(n => 
            <div key={n} className={classes['tag']} onClick={() => bindDistricts?.change(
              bindDistricts?.value?.filter(d => d !== n)
            )}>
              {allDistrictOptions[n]}
            </div>
          )}
        </div>
      </VerticalScrollView> */}
      <div className={classes['filter-btn']} onClick={() => setDistrictDialogOpen(true)}>
        <i className="fa fa-filter" />
      </div>
      
      <div className={classes['district-container']}>
        <div className={classes['caption']}>
          {lang.captionDistrict + ': '}
        </div>
        <div className={classes['selected-district-text']}>
        {selectedDistrictText}
        </div>
      </div>
      
    </div>;
}