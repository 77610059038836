import 'common/general-imports';
import useLocale from 'common/hooks/useLocale';
import { registerTouchableClassNames } from 'common/polyfills/touch-polyfill';
import React from 'react';
import classes from './SocialLoginButton.module.scss';



export interface SocialLoginButtonProps {
	caption: string;
	logoImage: string;
	
	onClick?: () => any;
}

registerTouchableClassNames(
  classes['social-login-btn']
);

export default function SocialLoginButton(props: SocialLoginButtonProps) {
	const { caption, logoImage, onClick } = props;

	const { lang } = useLocale();

	return <div className={classes['social-login-btn']} onClick={onClick}>
		<div className={classes['btn-icon']}>
			<img src={logoImage} />
		</div>
		<div className={classes['btn-caption']}>
			{caption}
		</div>
	</div>
}