import { BASE_URL } from 'api';
import useLocale from 'common/hooks/useLocale';
import { handleBlockDisplay, multiLang } from 'common/utils/utils';
import ContactAgentDialog from 'components/overlay/ContactAgentDialog';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { matchPath } from 'react-router';
import { useLocation } from 'react-router-dom';
import { IRootState } from 'reducers';
import { agentContactActions, agentContactFormSpec } from 'reducers/agent-contact';
import { useFormSpec } from 'reducers/common/form';

const EMPTY = {};
export default function GlobalAgentContactDialog() {
  const dispatch = useDispatch();
  const location = useLocation();
  
  const match = matchPath(location.pathname, {
    path: "/properties/:id",
    exact: true,
    strict: true
  });

  const { open, currentAgent } = useSelector((state: IRootState) => state.agentContact);
  const { currentPropertyStock } = useSelector((state: IRootState) => state.propertyDetail) ?? EMPTY;
  const { email, phoneNumber, name } = useSelector((state: IRootState) => state.login);
  
  const { districtHkiOptions, districtKltOptions, districtNtOptions, 
    langEnquiryDialog, levelOptions, locale } = useLocale();

  const districtOptions = { ...districtHkiOptions, ...districtKltOptions, ...districtNtOptions };

  const form = useFormSpec(
    (state: IRootState) => state.agentContact,
    agentContactFormSpec,
    langEnquiryDialog,
  );

  const dialogPropertyInfoProps = ({
    id: currentPropertyStock.id,
    street: `${multiLang(locale, currentPropertyStock.streetZh, currentPropertyStock.streetEn, currentPropertyStock.streetSc) ?? ''}`,
    buildingName: `${multiLang(locale, currentPropertyStock.buildingNameZh, currentPropertyStock.buildingNameEn, currentPropertyStock.buildingNameSc) ?? ''}`,
    level: `${levelOptions[currentPropertyStock.level ?? ''] ?? ''}`,
    image: currentPropertyStock.photos.length > 0 ? `${BASE_URL}/files/${currentPropertyStock.photos?.[0]}` : '',
    net: currentPropertyStock.net,
    district: districtOptions[currentPropertyStock.district],
    gross: currentPropertyStock.gross,
    rent: currentPropertyStock.rent,
    price: currentPropertyStock.price
  });

  useEffect(() => {
    dispatch(agentContactActions.edit('contact', phoneNumber ?? form.field('contact').value));
    dispatch(agentContactActions.edit('email', email ?? form.field('email').value));
    dispatch(agentContactActions.edit('name', name ?? form.field('name').value));
  }, [ dispatch, email, phoneNumber, open ]);

  return <ContactAgentDialog open={open} onHide={() => {
      dispatch(agentContactActions.closeDialog());
      // dispatch(agentContactActions.reset());
    }}
    agentViewProps={currentAgent}
    dialogPropertyInfoProps={dialogPropertyInfoProps}
    propertySpecific={match?.isExact}
    bindContact={form.field('contact')}
    bindEmail={form.field('email')}
    bindName={form.field('name')}
    onSubmitClick={async () => {
      const result = await form.validateAll();
      if (result) dispatch(agentContactActions.doContact());    
    }}
  />;
}