import AgentDetailDialog from 'components/overlay/AgentDetailDialog';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from 'reducers';
import { agentContactActions } from 'reducers/agent-contact';
import { agentDetailActions } from 'reducers/agent-detail';

export default function GlobalAgentDetailDialog() {
  const dispatch = useDispatch();
  // const history = useHistory();

  const { open, comments, currentAgent } = useSelector((state: IRootState) => state.agentDetail);
  
  return <AgentDetailDialog open={open} onHide={() => {
      dispatch(agentDetailActions.closeDialog());
      dispatch(agentDetailActions.reset());
    }}
    agentViewProps={currentAgent}
    comments={comments.map(c => ({ 
      id: c.id, 
      comment: c.comment ?? '',
      rating: c.rating,
      name: c.customerName ?? '',
      isAnonymous: c.isAnonymous ?? false,
    }))}

    onContactAgentClick={() => {
      dispatch(agentContactActions.openDialog({
        ...currentAgent,
        entry: 'D',
      }));
    }}

  />;
}