import CACustomerDTO from 'common/dto/CACustomerDTO';
import useLayoutProps from 'common/hooks/useLayoutProps';
import useLocale from 'common/hooks/useLocale';
import { printPasswordComplexityRequirement } from 'common/utils/utils';
import RegisterScreenDesktop from 'components/register/RegisterScreenDesktop';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from 'reducers';
import { useCommonFormReducer } from 'reducers/common/form';
import { customerActions, customerValidations } from 'reducers/customer';
import { loginActions } from 'reducers/login';
import { loginFormActions } from 'reducers/login-form';
import { registerDialogActions } from 'reducers/register-dialog';
import { registerFormActions, registerFormValidations } from 'reducers/register-form';

const FALSE = false;

export default function GlobalLoginDialog() {
  const dispatch = useDispatch();
  const layoutProps = useLayoutProps();
  const { langRegister, langClientProfile } = useLocale();

  const { loggedIn } = useSelector((state: IRootState) => state.login);
  const { open, currentStep } = useSelector((state: IRootState) => state.registerDialog);

  const registerForm = useCommonFormReducer(
    (state: IRootState) => state.registerForm,
    registerFormActions.edit,
    registerFormActions.addFormError,
    registerFormValidations(langRegister)
  );

  const { MIN_LENGTH, MIN_LENGTH_UPPER, MIN_LENGTH_LOWER, MIN_LENGTH_NUM, MIN_LENGTH_SPECIAL } = useSelector((state: IRootState) =>
    state.systemSettings?.ClientApp?.PASSWORD) ?? {};

  const pwRequirement = printPasswordComplexityRequirement(langRegister, MIN_LENGTH, MIN_LENGTH_UPPER, MIN_LENGTH_LOWER, MIN_LENGTH_NUM, MIN_LENGTH_SPECIAL);
  
  const { showPasswordComplexityRequirement }= useSelector((state: IRootState) => state.registerForm);
  
  useEffect(() => {
    if (loggedIn) dispatch(customerActions.doFetchCustomer());
  }, [ loggedIn ?? FALSE ])

  const customerForm = useCommonFormReducer(
    (state: IRootState) => state.customer,
    customerActions.edit,
    customerActions.addFormError,
    customerValidations(langClientProfile)
  );

  const customerProfileFormValues = useSelector((state: IRootState) => state.customer.contents);
  return <RegisterScreenDesktop 
    open={open}
    onHide={() => {
      dispatch(registerDialogActions.close());
      dispatch(registerDialogActions.setCurrentStep(0));
      dispatch(registerFormActions.reset());
    }}
    step={currentStep}
    registerViewProps={{
      layoutProps: layoutProps,
      bindEmail: registerForm.field('email'),
      bindPassword: registerForm.field('password'),
      bindConfirmPassword: registerForm.field('confirmPassword'),
      bindPhoneNumber: registerForm.field('phoneNumber'),
      bindName: registerForm.field('name'),
      onRegisterClick: async ()=>{
        const result = await registerForm.validateAll();
        if (result){
          dispatch(loginActions.register('normal', registerForm.field('email')?.value ?? '', registerForm.field('password')?.value ?? '', registerForm.field('phoneNumber')?.value ?? '', registerForm.field('name')?.value ?? ''));
        } else {
          dispatch(registerFormActions.clearInput());
        }
      },
      onGoToLoginClick: () => {
        dispatch(registerDialogActions.close());
        dispatch(registerFormActions.reset());
        dispatch(loginFormActions.openDialog());
      },

      passwordComplexityRequirement: pwRequirement,
      showPasswordComplexityRequirement: showPasswordComplexityRequirement
    }}
    registerMoreInfoViewProps={{
      layoutProps: layoutProps,
      name: customerForm.field('name').value ?? '',
      bindPhoneNumber: customerForm.field('phoneNumber'),
      bindDistrict: customerForm.field('districts'),
      bindReceiveEmail: customerForm.field('receiveEmail'),
      bindSubscribeNewsletter: customerForm.field('subscribeNewsletter'),
      onSaveClick: () => {
        dispatch(customerActions.doUpdateCustomer({
          ...customerProfileFormValues,
        } as CACustomerDTO));
        dispatch(registerDialogActions.close());
        dispatch(registerDialogActions.setCurrentStep(0));
      },
      onSkipClick: () => {
        dispatch(registerDialogActions.close());
        dispatch(registerDialogActions.setCurrentStep(0));
      }
    }}
  />
}