import { ActionType, createAction, createReducer } from 'typesafe-actions';

export interface ForgotPasswordState {
	email?: string;
	open?: boolean;
	emailErrorMessage?: string;
}

export const forgotPasswordActions = {
	reset: createAction('ForgotPassword.Reset')(),
	updateEmail: createAction('ForgotPassword.FieldUpdated')<string>(),
	updateEmailError: createAction('ForgotPassword.EmailErrorUpdated')<string>(),
	openDialog: createAction('ForgotPassword.DialogOpen')(),
	closeDialog: createAction('ForgotPassword.DialogClose')(),
	doRequest: createAction('ForgotPassword.ConfirmRequest')<string>(),
	success: createAction('ForgotPassword.Success')(),
	clearInput: createAction('ForgotPassword.ClearInput')(),
};

export type ForgotPasswordActions = ActionType<typeof forgotPasswordActions>;

const initialState = (): ForgotPasswordState => ({});

export const forgotPasswordReducer = createReducer<ForgotPasswordState, ForgotPasswordActions>(initialState())
	.handleAction(forgotPasswordActions.reset,
		(_state, _action) => initialState()
	)
	.handleAction(forgotPasswordActions.updateEmail,
		(state, action) => ({ ...state, email: action.payload })
	)
	.handleAction(forgotPasswordActions.updateEmailError,
		(state, action) => ({ ...state, emailErrorMessage: action.payload })
	)
	.handleAction(forgotPasswordActions.openDialog, 
		(state, _) => ({ ...state, open: true })
	)
	.handleAction(forgotPasswordActions.closeDialog, 
		(state, _) => ({ ...state, open: false })
	)
	.handleAction(forgotPasswordActions.clearInput,
		(state, _) => ({ ...state, email: "" })
	)
;