import clsx from 'clsx';
import 'common/general-imports';
import React from 'react';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import baseTextFieldProps from './baseTextFieldProps';
import { FormBinder } from './IFormBinder';
import classes from './TextField.module.css';


export interface FormTextFieldProps {
  bind?: FormBinder<string>;
  name?: string;
  label: string;
  readOnly?: boolean;
  hideClearButton?: boolean;
  // TextArea Props
  multiline?: boolean;
  rows?: number;
  type?: string;
  overrideClassName?: string;
  errorMessagePlaceHeld?: boolean;
  onClick?: AnyFn;
  prepend?: JSX.Element;
  prependClassName?: string;
  onSubmit?: AnyFn;
}

export default function FormTextField({ bind, name, label, multiline, rows, type, readOnly, hideClearButton, overrideClassName, errorMessagePlaceHeld, onClick, prepend, prependClassName, onSubmit }: FormTextFieldProps) {
  const inputEl = <Form.Group className={classes['gray-input-group']} onClick={onClick}>
    <InputGroup>
      {prepend ? <InputGroup.Prepend>
        <InputGroup.Text className={clsx(classes['gray-input-append'], prependClassName)}>
          {prepend}
        </InputGroup.Text>
      </InputGroup.Prepend> : null}
      <Form.Control
        name={name}
        isInvalid={!!bind?.errorMessage}
        {...baseTextFieldProps(bind)}
        className={clsx(classes['gray-input'], overrideClassName)}
        placeholder={label}
        as={multiline ? 'textarea' : undefined}
        rows={rows}
        type={type ?? undefined}
        readOnly={readOnly}
        autoComplete={type === 'password' ? 'new-password' : 'off'}
      />
      {!hideClearButton && bind?.value ? <InputGroup.Append onClick={() => bind.change('')}>
        <InputGroup.Text className={clsx(classes['gray-input-append'], overrideClassName)}>
          <i className={`fas fa-times-circle`} />
        </InputGroup.Text>
      </InputGroup.Append> : null}
    </InputGroup>

    <Form.Text className={clsx({
      'text-danger': !!bind?.errorMessage,
    })}>{bind?.errorMessage}{errorMessagePlaceHeld ? <>&nbsp;</> : ''}</Form.Text>
  </Form.Group>;

  const wrappedSubmit = (ev: React.FormEvent<any>) => {
    ev.preventDefault();
    onSubmit?.();
  };
  return onSubmit ? <form onSubmit={wrappedSubmit}>{inputEl}</form> : inputEl;
}