import clsx from 'clsx';
import useLocale from 'common/hooks/useLocale';
import ScreenTopBarMobile from 'components/navigation/ScreenTopBarMobile';
import ListView, { ListViewItem } from 'components/scaffold/ListView';
import MainLayoutMobile from 'components/scaffold/MainLayoutMobile';
import { BaseLayoutProps } from 'components/scaffold/MainLayoutProps';
import React, { useState } from 'react';
import classes from './QAScreenMobile.module.scss';

export interface QAScreenMobileProps {
  layoutProps: BaseLayoutProps;
  qaItems: { 
    index: number;
    question: string;
    answer: string;
  } [];
  onBackClick?: AnyFn;
}

export default function QAScreenMobile(props: QAScreenMobileProps) {
  const { layoutProps, qaItems, onBackClick } = props;
  const { langSetting } = useLocale();

  const [ openedIndex, setOpenedIndex ] = useState<number[]>([]);

  const open = (index: number) => {
    if (openedIndex.includes(index)) {
      setOpenedIndex(openedIndex.filter(i => i !== index ));
    } else {
      setOpenedIndex([ ...openedIndex, index ]);
    }
  }

  return <MainLayoutMobile {...layoutProps}>
    <div className={classes['root']}>
      <ScreenTopBarMobile onBackClick={onBackClick} shadow>
        <div className={classes['title']}>{langSetting.captionQA}</div>
      </ScreenTopBarMobile>

      <div className={classes['list-container']}>
        <ListView>
          {qaItems.map(item => <>
            <ListViewItem key={item.question} overrideClassName={classes['item-container']}>
              <div className={clsx(classes['question'])} onClick={() => open(item.index)}>
                <div className={clsx({ [classes['caption']]: true, [classes['active']]: openedIndex.includes(item.index) })}>
                  {item.question}
                </div>
                <div className={classes['chevron']}>
                  { !openedIndex.includes(item.index) && <i className={"fas fa-chevron-right"} /> }
                  { openedIndex.includes(item.index) && <i className={"fas fa-chevron-down"} /> }
                </div>
              </div>
            </ListViewItem>
            { openedIndex.includes(item.index) && <ListViewItem key={item.answer} overrideClassName={classes['item-container']}>
              <div className={classes['answer']}>
                <div className={classes['caption']}>{item.answer}</div>
              </div>
            </ListViewItem> }
          </>)}
        </ListView>
      </div>
    </div>
  </MainLayoutMobile>
}