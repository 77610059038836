import { BASE_URL } from 'api';
import CAPropertyStockListItemDTO from 'common/dto/CAPropertyStockListItemDTO';
import useComponentDidMount from 'common/hooks/useComponentDidMount';
import useLayoutProps from 'common/hooks/useLayoutProps';
import useLocale from 'common/hooks/useLocale';
import { jumpers, useJump } from 'common/theme/jumper';
import { multiLang, useQuery } from 'common/utils/utils';
import { PropertyResultListItemProps } from 'components/property/search/PropertyResultListItem';
import PropertySearchResultScreenMobile from 'components/property/search/PropertySearchResultScreenMobile';
import deepEqual from 'deep-equal';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { IRootState } from 'reducers';
import { alertConfirmDialogActions } from 'reducers/alert-confirm-dialog';
import { loginSelectors } from 'reducers/login';
import { propertyListActions } from 'reducers/property-list';

const EMPTY_ARRAY: any[] = [];
const EMPTY_STRING = '';

export function getMergedProperties<V>(
    properties: CAPropertyStockListItemDTO[],
    soldLeasedProperties: CAPropertyStockListItemDTO[],
    propertyPropsMap: (dto: CAPropertyStockListItemDTO, soldLeased?: boolean) => V,
    DISPLAY_FIRST_SOLD_LEASED_PROPERTY_AFTER_N_PROPERTIES: string,
    DISPLAY_REST_SOLD_LEASED_PROPERTIES_AFTER_N_PROPERTIES: string,
  ) {
  if (properties?.length > 0 || soldLeasedProperties?.length > 0) {       
    const soldLeasedPropertiesProps: V[] = soldLeasedProperties.map(property => propertyPropsMap(property, true));
    const propertiesProps: V[] = properties.map(property => propertyPropsMap(property));

    let sl = 0;
    let merged = [];
    
    for (let i = 0; i < propertiesProps.length; i++) {
      const firstInsertPos = parseInt(DISPLAY_FIRST_SOLD_LEASED_PROPERTY_AFTER_N_PROPERTIES) - 1;
      const isFirstInsertPos = i === firstInsertPos;
      const isRestInsertPos = i !== 0 && ((i + 1) - (firstInsertPos + 1)) % (parseInt(DISPLAY_REST_SOLD_LEASED_PROPERTIES_AFTER_N_PROPERTIES)) === 0;
  
      if (isFirstInsertPos || isRestInsertPos) {
        // console.log("i:", i);
        // console.log("(i + 1) - firstInsertPos:", (i + 1) - firstInsertPos);
        // console.log("rest:", ((i + 1) - firstInsertPos) % (parseInt(DISPLAY_REST_SOLD_LEASED_PROPERTIES_AFTER_N_PROPERTIES)));
        
        merged.push(propertiesProps[i]);
        if (sl < soldLeasedPropertiesProps.length) {
          merged.push(soldLeasedPropertiesProps[sl]);
          sl = sl + 1;
        }
      } else {
        merged.push(propertiesProps[i]);
      }
    }

    // setMergedPropertiesProps(merged);
    return merged;
  } else {
    return [];
  }
}

export default function PropertySearchListingPage() {
  const layoutProps = useLayoutProps();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { properties, soldLeasedProperties, contents: searchForm, hasMore } = useSelector((state: IRootState) => state.propertyList);
  const { lang, locale, districtHkiOptions, districtKltOptions, districtNtOptions, levelOptions, langProperty } = useLocale();

  const { DISPLAY_FIRST_SOLD_LEASED_PROPERTY_AFTER_N_PROPERTIES, 
    DISPLAY_REST_SOLD_LEASED_PROPERTIES_AFTER_N_PROPERTIES } = useSelector((state: IRootState) => state.systemSettings.ClientApp.PROPERTY_STOCK);
  
  // const DISPLAY_FIRST_SOLD_LEASED_PROPERTY_AFTER_N_PROPERTIES = '1';
  // const DISPLAY_REST_SOLD_LEASED_PROPERTIES_AFTER_N_PROPERTIES = '3';

  const jump = useJump();
  const loggedIn = useSelector(loginSelectors.loggedIn());

  // Add useLocation for monitoring the entries from homePage's tags
  const searchFormRef = useRef(searchForm);

  // Update the values of searchForm for comparing the init data
  useEffect(()=>{
    searchFormRef.current = searchForm;
  }, [searchForm]); 

  //Reset the search criteria when entered from homepage's tags and the search criteria have changed
  useEffect(() => {
    return () => {
      // Note: `searchForm` here stands for the initial search data when the page is first rendered.
      if ((location.state as any)?.fromTag && deepEqual(searchFormRef.current, searchForm)){
        dispatch(propertyListActions.reset());
      }
    };
  }, []);

  const searchParam = useQuery(location).get('buyOrRent') ?? '';

  useComponentDidMount(() => {
    if (properties.length === 0) {
      // If property list are empty, try fetch once
      // if (isNonEmpty(searchParam)) dispatch(propertyListActions.edit('buyOrRent', searchParam));
      dispatch(propertyListActions.fetch({ isSwitchingPage: false }));
    } else {
      dispatch(propertyListActions.fetch({ isSwitchingPage: false }));
    }
  });

  // useEffect(() => {
  //   dispatch(propertyListActions.fetch({ isSwitchingPage: false }));
  // }, [ searchForm ]);

  const districtOptions = { ...districtHkiOptions, ...districtKltOptions, ...districtNtOptions };

  const propertyPropsMap = (property: CAPropertyStockListItemDTO, soldLeased = false) => ({
    id: property.caPropertyStockId,
    street: multiLang(locale, property.streetZh, property.streetEn, property.streetSc) ?? '',
    buildingName: multiLang(locale, property.buildingNameZh, property.buildingNameEn, property.buildingNameSc) ?? '',
    level: levelOptions[property.level] ?? '',
    image: property.photoFilename ? `${BASE_URL}/files/${property.photoFilename}` : '' , // property.image, 
    net: property.net,
    gross: property.gross,
    district: districtOptions[property.district], //
    price: property.price,
    rent: property.rent,
    status: property.status,
    bookmarked: property.isBookmarked,
    isHotpick: property.isHotPick,

    onBookmarkClick: () => {
      if (!loggedIn) {
        jump(jumpers.toLogin());
        return;
      }
      property.isBookmarked ? 
        dispatch(propertyListActions.deleteBookmark({ caPropertyStockId: property.caPropertyStockId })) :
        dispatch(propertyListActions.addBookmark({ caPropertyStockId: property.caPropertyStockId }))
      ;
    },

    onClick: () => {
      if (soldLeased) {
        dispatch(alertConfirmDialogActions.alert(
          '',
          property.status === 'SOLD' ? langProperty.msgSoldClicked : langProperty.msgLeasedClicked,
          lang.actionGoBack, ''
        ));
      } else {
        history.push(`/properties/${property.propertyNo}`);
      }
    },
  });

  const [ mergedPropertiesProps, setMergedPropertiesProps ] = useState<PropertyResultListItemProps[]>([]);

  useEffect(() => {
    const merged = getMergedProperties(
      properties, soldLeasedProperties,
      propertyPropsMap,
      DISPLAY_FIRST_SOLD_LEASED_PROPERTY_AFTER_N_PROPERTIES, DISPLAY_REST_SOLD_LEASED_PROPERTIES_AFTER_N_PROPERTIES
    );
    setMergedPropertiesProps(merged);
  }, [ properties ?? EMPTY_ARRAY, soldLeasedProperties ?? EMPTY_ARRAY ]);

  return <PropertySearchResultScreenMobile
    layoutProps={layoutProps}
    properties={mergedPropertiesProps}
    sortKey={searchForm.sortKey ?? null}
    direction={searchForm.direction ?? 'DESC'}

    onSortSelect={(newSort, newDirection) => {
      dispatch(propertyListActions.edit('sortKey', newSort));
      dispatch(propertyListActions.edit('direction', newDirection));
      dispatch(propertyListActions.fetch({ isSwitchingPage: false }));
    }}
    hasMore={hasMore}
    onBackClick={() => history.goBack()}
    onMapClick={() => history.replace('/map')}
    onRequestNextPage={() => dispatch(propertyListActions.fetch({ isSwitchingPage: true }))}
    onSearchClick={() => history.push('/advanced-search')}
  />
}