import useLocale from 'common/hooks/useLocale';
import MainLayoutMobile from 'components/scaffold/MainLayoutMobile';
import React from 'react';
import InfoScreenMobile from './InfoScreenMobile';
import Privacy from './Privacy';
import { PrivacyScreenViewProps } from './PrivacyScreenViewProps';

export default function PrivacyMobile(props: PrivacyScreenViewProps) {
  const { layoutProps, privacyProps, onBackClick } = props;

  const { langSetting } = useLocale();

  return <MainLayoutMobile {...layoutProps}>
    <InfoScreenMobile title={langSetting.captionPrivacy}
      children={{
        privacy: <Privacy 
          items={privacyProps}
        />
      }}
      onBackClick={onBackClick}
    />
  </MainLayoutMobile>
}