import clsx from 'clsx';
import useLocale from 'common/hooks/useLocale';
import { useMobileLayoutHeight } from 'common/hooks/useMobileLayoutHeight';
import breakpoints from 'common/theme/breakpoints.module.scss';
import { jumpers, useJump } from 'common/theme/jumper';
import { isNonEmpty, multiLang } from 'common/utils/utils';
import SharePanel from 'components/display-cards/SharePanel';
import { ProfileImage } from 'components/misc/ProfileImage';
import ScrollToTopButton from 'components/misc/ScrollToTopButton';
import MainLayoutDesktop from 'components/scaffold/MainLayoutDesktop';
import VerticalScrollView from 'components/scaffold/VerticalScrollView';
import TransactionListItem, { TransactionListItemProps } from 'components/transaction/TransactionListItem';
import TransactionTable, { TransactionTableDialog } from 'components/transaction/TransactionTable';
import React, { useState } from 'react';
import Button from 'react-bootstrap/esm/Button';
import { useMediaQuery } from 'react-responsive';
import PhotoGallery from './PhotoGallery';
import PropertyDetailBasicInfo from './PropertyDetailBasicInfo';
import { PropertyDetailProps } from './PropertyDetailProps';
import classes from './PropertyDetailScreenDesktop.module.scss';
import PropertyFeaturePanel from './PropertyFeaturePanel';
import PropertyFeaturePanelSmall from './PropertyFeaturePanelSmall';
import PropertyMiniMap from './PropertyMiniMap';

export default function PropertyDetailScreenDesktop(props: PropertyDetailProps) {
  const { layoutProps, photos, videoLink, floorPlans, sharePanelProps, propertyDetailBasicInfo, 
    agents, lat, lng, mainFeatures, features, otherInfo, schoolNet, transactions,
    onSameBuildingSearchClick, onSameDistrictSearchClick, onContactLeadAgentClick, onAgentClick, onBackClick,
    notFound, loading, loggedIn,
  } = props;
  const jump = useJump();

  const minDesktopHeight = useMobileLayoutHeight(true);

  const { lang, langProperty, locale } = useLocale();

  const [ trnxTableShow, setTrnxTableShow ] = useState(false);
  const handleTrnxTableHide = () => {
    setTrnxTableShow(false);
  };

  const isSmallScreen = useMediaQuery({ maxWidth: breakpoints.md });

  const notFoundView = () => <div className={classes['not-found-view']} style={{ minHeight: minDesktopHeight }}>
    <i className={"far fa-building " + classes['icon']}></i>
    <div className={classes['msg']}>{langProperty.msgNotExists}</div>
  </div>

  const mainPageContent = () => <div className={classes['property-detail-desktop']}>
    <div className="row">
    {
      (photos.length > 0 || isNonEmpty(videoLink) || floorPlans.length > 0) && <div className={`col-sm-12 col-md-6 col-lg-6 col-xl-7`}>
        <div className={classes['photo-gallery-container']}>
          <PhotoGallery photoList={photos} video={videoLink} floorPlans={floorPlans} />
        </div>
      </div>
    }
      <div className={`col-sm-12 col-md-6 col-lg-6 col-xl-5`}>
        {/* <div className={`col-sm-12 col-md-12 col-lg-12 col-xl-12`}> */}
          <PropertyDetailBasicInfo {...propertyDetailBasicInfo} isDesktop/>
        {/* </div> */}
        {/* <div className={`col-sm-12 col-md-12 col-lg-12 col-xl-12`}> */}
          <SharePanel {...sharePanelProps}/>
        {/* </div> */}
      </div>
      <div className={`${classes['slot']} col-sm-12 col-md-12 col-lg-12 col-xl-12`}>
        <PropertyMiniMap lat={lat} lng={lng} defaultZoom={16}/>
      </div>
      
      <div className={`${classes['slot']} col-sm-12 col-md-12 col-lg-12 col-xl-12`}>
        <div className={classes['sub-header']}>{langProperty.titlePropertyFeatures}</div>
      </div>
      <div className={`col-sm-12 col-md-12 col-lg-12 col-xl-12`}>
        <PropertyFeaturePanel features={mainFeatures} isDesktop/>
      </div>

      {
        features.length > 0 && <div className={`${classes['slot']} col-sm-12 col-md-12 col-lg-12 col-xl-12`}>
          <div>
            <div className={classes['more-info-sub-header']}>{langProperty.captionFeaturesOrFacilities}</div>
          </div>
          <div>
            <PropertyFeaturePanelSmall features={features}/>
          </div>
        </div>
      }
      
      {
        otherInfo.length > 0 && <div className={`${classes['slot']} col-sm-12 col-md-12 col-lg-12 col-xl-12`}>
          <div>
            <div className={classes['more-info-sub-header']}>{langProperty.captionOtherInfo}</div>
          </div>
          <div>
            <PropertyFeaturePanelSmall features={otherInfo}/>
          </div>
        </div>
      }
      
      {
        schoolNet.length > 0 && <div className={`${classes['slot']} col-sm-12 col-md-12 col-lg-12 col-xl-12`}>
          <div>
            <div className={classes['more-info-sub-header']}>{langProperty.captionSchoolNet}</div>
          </div>
          <div>
            <PropertyFeaturePanelSmall features={schoolNet}/>
          </div>
        </div>
      }

      <div className={`${classes['slot']} col-sm-6 col-md-4 col-lg-4 col-xl-4`}  style={{ paddingRight: '0rem'}}>
        <div className={classes['other-property-action-container']}>
          <button className={classes['btn']} onClick={onSameBuildingSearchClick}>
            <i className="fas fa-search"/><div>{langProperty.captionOtherPropertiesForSameBuilding}</div>
          </button>
          <button className={classes['btn']} onClick={onSameDistrictSearchClick}>
            <i className="fas fa-search"/><div>{langProperty.captionOtherPropertiesForSameDistrict}</div>
          </button>
        </div>
      </div>
      <div className={`${classes['slot']} col-sm-6 col-md-8 col-lg-8 col-xl-8`}>
      {
        agents.length !== 0 ? <div>
          <div className={classes['agent-list-title']}>{langProperty.titlePropertyAgents}</div>
          <div><VerticalScrollView>
            <div className={classes['agent-list-container']}>
              {agents.map(agent => <div key={agent.id} className={classes['item']} onClick={() => onAgentClick?.(agent.id)}>
                  <div className={classes['image']}><ProfileImage src={agent.photoFilename} width={'3.125rem'} /></div>
                  <div>{multiLang(locale, agent.chineseName, agent.englishName)}</div>
                </div>)}
            </div>
          </VerticalScrollView></div>
        </div> : null
      }
      </div>
      <TransactionTableDialog 
        show={trnxTableShow}
        onHide={handleTrnxTableHide}
        {...props.transactionList}
      />
      {
        transactions.length !== 0 ? <div className={`col-sm-12 col-md-12 col-lg-12 col-xl-12`}>
          <div className={clsx(classes['trnx-header'])}>
            <div className={classes['sub-header']}>{langProperty.titleRecentTransactions}</div>
            {/* <div className={classes['sub-header']}></div> */}
            <Button variant="pas-green" onClick={() => {
              if (!loggedIn) {
                jump(jumpers.toLogin());
                return;
              }
              setTrnxTableShow(true);
            }}>{lang.actionMore}</Button>
          </div>
          <div className={`${classes['sub-slot']} row`}>
            { 
              isSmallScreen ? transactions.map((transaction: TransactionListItemProps) => <div className={`${classes['sub-slot']} col-sm-12 col-md-6 col-lg-4 col-xl-4`}>
                <TransactionListItem {...transaction} overrideClassName={classes['trnx-item']}/>
              </div>) : <TransactionTable
                transactions={transactions}
                sorting=""
                direction="DESC"
              />
            }
          </div>
        </div> : null
      }
    </div>
  </div>
  
  return <MainLayoutDesktop 
    {...layoutProps}
    tabBarIconExtraClasses={{
      'EnquiryDialog.Open': !notFound ? classes['enquiry-btn-web-shadow'] : '',
    }}
    onNavigate={(key) => {
      if (!notFound && key === 'EnquiryDialog.Open') {
        onContactLeadAgentClick?.();
      } else {
        layoutProps.onNavigate?.(key);
      }
    }}
  >
    {
      loading ?  <div style={{ minHeight: minDesktopHeight }} />:
      notFound ? notFoundView() : 
      mainPageContent()
    }
    <ScrollToTopButton />
  </MainLayoutDesktop>

}