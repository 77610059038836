const ITEM_NAME = process.env['PUBLIC_URL'] + '/state';

export const loadState = () => {
  try {
    const serializedState = localStorage.getItem(ITEM_NAME);
    if (serializedState === null) {
        return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

export const saveState = (state: any) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem(ITEM_NAME, serializedState);
  } catch {
    // ignore write errors
  }
};