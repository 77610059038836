import { ApiError, ApiPageResult } from 'api';
import { homeApi } from 'api/homeApi';
import CAHomeDTO from 'common/dto/CAHomeDTO';
import { multiLang } from "common/utils/utils";
import { IRootState } from 'reducers';
import { homeActions, HomeState } from "reducers/home";
import { call, cancelled, put, select, takeLeading } from "redux-saga/effects";
import { PASSagaContext } from 'sagas';
import { ActionType, getType } from "typesafe-actions";
import { apiTaskWrapper } from './saga-commons';

export function* watchHomeFetchRequested(context: PASSagaContext) {
    yield takeLeading(
      getType(homeActions.doFetchHome),
      apiTaskWrapper(fetchHome, { noMask: true }),
      context,
    );
  }
  
  export function* fetchHome(_context: PASSagaContext, action: ActionType<typeof homeActions['doFetchHome']>) {
    // const { isSwitchingPage } = action.payload;
    // const { page }: AgentListState['criteria'] = yield select((state: IRootState) => state.agentList.criteria);
    const { token } = yield select((state: IRootState) => state.login);
    try {
        yield put(homeActions.setLoading(true));

        const home: HomeState = yield select((state: IRootState) => state.home);

        const { locale } = yield select((state: IRootState) => state.locale);

        const { data, error }: ApiPageResult<CAHomeDTO> = yield call(homeApi.getHome,
          multiLang(locale, 'Traditional Chinese', 'English', 'Simplified Chinese') ?? '',
          token
        );
      
        if (error) {
          throw ApiError.of(error!);
        }

        if (data) {
          yield put(homeActions.updateHome({...home, ...data}));
        } else {
            
        }
    } catch (e) {
      if (yield cancelled()) {
        console.log('Home Fetch Cancelled');
      }
    } finally {
      yield put(homeActions.setLoading(false));
    }

  }
  
  export default [ watchHomeFetchRequested ];