import CANewsDetailDTO from 'common/dto/CANewsDetailDTO';
import CANewsListItemDTO from 'common/dto/CANewsListItemDTO';
import { ActionType, createAction, createReducer } from 'typesafe-actions';

export interface NewsState {
	totalCount: number;
	news: CANewsListItemDTO[],
	hasMore: boolean;
	
	criteria: {
		limit: number;
		page: number;
	}

	current: CANewsDetailDTO;
};

const newsInitialState = (): NewsState => ({
	totalCount: 0,
	news: [],
	hasMore: false,
	criteria: {
    limit: 10,
    page: 0,
	},
	current: {
		id: 0,
		coverImageUrl: '',
		heading: '',
		webSource: '',
		publishDate: '',
		content: '',
		tags: []
	}
});

export const newsActions = {
	fetch: createAction('NewsList.FetchRequested')<{ isSwitchingPage: boolean }>(),
	update: createAction('NewsList.Updated')<{ news: CANewsListItemDTO[], hasMore: boolean}>(),
	appendNextPage: createAction('NewsList.AppendNextPage')<{ news: CANewsListItemDTO[], hasMore: boolean }>(),
	updateCriteria: createAction('NewsList.CriteriaUpdated')<Partial<NewsState['criteria']>>(),
	fetchDetail: createAction('News.Detail.FetchRequested')<{ id: number }>(),
	updateDetail: createAction('News.Detail.Updated')<{ news: CANewsDetailDTO }>(),
};

export type NewsActions = ActionType<typeof newsActions>;

export const newsReducer = createReducer<NewsState, NewsActions>(newsInitialState())
  .handleAction(newsActions.fetch, (state, { payload }) => ({
    ...state,
    news: payload.isSwitchingPage ? state.news : [],
    hasMore: payload.isSwitchingPage ? state.hasMore : true,
	}))
	.handleAction(newsActions.appendNextPage, (state, { payload }) => ({
    ...state, news: [ ...state.news, ...payload.news ], hasMore: payload.hasMore,
  }))
  .handleAction(newsActions.update, (state, { payload }) => ({
    ...state, news: payload.news, hasMore: payload.hasMore,
	}))
	.handleAction(newsActions.updateCriteria, (state, { payload }) => ({
    ...state, criteria: { ...state.criteria, ...payload },
	}))
	.handleAction(newsActions.updateDetail, (state, { payload }) => ({
		...state, current: payload.news
	}))
;