import clsx from 'clsx';
import 'common/general-imports';
import useLocale from 'common/hooks/useLocale';
import { ProfileImage } from 'components/misc/ProfileImage';
import React from 'react';
import Button from 'react-bootstrap/esm/Button';
import RatingStars from '../data-display/RatingStars';
import classes from './AgentCard.module.css';


export interface AgentCardProps {
  id: number;
  image: string;
  chineseName: string;
  englishName: string;
  rating: number;
  maxRating: number;
  comment?: string | null;
  onClickMore?: () => void;
  onClickRating?: () => void;
  onClickContact?: () => void;
  size?: 'lg' | 'sm' | undefined;
}

export default function AgentCard(props: AgentCardProps) {
  const { id, image, chineseName, englishName, rating, maxRating, comment,
    onClickMore, onClickRating, onClickContact, size } = props;

  const { langAgent } = useLocale();

  return <div id={`agent-card-${id}`} className={clsx(classes['agent-card'], classes[size === 'lg' ? 'desktop' : 'mobile'])}>
    <div className={classes['content']}>
      <div className={classes['left']}>
        <ProfileImage src={image} width={'5.75rem'} />
      </div>
      <div className={classes['right']}>
        <div className={classes['agent-name']}><b>{englishName} {chineseName}</b></div>
        <div className={classes['agent-rating']}>
          {langAgent.captionCustomerRating} <RatingStars maxRating={maxRating} rating={rating} color={'#F96401'} />
        </div>

        <div className={classes['agent-comment-section']}>
          <div className={classes['agent-last-comment']}>{comment || langAgent.msgNoComment}</div>
          {/* <div className={classes['agent-more-comment']}>
            
          </div> */}
        </div>
      </div>
    </div>

    <div className={classes['agent-action-panel']}>
      <div className={classes['left']}>
        <Button variant="pas-gray" size="sm"
          className={classes['agent-action-btn']}
          onClick={onClickMore}
        >
          {langAgent.actionMoreComment}
        </Button>
        <Button variant="pas-orange" size="sm"
          className={classes['agent-action-btn']}
          onClick={onClickRating}
        >
          {langAgent.actionRating}
        </Button>

        <Button variant="pas-green" size="sm"
          className={classes['agent-action-btn']}
          onClick={onClickContact}
        >
          {langAgent.actionContactAgent}
        </Button>
      </div>

      <div className={classes['right']}>
        <div>{rating.toFixed(1)}/{maxRating}</div>
      </div>
    </div>
  </div>
}