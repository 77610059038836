import { ApiError, ApiPageResult, ApiResult } from 'api';
import { newsApi } from 'api/newsApi';
import CANewsDetailDTO from "common/dto/CANewsDetailDTO";
import CANewsListItemDTO from 'common/dto/CANewsListItemDTO';
import { multiLang } from "common/utils/utils";
import { IRootState } from 'reducers';
import { newsActions, NewsState } from 'reducers/news';
import { call, put, select, takeLeading } from "redux-saga/effects";
import { PASSagaContext } from 'sagas';
import { ActionType, getType } from "typesafe-actions";
import { apiTaskWrapper } from './saga-commons';

export function* watchNewsPageFetched(context: PASSagaContext) {
	yield takeLeading(
		getType(newsActions.fetch),
		apiTaskWrapper(fetchNewsPage),
		context
	);
}

const localeDisplayLangMap : { [key: string]: string } = {
  zh_HK: 'Traditional Chinese',
  zh_CN: 'Simplified Chinese',
  en: 'English'
}

export function* fetchNewsPage(_context: PASSagaContext, action: ActionType<typeof newsActions['fetch']>) {
	const { isSwitchingPage } = action.payload;

	const criteria: NewsState['criteria'] = yield select((state: IRootState) => state.news.criteria);
  const { token } = yield select((state: IRootState) => state.login);
  const { locale } = yield select((state: IRootState) => state.locale);

  const { data, error }: ApiPageResult<CANewsListItemDTO> = yield call(newsApi.getPage,
    { ...criteria, displayLanguage: multiLang(locale, 'Traditional Chinese', 'English', 'Simplified Chinese'), page: isSwitchingPage ? criteria.page + 1 : 0 },
    token
  );

  if (error) {
    throw ApiError.of(error!);
  }

	const news = data!.content;

  if (isSwitchingPage) {
    yield put(newsActions.updateCriteria({ page: criteria.page + 1 }));
    yield put(newsActions.appendNextPage({ news, hasMore: news.length > 0 }));
  } else {
    yield put(newsActions.updateCriteria({ page: 0 }));
    yield put(newsActions.update({ news, hasMore: news.length > 0 }));
  }
}

export function* watchNewsDetailFetched(context: PASSagaContext) {
	yield takeLeading(
		getType(newsActions.fetchDetail),
		apiTaskWrapper(fetchNewsDetail),
		context
	);
}


export function* fetchNewsDetail(_context: PASSagaContext, action: ActionType<typeof newsActions['fetchDetail']>) {
  const { token } = yield select((state: IRootState) => state.login);

  const { data, error }: ApiResult<CANewsDetailDTO> = yield call(newsApi.getDetail,
    action.payload.id,
    token
  );

  if (error) {
    throw ApiError.of(error!);
  }

  if (data) {
    yield put(newsActions.updateDetail({ news: data! }));
  }
}

export default [ 
  watchNewsPageFetched,
  watchNewsDetailFetched
];