import 'common/general-imports';
import useLocale from 'common/hooks/useLocale';
import React from 'react';
import Button from 'react-bootstrap/esm/Button';
import classes from './HomeSectionHeader.module.css';



export interface HomeSectionHeaderProps {
	title: string;
	/** Hide the More button if undefined */
	onClickMore?: () => any;
}

export default function HomeSectionHeader(props: HomeSectionHeaderProps) {
	const { title, onClickMore } = props;

	const { langHome } = useLocale();

	return <div className={classes['home-section']}>
		<div className={classes['header']}>{title}</div>
		<Button variant="pas-orange" 
			className={classes['more-btn']}
			onClick={onClickMore}
		>
			{langHome.actionMore}
		</Button>
	</div>
}