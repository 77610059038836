import clsx from 'clsx';
import DistrictSelectDialog from 'components/overlay/DistrictSelectDialog';
import React, { useMemo, useState } from 'react';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { FormBinder } from './IFormBinder';
import classes from './TextField.module.css';



export interface FormDistrictSelectFieldProps {
  bind?: FormBinder<string[]>;
  regionBundles: RegionBundles;
  label: string;
  overrideClassName?: string;
  errorMessagePlaceHeld?: boolean;
}

export default function FormDistrictSelectField(props: FormDistrictSelectFieldProps) {
  const { bind, label, regionBundles, overrideClassName, errorMessagePlaceHeld } = props;
  const [ districtDialogOpen, setDistrictDialogOpen ] = useState(false);
  const handleDistrictDialogClose = () => setDistrictDialogOpen(false);

  const allDistrictOptions = useMemo(() => {
    return {
      ...regionBundles.districtHkiOptions,
      ...regionBundles.districtKltOptions,
      ...regionBundles.districtNtOptions,
    };
  }, [ regionBundles ]);
  
  return <Form.Group className={classes['gray-input-group']}>
    <DistrictSelectDialog {...regionBundles} open={districtDialogOpen} onHide={handleDistrictDialogClose} districts={bind} /> 
    <InputGroup>
      <Form.Control
        isInvalid={!!bind?.errorMessage}
        className={clsx(classes['gray-input'], overrideClassName)}
        placeholder={label}
        as={'input'}
        value={bind?.value?.map(d => allDistrictOptions[d] ?? d).join(' / ') ?? ''}
        onClick={() => setDistrictDialogOpen(true)}
        readOnly
      />
      {bind?.value?.length ? <InputGroup.Append onClick={() => bind.change([])}>
        <InputGroup.Text className={clsx(classes['gray-input-append'], overrideClassName)}>
          <i className={`fa fa-backspace`} />
        </InputGroup.Text>
      </InputGroup.Append> : null}
    </InputGroup>

    <Form.Text className={clsx({
      'text-danger': !!bind?.errorMessage,
    })}>{bind?.errorMessage}{errorMessagePlaceHeld ? <>&nbsp;</> : ''}</Form.Text>
  </Form.Group>
}