import clsx from 'clsx';
import usePrevious from 'common/hooks/usePrevious';
import PopupOverlay from 'components/overlay/PopupOverlay';
import React, { useLayoutEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import SwipeableViews from 'react-swipeable-views';
import { NotificationListView } from './NotificationListScreenLayoutMobile';
import classes from './NotificationListViewOverlay.module.scss';
import NotificationListViewProps from './NotificationListViewProps';

export interface NotificationListViewOverlayProps extends NotificationListViewProps {
  overlayViewOpened: boolean;
}

export default function NotificationListViewOverLay(props: NotificationListViewOverlayProps) {
  const { overlayViewOpened, ...rest} = props;

  const prevOverlayViewOpened = usePrevious<boolean>(overlayViewOpened);

  const [ index, setIndex ] = useState<number>(1);

  // Small Screen Handling
  const isSmallScreen = useMediaQuery({ maxWidth: 992 });

  // Extra Small Screen Handling
  const isExtraSmallScreen = useMediaQuery({ maxWidth: 685 });

  useLayoutEffect(() => {
    if (prevOverlayViewOpened === false && overlayViewOpened === true) {
      setTimeout(() => {
        setIndex(0);
      }, 160);
    }

    if (prevOverlayViewOpened === true && overlayViewOpened === false) {
      setIndex(1);
    }
  }, [ overlayViewOpened ]);

  return <PopupOverlay show={overlayViewOpened} animation='fade' noIOSInsect>
    <div className={classes['root']}>
      <div className={clsx(classes['swipe-view-container'], 
        isSmallScreen && classes['small-screen'], 
        isExtraSmallScreen && classes['extra-small-screen'])}
      >
        <SwipeableViews index={index}>
          <div className={classes['noti-list-view-container']}>
            <NotificationListView {...rest} />
          </div>
          <div />
        </SwipeableViews>
      </div>
    </div>
  </PopupOverlay>
}
