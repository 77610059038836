import usePrevious from 'common/hooks/usePrevious';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useImage } from 'react-image';

type ImgProps = JSX.IntrinsicElements['img'];
type DivProps = JSX.IntrinsicElements['div'];

export interface ImageProps extends ImgProps {
  ratio?: number;
  altImage?: string;
}

export default function Image(props: ImageProps) {
  const { src, error } = useImage({
    srcList: [ props.src ?? '' ],
    useSuspense: false,
  });

  const { ratio, altImage, ...imageProps } = props;

  const ref = useRef<HTMLImageElement>(null);
  const [ height, setHeight ] = useState<number | undefined>(undefined);

  const prevWidth = usePrevious(ref.current?.offsetWidth);

  const adjustRatio = useMemo(() => () => {
    if (!ref.current) {
      return;
    }

    const el = ref.current;
    const width = el.offsetWidth;
    if (ratio && width !== prevWidth) {
      setHeight(el.offsetWidth / ratio);
    }
  }, [ src, ref.current, ratio, setHeight ]);

  useEffect(() => {
    adjustRatio();
  }, [ adjustRatio ]);

  useEffect(() => {
    const handle = setInterval(adjustRatio, 16);
    return () => {
      clearInterval(handle);
    };
  }, [ adjustRatio ]);

  if (error && altImage) {
    return <img ref={ref} {...imageProps} style={{ ...imageProps.style, height: `${height}px`, objectFit: 'cover' }} src={altImage} />;
  }
  return <img ref={ref} {...imageProps} style={{ ...imageProps.style, height: `${height}px`, objectFit: 'cover' }} src={error ? 'images/no-image.svg' : src} />;
}

export interface ImageBgProps extends DivProps {
  src?: string;
  overrideClassName?: string;
}

export function ImageBg(props: ImageBgProps) {
  const { src, error } = useImage({
    srcList: [ props.src ?? '' ],
    useSuspense: false,
  });

  return <div {...props} className={props?.overrideClassName} style={{ ...props.style, backgroundImage: `url("${error ? 'images/no-image.svg' : src}")` }} />;
}