import { homeApi } from 'api/homeApi';
import clsx from 'clsx';
import useLocale from 'common/hooks/useLocale';
import { useMobileLayoutHeight } from 'common/hooks/useMobileLayoutHeight';
import { registerTouchableClassNames } from 'common/polyfills/touch-polyfill';
import { handlePriceDisplay, handlePriceDisplayUnit, isNonEmpty, multiLang, multiLangNum, priceFromView, priceToView } from 'common/utils/utils';
import FormDistrictSelectField from 'components/form-controls/FormDistrictSelectField';
import FormTextField from 'components/form-controls/FormTextField';
import { FormBinder } from 'components/form-controls/IFormBinder';
import RangeInput from 'components/form-controls/RangeInput';
import React, { useLayoutEffect, useRef, useState } from 'react';
import Button from 'react-bootstrap/esm/Button';
import Overlay from 'react-bootstrap/esm/Overlay';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from 'reducers';
import { priceRangeMapping10k, propertyListActions, rentRangeMapping } from 'reducers/property-list';
import { HistoryListItemProps } from './HistoryListItem';
import classes from './HomeScreenSearchPanel.module.scss';
import HomeSearchAutocompletePopup from './HomeSearchAutocompletePopup';

export interface HomeScreenSearchPanelProps {
  variant?: 'mobile' | 'desktop';
  bindSearch?: FormBinder<string>;
  /** Note: This price is view value. */
  bindPriceRange?: FormBinder<number[]>;
  bindRentRange?: FormBinder<number[]>;
  bindRoomCount?: FormBinder<number>;
  bindType?: FormBinder<'BUY' | 'RENT' | 'SELLER_ENQUIRY'>;
  regionBundles?: RegionBundles;

  existingMoreOptionSelections?: boolean;

  onSearchClick?: AnyFn;
  onMapButtonClick?: AnyFn;
  onAdvancedSearchClick?: AnyFn;
  onSellerEnquiryClick?: AnyFn;
  onAutocompleteStateChange?: (willShow: boolean) => any;

  // For Seller Enquiry
  bindEmail?: FormBinder<string>;
  bindContact?: FormBinder<string>;
  bindDistricts?: FormBinder<string[]>;
  bindName?: FormBinder<string>;
}

// addTouchableHoveringSupport(
//   `.${classes['decrease']}, .${classes['increase']}, .${classes['tab-btn']}, .${classes['search-btn']}`,
//   [],
//   classes['no-hover'],
// );

registerTouchableClassNames(
  classes['decrease'], classes['increase'], classes['tab-btn'], classes['search-btn'],
);

const getResultKeys = (keyword: string, options: { [key: string]: string }, display: LocaleOptions) => {
  let resultKeyDisplayList: { key: string; display: string }[] = [];
  if (keyword.length >= 2) {
    Object.keys(options)
    .filter(name => name.toLowerCase().search(keyword.toLowerCase()) > -1)
    .forEach(name => {
      resultKeyDisplayList.push({ key: options[name], display: display[options[name]] ?? name })
    })
  ;
  }
  
  return resultKeyDisplayList;
}

const getResultNames = (keyword: string, options: { ['zhHK']: string; ['en']: string, ['zhCN']: string }[], locale: string): string[] => {
  let resultNameList = new Set<string>();
  options.forEach(option => {
    if (isNonEmpty(option.en) && option.en?.toLowerCase().search(keyword.toLowerCase()) > -1 || 
      isNonEmpty(option.zhHK) && option.zhHK?.toLowerCase().search(keyword.toLowerCase()) > -1 ||
      isNonEmpty(option.zhCN) && option.zhCN?.toLowerCase().search(keyword.toLowerCase()) > -1
    ) {
      resultNameList.add(multiLang(locale, option.zhHK, option.en, option.zhCN) ?? '');
    }
  })
  return [...resultNameList];
}

export default function HomeScreenSearchPanel(props: HomeScreenSearchPanelProps) {
  const {
    bindSearch, bindPriceRange, bindRentRange, bindRoomCount, bindType, regionBundles,
    onSearchClick, onMapButtonClick, onAdvancedSearchClick, onSellerEnquiryClick, onAutocompleteStateChange,
    bindEmail, bindContact, bindName, bindDistricts, variant,
  } = props;

  const token: string = useSelector((state: IRootState) => state.login?.token ?? '');
  const TOP_N_AUTOCOMPLETE_RESULT = useSelector((state: IRootState) => state.systemSettings?.ClientApp.HOME.TOP_N_AUTOCOMPLETE_RESULT);
  const TOP_N_SEARCH_HISTORY = useSelector((state: IRootState) => state.systemSettings?.ClientApp.HOME.TOP_N_SEARCH_HISTORY);

  const { lang, langHome, langEnquiryDialog, locale } = useLocale();

  const dispatch = useDispatch();

  const isSellerEnquiry = bindType?.value === 'SELLER_ENQUIRY';
  const bindRange = bindType?.value === 'RENT' ? bindRentRange : bindPriceRange;
  const rangeStart = (bindRange?.value?.[0]) ?? 0;
  const rangeEnd = (bindRange?.value?.[1]) ?? Infinity;
  const allowedRange = bindType?.value === 'RENT' ? {
    min: 4000, max: 35000,
    valueMapping: rentRangeMapping,
  } : {
    min: multiLangNum(locale, 300, 300/100), max: multiLangNum(locale, 1500, 1500/100),
    valueMapping: locale === 'en' ? priceRangeMapping10k.map(n => n / 100) : priceRangeMapping10k,
  };

  // Search Autocomplete //
  const layoutHeight = useMobileLayoutHeight();
  const rootRef = useRef<HTMLDivElement>(null);
  const searchInputRef = useRef<HTMLFormElement>(null);
  // const [ searchDraft, setSearchDraft ] = useState('');
  const [ searchFocus, setSearchFocus ] = useState(false);
  useLayoutEffect(() => {
    onAutocompleteStateChange?.(searchFocus);
  }, [ searchFocus ]);
  const popperRef = useRef<{ scheduleUpdate: () => void } | null>();
  const keywordSearch = (kw: string) => {
    // dispatch(propertyListActions.editHomeSearch('district', []));
    // dispatch(propertyListActions.editHomeSearch('buildingName', ''));
    // dispatch(propertyListActions.editHomeSearch('street', ''));
    // dispatch(propertyListActions.editHomeSearch('primarySchoolNet', []));
    // dispatch(propertyListActions.editHomeSearch('secondarySchoolNet', []));
    dispatch(propertyListActions.editHomeSearch('freeTextSearch', kw));
    bindSearch?.change(kw);
    // setSearchDraft(kw);
  }

  useLayoutEffect(() => {
    if (!searchFocus || !layoutHeight) {
      return;
    }

    setTimeout(() => {
      rootRef.current?.scrollIntoView({ block: 'center', behavior: 'smooth' });
      popperRef.current?.scheduleUpdate();
    }, 80);
  }, [ popperRef.current, searchFocus, layoutHeight ]);

  const [ histories, setHistories ] = useState<HistoryListItemProps[]>([]);

  const fetchSearchHistory = () => {
    if (!isNonEmpty(token)) { return; }
    homeApi.getSearchHistory(token).then(result => {
      if (result.data!) {
        setHistories(result.data?.map(history => ({
          ...history,
          priceDisplay: [ history.price[0] !== null ? priceToView(history.price[0], locale) : 0, history.price[1] !== null ? priceToView(history.price[1], locale) : Infinity ],
          rent: [ history.rent[0] !== null ? history.rent[0] : 0, history.rent[1] !== null ? history.rent[1] : Infinity ]
        })).slice(0, parseInt(TOP_N_SEARCH_HISTORY)) ?? [])
      }
    });
  }

  const [ buildingNames, setBuildingNames ] = useState<string[]>([]);
  const [ streets, setStreets ] = useState<string[]>([]);

  const fetchBuildingForMatchedBuildingName = (keyword: string) => {
    if (keyword.length < 2) { 
      setBuildingNames([]);
      return; 
    }
    homeApi.getBuildings({ buildingName: keyword }).then(result => {
      if (result.data!) {
        setBuildingNames(getResultNames(
          keyword, 
          result.data?.map(building => ({ 
            zhHK: building.buildingNameZh, en: building.buildingNameEn, zhCN: building.buildingNameSc })) ?? [],
          locale,
        ).slice(0, parseInt(TOP_N_AUTOCOMPLETE_RESULT)));
      }
    });
  }

  const fetchBuildingForMatchedStreet = (keyword: string) => {
    if (keyword.length < 2) { 
      setStreets([]);
      return; 
    }
    homeApi.getBuildings({ street: keyword }).then(result => {
      if (result.data!) {
        setStreets(getResultNames(
          keyword, 
          result.data?.map(building => ({ 
            zhHK: building.streetZh, en: building.streetEn, zhCN: building.streetSc })) ?? [],
          locale,
        ).slice(0, parseInt(TOP_N_AUTOCOMPLETE_RESULT)));
      }
    });
  }
  /* IME composition */
  const [onComposition, setOnComposition] = useState(false);
  function handleComposition(e: any) {
    if (e.type === 'compositionstart') {
      console.log("start");
      setOnComposition(true);
    }
    if (e.type === 'compositionend') {
      console.log("end");
      setOnComposition(false);
      // here is where the actual fetching is taking place
      const temp = e.target.value;
      fetchBuildingForMatchedBuildingName(temp);
      fetchBuildingForMatchedStreet(temp);
    }
  }
  
  function handleChange(ev: any) {
    console.log("onComposition:", onComposition)
    let temp = ev.target.value;
    console.log("temp:", temp);
    console.log("onComposition:", onComposition);
    if (!onComposition) {
      // this block won't take place since componsitionend is fired after onchange,
      // but still keep it for readablity
      fetchBuildingForMatchedBuildingName(temp);
      fetchBuildingForMatchedStreet(temp);
    }
    keywordSearch(temp);
  }


  const { districtHkiOptions: districtHkiOptionsDisplay, districtNtOptions: districtNtOptionsDisplay, districtKltOptions: districtKltOptionsDisplay } = useLocale();
  const { districtHkiOptions: districtHkiOptionsEn, districtNtOptions: districtNtOptionsEn, districtKltOptions: districtKltOptionsEn } = useSelector((state: IRootState) => state.locale._bundle['en']);
  const { districtHkiOptions: districtHkiOptionsZhHK, districtNtOptions: districtNtOptionsZhHK, districtKltOptions: districtKltOptionsZhHK } = useSelector((state: IRootState) => state.locale._bundle['zh_HK']);

  const districtOptionsDisplay = {
    ...districtHkiOptionsDisplay,
    ...districtKltOptionsDisplay,
    ...districtNtOptionsDisplay,
  };
  let districtOptions: { [key: string]: string; } = {};
  for (const [key, value] of Object.entries(districtHkiOptionsEn ?? {})) districtOptions[value] = key;
  for (const [key, value] of Object.entries(districtHkiOptionsZhHK ?? {})) districtOptions[value] = key;
  for (const [key, value] of Object.entries(districtKltOptionsEn ?? {})) districtOptions[value] = key;
  for (const [key, value] of Object.entries(districtKltOptionsZhHK ?? {})) districtOptions[value] = key;
  for (const [key, value] of Object.entries(districtNtOptionsEn ?? {})) districtOptions[value] = key;
  for (const [key, value] of Object.entries(districtNtOptionsZhHK ?? {})) districtOptions[value] = key;

  const districtOptionsResultKeyDisplay = getResultKeys(bindSearch?.value ?? '', districtOptions, districtOptionsDisplay).slice(0, parseInt(TOP_N_AUTOCOMPLETE_RESULT));

  return <div className={clsx(classes['home-screen-search-panel'], classes[variant ?? 'mobile'])} ref={rootRef}>
    <div className={classes['tab-container']}>
      <div onClick={() => bindType?.change('BUY')} className={clsx({
        [classes['tab-btn']]: true,
        [classes['active']]: bindType?.value === 'BUY',
      })}>{langHome.actionBuy}</div>
      <div onClick={() => bindType?.change('RENT')} className={clsx({
        [classes['tab-btn']]: true,
        [classes['active']]: bindType?.value === 'RENT',
      })}>{langHome.actionRent}</div>
      <div onClick={() => bindType?.change('SELLER_ENQUIRY')} className={clsx({
        [classes['tab-btn']]: true,
        [classes['active']]: bindType?.value === 'SELLER_ENQUIRY',
      })}>{langHome.actionSell}</div>
      
      <div className={classes['tab-gap']} />

      {!isSellerEnquiry ? <Button className={classes['advance-search-btn']} variant="pas-orange" size="lg" onClick={onAdvancedSearchClick}>
        {langHome.actionAdvanceSearch}
        {props.existingMoreOptionSelections ? <div className={classes['red-dot']}></div> : null}
      </Button> : null}
    </div>

    {isSellerEnquiry ? <div className={classes['seller-enquiry-form']}>
      {/* Message */}
      <div className={classes['enquiry-message']}>{langEnquiryDialog.msgProvideInfo}</div>
      {/* Forms */}
      <div className={classes['form-row']}>
        <FormTextField label={langEnquiryDialog.captionName} bind={bindName} />
      </div>
      <div className={classes['form-row']}>
        <FormTextField label={langEnquiryDialog.captionContact} bind={bindContact} />
      </div>
      <div className={classes['form-row']}>
        <FormTextField label={langEnquiryDialog.captionEmail} bind={bindEmail} />
      </div>
      <div className={classes['form-row']}>
        <FormDistrictSelectField regionBundles={regionBundles!} label={langEnquiryDialog.captionDistrict} bind={bindDistricts} />
      </div>
    </div> : null}

    {!isSellerEnquiry ? <>
      <form className={classes['search-input']} ref={searchInputRef} autoComplete="off" onSubmit={(ev) => {
        ev.preventDefault();
        onSearchClick?.();
      }}>
        <input name="HomeScreenSearchPanel-input"
          // TODO: replace bindSearch?.value with corresponding search criteria.
          value={bindSearch?.value}
          onChange={(ev) => {
            handleChange(ev);
          }}
          onBlur={(ev) => {
            // Note: sleep 10 frames to prevent clicking targets in popup being invalidated.
            setTimeout(() => {
              setSearchFocus(false);
              bindSearch?.blur();
            }, 160); 
          }}
          // autoFocus
          onFocus={() => {
            setSearchFocus(true);
            // setSearchDraft(bindSearch?.value ?? '');
            fetchSearchHistory();
          }}
          onCompositionStart={handleComposition}
          onCompositionEnd={handleComposition}
          // className={classes['search-input']}
          placeholder={langHome.captionSearchPlaceholder}
          formNoValidate autoComplete="off"
        />

        {bindSearch?.value ? <div className={classes['clear-icon']} onClick={() => keywordSearch('')}>
          <i className="fas fa-times-circle"></i>
        </div> : null}
      </form>

      <Overlay target={searchInputRef} placement="bottom" show={searchFocus}>
        {({ placement, arrowProps, show: _show, popper, ...props }) => {
          popperRef.current = popper;
          return <div
            {...props}
            style={{
              backgroundColor: 'var(--white)',
              width: `${searchInputRef.current?.offsetWidth ?? 320}px`,
              // padding: '2px 10px',
              color: 'white',
              borderRadius: 5,
              overflow: 'auto',
              ...props.style,
            }}
          >
            <HomeSearchAutocompletePopup 
              mode={isNonEmpty(bindSearch?.value) ? "suggestion" : "history"}
              histories={histories} 
              buildingNames={buildingNames}
              streets={streets}
              districts={districtOptionsResultKeyDisplay}
              bindSearch={bindSearch}
            />
          </div>
        }}
      </Overlay>
    </>: null}

    {!isSellerEnquiry ? <div className={classes['criteria-row']}>
      <div className={classes['price-range']}>
        <div className={classes['price-range-display']}>
          <div className={classes['from']}>
            <div className={classes['caption']}>{langHome.captionRange}</div>
            <div>
              <span className={classes['price']}>{rangeStart === Infinity ? lang.captionUnlimited : bindType?.value === 'BUY' ? handlePriceDisplay(priceFromView(rangeStart, locale), locale) : rangeStart}</span>&nbsp;
              {bindType?.value === 'BUY' ? <span className={classes['caption']}>{handlePriceDisplayUnit(priceFromView(rangeStart, locale), locale, lang)}</span> : null}
            </div>
          </div>
          <div className={classes['to']}>
            <div />
            <div>
              <span className={classes['price']}>{rangeEnd === Infinity ? lang.captionUnlimited : bindType?.value === 'BUY' ? handlePriceDisplay(priceFromView(rangeEnd, locale), locale): rangeEnd}</span>&nbsp;
              {bindType?.value === 'BUY' ? <span className={classes['caption']}>{handlePriceDisplayUnit(priceFromView(rangeEnd, locale), locale, lang)}</span> : null}
            </div>
          </div>
        </div>

        <div className={classes['slider-container']}>
          <RangeInput unlimited bind={bindRange} {...allowedRange} step={multiLangNum(locale, 100, 100/100)} />
        </div>
      </div>

      <div className={classes['room-count']}>
        <div className={classes['count-display']}>
          <div className={classes['caption']}>
            {langHome.captionRoomCount}
          </div>
          <div className={classes['price']}>
            {bindRoomCount?.value ?? 0}
          </div>
        </div>

        <div className={classes['adjuster']}>
          <div className={classes['decrease']} onClick={() => bindRoomCount?.change(Math.max(0, (bindRoomCount?.value ?? 0) - 1))}>-</div>
          <div className={classes['increase']} onClick={() => bindRoomCount?.change((bindRoomCount?.value ?? 0) + 1)}>+</div>
        </div>
      </div>
    </div> : null}

    <div className={classes['search-btn-bar']}>
      {!isSellerEnquiry ? <>
        <div className={clsx(classes['search-btn'], classes['left'])} onClick={onSearchClick}>
          <div className={classes['icon']}>
            <i className="fas fa-search" />
            {variant === 'desktop' ? <span style={{ fontSize: '1rem' }}>&nbsp;{lang.actionSearch}</span> : null}
          </div>
        </div>
        {variant !== 'desktop' ? <div className={clsx(classes['search-btn'], classes['right'])} onClick={onMapButtonClick}>
          <div className={classes['icon']}>
            <i className="fas fa-map" />
          </div>
        </div> : null}
      </>: null}

      {isSellerEnquiry ? <div className={clsx(classes['search-btn'], classes['right'])} style={{ width: variant !== 'desktop' ? '100%' : undefined }} onClick={onSellerEnquiryClick}>
        <div className={classes['icon']} style={{ fontSize: '1rem' }}>
          {langEnquiryDialog.actionContactMe}
        </div>
      </div> : null}
    </div>

  </div>
}