import SectionTitleDesktop from 'components/home/SectionTitleDesktop';
import React from 'react';
import Container from 'react-bootstrap/esm/Container';
import classes from './InfoScreenDesktop.module.scss';

export interface InfoScreenDesktopProps {
  title: string;
  children: { [key: string]: React.ReactNode };
  onBackClick?: AnyFn;
}

export default function InfoScreenDesktop(props: InfoScreenDesktopProps) {
  const { title, children, onBackClick } = props;

  return  <div className={classes['root']}>
    <SectionTitleDesktop title={title} />
    <Container>
      <div className={classes['body']}>
        {
          Object.entries(children).map(([_, value]) => <div className={classes['item']}>{value}</div>)
        }
      </div>
    </Container>
  </div>
}