import clsx from 'clsx';
import useLocale from 'common/hooks/useLocale';
import { useMobileLayoutHeight } from 'common/hooks/useMobileLayoutHeight';
import useNativeStatusBarHeight from 'common/hooks/useNativeStatusBarHeight';
import { registerTouchableClassNames } from 'common/polyfills/touch-polyfill';
import zIndexes from 'common/theme/z-indexes.module.scss';
import { isNonEmpty } from 'common/utils/utils';
import AgentCardSmall from 'components/display-cards/home/AgentCardSmall';
import HomeSectionHeader from 'components/display-cards/home/HomeSectionHeader';
import NewsCard from 'components/display-cards/home/NewsCard';
import PropertyCard from 'components/display-cards/home/PropertyCard';
import TagPanelMobile from 'components/display-cards/home/TagPanelMobile';
import MainLayoutMobile from 'components/scaffold/MainLayoutMobile';
import { useCreateScrollContext } from 'components/scaffold/ScrollContext';
import VerticalScrollView from 'components/scaffold/VerticalScrollView';
import React, { useLayoutEffect, useRef, useState } from 'react';
import Spinner from 'react-bootstrap/esm/Spinner';
import Image from 'react-bootstrap/Image';
import SwipeableViews from 'react-swipeable-views';
import classes from './HomeScreenLayoutMobile.module.scss';
import HomeScreenProps from './HomeScreenProps';
import HomeScreenSearchPanel from './HomeScreenSearchPanel';
import { useSelector } from 'react-redux';
import { IRootState } from 'reducers';



registerTouchableClassNames(
  classes['goto-content-btn'], classes['goto-search-btn'],
  classes['account-btn'],
)

// addTouchableHoveringSupport(
//   `.${classes['goto-content-btn']}, .${classes['goto-search-btn']}`,
//   [],
//   classes['no-hover'],
// );

export default function HomeScreenLayoutMobile(props: HomeScreenProps) {
  const { layoutProps, searchPanelProps, backgroundImage,
    latestPropertiesForSale, latestPropertiesForLeasing, latestNews, topAgents,
    tags, onTagClick, onClientProfileClick, onShowContentSubPage, clientProfilePhotoImgSrc,
    onClickMoreSell, onClickMoreLease, onClickMoreNews, onClickMoreAgent, homeContentLoading,
    notificationTotalCount
  } = props;
  const mobileLayoutHeight = useMobileLayoutHeight();
  const statusBarHeight = useNativeStatusBarHeight();

  const { langHome } = useLocale();

  const { loggedIn } = useSelector((state: IRootState) => state.login);
  // Swipeable State
  const [ swipeIndex, setSwipeIndex ] = useState(0);
  const handleGotoContent = () => { setSwipeIndex(1); onShowContentSubPage?.(); };
  const handleGotoSearch = () => { setSwipeIndex(0); };
  const handleSwipeIndex = (i: number) => {
    setSwipeIndex(i);
    if (i === 1) {
      onShowContentSubPage?.();
    }
  }
  const pointerEvents = swipeIndex === 0 ? 'none' : 'all';

  // Autocomplete Handing: hide tab bar & swipe area when open
  const [ autocompleteOpen, setAutocompleteOpen ] = useState(false);
  const handleAutocompleteStateChange = (willShow: boolean) => {
    setAutocompleteOpen(willShow);
  };

  // Disable swipe view scroll, when content sub page open & content inner scroll is not at the top.
  // This is particularly needed for iOS platform, as Safari webview does not handle nested scroll well.
  // See: https://stackoverflow.com/questions/45255552/a-simple-solution-to-prevent-scrolling-problems-mobile-safari-ios
  //
  const contentViewRef = useRef<HTMLDivElement>(null);
  const contentSubPageScrollContext = useCreateScrollContext(contentViewRef);
  const [ swipeViewDisabled, setSwipeViewDisabled ] = useState(false);
  useLayoutEffect(() => {
    setSwipeViewDisabled(contentSubPageScrollContext.scrollTop !== 0);
  }, [ contentSubPageScrollContext.scrollTop ]);

  return <MainLayoutMobile {...layoutProps}>
    <div className={classes['home-screen-mobile']} style={{ backgroundImage: `url("${backgroundImage}")` }}>
      <div className={classes['inner']} style={{ height: mobileLayoutHeight }}>
        <div className={classes['account-btn-container']}>
          <div className={classes['account-btn']} onClick={onClientProfileClick}>
            {loggedIn && notificationTotalCount > 0 && <div className={classes['red-reminder']}><div>{notificationTotalCount > 99 ? '99+' : notificationTotalCount}</div></div>}
            {isNonEmpty(clientProfilePhotoImgSrc) ? 
              <Image src={clientProfilePhotoImgSrc} roundedCircle className={classes['image']} />
              :
              <i className="far fa-user-circle" />
            }
          </div>
        </div>

        <div className={clsx(classes['search-panel-container'], 'home-search-panel-normal')}>
          <HomeScreenSearchPanel {...searchPanelProps} onAutocompleteStateChange={handleAutocompleteStateChange} />
        </div>

        
      </div>
    </div>
    <div style={{ height: mobileLayoutHeight, width: '100%',
      position: 'absolute', top: 0, left: 0, zIndex: +zIndexes.homeContent,
      pointerEvents,
    }}>
      <SwipeableViews index={swipeIndex} style={{ pointerEvents }} containerStyle={{ height: `calc(${mobileLayoutHeight} + ${statusBarHeight}px)`, pointerEvents }} axis="y" disabled={swipeViewDisabled} onChangeIndex={handleSwipeIndex}>
        {/* Search Sub-Page */}
        <div className={classes['scroll-mask']} style={{ height: `calc(${mobileLayoutHeight} + ${statusBarHeight}px)` }}>
          <div className={classes['goto-content-btn']} onClick={handleGotoContent} style={{ display: autocompleteOpen ? 'none' : undefined }}>
            <div className={classes['icon']}>
              <i className="fas fa-angle-double-up" />
            </div>
          </div>
        </div>
        {/* Content Sub-Page */}
        <div className={classes['content-sub-page']} style={{ height: `calc(${mobileLayoutHeight} + ${statusBarHeight}px)`, paddingTop: `${statusBarHeight}px` }}>
          <div className={classes['content']} ref={contentViewRef}>
            <div className={classes['scroll-inner']}>

              <div className={classes['goto-search-btn']} onClick={handleGotoSearch}>
                <div className={classes['icon']}>
                  <i className="fas fa-angle-double-down" />
                </div>
              </div>

              {homeContentLoading ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '1rem 0' }}>
                <Spinner animation="grow" variant="pas-blue" /> 
              </div>: null}

              <div className={classes['tag-panel-container']}>
                {tags ? <TagPanelMobile tags={tags} cols={2} onTagClick={onTagClick} /> : null}
              </div>

              <div className={classes['section-container']}>
                <HomeSectionHeader title={langHome.titleLatestPropertiesSell} onClickMore={onClickMoreSell} />
              </div>
              <VerticalScrollView>
                <div className={classes['card-list']}>
                  {latestPropertiesForSale.map(propertyProps => <div key={propertyProps.id} className={classes['item']}>
                    <PropertyCard {...propertyProps} />
                  </div>)}
                </div>
              </VerticalScrollView>

              <div className={classes['section-container']}>
                <HomeSectionHeader title={langHome.titleLatestPropertiesLease} onClickMore={onClickMoreLease} />
              </div>
              <VerticalScrollView>
                <div className={classes['card-list']}>
                  {latestPropertiesForLeasing.map(propertyProps => <div key={propertyProps.id} className={classes['item']}>
                    <PropertyCard {...propertyProps} />
                  </div>)}
                </div>
              </VerticalScrollView> 

              <div className={classes['section-container']}>
                <HomeSectionHeader title={langHome.titleTopAgents} onClickMore={onClickMoreAgent} />
              </div>
              <VerticalScrollView>
                <div className={classes['card-list']}>
                  {topAgents.map(agentProps => <div key={agentProps.id} className={classes['item']}>
                    <AgentCardSmall {...agentProps} />
                  </div>)}
                </div>
              </VerticalScrollView>

              {/* <div className={classes['section-container']}>
                <HomeSectionHeader title={langHome.titleNews} onClickMore={onClickMoreNews} />
              </div>
              <VerticalScrollView>
                <div className={classes['card-list']}>
                  {latestNews.map(newsProps => <div key={newsProps.id} className={classes['item']}>
                    <NewsCard {...newsProps} />
                  </div>)}
                </div>
              </VerticalScrollView> */}
            </div>
          </div>
        </div>
      </SwipeableViews>
    </div>
    
  </MainLayoutMobile>
}