import clsx from 'clsx';
import 'common/general-imports';
import useLocale from 'common/hooks/useLocale';
import { ProfileImage } from 'components/misc/ProfileImage';
import React, { useMemo, useState } from 'react';
import classes from './AgentCardSmall.module.scss';
import AgentDistrictDialog from './AgentDistrictDialog';



export interface AgentCardSmallProps {
	id: number;
	chineseName: string;
	englishName: string;
	image: string;
	districts?: string[];
	fullWidth?: boolean;
	noShadow?: boolean;
	onClick: AnyFn;
}

export default function AgentCardSmall(props: AgentCardSmallProps) {
	const { id, chineseName, englishName, image, districts, fullWidth, noShadow, onClick } = props;

	const regionBundles = useLocale();

	const allDistrictOptions = useMemo(() => {
		return {
		  ...regionBundles.districtHkiOptions,
		  ...regionBundles.districtKltOptions,
		  ...regionBundles.districtNtOptions,
		};
	  }, [ regionBundles ]);

	const districtDisplay = useMemo(() => {
		return districts?.map(d => allDistrictOptions[d] ?? d)?.join(', ');
	}, [districts, allDistrictOptions]);

	const [showAllDistricts, setShowAllDistricts] = useState(false);
	const handleShowAllDistrictsClose = () => {
		setShowAllDistricts(false);
	}

	const handleDistrictOnClick = () => {
		setShowAllDistricts(true);
	}

	return <div id={`agent-card-small-${id}`} className={clsx([
		classes['agent-card'],
		fullWidth ? classes['full-width'] : classes['fixed-size'],
		noShadow ? undefined : classes['shadow'],
	])}>
		<div onClick={onClick} style={{ cursor: 'pointer' }}>
			<ProfileImage src={image}  width={'5.825rem'} />
		</div>
		<div className={clsx([classes['agent-name'], classes['top-line'], classes['english']])} onClick={onClick}>{englishName}</div>
		<div className={clsx(classes['agent-name'], classes['chinese'])} onClick={onClick}>{chineseName}</div>
		<AgentDistrictDialog allDistricts={districtDisplay ?? ""} open={showAllDistricts} onHide={handleShowAllDistrictsClose} />
		{districtDisplay && districtDisplay.trim().length && <div className={classes['agent-district']} onClick={handleDistrictOnClick}><span>{districtDisplay}</span></div>}
	</div>
}
   