import useLayoutProps from 'common/hooks/useLayoutProps';
import useLocale from 'common/hooks/useLocale';
import { isMobileUI, multiLang } from 'common/utils/utils';
import PrivacyDesktopScreen from 'components/setting/PrivacyDesktopScreen';
import PrivacyMobileScreen from 'components/setting/PrivacyMobileScreen';
import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { IRootState } from 'reducers';


const PrivacyScreen = isMobileUI() ? PrivacyMobileScreen : PrivacyDesktopScreen;

const PrivacyPage = () => {
  const history = useHistory();
  const layoutProps = useLayoutProps();
  const privacyItems = useSelector((state: IRootState) => state.systemSettings['Privacy']);
  const { locale } = useLocale();

  return <PrivacyScreen
    layoutProps={layoutProps}
    privacyProps={Object.entries(privacyItems).sort((a, b) => parseInt(a[1]['seq'] ?? 0) - parseInt(b[1]['seq'] ?? 0))
      .map(([_, item]) => ({ title: multiLang(locale, item['TITLE_TC'], item['TITLE_EN'], item['TITLE_SC'])!, content: multiLang(locale, item['CONTENT_TC'], item['CONTENT_EN'], item['CONTEXT_SC'])! }))}
    onBackClick={() => history.goBack()}
  />

}

export default PrivacyPage;