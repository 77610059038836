import useLocale from 'common/hooks/useLocale';
import AgentRatingCommentDialog from 'components/overlay/AgentRatingCommentDialog';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from 'reducers';
import { agentRatingCommentActions, AgentRatingCommentForm, agentRatingCommentFormSpec } from 'reducers/agent-rating-comment';
import { useFormSpec } from 'reducers/common/form';

export default function GlobalAgentRatingCommentDialog() {
  const dispatch = useDispatch();
  
  const { open, currentAgent, contents } = useSelector((state: IRootState) => state.agentRatingComment);
  
  const { langAgent } = useLocale();

  const form = useFormSpec(
		(state: IRootState) => state.agentRatingComment,
    agentRatingCommentFormSpec,
    langAgent
  );
  
  return <AgentRatingCommentDialog
    open={open}
    onHide={() => {
      dispatch(agentRatingCommentActions.closeDialog());
      dispatch(agentRatingCommentActions.reset());
    }}
    image={currentAgent.image}
    chineseName={currentAgent.chineseName}
    englishName={currentAgent.englishName}
    maxRating={currentAgent.maxRating}
    bindRating={form.field('rating')}
    bindComment={form.field('comment')}
    bindIsAnonymous={form.field('isAnonymous')}
    onSubmitClick={async () => {
      const result = await form.validateAll();
      if (result) dispatch(agentRatingCommentActions.addRatingComment({ uid: currentAgent.id, ratingComment: contents as AgentRatingCommentForm}))
    }}
  />
}