import clsx from 'clsx';
import { registerTouchableClassNames } from 'common/polyfills/touch-polyfill';
import { FormBinder } from 'components/form-controls/IFormBinder';
import React, { useMemo } from 'react';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import classes from "./Numbering.module.scss";

registerTouchableClassNames(
	classes['btn'],
)

export interface NumberingProps {
  label: string;
  min: number;
  max: number;
  bind?: FormBinder<number>;
  bindEnabled?: FormBinder<boolean>;
  /** If true, use vertical label view */
  vertical?: boolean;
}

let idx = 0;

export default function Numbering(props: NumberingProps) {
  const { label, min, max, bind, vertical, bindEnabled } = props;

  const minus = () => {
    const newNum = +(bind?.value ?? (min + 1)) - 1;
    if (newNum >= min) {
      bindEnabled?.change(true);
      bind?.change(newNum);
    }
  }

  const add = () => {
    const newNum = +(bind?.value ?? min) + 1;
    if (newNum <= max) {
      bindEnabled?.change(true);
      bind?.change(newNum);
    }
  }

  const switchControlId = useMemo(() => `numbering-switch-${++idx}`, []);

  return <div className={clsx(classes['numbering'], { [classes['vertical']]: vertical })}>
    {/* <div className={classes['label']}>{label}</div> */}
    <Form.Group controlId={switchControlId} className="fg-dark">
      <Form.Check>
        <Form.Check.Input
          checked={bindEnabled?.value ?? false}
          onChange={()=>{
            bindEnabled?.change(!bindEnabled?.value);
          }}
        />
        <Form.Check.Label>{label}</Form.Check.Label>
      </Form.Check>
    </Form.Group>
    <InputGroup className={classes['btn-group']}>
      <InputGroup.Prepend onClick={() => minus()}>
        <InputGroup.Text className={clsx(classes['btn'], classes['left'])}>-</InputGroup.Text>
      </InputGroup.Prepend>
      <Form.Control disabled className={clsx(classes['input'])} value={bind?.value}></Form.Control>
      <InputGroup.Append onClick={() => add()}>
        <InputGroup.Text className={clsx(classes['btn'], classes['right'])}>+</InputGroup.Text>
      </InputGroup.Append>
    </InputGroup>
  </div>
}