import { ActionType, createAction, createReducer } from 'typesafe-actions';

export type LayoutActions = ActionType<typeof layoutActions>;

export const layoutActions = {
  // Alert
  alert: createAction('Layout.AlertMessageAdded', (message: string, severity?: string) => ({ message, severity }))(),
  _presentAlertMessage: createAction('Layout.AlertMessagePresented', (message: string, severity?: string) => ({ message, severity }))(),
  _removeAlertMessage: createAction('Layout.AlertMessageRemoved')(),
  
  // Mask
  presentMask: createAction('Layout.MaskPresentRequested')(),
  dismissMask: createAction('Layout.MaskDismissRequested')(),

  // Layout
  initialize: createAction('Layout.Initialize')(),
  reloadData: createAction('Layout.DataReloadRequested')(),
  changeInitialStatus: createAction('Layout.InitialStatusChanged', (initialized: boolean, initializationFailed?: boolean) => ({ initialized, initializationFailed }))(),
  updateIOSStatusBar: createAction('Layout.IOSStatusBarUpdated', (height: number) => ({ height }))(),
};

const layoutInitials = {
  maskRefCount: 0,
  initialized: false,
  initializationFailed: false as boolean | undefined,
  // alerts: [] as Array<{ message: string, severity?: string }>,
  alert: null as { message: string, severity?: string } | null,
  iosStatusBarHeight: 0,
};

export type LayoutState = typeof layoutInitials;

export const layoutReducer = createReducer<LayoutState, LayoutActions>(layoutInitials)
  .handleAction(layoutActions.presentMask,
    (state) => ({ ...state, maskRefCount: state.maskRefCount + 1 })
  )
  .handleAction(layoutActions.dismissMask,
    (state) => ({ ...state, maskRefCount: state.maskRefCount - 1 < 0 ? 0 : state.maskRefCount - 1 })
  )
  .handleAction(layoutActions._presentAlertMessage, 
    (state, action) => ({ ...state, alert: action.payload })
  )
  .handleAction(layoutActions._removeAlertMessage,
    (state) => ({ ...state, alert: null })
  )
  .handleAction(layoutActions.changeInitialStatus,
    (state, action) => ({ ...state,
      initialized: action.payload.initialized,
      initializationFailed: action.payload.initializationFailed,
    })  
  )
  .handleAction(layoutActions.updateIOSStatusBar,
    (state, action) => ({ ...state, iosStatusBarHeight: action.payload.height })  
  )
;
