import { ApiPageResult, ApiResult, BASE_URL } from 'api';
import CAAgentDTO from 'common/dto/CAAgentDTO';
import CAAgentRatingCommentDTO from 'common/dto/CAAgentRatingCommentDTO';
import CAClientContactDTO from 'common/dto/CAClientContactDTO';

export const agentApi = {

  getAgents: async (criteria: object, token: string): Promise<ApiPageResult<CAAgentDTO>> => {
    try {
      const response = await fetch(`${BASE_URL}/ca/agents/search`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(criteria),
      });
      return await response.json();
    } catch (e) {
      return { error: String(e) };
    }
  },

  contactAgent: async (contact: CAClientContactDTO, token: string): Promise<ApiResult<boolean>> => {
    try {
      const response = await fetch(`${BASE_URL}/ca/agents/enquiry`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(contact),
      });
      return await response.json();
    } catch (e) {
      return { error: String(e) };
    }
  },

  getInTouch: async (contact: CAClientContactDTO): Promise<ApiResult<boolean>> => {
    try {
      const response = await fetch(`${BASE_URL}/ca/agents/get-in-touch`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(contact),
      });
      return await response.json();
    } catch (e) {
      return { error: String(e) };
    }
  },
  
  getRatingComments: async (uid: number, token: string): Promise<ApiResult<CAAgentRatingCommentDTO[]>> => {
    try {
      const response = await fetch(`${BASE_URL}/ca/agents/${uid}/comments`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      return await response.json();
    } catch (e) {
      return { error: String(e) };
    }
  }, 
  
  addRatingComment: async (uid: number, ratingComment: CAAgentRatingCommentDTO, token: string): Promise<ApiResult<boolean>> => {
    try {
      const response = await fetch(`${BASE_URL}/ca/agents/${uid}/comments/add`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(ratingComment),
      });
      return await response.json();
    } catch (e) {
      return { error: String(e) };
    }
  }
};

export default agentApi;