import { multiLang } from "common/utils/utils";

export default interface CAPropertyStockListItemDTO {

  //TODO add SC fields
  caPropertyStockId: number;
  propertyNo: string;
  district: string;
  streetZh: string;
  streetEn: string;
  streetSc: string;
  buildingNameZh: string;
  buildingNameEn: string;
  buildingNameSc: string;
  blockZh: string;
  blockEn: string;
  blockSc: string;
  level: string;
  unit: string;
  // List<String> levels;

  gross: number;
  net: number;
  price: number;
  rent: number;
  status: string;

  latitude: number;
  longitude: number;

  isBookmarked: boolean;
  isHotPick: boolean;

  pricePerGross: number;
  rentPerGross: number;
  pricePerNet: number;
  rentPerNet: number;

  photoFilename: string;

  //--- for finding latitude and latitude
  usage: string;
  lot: string;

  punchlineEN: string;
  punchlineTC: string;
  punchlineSC: string;


  // For bookmark only
  unpublished?: boolean;
}

export const getLocalePunchline = (ps: CAPropertyStockListItemDTO, locale: string) => {
  // return ({
  //   'en': ps.punchlineEN,
  //   'zh_HK': ps.punchlineTC,
  //   'zh_CN': ps.punchlineSC,
  // } as Record<string, string>)[locale];
  return multiLang(locale, ps.punchlineTC, ps.punchlineEN, ps.punchlineSC);
}