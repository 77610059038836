import { BASE_URL } from 'api';
import { getLocalePunchline } from 'common/dto/CAPropertyStockListItemDTO';
import useLayoutProps from 'common/hooks/useLayoutProps';
import useLocale from 'common/hooks/useLocale';
import { jumpers, useJump } from 'common/theme/jumper';
import { isMobileUI, multiLang } from 'common/utils/utils';
import BookmarkListViewDesktop, { BookmarkListViewDesktopProps } from 'components/property/bookmark/BookmarkListViewDesktop';
import BookmarkScreenMobile from 'components/property/bookmark/BookmarkScreenMobile';
import { PropertyResultListItemProps } from 'components/property/search/PropertyResultListItem';
import { SavedSearchListItemProps } from 'components/property/search/SavedSearchListItem';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { IRootState } from 'reducers';
import { bookmarkListActions } from 'reducers/bookmark-list';
import { loginSelectors } from 'reducers/login';
import { propertyListActions } from 'reducers/property-list';
import { saveSearchActions } from 'reducers/save-search';

export default function InlinePropertyListPage() {
  const layoutProps = useLayoutProps();
  const history = useHistory();
  const dispatch = useDispatch();
  const { properties, hasMore } = useSelector((state: IRootState) => state.bookmarkList);
  const { savedCriterias } = useSelector((state: IRootState) => state.saveSearch);
  const { locale, districtHkiOptions, districtKltOptions, districtNtOptions, levelOptions, langProperty } = useLocale();
  const districtOptions = { ...districtHkiOptions, ...districtKltOptions, ...districtNtOptions };
  const DISPLAY_DATE_FORMAT = useSelector((state: IRootState) => state.systemSettings.System?.SYSTEM_PARAM?.DISPLAY_DATE_FORMAT);

  const jump = useJump();
  const loggedIn = useSelector(loginSelectors.loggedIn());

  useEffect(() => {
    dispatch(bookmarkListActions.fetch({ isSwitchingPage: false }));
    dispatch(saveSearchActions.fetchSavedSearchCriterias());
  }, [ dispatch ]);

  const propertiesProps: PropertyResultListItemProps[] = properties.map((property, i) => ({
    id: property.caPropertyStockId,
    street: `${multiLang(locale, property.streetZh, property.streetEn, property.streetSc) ?? ''}`,
    buildingName: `${multiLang(locale, property.buildingNameZh, property.buildingNameEn, property.buildingNameSc) ?? ''}`,
    level: `${levelOptions[property.level] ?? ''}`,
    image: property.photoFilename ? `${BASE_URL}/files/${property.photoFilename}` : '' , // property.image, 
    net: property.net,
    gross: property.gross,
    district: districtOptions[property.district], //
    price: property.price,
    rent: property.rent,
    
    bookmarked: property.isBookmarked,
    isHotpick: property.isHotPick,

    unpublished: property.unpublished,

    onBookmarkClick: () => {
      if (!loggedIn) {
        jump(jumpers.toLogin());
        return;
      }
      property.isBookmarked ? 
        dispatch(bookmarkListActions.deleteBookmark({ caPropertyStockId: property.caPropertyStockId })) :
        dispatch(bookmarkListActions.addBookmark({ caPropertyStockId: property.caPropertyStockId }))
      ;
    },

    onClick: () => {
      history.push(`/properties/${property.propertyNo}`);
    },
  }));

  const savedCriteriasProps: SavedSearchListItemProps[] = savedCriterias.map(criteria => ({
    name: criteria.title,
    date: criteria.searchDate ? moment(criteria.searchDate).format(DISPLAY_DATE_FORMAT) : '',
    onDelete: () => {
      dispatch(saveSearchActions.deleteSavedSearchCriteria({ criteriaId: criteria.criteriaId }));
    },
    onClick: () => {
      dispatch(propertyListActions.updateCurrentCriteria({ savedCriteria: criteria.contents }));
      history.push(`/properties`);
    }
  }));

  const propertiesDesktopProps: BookmarkListViewDesktopProps['properties'] = properties.map((property, i) => ({
    id: property.caPropertyStockId,
    street: `${multiLang(locale, property.streetZh, property.streetEn, property.streetSc) ?? ''}`,
    buildingName: `${multiLang(locale, property.buildingNameZh, property.buildingNameEn, property.buildingNameSc) ?? ''}`,
    level: `${levelOptions[property.level] ?? ''}`,
    propertyNo: property.propertyNo,
    image: property.photoFilename ? `${BASE_URL}/files/${property.photoFilename}` : '' ,
    title: `${multiLang(locale, property?.streetZh, property?.streetEn,property?.streetSc) ?? ''} ${multiLang(locale, property?.buildingNameZh, property?.buildingNameEn,property?.buildingNameSc) ?? ''} ${levelOptions[property?.level] ?? ''}`,
    punchline: getLocalePunchline(property, locale),
    gross: property.gross,
    net: property.net,
    district: districtOptions[property.district],
    price: property.price,
    rent: property.rent,
    fullWidth: true,
    noShadow: true,
    bookmarked: property.isBookmarked,
    unpublished: property.unpublished,
    onBookmarkClick: () => {
      if (!loggedIn) {
        jump(jumpers.toLogin());
        return;
      }
      property.isBookmarked ? 
        dispatch(bookmarkListActions.deleteBookmark({ caPropertyStockId: property.caPropertyStockId })) :
        dispatch(bookmarkListActions.addBookmark({ caPropertyStockId: property.caPropertyStockId }))
      ;
    },
    isHotpick: property.isHotPick,
    onClick: () => {
      history.push(`/properties/${property.propertyNo}`);
    },
  }));

  return isMobileUI() ? <BookmarkScreenMobile 
    layoutProps={layoutProps} 
    properties={propertiesProps}
    savedCriterias={savedCriteriasProps}
  /> : <BookmarkListViewDesktop
    properties={propertiesDesktopProps}
    hasMore={hasMore}
    onRequestNextPage={() => dispatch(bookmarkListActions.fetch({ isSwitchingPage: true }))}
    {...layoutProps}
  />
  // <InlinePropertyList
  //   pageTitle={langProperty.titleBookmarkList}
  //   layoutProps={layoutProps}
  //   properties={propertiesProps}
  //   sortKey={null}
  //   direction={'DESC'}
  //   onRequestNextPage={() => dispatch(bookmarkListActions.fetch({ isSwitchingPage: true }))}
  // />
}