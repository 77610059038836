import SavedSearchDialog from 'components/property/search/SavedSearchDialog';
import { SavedSearchListItemDesktopProps } from 'components/property/search/SavedSearchListItemDesktop';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { IRootState } from 'reducers';
import { propertyListActions } from 'reducers/property-list';
import { saveSearchActions } from 'reducers/save-search';

export default function GlobalEnquiryDialog() {
  const { savedCriterias, openSavedSearchCriteriasDialog } = useSelector((state: IRootState) => state.saveSearch);

  const DISPLAY_DATE_FORMAT = useSelector((state: IRootState) => state.systemSettings.System?.SYSTEM_PARAM?.DISPLAY_DATE_FORMAT);

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (openSavedSearchCriteriasDialog) dispatch(saveSearchActions.fetchSavedSearchCriterias());
  }, [dispatch, openSavedSearchCriteriasDialog, savedCriterias.length]);

  const savedCriteriasProps: SavedSearchListItemDesktopProps[] = savedCriterias.map(criteria => ({
    name: criteria.title,
    date: criteria.searchDate ? moment(criteria.searchDate).format(DISPLAY_DATE_FORMAT) : '',
    onDelete: () => {
      dispatch(saveSearchActions.deleteSavedSearchCriteria({ criteriaId: criteria.criteriaId }));
    },
    onClick: () => {
      dispatch(propertyListActions.updateCurrentCriteria({ savedCriteria: criteria.contents }));
      history.push(`/map-list`);
      dispatch(saveSearchActions.closeSavedSearchCriteriasDialog())
    }
  }));

  return <SavedSearchDialog
    savedSearchCriterias={savedCriteriasProps}
    open={openSavedSearchCriteriasDialog}
    onHide={() => dispatch(saveSearchActions.closeSavedSearchCriteriasDialog())}
  />
}