import 'common/general-imports';
import useLocale from 'common/hooks/useLocale';
import { useMobileLayoutHeight } from 'common/hooks/useMobileLayoutHeight';
import { registerTouchableClassNames } from 'common/polyfills/touch-polyfill';
import FormTextField from 'components/form-controls/FormTextField';
import ClientScreenTopBar from 'components/navigation/ClientScreenTopBar';
import MainLayoutMobile from 'components/scaffold/MainLayoutMobile';
import React from 'react';
import classes from './LoginScreenMobile.module.scss';
import { LoginViewProps } from './LoginViewProps';
import SocialLoginButton from './SocialLoginButton';




registerTouchableClassNames(
	classes['login-btn'],
	classes['forget-password-btn'],
	classes['registration-btn']
)

export default function LoginScreenMobile(props: LoginViewProps) {
	const { layoutProps, bindEmail, bindPassword, onBackClick, onLoginClick,
		onForgotPasswordClick, onRegisterClick, onFacebookLoginClick, onGoogleLoginClick, onAppleLoginClick } = props;

	const mobileLayoutHeight = useMobileLayoutHeight();

	const { langLogin } = useLocale();

	const facebookIcon = 'images/facebookIcon.png';
	const googleIcon = 'images/googleIcon.png';
	const appleIcon = 'images/appleIcon.png';
	return <MainLayoutMobile {...layoutProps}>
		<form autoComplete="off" className={classes['login-screen-mobile']} onSubmit={(ev) => {
			ev.preventDefault();
		}}>
			<div className={classes['inner']} style={{ height: mobileLayoutHeight }}>
				<div className={classes['client-screen-topbar-container']}>
					<ClientScreenTopBar title={langLogin.titleMemberLogin} onBackClick={onBackClick} />
				</div>
				<div className={classes['main']}>
					<div className={classes['input-caption']}>{langLogin.captionEmail}</div>
					<div><FormTextField label={''} bind={bindEmail} /></div>
					<div className={classes['input-caption']}>{langLogin.captionPassword}</div>
					<div><FormTextField label={''} bind={bindPassword} type={'password'} /></div>
					<div className={classes['login-btn']}>
						<button type="submit" onClick={onLoginClick}>{langLogin.actionLogin}</button>
					</div>
					<div className={classes['forget-password-or-registration-container']}>
						<button className={classes['registration-btn']} onClick={onRegisterClick}>{langLogin.actionMemberRegistration}</button>
						<button className={classes['forget-password-btn']} onClick={onForgotPasswordClick}>{langLogin.actionForgetPassword}</button>
					</div>
					<div className={classes['divider-container']}>
						<div className={classes['divider']} />
					</div>
					<div className={classes['social-login-btn-container']}>
						<SocialLoginButton caption={langLogin.actionLoginWithFacebook} logoImage={facebookIcon} onClick={onFacebookLoginClick}></SocialLoginButton>
						<SocialLoginButton caption={langLogin.actionLoginWithGoogle} logoImage={googleIcon} onClick={onGoogleLoginClick}></SocialLoginButton>
						{onAppleLoginClick ? <SocialLoginButton caption={langLogin.actionLoginWithApple} logoImage={appleIcon} onClick={onAppleLoginClick}></SocialLoginButton> : null}
					</div>
				</div>
			</div>
		</form>
	</MainLayoutMobile>
}