import useLocale from 'common/hooks/useLocale';
import { addTouchableHoveringSupport } from 'common/polyfills/touch-polyfill';
import { FormBinder } from 'components/form-controls/IFormBinder';
import ScreenTopBarMobile from 'components/navigation/ScreenTopBarMobile';
import DistrictSelectDialog from 'components/overlay/DistrictSelectDialog';
import VerticalScrollView from 'components/scaffold/VerticalScrollView';
import React, { useMemo, useState } from 'react';
import classes from './DistrictFilter.module.scss';


export interface DistrictFilterProps {
  bindDistricts?: FormBinder<string[]>;
  regionBundles: RegionBundles;

  onBackClick?: AnyFn;
}

addTouchableHoveringSupport(
  `.${classes['filter-btn']}`, [], classes['no-hover'],
);

export default function DistrictFilter(props: DistrictFilterProps) {
  const { bindDistricts, regionBundles, onBackClick } = props;
  const [ districtDialogOpen, setDistrictDialogOpen ] = useState(false);
  const handleDistrictDialogClose = () => setDistrictDialogOpen(false);

  const allDistrictOptions = useMemo(() => {
    return {
      ...regionBundles.districtHkiOptions,
      ...regionBundles.districtKltOptions,
      ...regionBundles.districtNtOptions,
    };
  }, [ regionBundles ]);

  const { lang } = useLocale();
  const numberOfAllDistrict = useMemo(() => Object.keys(allDistrictOptions).length, [allDistrictOptions]);
  const selectedDistricts = bindDistricts?.value ?? []

  return <ScreenTopBarMobile shadow onBackClick={onBackClick} style={{ width: "85%" }} >
    <div className={classes['district-filter']}>
      <DistrictSelectDialog confirmRequired {...regionBundles} open={districtDialogOpen} onHide={handleDistrictDialogClose} districts={bindDistricts} />    
      <div className={classes['filter-btn']} onClick={() => setDistrictDialogOpen(true)}>
        <i className="fa fa-filter" />
      </div>
      <VerticalScrollView>
        <div className={classes['tag-pane']}>
          {(selectedDistricts.length === 0 || selectedDistricts.length === numberOfAllDistrict)
            ? <div className={classes['tag']} key="ALL">{lang.captionAllDistricts}</div>
            : bindDistricts?.value?.map(n =>
              <div key={n} className={classes['tag']} onClick={() => bindDistricts?.change(
                bindDistricts?.value?.filter(d => d !== n)
              )}>
                {allDistrictOptions[n]}
              </div>)
          }
        </div>
      </VerticalScrollView>
    </div>
  </ScreenTopBarMobile>;
}