import clsx from 'clsx';
import useLocale from 'common/hooks/useLocale';
import { handlePriceDisplay, handlePriceDisplayUnit, isNonEmpty, numberWithCommas } from 'common/utils/utils';
import React from 'react';
import classes from './TransactionListItem.module.scss';

export interface TransactionListItemProps {
  address: string;
  date: string;
  gross: number;
  net: number;
  /** Note: Full price, not price display */
  price: number;
  overrideClassName?: string;
  pricePerNet: number;
  pricePerGross: number;
}

export default function TransactionListItem(props: TransactionListItemProps) {
  const { address, date, gross, net, price, overrideClassName } = props;

  const { lang: langCommon, langProperty, locale } = useLocale();

  const grossDisplay = () => {
		const grossDisplay = !(gross) ? '' : (langProperty.grossAbbr + String(gross) + langCommon.uFt);

    return isNonEmpty(grossDisplay) ? <span className={classes['gross']}>{grossDisplay}</span> : null;
	}

	const netDisplay = () => {
		const netDisplay = !(net) ? '' : (langProperty.netAbbr + String(net) + langCommon.uFt);
		
		return isNonEmpty(netDisplay) ? <span className={classes['net']}>{netDisplay}</span> : null;
	}

  return <div className={clsx(classes['transaction-list-item'], overrideClassName)}>
    <div className={classes['address-date']}>
      <div className={classes['address']}>{address}</div>
    </div>
    <div className={classes['area-price-container']}>
      <div className={classes['date']}>{date}</div>
      <div className={classes['gap']}></div>
      <div className={classes['area-price']}>
        <span className={classes['area']}>
          {netDisplay()}
          {
          !!(net) && !!(gross) && 
            <span className={classes['area-divider']}>{'/'}</span>
          }
          {grossDisplay()}
        </span>
        <span className={classes['divider']}>{'|'}</span>
        <span className={classes['price']}>
          {langCommon.uCurr + numberWithCommas(handlePriceDisplay(price ?? 0, locale)) + handlePriceDisplayUnit(price, locale, langCommon)}
        </span>
      </div>     
    </div>
  </div>
}