import CAPropertyStockListItemDTO from 'common/dto/CAPropertyStockListItemDTO';
import { ActionType, createAction, createReducer } from 'typesafe-actions';
import { CommonFormState, createFormSpec } from './common/form';

export interface BookmarkListState extends CommonFormState<BookmarkListSearchForm> {
	properties: CAPropertyStockListItemDTO[];
	hasMore: boolean;
	totalCount: number;
}

export const bookmarkSearchFormSpec = createFormSpec(
	'bookmarkList.CriteriaUpdated',
	'bookmarkListInitialState.CriteriaErrorAdded'
)<BookmarkListSearchForm>(() => []);

export interface BookmarkListSearchForm {
	// Pagination //
	limit: number;
	page: number;
}

const bookmarkListInitialState = (): BookmarkListState => ({
	...bookmarkSearchFormSpec.initialState,
	properties: [],
	hasMore: true,
	totalCount: 0,
	contents: {
		limit: 20,
		page: 0
	}
});

export const bookmarkListActions = {
	...bookmarkSearchFormSpec.actions,
	reset: createAction('BookmarkList.Reset')(),
	fetch: createAction('BookmarkList.FetchRequested')<{ isSwitchingPage: boolean }>(),
	update: createAction('BookmarkList.Updated')<{ properties: CAPropertyStockListItemDTO[], hasMore: boolean }>(),
	appendNextPage: createAction('BookmarkList.AppendNextPage')<{ properties: CAPropertyStockListItemDTO[], hasMore: boolean }>(),
	addBookmark: createAction('BookmarkList.AddBookmark')<{ caPropertyStockId: number }>(),
	deleteBookmark: createAction('BookmarkList.DeleteBookmark')<{ caPropertyStockId: number }>(),
	visitBookmark: createAction('BookmarkList.VisitBookmark')<{ caPropertyStockId: number }>()
}

export type BookmarkListActions = ActionType<typeof bookmarkListActions>;

export const bookmarkListReducer = createReducer<BookmarkListState, BookmarkListActions>(bookmarkListInitialState(), bookmarkSearchFormSpec.reducer)
  .handleAction(bookmarkListActions.reset, () => bookmarkListInitialState())
  .handleAction(bookmarkListActions.fetch, (state, { payload }) => ({
    ...state,
    properties: payload.isSwitchingPage ? state.properties : [],
    hasMore: payload.isSwitchingPage ? state.hasMore : true,
  }))
  .handleAction(bookmarkListActions.appendNextPage, (state, { payload }) => ({
    ...state, properties: [ ...state.properties, ...payload.properties ], hasMore: payload.hasMore,
  }))
  .handleAction(bookmarkListActions.update, (state, { payload }) => ({
    ...state, properties: payload.properties, hasMore: payload.hasMore,
	}))
	.handleAction(bookmarkListActions.addBookmark, (state, { payload }) => ({
    ...state, properties: state.properties.map(p => p.caPropertyStockId === payload.caPropertyStockId ?
      { ...p, isBookmarked: true } : p,
    )
  }))
  .handleAction(bookmarkListActions.deleteBookmark, (state, { payload }) => ({
    ...state, properties: state.properties.map(p => p.caPropertyStockId === payload.caPropertyStockId ?
      { ...p, isBookmarked: false } : p,
    )
  }))
;

