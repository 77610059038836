import clsx from 'clsx';
import useLocale from 'common/hooks/useLocale';
import { registerTouchableClassNames } from 'common/polyfills/touch-polyfill';
import { FormBinder } from 'components/form-controls/IFormBinder';
import PopupOverlay from 'components/overlay/PopupOverlay';
import { ScrollContext, useCreateScrollContext } from 'components/scaffold/ScrollContext';
import React, { useRef, useState } from 'react';
import classes from "./MultiSelectView.module.scss";

registerTouchableClassNames(
  classes['list-item'],
  classes['option'],
  classes['action-btn']
)

export interface MultiSelectViewProps {
  label: string;
  options: LocaleOptions;
  bind?: FormBinder<string[]>;
}

export default function MultiSelectView({ label, options, bind }: MultiSelectViewProps) {
  const { lang, langProperty } = useLocale();

  const [ showPopup, setShowPopup ] = useState(false);
  const scrollRef = useRef<HTMLDivElement>(null);
  const scrollContext = useCreateScrollContext(scrollRef);

  return <li className={clsx(["list-group-item", classes['list-item']])}>
    <div className={classes['search-item']} onClick={() => setShowPopup(true)}>
      <div>{label}</div>
      <div className={classes['action']}>
        <div className={classes['selected-value-text']}>
          {bind?.value?.map(key=> options[key])?.join(' / ')}
        </div>
        <div className={classes['show-btn']}>
          <i className={clsx([ classes['icon'], 'fa fa-angle-right' ])}></i>
        </div>
      </div>
     
    </div>
    <PopupOverlay show={showPopup}>
       <ScrollContext.Provider value={scrollContext}><div className={classes['options-view']} ref={scrollRef}>
       <div className={classes['part-container']}>
          <div className={classes['header-container']}>
            {langProperty.captionAdvancedSearch}
          </div>
        </div>
      
      <ul className="list-group list-group-flush">
        <li className={clsx(["list-group-item", classes['header']])}>{label}</li>    
        {
          Object.keys(options).map(key => <li style={{ height: 'auto', paddingTop: '0.5rem', paddingBottom: '0.5rem' }}
            className={clsx(["list-group-item", classes['option']])}
            onClick={() => {
              const currValue = bind?.value ?? [];
              if (currValue.includes(key)) {
                bind?.change(currValue.filter(v => v !== key));
              } else {
                bind?.change([ ...currValue, key]);
              }
          }}>
            <div>{options[key]}</div>
            <div className={classes['icon-container']}>
              {bind?.value?.includes(key) ? <i className={clsx(["fas fa-check-circle", classes['icon']])}/> : null}
            </div>
          </li>)
        }    
        <li className={clsx(["list-group-item", classes['bottom']])}>
          <button className={classes['action-btn']} onClick={() => setShowPopup(false)}>{lang.actionContinue}</button>
        </li> 
      </ul>
      </div></ScrollContext.Provider>
    </PopupOverlay>
  </li>
}