import CAAgentDTO from 'common/dto/CAAgentDTO';
import CAAgentRatingCommentDTO from 'common/dto/CAAgentRatingCommentDTO';
import CAFeatureTagDTO from 'common/dto/CAFeatureTagDTO';
import CANewsListItemDTO from 'common/dto/CANewsListItemDTO';
import CAPropertyStockHomeItemDTO from 'common/dto/CAPropertyStockHomeItemDTO';
import { ActionType, createAction, createReducer } from 'typesafe-actions';

export interface HomeState {
	latestPropertiesForSale: CAPropertyStockHomeItemDTO[];
	latestPropertiesForLeasing: CAPropertyStockHomeItemDTO[];
	latestNewsList: CANewsListItemDTO[];
	topAgents: CAAgentDTO[];
	topAgentComments: CAAgentRatingCommentDTO[];
	topFeatureTags: CAFeatureTagDTO[];
	searchType: 'BUY' | 'RENT' | 'SELLER_ENQUIRY';
	loading?: boolean;
}

const homeInitialState = (): HomeState => ({
	latestPropertiesForSale: [],
	latestPropertiesForLeasing: [],
	latestNewsList: [],
	topAgents: [],
	topAgentComments: [],
	topFeatureTags: [],
	searchType: 'BUY',
});

export const homeActions = {
	setLoading: createAction('Home.SetLoading', (loading: boolean) => ({ loading }))(),
	doFetchHome: createAction('Home.FetchRequested')(),
	updateHome: createAction('Home.Updated')<{ 
		latestPropertiesForSale: CAPropertyStockHomeItemDTO[],
		latestPropertiesForLeasing: CAPropertyStockHomeItemDTO[],
		latestNewsList: CANewsListItemDTO[],
		topAgents: CAAgentDTO[],
		topAgentComments: CAAgentRatingCommentDTO[],
		topFeatureTags: CAFeatureTagDTO[],
	}>(),
	updateSearchType: createAction('Home.SearchTypeUpdated')<'BUY' | 'RENT' | 'SELLER_ENQUIRY'>(),
}

export type HomeActions = ActionType<typeof homeActions>;

export const homeReducer = createReducer<HomeState, HomeActions>(homeInitialState())
	.handleAction(homeActions.updateHome, (state, { payload }) => ({
		...state, ...payload
	}))
	.handleAction(homeActions.updateSearchType, (state, { payload }) => ({
		...state, searchType: payload,
	}))
	.handleAction(homeActions.setLoading, (state, { payload }) => ({
		...state, loading: payload.loading,
	}))
;