import useLocale from 'common/hooks/useLocale';
import breakpoints from 'common/theme/breakpoints.module.scss';
import ModalDialog from 'components/overlay/ModalDialog';
import React, { useEffect, useMemo } from 'react';
import Container from 'react-bootstrap/esm/Container';
import { useMediaQuery } from 'react-responsive';
import classes from './SavedSearchDialog.module.scss';
import SavedSearchListItemDesktop, { SavedSearchListItemDesktopProps } from './SavedSearchListItemDesktop';
import { format } from 'util';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from 'reducers';
import { saveSearchActions } from 'reducers/save-search';

export interface SavedSearchDialogProps {
  savedSearchCriterias: SavedSearchListItemDesktopProps[];
  open?: boolean;
  onHide?: () => void;
}

export default function SavedSearchDialog(props: SavedSearchDialogProps) {
  const { savedSearchCriterias, open, onHide } = props;
  const { lang, langProperty } = useLocale();

  const isSmallScreen = useMediaQuery({ maxWidth: breakpoints.lg });

  const notFoundView = () => <div className={classes['no-record-view']}>
    <div className={classes['msg']}>{langProperty.msgNoRecord}</div>
  </div>

  const LATEST_N_SAVED_SEARCH_CRITERIA = useSelector((state: IRootState) => state.systemSettings?.ClientApp.PROPERTY_STOCK.LATEST_N_SAVED_SEARCH_CRITERIA);

  const latestSaveSearchShown = useMemo(() => format(
    langProperty.captionLatestSaveSearchShown,
    LATEST_N_SAVED_SEARCH_CRITERIA,
  ), [LATEST_N_SAVED_SEARCH_CRITERIA, langProperty]);


  return <ModalDialog size={'xl'} title={lang.navSavedSearchCriteria} 
    overrideClassName={{ overrideModal: !isSmallScreen ? classes['modal'] : undefined, overrideTitle: classes['title'] }} 
    show={open} onHide={onHide}
  >
    <div className={classes['saved-search-dialog']}>
      {/* <hr className={clsx(classes['divider'], classes['bg-saffron'])}/> */}
      <Container>
        <div className={classes['latestSaveSearch']}>
          <div className={classes['msg']}>{latestSaveSearchShown}</div>
        </div>

        {
          savedSearchCriterias.length > 0 ? <div className="row">
            <div className={`col-sm-12 col-md-12 col-lg-12 col-xl-12`}>
              <div className={`row ${classes['container']}`}>
              {
                savedSearchCriterias.map(criteria => <div className={`${classes['slot']} col-sm-12 col-md-12 col-lg-6 col-xl-6`}>
                  <SavedSearchListItemDesktop {...criteria} />
                </div>)
              }
              </div>
            </div>
          </div> : notFoundView()
        }
        
      </Container>
    </div>
  </ModalDialog>
};