import { ActionType, createAction, createReducer } from 'typesafe-actions';
import { CommonFormState, createFormSpec } from './common/form';

export interface AgentRatingCommentForm {
  comment: string;
  rating: number;
  isAnonymous: boolean;
}

export interface AgentContactInfo {
  id: number;
  image: string;
  chineseName: string;
  englishName: string;
  maxRating: number;
  rating: number;
  entry: 'D' | 'P';
}

export interface AgentRatingCommentState extends CommonFormState<AgentRatingCommentForm> {
  open: boolean;
  currentAgent: AgentContactInfo;
}

export const agentRatingCommentFormSpec = createFormSpec(
  'AgentRatingComment.Edit',
  'AgentRatingComment.AddFormError'
)<AgentRatingCommentForm>((langAgent: LocaleOptions) => [
  (values) => !values.rating ? { rating: langAgent.errPleaseRate } : {},
  (values) => {
    // if (!isNonEmpty(values.comment)) return { comment: langAgent.errPleaseComment };
    const matches = values.comment?.match(/[\u00ff-\uffff]|\S+/g);
    if (matches && matches.length > 50) {
      return { comment: langAgent.errPleaseEnterNoMoreThan50words }
    } else {
      return {}
    }
  }
]);

export const agentRatingCommentActions = {
  ...agentRatingCommentFormSpec.actions,
  reset: createAction('AgentRatingComment.Reset')(),
  openDialog: createAction('AgentRatingComment.Open')<AgentContactInfo>(),
  closeDialog: createAction('AgentRatingComment.Close')(),
  addRatingComment: createAction('AgentRatingComment.AddRequested')<{ uid: number, ratingComment: AgentRatingCommentForm }>()
};

export type AgentRatingCommentActions = ActionType<typeof agentRatingCommentActions>;

const initialState = (): AgentRatingCommentState => ({
  ...agentRatingCommentFormSpec.initialState,
  open: false,
  currentAgent: {
    id: 0,
    image: '',
    chineseName: '',
    englishName: '',
    rating: 0,
    maxRating: 0,
    entry: 'D',
  },
  contents: {
    rating: 0,
    comment: '',
    isAnonymous: false,
  }
});

export const agentRatingCommentReducer = createReducer<AgentRatingCommentState, AgentRatingCommentActions>(initialState(), agentRatingCommentFormSpec.reducer)
  .handleAction(agentRatingCommentActions.openDialog, (state, action) => 
    ({ ...state, open: true, currentAgent: action.payload })
  )
  .handleAction(agentRatingCommentActions.closeDialog, (state) => 
    ({ ...state, open: false })
  )
  .handleAction(agentRatingCommentActions.reset, (_) => 
    initialState()
  )
;
