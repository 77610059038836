import clsx from 'clsx';
import { addTouchableHoveringSupport } from 'common/polyfills/touch-polyfill';
import React from 'react';
import classes from './ModalDialogActions.module.scss';


export interface ModalDialogActionsProps {
  children: React.ReactElement<ModalDialogActionButtonProps>[] | React.ReactElement<ModalDialogActionButtonProps>;
}

export interface ModalDialogActionButtonProps {
  children: string;
  onClick?: AnyFn;
  _last?: boolean;
  _width?: string;
}

addTouchableHoveringSupport(
  `.${classes['action']}`, [],
  classes['no-hover'],
);

export default function ModalDialogActions(props: ModalDialogActionsProps) {
  const buttons = Array.isArray(props.children) ? props.children : [ props.children ];
  return <div className={classes['dialog-actions']}>
    {buttons.map((element, i) => {
      return <ModalDialogActionButton key={i} {...element.props}
        _last={i === buttons.length - 1}
        _width={`${100* (1 / buttons.length)}%`}
      />;
    })}
  </div>;
}

export function ModalDialogActionButton(props: ModalDialogActionButtonProps) {
  return <div className={clsx(classes['action'], !props._last && classes['left'])}
    onClick={props.onClick}
    style={{ width: props._width }}
  >
    {props.children}
  </div>;
}