import { BASE_URL } from 'api';

export const optionApi = {
  loadOptions: async (_token: string): Promise<any> => {
    try {
        return await fetch(`${BASE_URL}/options`, {
            method: 'GET',
        }).then(res => res.json());
    } catch (e) {
        return { error: String(e) };
    }
  }
};

export default optionApi;