import clsx from 'clsx';
import 'common/polyfills/viewport-height';
import React from 'react';
import Spinner from 'react-bootstrap/Spinner';
import classes from './ProgressMask.module.scss';



export interface ProgressMaskProps {
  show: boolean;
}

export default function ProgressMask(props: ProgressMaskProps) {
  const { show } = props;
  // const backdrop = () => <div className={classes['progress-mask']} />;
  return <div className={clsx([
    classes['progress-mask'],
    show ? undefined : classes['click-through'],
    show ? undefined : classes['transparent'],
  ])}>
    <div className={classes['progress-box']}>
      <Spinner animation="grow" variant="light" />
    </div>
  </div>;
}