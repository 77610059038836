import ProgressMask from 'components/overlay/ProgressMask';
import React from 'react';
import { useSelector } from 'react-redux';
import { IRootState } from 'reducers';

export default function GlobalProgressMask() {
  const show = useSelector((state: IRootState) => state.layout.maskRefCount) > 0;

  // const [ reallyShow, setReallyShow ] = useState(show);
  // useEffect(() => {
  //   if (show) {
  //     setTimeout(() => {
  //       setReallyShow(true);
  //     }, 1500);
  //   } else {
  //     setReallyShow(false);
  //   }
  // }, [ show ]);

  return <ProgressMask
    show={show}
  />
}