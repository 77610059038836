import { ActionType, createAction, createReducer } from 'typesafe-actions';

export interface FacebookEmailState {
  email?: string;
  open?: boolean;
  emailErrorMessage?: string;
  ticket?: string;
  code?: string;
}

export const facebookEmailActions = {
  reset: createAction('Login.FacebookEmail.Reset')(),
  sendVerificationCode: createAction('Login.FacebookEmail.SendVerificationCode')<string>(), // email
  verificationSent: createAction('Login.FacebookEmail.VerificationSent')<string>(), // ticket
  updateCode: createAction('Login.FacebookEmail.CodeUpdated')<string>(), // code
  updateEmail: createAction('Login.FacebookEmail.FieldUpdated')<string>(),
  updateEmailError: createAction('Login.FacebookEmail.EmailErrorUpdated')<string>(),
  openFacebookEmailDialog: createAction('Login.FacebookEmail.DialogOpen')(),
  closeFacebookEmailDialog: createAction('Login.FacebookEmail.DialogClose')(),
  confirmFacebookAdHocEmail: createAction('Login.FacebookEmail.Confirmed')<{ email: string, code: string, ticket: string }>(), // saga
};

export type FacebookEmailActions = ActionType<typeof facebookEmailActions>;

const initialState = (): FacebookEmailState => ({});

export const facebookEmailReducer = createReducer<FacebookEmailState, FacebookEmailActions>(initialState())
  .handleAction(facebookEmailActions.updateEmail,
    (state, action) => ({ ...state, email: action.payload }),
  )
  .handleAction(facebookEmailActions.reset,
    (_state, _action) => initialState()
  )
  .handleAction(facebookEmailActions.openFacebookEmailDialog,
    (state, _) => ({ ...state, open: true })
  )
  .handleAction(facebookEmailActions.closeFacebookEmailDialog,
    (state, _) => ({ ...state, open: false })
  )
  .handleAction(facebookEmailActions.updateEmailError,
    (state, action) => ({ ...state, emailErrorMessage: action.payload })
  )
  .handleAction(facebookEmailActions.verificationSent,
    (state, action) => ({ ...state, ticket: action.payload }),  
  )
  .handleAction(facebookEmailActions.updateCode,
    (state, action) => ({ ...state, code: action.payload }),  
  )
;