import clsx from 'clsx';
import 'common/general-imports';
import { jsxTouchableProps, registerTouchableClassNames } from 'common/polyfills/touch-polyfill';
import { getIOSInsectBottom } from 'common/utils/utils';
import React from 'react';
import classes from './TabBar.module.css';



// touchable effect
registerTouchableClassNames(
  `${classes['tabbar-item']}`
);

export const tabBarHeight = () => `calc(3.8rem + ${getIOSInsectBottom()})`;
export interface TabBarProps {
  children: React.ReactElement<TabBarIconProps>[];
}

export default function TabBar(props: TabBarProps) {
  const { children } = props;
  const length = children.length;
  const itemWidth = `${100 / length}%`;
  return <div className={classes['tabbar']} style={{ height: tabBarHeight(), paddingBottom: getIOSInsectBottom() || undefined }}>
    {children.map(element => <div
      key={element.props.iconClassName}
      className={clsx({
        [classes['tabbar-item']]: true,
        [classes['disabled']]: element.props.disabled,
      })}
      onClick={element.props.onClick}
      style={{ width: itemWidth }}
    >
      {element}
    </div>)}
  </div>
}

export interface TabBarIconProps {
  iconClassName: string;
  active?: boolean;
  disabled?: boolean;
  onClick?: () => any;
}

export function TabBarIcon(props: TabBarIconProps) {
  return <i {...jsxTouchableProps()} className={clsx({
    [props.iconClassName]: true,
    [classes['tabbar-icon']]: true,
    [classes['active']]: props.active,
  })} />;
}