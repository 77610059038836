import 'common/general-imports';
import { addTouchableHoveringSupport } from 'common/polyfills/touch-polyfill';
import React, { useLayoutEffect, useRef, useState } from 'react';
import classes from './TagPanel.module.css';



export interface TagPanelProps {
	tags: {
		id: number,
		tagName: string,
		// tagNameTC: string,
		// tagNameSC: string,
		// tagNameEN: string,
		coverPhotoFilename: string,
	}[];
	rows: number;
	onTagClick?: (id: number) => any;
}

addTouchableHoveringSupport(
	`.${classes['item']}`,
	[],
	classes['no-hover'],
);

export default function TagPanel(props: TagPanelProps) {
	const { tags, rows, onTagClick } = props;

	const [ height, setHeight ] = useState(89);
	const rootRef = useRef<HTMLDivElement>(null);
	const lastWidth = useRef(-1);

	useLayoutEffect(() => {
		const handle = setInterval(() => {
			const width = rootRef.current?.offsetWidth;
			if (width && lastWidth.current && lastWidth.current !== width) {
				setHeight((width * 0.25 - 24) * 0.63);
				lastWidth.current = width;
			}
		}, 16);

		return () => clearInterval(handle);
	}, [ height, setHeight, rootRef ]);

	return <div className={classes['tag-panel']} ref={rootRef} style={{ height: rows !== 1 ? undefined : `${height}px` }}>
		{
			tags.map(tag => 
			<div key={tag.id} 
				className={classes['item'] + ' ' + (rows > 1 ? classes['item-mobile'] : classes['item-desktop']) } 
				style={{ backgroundImage: `url("${tag.coverPhotoFilename}")` }} 
				onClick={() => onTagClick?.(tag.id)}
			>
				{/* <img className={classes['image']} alt="Tag" src={tag.coverPhotoFilename} onClick={onTagClick?.(tag.id)} /> */}
				<div className={classes['mask']}>
					<div className={classes['display']}>{tag.tagName}</div>
				</div>
			</div>)
		}
	</div>
}