import useLocale from 'common/hooks/useLocale';
import { registerTouchableClassNames } from 'common/polyfills/touch-polyfill';
import PopupOverlay from 'components/overlay/PopupOverlay';
import { ScrollContext, useCreateScrollContext } from 'components/scaffold/ScrollContext';
import React, { useCallback, useEffect, useRef, useState } from "react";
import Button from 'react-bootstrap/esm/Button';
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import classes from './ImageCropperDialog.module.scss';
import { ImageCropperDialogProps } from './ImageCropperDialogProps';

registerTouchableClassNames(
	classes['action-button'],
);

// Setting a high pixel ratio avoids blurriness in the canvas crop preview.
export const pixelRatio = 4;

/**
 * @param {HTMLCanvasElement} canvas 
 * @param {number} newWidth 
 * @param {number} newHeight
 * @returns {HTMLCanvasElement} 
 */
// We resize the canvas down when saving on retina devices otherwise the image
// will be double or triple the preview size.
export function getResizedCanvas(canvas, newWidth, newHeight) {
  const tmpCanvas = document.createElement("canvas");
  tmpCanvas.width = newWidth;
  tmpCanvas.height = newHeight;

  const ctx = tmpCanvas.getContext("2d");
  ctx.drawImage(
    canvas,
    0,
    0,
    canvas.width,
    canvas.height,
    0,
    0,
    newWidth,
    newHeight
  );

  return tmpCanvas;
}

export function generateDownload(previewCanvas, crop) {
  if (!crop || !previewCanvas) {
    return;
  }

  const canvas = getResizedCanvas(previewCanvas, crop.width, crop.height);

  canvas.toBlob(
    blob => {
      const previewUrl = window.URL.createObjectURL(blob);

      const anchor = document.createElement("a");
      anchor.download = "cropPreview.png";
      anchor.href = URL.createObjectURL(blob);
      anchor.click();

      window.URL.revokeObjectURL(previewUrl);
    },
    "image/png",
    1
  );
}

/**
 * @param {ImageCropperDialogProps} props 
 */
const ImageCropperDialog = props => {
  const {  lang } = useLocale();

	const {image: inputImgFile, ratio, show, onCancel, onDone} = props;

  const [upImg, setUpImg] = useState();
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const [crop, setCrop] = useState({ unit: "%", width: 30, aspect: ratio });
  const [completedCrop, setCompletedCrop] = useState(null);


  const onLoad = useCallback(img => {
    imgRef.current = img;
  }, []);

  useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return;
    }

    const image = imgRef.current;
    const canvas = previewCanvasRef.current;
    const crop = completedCrop;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext("2d");

    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingEnabled = false;

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );
  }, [completedCrop]);
	
	
	//----------- newly added
	// const [inputImgFile, setInputImgFile] = useState();
	// const handleUpload = (files) => {
	// 	setInputImgFile(files[0]);
	// }

	useEffect(()=>{
		if (inputImgFile){
			const reader = new FileReader();
      reader.addEventListener("load", () => setUpImg(reader.result));
      reader.readAsDataURL(inputImgFile);
		}
	},[inputImgFile])


	// Scroll Context
  // const scrollRef = useRef<HTMLDivElement>(null);
  const scrollRef = useRef(null);
  const scrollContext = useCreateScrollContext(scrollRef);

	const handleDone = (previewCanvas, crop) =>{
		if (!crop || !previewCanvas) {
			return;
		}

		const canvas = getResizedCanvas(previewCanvas, crop.width, crop.height);

		canvas.toBlob(
			blob => {
				onDone(blob);
			},
			"image/png",
			1
		);
	}

  //------------ end: newly added


  return (<PopupOverlay show={show} animation="fade">
    <ScrollContext.Provider value={scrollContext}><div className={classes['image-cropper-dialog']} ref={scrollRef}>
      <div className={classes['inner']}>
			{/* <div>
        <input type="file" accept="image/*" onChange={onSelectFile} />
      </div>
      <div>
         <input
            type="file"
            onChange={(ev) => {
              handleUpload(Array.from(ev.target.files ?? []));
              // resetFileInput(ev);
              
            }}
          />
      </div> */}
			<div className={classes['react-crop-container']}>
				<ReactCrop
					src={upImg}
					onImageLoaded={onLoad}
					crop={crop}
					onChange={c => setCrop(c)}
          onComplete={c => setCompletedCrop(c)}
          keepSelection
					// style={{overflow: 'visible'}}
				/>
			</div>
      <div style={{display: 'none'}}>
        <canvas
          ref={previewCanvasRef}
          style={{
            width: completedCrop?.width ?? 0,
            height: completedCrop?.height ?? 0
          }}
        />
      </div>
      {/* <p>
        For some browsers e.g. Chrome access from{" "}
        <a
          href="https://y831o.csb.app/"
          target="_blank"
          rel="noopener noreferrer"
        >
          outside the preview iframe
        </a>{" "}
        to download due to programmatically triggering a click (security).
      </p>
      <p>
        The alternative would be to generate a blob each time the crop is
        complete and render out an anchor tag if you want to download from
        inside an iframe.
      </p>
      <button
        type="button"
        disabled={!completedCrop?.width || !completedCrop?.height}
        onClick={() =>
          generateDownload(previewCanvasRef.current, completedCrop)
        }
      >
        Download cropped image
      </button> */}
      <div className={classes['action-button-outer-container']}>
				<div className={classes['action-buttons-container']}>
					<Button 
						onClick={onCancel}
						variant="outline-pas-gray"
						className={classes['action-button']}
					>{lang.actionCancel}</Button>
					<Button 
            onClick={()=>{
              handleDone(previewCanvasRef.current, completedCrop)
            }}
            disabled={!completedCrop?.width || !completedCrop?.height}
						variant="outline-dark"
						className={classes['action-button']}
					>{lang.actionDone}
					</Button>
				</div>
			</div>

			</div>
    </div></ScrollContext.Provider>
		</PopupOverlay>);
}

ImageCropperDialog.propTypes = ImageCropperDialogProps;

export default ImageCropperDialog;