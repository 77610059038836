import { ActionType, createAction, createReducer } from 'typesafe-actions';

let idx = 0;

interface AlertConfirm {
  id: number;
  title: string;
  message: string;
  show: boolean;
  actionConfirm: string;
  actionCancel: string;
}

export interface GlobalAlertConfirmDialogState {
  dialogs: { [id: string]: AlertConfirm };
}

const initialState = (): GlobalAlertConfirmDialogState => ({
  dialogs: {},
});

const createAlert = (title: string, message: string, actionConfirm: string, actionCancel: string): AlertConfirm => {
  return {
    title, message, actionConfirm, actionCancel, id: idx++, show: true,
  }
}

export const alertConfirmDialogActions = {
  alert: createAction('GlobalAlertConfirm.Alert', createAlert)(),

  hidden: createAction('GlobalAlertConfirm.Hidden')<{ id: number, confirmed: boolean; }>(),
  // cancelled: createAction('GlobalAlertConfirm.Cancelled')<{ id: number }>(),
};

export type AlertConfirmDialogActions = ActionType<typeof alertConfirmDialogActions>;

export const alertConfirmDialogReducer = createReducer<GlobalAlertConfirmDialogState, AlertConfirmDialogActions>(initialState())
  .handleAction(alertConfirmDialogActions.alert, (state, { payload }) => ({
    dialogs: { ...state.dialogs, [payload.id]: payload },
  }))
  .handleAction(alertConfirmDialogActions.hidden, (state, { payload }) => {
    const newDialogs = { ...state.dialogs };
    delete newDialogs[payload.id];
    return { dialogs: newDialogs };
  })
  // .handleAction(alertConfirmDialogActions.cancelled, (state, { payload }) => {
  //   const newDialogs = { ...state.dialogs };
  //   delete newDialogs[payload.id];
  //   return { dialogs: newDialogs };
  // })