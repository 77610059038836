import clsx from 'clsx';
import React, { useLayoutEffect, useState } from 'react';
import Button from 'react-bootstrap/esm/Button';
import classes from './ScrollToTopButton.module.scss';


export interface ScrollToTopButtonProps {
  onClick?: AnyFn;
  hidden?: boolean;
}

const defaultClick = () => {
  document.documentElement.scrollTo({ top: 0, behavior: 'smooth' });
}

export default function ScrollToTopButton(props: ScrollToTopButtonProps) {
  const [ defaultHidden, setDefaultHidden ] = useState(true);

  useLayoutEffect(() => {
		if (props.hidden !== undefined) {
			return;
		}

		const listener = () => {
			const currentScrollPos = window.pageYOffset;
			setDefaultHidden(currentScrollPos <= 100);
		};

    window.addEventListener('scroll', listener);
    listener(); // one-off execution
		return () => window.removeEventListener('scroll', listener);
	}, [ setDefaultHidden ]);

  const shouldHide = props.hidden !== undefined ? props.hidden : defaultHidden;

  return <div className={classes['root']}>
    <Button variant="dark"
      // style={{ display: shouldHide ? 'none' : 'block' }}
      className={clsx(classes['back-to-top-button'], shouldHide && classes['hidden'])}
      onClick={props.onClick ?? defaultClick}
    >
      <i className="fas fa-chevron-up" />
    </Button>
  </div>
}