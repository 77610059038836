import { useEffect } from "react";
import { useSelector } from "react-redux";
import { IRootState } from "reducers";

export default function usePageTitle(topicTitle: string | undefined) {
  const DEFAULT_TITLE = useSelector((root: IRootState) => root.systemSettings?.ClientApp?.HOME?.PAGE_TITLE) ?? 'Property Mavin 邁房';
  useEffect(() => {
    if (topicTitle) {
      document.title = `${topicTitle} - ${DEFAULT_TITLE}`;
    } else {
      document.title = `${DEFAULT_TITLE}`;
    }

    return () => {
      document.title = `${DEFAULT_TITLE}`;
    };
  }, [ topicTitle, DEFAULT_TITLE ]);
}