import { checkValidEmail, checkValidPhoneNumber } from 'common/utils/utils';
import { ActionType, createAction, createReducer } from 'typesafe-actions';
import { CommonFormState, createFormSpec } from './common/form';

export interface SellerEnquiryForm {
  name: string;
  email: string;
  contact: string;
  districts: string[];
}

export interface SellerEnquiryState extends CommonFormState<SellerEnquiryForm> {

}

export const sellerEnquiryFormSpec = createFormSpec(
  "SellerEnquiry.Edit",
  "SellerEnquiry.AddError"
)<SellerEnquiryForm>((langEnquiryDialog: LocaleOptions) => [
  (values) =>
    values.contact && !checkValidPhoneNumber(values.contact)
      ? { contact: langEnquiryDialog.errContact }
      : {},
  (values) =>
    values.email && !checkValidEmail(values.email)
      ? { email: langEnquiryDialog.errEmail }
      : {},
  (values) =>
    values.contact || values.email
      ? {}
      : {
          contact: langEnquiryDialog.errAtLeastTelOrEmail,
          email: langEnquiryDialog.errAtLeastTelOrEmail,
        },
  (values) =>
    !values.districts || !(values.districts?.length > 0)
      ? { districts: langEnquiryDialog.errDistrict }
      : {},
  (values) => (!values.name ? { name: langEnquiryDialog.errName } : {}),
]);

export const sellerEnquiryFormActions = {
  ...sellerEnquiryFormSpec.actions,
  reset: createAction('SellerEnquiry.Reset')(),
  doEnquiry: createAction('SellerEnquiry.DoEnquiry')(),
};

export type SellerEnquiryFormActions = ActionType<typeof sellerEnquiryFormActions>;

export const initialState = (): SellerEnquiryState => ({
  ...sellerEnquiryFormSpec.initialState,
  contents: {
    districts: [],
  },
});

export const sellerEnquiryReducer = createReducer<SellerEnquiryState, SellerEnquiryFormActions>(initialState(), sellerEnquiryFormSpec.reducer)
  .handleAction(sellerEnquiryFormActions.reset, (state, _) => ({ 
    ...initialState(), 
    contents: {
      ...initialState().contents,
      name: state.contents.name,
      email: state.contents.email,
      contact: state.contents.contact
    } 
  }))
;