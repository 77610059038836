import { FormBinder } from 'components/form-controls/IFormBinder';
import { useMemo, useState } from 'react';

export default function useSimpleFormBinder<T>(value: T | undefined,
    change: (updated: T | undefined) => any,
    onValidate: AnyFn = () => {},
    errorMessage?: string,
  ) {
  const binder = useMemo((): FormBinder<T> => ({
    value, change,
    blur: () => { onValidate() },
    errorMessage,
  }), [ value, change ]);

  return binder;
}

export function useReadOnlyBinder<T>(value: T | undefined): FormBinder<T> {
  const binder = useMemo(() => ({
    value, change: () => {}, blur: () => {},
  }), [ value ]);

  return binder;
}

export function useLocalBinder<T>(defaultValue?: T | undefined): FormBinder<T> {
  const [ val, setVal ] = useState(defaultValue);

  const binder = useMemo(() => ({
    value: val,
    change: (newVal: T | undefined) => setVal(newVal),
    blur: () => {},
    errorMessage: undefined,
  }), [ val, setVal ]);

  return binder;
}