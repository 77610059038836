import { ApiPageResult, ApiResult, BASE_URL } from 'api';
import CAPropertyStockDetailDTO, { TransactionDTO } from 'common/dto/CAPropertyStockDetailDTO';
import CAPropertyStockListItemDTO from 'common/dto/CAPropertyStockListItemDTO';

export const propertyApi = {

  getList: async (criteria: object, token: string): Promise<ApiPageResult<CAPropertyStockListItemDTO>> => {
    try {
      const response = await fetch(`${BASE_URL}/ca/properties/search`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(criteria),
      });
      return await response.json();
    } catch (e) {
      return { error: String(e) };
    }
  },
  
  getMapItems: async (criteria: object, token: string): Promise<ApiPageResult<CAPropertyStockListItemDTO>> => {
    try {
      const response = await fetch(`${BASE_URL}/ca/properties/map`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(criteria),
      });
      return await response.json();
    } catch (e) {
      return { error: String(e) };
    }
  },
  
  getSoldLeasedList: async (criteria: object, token: string): Promise<ApiResult<CAPropertyStockListItemDTO[]>> => {
    try {
      const response = await fetch(`${BASE_URL}/ca/properties/search/sold-leased`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(criteria),
      });
      return await response.json();
    } catch (e) {
      return { error: String(e) };
    }
  },

  getDetail: async (propertyNo: string, token: string): Promise<ApiResult<CAPropertyStockDetailDTO>> => {
    try {
      const response = await fetch(`${BASE_URL}/ca/properties/${propertyNo}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      return await response.json();
    } catch (e) {
      return { error: String(e) };
    }
  }, 

  getPropertyTransactions: async (propertyNo: string, page: number, sort = 'transactionDate', direction = 'DESC', token: string = ''): Promise<ApiPageResult<TransactionDTO>> => {
    try {
      const response = await fetch(`${BASE_URL}/ca/properties/${propertyNo}/transactions?page=${page}&sort=${sort},${direction}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      return await response.json();
    } catch (e) {
      return { error: String(e) };
    }
  }
};