import useLocale from 'common/hooks/useLocale';
import MainLayoutDesktop from 'components/scaffold/MainLayoutDesktop';
import React from 'react';
import InfoScreenDesktop from './InfoScreenDesktop';
import { TCScreenViewProps } from './TCScreenViewProps';
import TermsAndConditions from './TermsAndConditions';

export default function TCScreenDesktop(props: TCScreenViewProps) {
  const { layoutProps, tcProps, onBackClick } = props;

  const { langSetting } = useLocale();

  return <MainLayoutDesktop {...layoutProps}>
    <InfoScreenDesktop title={langSetting.captionTermsAndConditions}
      children={{
        tc: <TermsAndConditions 
          items={tcProps}
          noShadow={true}
        />
      }}
      onBackClick={onBackClick}
    />
  </MainLayoutDesktop>
}