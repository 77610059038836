import useSimpleFormBinder from 'common/hooks/useFormBinder';
import useLocale from 'common/hooks/useLocale';
import SingleTextConfirmDialog from 'components/overlay/SingleTextConfirmDialog';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from 'reducers';
import { facebookEmailActions } from 'reducers/facebook-email';

export default function GlobalFacebookEmailConfirmDialog() {
  const dispatch = useDispatch();
  const { langLogin } = useLocale();
  const { email, code, ticket, emailErrorMessage, open } = useSelector((state: IRootState) => state.facebookEmail);

  const bindEmail = useSimpleFormBinder(
    email,
    (updated) => dispatch(facebookEmailActions.updateEmail(updated ?? '')),
    () => {},
    emailErrorMessage,
  );
  
  return <SingleTextConfirmDialog
    show={open}
    title={langLogin.titleFacebookEmail}
    message={langLogin.msgFacebookEmailRequired}
    bindText={bindEmail}
    onConfirm={() => dispatch(facebookEmailActions.confirmFacebookAdHocEmail({ email: email!, code: code!, ticket: ticket! }))}
    onHide={() => dispatch(facebookEmailActions.closeFacebookEmailDialog())}
  />;
}