import clsx from 'clsx';
import React, { useLayoutEffect, useRef, useState } from 'react';
import classes from './FloatingCaptionTile.module.scss';

export interface FloatingCaptionTileProps {
  caption: string;
  value: string;
  
  isDesktop?: boolean;
}

export default function FloatingCaptionTile(props: FloatingCaptionTileProps) {
  const { caption, value, isDesktop } = props;
  const captionRef = useRef<HTMLDivElement>(null);
  
  const [ bgWidth, setBgWidth ] = useState(0);

  useLayoutEffect(() => {
    setBgWidth(captionRef.current?.offsetWidth ?? 0);
  }, [ caption, captionRef ]);

  return <div className={classes['outer']}>
    <div className={classes['root']} style={{ minWidth: `${bgWidth + 16}px` }}>
      <div className={classes['caption-bg']} style={{ width: `${bgWidth + 5}px` }}></div>
      <div className={clsx(classes['caption'], isDesktop && classes['desktop'])} ref={captionRef}>{caption}</div>
      <div className={clsx(classes['value'], isDesktop && classes['desktop'])}>{value}</div>
    </div>
  </div>
}