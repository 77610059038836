import CAAgentDTO from 'common/dto/CAAgentDTO';
import { ActionType, createAction, createReducer } from 'typesafe-actions';

export interface AgentListState {
  /** Current fetched agents */
  agents: CAAgentDTO[];
  /** Whether having more agents to fetch (mobile only) */
  hasMore: boolean;

  criteria: {
    limit: number;
    page: number;
    totalCount: number;
    /** Current filtered districts */
    districts: string[];
  };
}

const agentListInitialState = (): AgentListState => ({
  agents: [],
  hasMore: true,
  criteria: {
    limit: 20,
    page: 0,
    totalCount: 0,
    districts: [],
  },
});

export const agentListActions = {
  reset: createAction('AgentList.Reset')(),
  doFetchAgents: createAction('AgentList.FetchRequested')<{ isSwitchingPage: boolean }>(),
  updateAgents: createAction('AgentList.Updated')<{ agents: CAAgentDTO[], hasMore: boolean }>(),
  appendNextPage: createAction('AgentList.AppendNextPage')<{ agents: CAAgentDTO[], hasMore: boolean }>(),
  updateCriteria: createAction('AgentList.CriteriaUpdated')<Partial<AgentListState['criteria']>>(),
};

export type AgentListActions = ActionType<typeof agentListActions>;

export const agentListReducer = createReducer<AgentListState, AgentListActions>(agentListInitialState())
  .handleAction(agentListActions.reset, () => agentListInitialState())
  .handleAction(agentListActions.doFetchAgents, (state, { payload }) => ({
    ...state,
    agents: payload.isSwitchingPage ? state.agents : [],
    hasMore: payload.isSwitchingPage ? state.hasMore : true,
  }))
  .handleAction(agentListActions.appendNextPage, (state, { payload }) => ({
    ...state, agents: [ ...state.agents, ...payload.agents ], hasMore: payload.hasMore,
  }))
  .handleAction(agentListActions.updateAgents, (state, { payload }) => ({
    ...state, agents: payload.agents, hasMore: payload.hasMore,
  }))
  .handleAction(agentListActions.updateCriteria, (state, { payload }) => ({
    ...state, criteria: { ...state.criteria, ...payload },
  }))
;