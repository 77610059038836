import useLocale from 'common/hooks/useLocale';
import { isNonEmpty } from 'common/utils/utils';
import FileUploadDialog from 'components/overlay/FileUploadDialog';
import ImageCropperDialog from 'components/overlay/ImageCropperDialog';
import React, { useRef, useState } from 'react';
import Button from 'react-bootstrap/esm/Button';
import ButtonGroup from 'react-bootstrap/esm/ButtonGroup';
import Image from 'react-bootstrap/esm/Image';
import Overlay from 'react-bootstrap/esm/Overlay';
import Popover from 'react-bootstrap/esm/Popover';
import Lightbox from 'react-image-lightbox';
import classes from './ClientAvatar.module.scss';
import { extname } from 'path';
import { format } from 'util';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from 'reducers';



export interface ClientAvatarProps {
  title: string;
  allowPhotoUpload?: boolean; //use to show profile picture and the photo upload actions
	photoImgSrc?: string;
	onConfirmUploadPhoto?:(output: Blob) => void; //onDone action in the image cropper
	onDeletePhoto?: AnyFn;
	width?: string;
}

export default function ClientAvatar(props: ClientAvatarProps) {
  const { title, allowPhotoUpload, photoImgSrc, onConfirmUploadPhoto, onDeletePhoto, width } = props;
  const { langClientProfile, lang } = useLocale();

  // for customer profile picture
	const [showPhotoActions, setShowPhotoActions] = useState<boolean>(false);
	const [target, setTarget] = useState<any>(null);
	const ref = useRef(null);

	const [showUploadDialog, setShowUploadDialog] = useState<boolean>(false);
	const [inputImgFile, setInputImgFile] = useState<File | undefined>();

	const dispatch = useDispatch();

	const { SUPPORT_FILE_TYPE } = useSelector((state: IRootState) => state.systemSettings.ClientApp.PHOTO_UPLOAD_POLICY);

	const validFileType = (file: File) => {
		const fileType = extname(file?.name || '').split('.')[1].toLocaleUpperCase();
		if (!SUPPORT_FILE_TYPE.includes(fileType)) {
			dispatch({ type: 'Layout.AlertMessageAdded', payload: { message: format(lang.msgUnsupportedFileFormat, fileType), severity: "error" } });
			setShowUploadDialog(false);
			return false;
		}
		return true;

	}

	const handleUpload = (files: File[]) => {
		if (!validFileType(files[0])) return;
		setInputImgFile(files[0]);
		setShowUploadDialog(false);
		setShowImageCropper(true);
	}

	const [showImageCropper, setShowImageCropper] = useState<boolean>(false);
  const [ photoLightBoxOpened, setPhotoLightBoxOpened ] = useState(false);

  return <>
    <div className={classes['user-content-container']}>
			<div className={classes['user-content']} style={{cursor: allowPhotoUpload ? 'pointer' : 'inherit'}} >
				<div ref={ref} onClick={(event)=>{
					if (!allowPhotoUpload){
						return;
					}
					setShowPhotoActions(!showPhotoActions);
    			setTarget(event.target);
				}}>

					{allowPhotoUpload && isNonEmpty(photoImgSrc) ?
						<div className={classes['image-container']}>
							<Image src={photoImgSrc} roundedCircle className={classes['image']} style={{ width: width, height: width }} />
						</div>
						:
						<i className={"far fa-user-circle" + " " + classes['icon']}></i>
					}
				</div>
				<div className={classes['title']}>{title}</div>
			</div>
		</div>

    {/* ------ components related to profile picture upload ------------*/}

		{photoLightBoxOpened &&
			<Lightbox
				reactModalStyle={{ overlay: { zIndex: 1500 } }}
				mainSrc={photoImgSrc ?? ''}
				onCloseRequest={() => setPhotoLightBoxOpened(false)}

			/>
		}

		<FileUploadDialog title={langClientProfile.actionUploadPhoto}
			fileAccept={"image/*"}
			message={langClientProfile.msgPleaseUploadFile}
			show={showUploadDialog}
			onHide={() => setShowUploadDialog(false)}
			handleUpload={handleUpload}
		/>

		<Overlay
			show={showPhotoActions}
			target={target}
			placement="bottom"
			container={ref.current}
			containerPadding={20}
			onHide={() => setShowPhotoActions(false)}
			rootClose={true}
			rootCloseEvent={'click'}
		>
			<Popover id="popover-contained">
				<Popover.Content>
					<ButtonGroup aria-label="profile-photo-actions">
						{isNonEmpty(photoImgSrc) &&
							<Button variant="outline-pas-blue"
								className={classes['photo-buttons']}
								onClick={() => {
									setPhotoLightBoxOpened(true);
								}}
							>
								{lang.actionView}
							</Button>
						}
						<Button variant="outline-pas-blue"
							className={classes['photo-buttons']}
							onClick={() => {
								setShowPhotoActions(false); setShowUploadDialog(true);
							}}>
							{lang.actionUpload}
						</Button>
						{isNonEmpty(photoImgSrc) &&
							<Button variant="outline-pas-blue"
								className={classes['photo-buttons']}
								onClick={() => {
									onDeletePhoto?.();
								}}
							>
								{lang.actionDelete}
							</Button>
						}
					</ButtonGroup>
				</Popover.Content>
			</Popover>
		</Overlay>

		<ImageCropperDialog
			image={inputImgFile}
			show={showImageCropper}
			onHide={() => setShowImageCropper(false)}
			onCancel={() => setShowImageCropper(false)}
			onDone={(output: Blob) => {
				onConfirmUploadPhoto?.(output);
				setShowImageCropper(false);
			}}
			ratio={1} />
		{/* ------ end: components related to profile picture upload ------------*/}
  </>
}