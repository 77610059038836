import useLocale from 'common/hooks/useLocale';
import FormTextField from 'components/form-controls/FormTextField';
import { FormBinder } from 'components/form-controls/IFormBinder';
import RatingStarsField from 'components/form-controls/RatingStarsField';
import { ProfileImage } from 'components/misc/ProfileImage';
import React from 'react';
import classes from './AgentRatingCommentDialog.module.scss';
import ModalDialog from './ModalDialog';
import ModalDialogActions, { ModalDialogActionButton } from './ModalDialogActions';
import { useMediaQuery } from 'react-responsive';
import breakpoints from 'common/theme/breakpoints.module.scss';



export interface AgentRatingCommentDialogProps {
  open: boolean;
  onHide?: AnyFn;
  image?: string;
  chineseName: string;
  englishName: string;
  maxRating: number;
  bindRating: FormBinder<number>;
  bindComment: FormBinder<string>;
  bindIsAnonymous: FormBinder<boolean>;
  onSubmitClick?: AnyFn;
}

export default function AgentRatingCommentDialog(props: AgentRatingCommentDialogProps) {
  const { open, onHide, image, chineseName, englishName, maxRating,
    bindRating, bindComment, bindIsAnonymous, onSubmitClick } = props;
  const { lang, langAgent } = useLocale();

  const isMobile = useMediaQuery({ maxWidth: breakpoints.sm });
  const modalSize = isMobile ? 'sm' : undefined;


  return <ModalDialog size={modalSize} title={langAgent.titleRatingAndCommentAgent} show={open} onHide={onHide}>
    <div className={classes['agent-rating-comment-dialog']}>
      <div className={classes['content-container']}>
        <div className={classes['agent-info-container']}>
          <ProfileImage src={image}  width={'5rem'} />
          <div className={classes['name-container']}>
            <div>{englishName}</div>
            <div>{chineseName}</div>
          </div>
        </div>
        
				<div className={classes['rating-container']}>
        	<RatingStarsField bind={bindRating} maxRating={maxRating} minRating={0}/>
				</div>
        
        <div className={classes['comment-section']}>
          <div className={classes['title']}>{langAgent.captionComment}</div>
          <div className={classes['description']}>{langAgent.msgMaxLength}</div>
        </div>

        <FormTextField label={''} bind={bindComment} multiline={true} rows={4}/>

        {/* <div className={classes['anonymous-selection-section']}>
          <div className={classes['input']} onClick={() => bindIsAnonymous.change(bindIsAnonymous.value ? false : true)}>
            {bindIsAnonymous.value ? <i className="fas fa-check"/> : null}
          </div>
          <div className={classes['description']}>{langAgent.captionSubmitAsAnonymous}</div>
        </div> */}
      </div>
      
      <ModalDialogActions>
        <ModalDialogActionButton onClick={onSubmitClick}>
          {lang.actionSubmit}
        </ModalDialogActionButton>
      </ModalDialogActions>
      
    </div>
  </ModalDialog>
}