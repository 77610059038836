import { Capacitor } from '@capacitor/core';
import { Device } from '@capacitor/device';
import useLayoutProps from 'common/hooks/useLayoutProps';
import useLocale from 'common/hooks/useLocale';
import { jumpers, useJump } from 'common/theme/jumper';
import { isMobileUI } from 'common/utils/utils';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { IRootState } from 'reducers';
import { useCommonFormReducer } from 'reducers/common/form';
import { forgotPasswordActions } from 'reducers/forgot-password';
import { loginActions } from 'reducers/login';
import { loginFormActions, loginFormValidations } from 'reducers/login-form';
import LoginScreenMobile from '../../components/login/LoginScreenMobile';


const LoginPage = () => {
  const dispatch = useDispatch();
  const layoutProps = useLayoutProps();
  const history = useHistory();
  const jump = useJump();
  const { langLogin } = useLocale();

  const form = useCommonFormReducer(
    (state: IRootState) => state.loginForm,
    loginFormActions.edit,
    loginFormActions.addFormError,
    loginFormValidations(langLogin)
  );

  const [ appleLoginEnabled, setAppleLoginEnabled ] = useState(false);
  useLayoutEffect(() => {
    if (Capacitor.isNativePlatform()) {
      Device.getInfo().then(info => {
        setAppleLoginEnabled(info.platform === 'ios');
      });
    } else {
      setAppleLoginEnabled(true);
    }
  }, [ setAppleLoginEnabled ]);

  useEffect(() => {
    if (!isMobileUI()) jump(jumpers.toHome());
    // if (!Capacitor.isNative) jump(jumpers.toHome());
  }, []);

  useEffect(() => {
    dispatch(loginFormActions.reset());
  }, [dispatch]);

  return <div>
    <LoginScreenMobile 
      bindEmail={form.field('email')} 
      bindPassword={form.field('password')} 
      layoutProps={layoutProps}
      onForgotPasswordClick={() => {
        dispatch(loginFormActions.reset());
        dispatch(forgotPasswordActions.openDialog())
      }}
      onFacebookLoginClick={() => dispatch(loginActions.startFacebookLogin())}
      onAppleLoginClick={appleLoginEnabled ? () => dispatch(loginActions.startAppleLogin()) : undefined}
      onGoogleLoginClick={() => dispatch(loginActions.startGoogleLogin())}
      onLoginClick={async () => {
        const result = await form.validateAll();
        if (result) {
          dispatch(loginActions.start(form.field('email')?.value ?? '', form.field('password')?.value ?? ''));
        } else {
          dispatch(loginFormActions.clearInput());
        }
      }}
      onRegisterClick={()=>{
        history.push('/register');
      }}
      onBackClick={() => history.goBack()}
    />
  </div>;

}

export default LoginPage;