import 'common/general-imports';
import { addTouchableHoveringSupport } from 'common/polyfills/touch-polyfill';
import React, { useLayoutEffect, useRef, useState } from 'react';
import classes from './TagPanelMobile.module.scss';



export interface TagPanelProps {
	tags: {
		id: number,
		tagName: string,
		// tagNameTC: string,
		// tagNameSC: string,
		// tagNameEN: string,
		coverPhotoFilename: string,
	}[];
	cols: number;
	onTagClick?: (id: number) => any;
}

addTouchableHoveringSupport(
	`.${classes['item']}`,
	[],
	classes['no-hover'],
);

export default function TagPanelMobile(props: TagPanelProps) {
	const { tags, cols, onTagClick } = props;

	const [ height, setHeight ] = useState(89);
	const rootRef = useRef<HTMLDivElement>(null);
	const lastWidth = useRef(-1);

	useLayoutEffect(() => {
		const handle = setInterval(() => {
			const width = rootRef.current?.offsetWidth;
			if (width && lastWidth.current && lastWidth.current !== width) {
				setHeight((width / cols) * 0.63);
				lastWidth.current = width;
			}
		}, 16);

		return () => clearInterval(handle);
	}, [ height, setHeight, rootRef ]);

	return <div className={classes['tag-panel']} ref={rootRef}>
		{
			tags.map(tag => <div key={tag.id} style={{
					width: `${100 / cols}%`, padding: '0.5rem 0.5rem',
					height: `calc(${height}px + 0rem)`
				}}>
				<div 
					className={classes['item']} 
					style={{ backgroundImage: `url("${tag.coverPhotoFilename}")` }} 
					onClick={() => onTagClick?.(tag.id)}
				>
					{/* <img className={classes['image']} alt="Tag" src={tag.coverPhotoFilename} onClick={onTagClick?.(tag.id)} /> */}
					<div className={classes['mask']}>
						<div className={classes['display']}>{tag.tagName}</div>
					</div>
				</div>
			</div>)
		}
	</div>
}