import { Capacitor } from '@capacitor/core';
import CABuildingListItemDTO from 'common/dto/CABuildingListItemDTO';
import React from 'react';

/**
 * Strict empty checking for all Javascript basic types.
 * @param val Value to test
 * @returns Test result
 */
export function isNonEmpty(val: any) {
    if (typeof val === 'undefined' || val === null) {
        return false;
    } else if (Array.isArray(val) && val.length === 0) {
        return false;
    } else if (typeof val === 'string') {
        return val !== '';
    } else if (typeof val === 'number') {
        return val !== 0;
    } else if (typeof val === 'boolean') {
        return val !== false;
    } else {
        return true;
    }
}

export function objectToQuery(object: any = {}) {
    return Object.keys(object).filter((key) => {
        return object[key] !== null &&
            object[key] !== undefined &&
            // (!Array.isArray(object[key]) || (object[key].length > 0 && !isEmptyRange(object[key]))) &&
            isNonEmpty(object[key]);
    }).map((key) => {
        let value = object[key];
        if (Array.isArray(value)) {
            value = value.map(item => item !== null && item !== undefined ? item : '').join(',');
        } else if (typeof value === 'object') {
            return Object.keys(value).map(subKey => `${key}=${encodeURIComponent(subKey)},${encodeURIComponent(value[subKey])}`).join('&');
        }
        return `${key}=${encodeURIComponent(value)}`;
    }).join('&');
}

export function multiLang(locale: string, zhDisplay: string | undefined, enDisplay: string | undefined, scDisplay?: string | undefined) {
    if (scDisplay && locale === 'zh_CN') {
        return scDisplay || zhDisplay || enDisplay;
    }
    return locale === 'en' ? (enDisplay || zhDisplay) : (zhDisplay || enDisplay);
}

export function multiLangNum(locale: string, zhDisplay: number = 0, enDisplay: number = 0) {
    return locale === 'en' ? (enDisplay || zhDisplay) : (zhDisplay || enDisplay);
}

export function handlePriceDisplay(price: number, locale: string) {
    if (locale === 'en') {
        if (price >= 1000 * 1_000_000) {
            return (price / (1000 * 1_000_000));
        } else {
            return (price / 1_000_000);
        }
    } else {
        if (price >= 10_000 * 10_000) {
            return (price / (10_000 * 10_000));
        } else {
            return (price / 10_000);
        }
    }
}

export function handlePriceLength(price: number) {
    if (price % 1 === 0 || price / 1000000000000 >= 1) {
        return price.toFixed(0);
    }
    else {
        return price.toFixed(12 - (price.toString().substring(0, price.toString().indexOf("."))).length);
    }
}

export function handlePriceDisplayUnit(price: number, locale: string, lang: LocaleOptions) {
    if (price === Infinity) {
        return '';
    }
    if (locale === 'en') {
        if (price >= 1000 * 1_000_000) {
            return lang.u1B;
        } else {
            return lang.u1M;
        }
    } else {
        if (price >= 10_000 * 10_000) {
            return lang.u100M;
        } else {
            return lang.u10k;
        }
    }
}

export function priceToView(price: number, locale: string) {
    if (locale === 'en') {
        return price / 1_000_000
    } else {
        return price / 10_000;
    }
}

export function priceFromView(price: number, locale: string) {
    if (locale === 'en') {
        return price * 1_000_000
    } else {
        return price * 10_000;
    }
}

export function isNonEmptyNumberInputRange(from?: number, end?: number) {
    return !(from === 0 && end === Infinity);
}

// export function furtherPriceDisplayProcessing(priceDisplay: number, locale: string) {
// 	if (locale === 'en') {
// 		if (priceDisplay >= 1000) {
// 			return priceDisplay / 1000;
// 		} else {
// 			return priceDisplay;
// 		}
// 	} else {
// 		if (priceDisplay >= 10_000) {
// 			return priceDisplay / 10_000;
// 		} else {
// 			return priceDisplay;
// 		}
// 	}
// }

export function checkValidPhoneNumber(phoneNumber: string) {
    const re = /^((\([+]{0,1}[0-9]{1,4}\))|[+])?[-\s\./0-9]+[0-9]$/;
    // const re = new RegExp("^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$");
    if (re.test(phoneNumber)) {
        return true;
    }
    return false;
}

export function checkValidEmail(email: string) {
    let re = /([\w\.\-_]+)?\w+@[\w-_]+(\.\w+){1,}/;
    // const re = new RegExp('/([\w\.\-_]+)?\w+@[\w-_]+(\.\w+){1,}/igm');
    if (re.test(email)) {
        return true;
    }
    return false;
}

export function isPositiveNumber(numberStr: string | undefined) {
    if (!isNonEmpty(numberStr)) {
        return false;
    }
    return (Number(numberStr)) > 0;
}

//print password policy
export function printPasswordComplexityRequirement(langRegister: LocaleOptions, MIN_LENGTH: string, MIN_LENGTH_UPPER: string, MIN_LENGTH_LOWER: string, MIN_LENGTH_NUM: string, MIN_LENGTH_SPECIAL: string) {
    let resultStr = "";
    let containAtLeast = "";
    //min length
    if (isPositiveNumber(MIN_LENGTH)) {
        resultStr = langRegister.pwMinLength + MIN_LENGTH;
    }
    if (isPositiveNumber(MIN_LENGTH_UPPER) || isPositiveNumber(MIN_LENGTH_LOWER) || isPositiveNumber(MIN_LENGTH_NUM) || isPositiveNumber(MIN_LENGTH_SPECIAL)) {
        containAtLeast = langRegister.pwAtLeast;
    }
    let charRequirment = [];
    if (isPositiveNumber(MIN_LENGTH_UPPER)) {
        charRequirment.push(MIN_LENGTH_UPPER + langRegister.pwUnit + langRegister.pwUppercaseChar);
    }
    if (isPositiveNumber(MIN_LENGTH_LOWER)) {
        charRequirment.push(MIN_LENGTH_LOWER + langRegister.pwUnit + langRegister.pwLowercaseChar);
    }
    if (isPositiveNumber(MIN_LENGTH_NUM)) {
        charRequirment.push(MIN_LENGTH_NUM + langRegister.pwUnit + langRegister.pwNumber);
    }
    if (isPositiveNumber(MIN_LENGTH_SPECIAL)) {
        charRequirment.push(MIN_LENGTH_SPECIAL + langRegister.pwUnit + langRegister.pwSpecialChar);
    }
    if (isNonEmpty(charRequirment)) {
        containAtLeast = containAtLeast + charRequirment.join(', ');
    }
    if (isNonEmpty(resultStr) && isNonEmpty(containAtLeast)) {
        return resultStr + ", " + containAtLeast;
    } else if (isNonEmpty(resultStr)) {
        return resultStr;
    } else if (isNonEmpty(containAtLeast)) {
        return containAtLeast;
    }
}

export function useQuery(location: any) {
    return new URLSearchParams(location.search);
}

export function handleBlockDisplay(locale: string, blockStr: string) {
    if (!isNonEmpty(blockStr)) {
        return '';
    } else if (!isNaN(Number(blockStr)) || blockStr.length === 1) { // is just a number || just a letter
        return locale === 'en' ? 'Block ' + blockStr : blockStr + '座';
    }
    return blockStr;
}


export function genAddress(locale: string, unit: string, floor: string, block: string, blockEn: string, blockSc: string, building: string, buildingEn: string, buildingSc: string, street: string, streetEn: string, streetSc: string, district: string) {
    let address = [];
    let addressString = '';
    if (locale == 'en') {
        address.push(!isNonEmpty(unit) ? '' : 'Unit ' + unit);
        address.push(!isNonEmpty(floor) ? '' : floor + '/F');
        address.push(handleBlockDisplay('en', multiLang(locale, block, blockEn) ?? ''));
        address.push(multiLang(locale, building, buildingEn));
        address.push(multiLang(locale, street, streetEn));
    } else if (locale == 'zh_CN') {
        address.push(!isNonEmpty(unit) ? '' : unit + '室');
        address.push(!isNonEmpty(floor) ? '' : floor + '楼');
        address.push(handleBlockDisplay('zh_CN', multiLang(locale, blockSc, blockEn) ?? ''));
        address.push(multiLang(locale, buildingSc, buildingEn));
        address.push(multiLang(locale, streetSc, streetEn));
    } else {
        address.push(!isNonEmpty(unit) ? '' : unit + '室');
        address.push(!isNonEmpty(floor) ? '' : floor + '樓');
        address.push(handleBlockDisplay('zh_HK', multiLang(locale, block, blockEn) ?? ''));
        address.push(multiLang(locale, building, buildingEn));
        address.push(multiLang(locale, street, streetEn));
    }
    address.push(district ?? '');

    if (locale == 'en') {
        addressString = address.join(' ');
    } else {
        addressString = address.reverse().join('');
    }
    return addressString;
}

declare interface NumberWithCommas {
    (x: number | string): string;
    (x: number | string | undefined): string | undefined;
}
export const numberWithCommas = ((x: number | string | undefined) => {
    return x !== undefined ? parseFloat(String(x)).toLocaleString('en-US', { style: 'decimal', maximumFractionDigits: 20 }) : undefined;
}) as NumberWithCommas;

/** Reset &lt;input type="file"> value */
export const resetFileInput = (ev: React.ChangeEvent<HTMLInputElement>) => {
    ev.target.value = '';
    if (!/safari/i.test(navigator.userAgent)) {
        ev.target.type = '';
        ev.target.type = 'file';
    }
    return true;
}

// Edit/Add `public/config.local.js` file to set PASClientConfig.enforcedMobileUI = true if needed.
export const isMobileUI = () => (Capacitor.isNative ?? false) || window.PASClientConfig?.enforcedMobileUI;

export const isMobileDevice = () => isMobileUI() || /Mobi/i.test(window.navigator.userAgent);

export const getIOSInsectBottom = () => {
    const sab = window.getComputedStyle(document.documentElement).getPropertyValue?.('--sab') || '0px';
    return isMobileDevice() ? sab : '0px';
};

export const getStrListWithoutDuplicates = (strs: string[]) => {
    const seen = new Set();
    const filteredArr = strs.filter(str => {
        const duplicate = seen.has(str);
        seen.add(str);
        return !duplicate;
    });
    return filteredArr;
}

export const getBuildingListWithoutDuplicates = (buildings: CABuildingListItemDTO[]) => {
    const seen = new Set();
    const filteredArr = buildings.filter(building => {
        const duplicate = seen.has(building.buildingNameZh);
        seen.add(building.buildingNameZh);
        return !duplicate;
    });
    return filteredArr;
}


export const getStreetListWithoutDuplicates = (streets: CABuildingListItemDTO[]) => {
    const seen = new Set();
    const filteredArr = streets.filter(street => {
        const duplicate = seen.has(street.streetZh);
        seen.add(street.streetZh);
        return !duplicate;
    });
    return filteredArr;
}

export const lineBreakString = (str: string) => {
    const lines = str.split('\n');
    return lines.map((n, i) => React.createElement('div', { key: i, children: n }));
}

export const rangeFriendlyDisplay = (range: number[], locale: string, fmt: (n: number) => string) => {
    if (range[0] === 0 && range[1] !== Infinity) {
        return locale === 'en' ? `No more than ${fmt(range[1])}` : `${fmt(range[1])}以下`;
    } else if (range[0] !== 0 && range[1] === Infinity) {
        return locale === 'en' ? `No less than ${fmt(range[0])}` : `${fmt(range[0])}起`;
    } else if (range[0] === 0 && range[1] === Infinity) {
        return locale === 'en' ? 'Unlimited' : '不限';
    } else {
        return `${fmt(range[0])} - ${fmt(range[1])}`;
    }
}

export function trackGoals(goalsToTrack: { goalName: string; customParamList: any; }[]) {
    // if (goalsToTrack?.length > 0) {
    //     goalsToTrack.map(goal => {
    //         window.SCBeacon?.trackGoal(goal);
    //     });
    // }
    if (goalsToTrack?.length > 0) {
        goalsToTrack.map((goal) => {
            const goalName = goal.goalName;
            const customParamList = goal.customParamList;
            if (customParamList?.length > 0) {
                const customParams =
                    customParamList.map(({ paramName, paramValue }: any, index: any) =>
                        index === 0 ? {
                            data: paramValue,
                            dataKey: paramName
                        } : {
                            [paramName]: paramValue
                        }
                    )
                        .reduce((a: any, b: any) => ({ ...a, ...b }), {})
                    ;
                window.SCBeacon?.trackGoal(goalName, customParams);
            } else {
                window.SCBeacon?.trackGoal(goalName);
            }
        });
    }
}

export function trackSearchGoal(district: string, priceRange: number[] | undefined, sizeRange: number[] | undefined) {
    window.SCBeacon?.trackGoal('Property Search', {
        xDistrict: district,
        xPriceRangeStart: priceRange?.[0],
        xPriceRangeEnd: priceRange?.[1],
        xSizeRangeStart: sizeRange?.[0],
        xSizeRangeEnd: sizeRange?.[1],
    });
};