import { ApiError } from 'api';
import { IRootState } from 'reducers';
import { layoutActions } from 'reducers/layout';
import { LocaleState } from 'reducers/locale';
import { loginActions } from 'reducers/login';
import { cancelled, put, select } from 'redux-saga/effects';


interface WrapperOptions {
  noMask?: boolean;
  onFinish?: AnyFn;
}

/**
 * Wrap API call related tasks and provide common functionalities, like loading mask and error handling.
 * 
 * @param func Saga task function which calls API
 */
export function apiTaskWrapper<T extends (...args: any[]) => Generator>(func: T, { noMask, onFinish }: WrapperOptions = {}) {
  return function* (...args: Parameters<T>) {
    if (!noMask) {
      yield put(layoutActions.presentMask());
    }

    const { lang, langLogin }: LocaleState = yield select((state: IRootState) => state.locale);

    try {
      yield* func(...args);
    } catch (e) {
      if (ApiError.isApiError(e)) {
        switch (e.code) {
          case 'ERR_NOT_FOUND':
            // Some cases you should handle not found error your self.
            yield put(layoutActions.alert(lang.msgDataNotFound, 'info'));
            break;
          case 'ERR_NOT_AUTHORIZED':
            yield put(layoutActions.alert(lang.msgLoggedOut, 'warning'));
            yield put(loginActions.logout());
            break;
          case 'ERR_PERMISSION_DENIED':
            yield put(layoutActions.alert(lang.msgPermissionDenied, 'error'));
            break;
          case 'ERR_VERSION_MISMATCHED':
            yield put(layoutActions.alert(lang.msgVersionMismatched, 'warning'));
            break;
          // case 'ERR_BUILDING_ALIAS_ALREADY_EXISTS':
          //   yield put(layoutActions.alert(lang.msgBuildingAliasAlreadyExists));
          //   break;
          case 'ERR_SERVER_ERROR':
          default:
            // Client related error should not be handled here (e.g. ERR_INVALID_REQUEST),
            // should be handled in saga functions instead.
            yield put(layoutActions.alert(lang.msgNetworkError, 'error'));
            break;
        }
      } else {
        console.error('apiTaskWrapper caught unknown error:', e);
        if (typeof e === 'object' && Object.keys(e as object).includes('accessToken')) {
          yield put(layoutActions.alert(langLogin.msgSocialLoginCancelled, 'info'));
          return
        }
        yield put(layoutActions.alert(lang.msgNetworkError, 'error'));
      }
    } finally {
      if (yield cancelled()) {
        console.info('apiTaskWrapper detected cancellation');
        if (!noMask) {
          yield put(layoutActions.dismissMask());
        }
      } else {
        console.info('apiTaskWrapper ran successfully');
        if (!noMask) {
          yield put(layoutActions.dismissMask());
        }
      }

      onFinish?.();
    }
  }
}