import { getCurrentHeight, subscribeHeightUpdate } from 'common/polyfills/viewport-height';
import { tabBarHeight } from 'components/navigation/TabBar';
import { useEffect, useState } from 'react';
import useNativeStatusBarHeight from './useNativeStatusBarHeight';

export const useMobileLayoutHeight = (includeTabs = false) => {
  const [ height, setHeight ] = useState(getCurrentHeight());
  const statusBarHeight = useNativeStatusBarHeight();
  useEffect(() => {
    return subscribeHeightUpdate(setHeight);
  }, [ setHeight ]);

  return includeTabs ? `${height - statusBarHeight}px` : `calc(${height - statusBarHeight}px - ${tabBarHeight()})`;
}