import { FCM } from '@capacitor-community/fcm';
// Native App Settings
import { Capacitor } from '@capacitor/core';
import { Device, DeviceId, DeviceInfo } from '@capacitor/device';
import { PermissionStatus, PushNotifications } from '@capacitor/push-notifications';
import { ApiError, ApiResult, PUBLIC_URL } from 'api';
import { customerApi } from 'api/customerApi';
// import { CustomerActions } from "reducers/customer";
// import { NotificationActions } from "reducers/notification";
// import { renew, loadOptions, userApi, registerDevice } from "api";
import loginApi from 'api/loginApi';
import optionApi from 'api/optionApi';
import systemSettingApi from 'api/systemSettingApi';
import CALoginStateDTO from 'common/dto/CALoginStateDTO';
import { selectLocale } from 'common/hooks/useLocale';
import { IRootState } from "reducers";
import { customerActions } from 'reducers/customer';
import { layoutActions, LayoutActions } from "reducers/layout";
import { LocaleActions, localeActions } from 'reducers/locale';
import { loginActions } from 'reducers/login';
import { call, put, select, takeLatest } from "redux-saga/effects";
import { PASSagaContext } from "sagas";
import { ActionType, getType } from 'typesafe-actions';
//import { StatusBar } from '@capacitor/status-bar';
const KEY_LOCALE = `${PUBLIC_URL}:locale`;

const protectedUrlPrefixes = [
  '/me',
  '/form-client-sign',
  // '/customer-profile',
];

export default [watchInitRequest, watchDataReloadRequest, watchLocaleChange];

export function* watchLocaleChange(context: PASSagaContext) {
  yield takeLatest(
    getType(localeActions.switchLocale),
    saveLocale,
    context
  );
}

export function* saveLocale(context: PASSagaContext, action: ActionType<typeof localeActions['switchLocale']>) {
  const { token } = yield select((state: IRootState) => state.login);
  localStorage.setItem(KEY_LOCALE, action.payload.locale);
  if (token) customerApi.switchCustomerLocale(action.payload.locale, token);
}

export function* watchInitRequest(context: PASSagaContext) {
  yield takeLatest(
    'Layout.Initialize',
    init,
    context
  );
}

export function* watchDataReloadRequest(context: PASSagaContext) {
  yield takeLatest(
    'Layout.DataReloadRequested',
    reloadData,
    context
  );
}

function* init(context: PASSagaContext, action: Extract<LayoutActions, { type: 'Layout.Initialize' }>) {
  yield put({ type: 'Layout.InitialStatusChanged', payload: { initialized: false, initializationFailed: false } });

  const { token } = yield select((state: IRootState) => state.login);

  const deviceInfo: DeviceInfo = yield call(() => Device.getInfo());
  const deviceObj: DeviceId = yield call(() => Device.getId());
  const deviceId = deviceObj.uuid;
  const deviceType = ({
    'android': 'ANDROID',
    'ios': 'IOS',
    'web': 'WEB',
  } as { [key: string]: string })[deviceInfo.platform] ?? 'ANDROID';

  let fcmAvailable = false;

  yield FCM.getToken().then(() => fcmAvailable = true).catch(() => fcmAvailable = false);

  if (Capacitor.isNativePlatform() && fcmAvailable) {
    const fcm = FCM;
    const pnResult: PermissionStatus = yield call(() => PushNotifications.requestPermissions());
    if (pnResult.receive === 'granted') {
      // Register with Apple / Google to receive push via APNS/FCM
      yield call(() => PushNotifications.register());
      const tokenResult: { token: string } = yield call(fcm.getToken);
      const fcmRegToken: string = tokenResult.token;
      yield call(loginApi.registerDevice, deviceId, deviceType, fcmRegToken);
      yield put({ type: 'Login.DeviceReady', payload: { deviceId } });
      console.info("Device registered successfully");
    } else {
      yield put({ type: 'Login.DeviceReady', payload: { deviceId: null } });
      yield call(loginApi.registerDevice, deviceId, deviceType, '');
      // Show some error
      console.error("Error: cannot register to FCM");
    }

    // PushNotifications.addListener('registration', (token) => {
    //   console.info('pnToken', token.value);
    // });
  } else {
    //const deviceId = deviceInfo.uuid;

    const deviceIdResult: DeviceId = yield call(Device.getId);
    const deviceId = deviceIdResult.uuid;
    yield call(loginApi.registerDevice, deviceId, deviceType, '');

    yield put({ type: 'Login.DeviceReady', payload: { deviceId } });

  }

  // Load options & languages
  const { data: optionData, error: optionLoadError } = yield call(optionApi.loadOptions, token);

  if (optionLoadError) {
    yield put({ type: 'Layout.InitialStatusChanged', payload: { initialized: false, initializationFailed: true } });
    return;
  }

  yield put({ type: 'Locale.RemoteBundleLoaded', payload: optionData });


  // Check Login
  const renewData: ApiResult<CALoginStateDTO> = yield call(loginApi.renew, token);
  if (renewData?.data?.token) {
    yield put({ type: 'Login.Success', payload: renewData });
    yield put(customerActions.doFetchCustomer());
    // yield put<UserActions>({ type: 'User.FetchRequested', payload: { id: renewData?.uid }});
    // TODO: CANotificationAPI to be implemented.
    // const { data: notifications, error: loadError } = yield call(notificationApi.getList, token);
    // if(loadError) return;
    // yield put({ type: 'Notifications.Loaded', payload: notifications });
  } else {
    yield put({ type: 'Login.LoggedOut' });
  }

  // load system settings
  const { data: systemSettingData, error: systemSettingError } = yield call(systemSettingApi.getListForFrontend, renewData?.data?.token ?? 'undefined');
  if (systemSettingData) {
    yield put({ type: 'SystemSetting.Loaded', payload: { data: systemSettingData } });
  } else {
    yield put({ type: 'Layout.InitialStatusChanged', payload: { initialized: false, initializationFailed: true } });
    return;
  }

  if (renewData?.data?.token) {
    const PROFILE: string = yield select((state: IRootState) => state.systemSettings?.BuildInfo?.ENV?.PROFILE) ?? null;
    window.SCBeacon?.trackEvent("Client contact event", {
      xclientId: (PROFILE ? `pas-client-${PROFILE.toLowerCase()}-` : "") + renewData?.data?.id,
      xfirstName: renewData?.data?.name,
      xlastName: '',
      xemail: renewData?.data?.email,
      xlanguage: renewData?.data?.language
    });
  }

  const { DEFAULT_DISPLAY_LANGUAGE } = yield select((state: IRootState) => state.systemSettings?.System?.SYSTEM_PARAM);

  const localStoredLocale = localStorage.getItem(KEY_LOCALE);

  yield put<LocaleActions>(localeActions.switchLocale(
    // 'en'
    renewData?.data?.language ?? localStoredLocale ?? DEFAULT_DISPLAY_LANGUAGE ?? 'zh_HK',
  ));

  // if (loginError) {
  //   yield put({ type: 'Layout.InitialStatusChanged', payload: { initialized: false, initializationFailed: true } });
  //   return;
  // }



  const isProtectedRoute = protectedUrlPrefixes.some(prefix =>
    context.browserHistory.location.pathname.startsWith(prefix)
  );

  if (isProtectedRoute) {
    if (renewData?.data?.token) {
      // Force change password
      // if (renewData?.passwordExpired){
      //   context.browserHistory.replace('/force-change-password');
      // }
    } else {
      context.browserHistory.replace('/sign-in');
    }
  }

  yield put({ type: 'Layout.InitialStatusChanged', payload: { initialized: true, initializationFailed: false } });
}

function* reloadData(context: PASSagaContext, action: Extract<LayoutActions, { type: 'Layout.DataReloadRequested' }>) {

  const { token } = yield select((state: IRootState) => state.login);
  const { lang } = yield select(selectLocale());
  // renew login state
  const renewData: ApiResult<CALoginStateDTO> = yield call(loginApi.renew, token);
  if (renewData?.data?.token) {
    yield put({ type: 'Login.Success', payload: renewData?.data });
    // yield put<UserActions>({ type: 'User.FetchRequested', payload: { id: renewData?.uid }});  
  } else {
    ApiError.isErrorCode(renewData.error, 'ERR_NOT_AUTHORIZED');
    yield put(layoutActions.alert(lang.msgLoggedOut, 'warning'));
    yield put(loginActions.logout());
  }

  // load data
  const { data: optionData, error: optionLoadError } = yield call(optionApi.loadOptions, token);

  if (optionLoadError) {
    return;
  }

  yield put({ type: 'Locale.RemoteBundleLoaded', payload: optionData });

  // load system settings
  const { data: systemSettingData } = yield call(systemSettingApi.getListForFrontend, token ?? 'undefined');
  if (systemSettingData) {
    yield put({ type: 'SystemSetting.Loaded', payload: { data: systemSettingData } });
  } else {
    return;
  }

}