import useLocale from 'common/hooks/useLocale';
import { isNonEmpty } from 'common/utils/utils';
import React, { useState } from 'react';
import classes from './AlertConfirmDialog.module.scss';
import ModalDialog from './ModalDialog';
import ModalDialogActions, { ModalDialogActionButton } from './ModalDialogActions';


export interface AlertConfirmDialogProps {
  show: boolean;
  onHide: (confirmed: boolean) => void;

  title: string;
  message: string;
  actionConfirm: string;
  actionCancel: string;
}


export const useAlertConfirmDialog = () => {
  const [ show, setOpen ] = useState(false);
  const [ title, setTitle ] = useState('');
  const [ message, setMessage ] = useState('');
  const [ actionCancel, setActionCancel ] = useState('');
  const [ actionConfirm, setActionConfirm ] = useState('');
  // const onHide = React.useRef((_: boolean) => {});
  const [ onHide, setOnClose ] = useState({
    current: (confirmed: boolean) => {},
  });

  return {
    render: () => <AlertConfirmDialog
      show={show} onHide={onHide.current ?? (() => {})}
      title={title}
      message={message}
      actionCancel={actionCancel} actionConfirm={actionConfirm} />,
    confirm: (message: string, actionConfirm: string, actionCancel: string, title=''): Promise<boolean> => {
      setOpen(true);
      setTitle(title);
      setMessage(message);
      setActionCancel(actionCancel);
      setActionConfirm(actionConfirm);
      return new Promise<boolean>((resolve) => {
        setOnClose({
          current: (confirmed) => {
            resolve(confirmed);
            setOpen(false);
          },
        });
      });
    },
  }
}
const When = ({ test, children }: { test?: boolean, children: JSX.Element }): JSX.Element => test ? children : <span />;

export const AlertConfirmDialog = ({ show, onHide, message, actionCancel, actionConfirm, title }: AlertConfirmDialogProps) => {
  const { lang } = useLocale();
  return (<ModalDialog title={title} show={show} onHide={() => { onHide(false) }}>
    <div className={classes['dialog-content']}>

      {message ? <div className={classes['message']}>{message}</div> : null}

      {isNonEmpty(actionConfirm) && isNonEmpty(actionCancel) ?
        <ModalDialogActions>
          <ModalDialogActionButton onClick={() => onHide(true)}>
            {actionConfirm}
          </ModalDialogActionButton>
          <ModalDialogActionButton onClick={() => onHide(false)}>
            {actionCancel}
          </ModalDialogActionButton>
        </ModalDialogActions>
        :
        <ModalDialogActions>
          <ModalDialogActionButton onClick={() => onHide(true)}>
            {actionConfirm}
          </ModalDialogActionButton>
        </ModalDialogActions>
      }
    </div>
  </ModalDialog>
  );
}

export default AlertConfirmDialog;