import { isMobileUI } from 'common/utils/utils';
import { BaseLayoutProps } from 'components/scaffold/MainLayoutProps';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { IRootState } from 'reducers';
import { renewAndReloadToken } from 'sagas/loginSaga';

export type LocationStateType = {
  from: string;
}

export const routesRequireLogin = ['/bookmarks', '/customer-profile'];

export default function useLayoutProps(): BaseLayoutProps {
  const history = useHistory();
  const dispatch = useDispatch();
  const currentTab = history.location.pathname.match(/\/([\w\-\d]+)/)?.[1] ?? '';
  const loginState = useSelector((state: IRootState) => state.login);
  const token = useSelector((state: IRootState) => state.login.token);
  useEffect(() => {
    if (token) {
      renewAndReloadToken(token);
    }
  }, [currentTab])

  return {
    activeNavigation: ('/' + currentTab) as any,
    onNavigate: (key) => {
      if (key.startsWith('/')) {
        // Handle Routes
        if ((!loginState.loggedIn || !loginState.token) && isMobileUI() && routesRequireLogin.includes(key)) {
          history.push({ pathname: '/login', state: { from: key } });
        } else {
          history.replace(`${key}`);
        }
      } else {
        // Handle ad-hoc actions
        dispatch({ type: key });
      }
    },
    loginState
  };
}