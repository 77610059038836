import clsx from 'clsx';
import useLocale from 'common/hooks/useLocale';
import { registerTouchableClassNames } from 'common/polyfills/touch-polyfill';
import FormTextField from 'components/form-controls/FormTextField';
import React from 'react';
import Button from 'react-bootstrap/esm/Button';
import Form from 'react-bootstrap/esm/Form';
import classes from './RegisterLayout.module.scss';
import { RegisterViewProps } from './RegisterViewProps';


registerTouchableClassNames(
	classes['register-button'],
	classes['login-button'],
)
export interface RegisterLayoutProps extends RegisterViewProps {
}

export default function RegisterLayout(props: RegisterLayoutProps) {
  const { bindConfirmPassword, bindEmail, bindName, bindPassword, bindPhoneNumber,
    showPasswordComplexityRequirement, passwordComplexityRequirement,
    onGoToLoginClick, onRegisterClick } = props;
  const { langRegister } = useLocale();

  return <div className={classes['main']}>
    <div className={classes['form-container']} >
      <div className={classes['form-element']} >
        <div className={classes['form-label']} >{langRegister.captionName}</div>
        <FormTextField label={langRegister.captionName} bind={bindName}/>
      </div>
      <div className={classes['form-element']} >
        <div className={classes['form-label']} >{langRegister.captionEmail}</div>
        <FormTextField label={langRegister.captionEmail} bind={bindEmail} />
      </div>
      <div className={classes['form-element']} >
        <div className={classes['form-label']} >{langRegister.captionPassword}</div>
        <FormTextField label={langRegister.captionPassword} bind={bindPassword} type={'password'}/>
      </div>
      <div className={classes['form-element']} >
        <div className={classes['form-label']} >{langRegister.captionConfirmPassword}</div>
        <FormTextField label={langRegister.captionConfirmPassword} bind={bindConfirmPassword} type={'password'}/>
      
        {showPasswordComplexityRequirement &&
        <Form.Text className={clsx({
          'text-danger': true,
        })}>{passwordComplexityRequirement}</Form.Text>}
        
      </div>
    </div>
    <div className={classes['register-button-container']}>
      <Button className={classes['register-button']} variant="outline-pas-darkblue" onClick={onRegisterClick}>
        {langRegister.actionRegister}
      </Button>
    </div>

    <div className={classes['login-button-container']}>
      <Button className={classes['login-button']} variant="link"  onClick={onGoToLoginClick}>
        {langRegister.actionLogin}
      </Button>
    </div>
  </div>
}