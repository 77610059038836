import clsx from "clsx";
import useLocale from "common/hooks/useLocale";
import { isNonEmpty } from "common/utils/utils";
import { FormBinder } from "components/form-controls/IFormBinder";
import React, { useEffect, useMemo, useState } from "react";
import Button from "react-bootstrap/esm/Button";
import { useSelector } from 'react-redux';
import { IRootState } from "reducers";
import classes from './DistrictSelectDesktopDialog.module.scss';
import ModalDialog from './ModalDialog';
import ModalDialogActions from "./ModalDialogActions";
import deepEqual from "deep-equal";

export interface DistrictSelectDesktopDialogProps {
  open?: boolean;
	onHide?: () => void;
	districtHkiOptions: LocaleOptions;
	districtKltOptions: LocaleOptions;
	districtNtOptions: LocaleOptions;
	districts?: FormBinder<string[]>;

	confirmRequired?: boolean;
}

export default function DistrictSelectDesktopDialog(props: DistrictSelectDesktopDialogProps) {
	const { open, onHide, confirmRequired, districtHkiOptions, districtKltOptions, districtNtOptions, districts } = props;
	const { langDistrictSelectDialog, lang, locale } = useLocale();
	const [ districtSelect, setDistrictSelected ] = useState<'HKI' | 'KLN' | 'NT'>('HKI'); 
	const [ districtOptions, setDistrictOptions ] = useState<LocaleOptions>(districtHkiOptions);

	const regionMap = {
    HKI: districtHkiOptions,
    KLN: districtKltOptions,
    NT: districtNtOptions,
	};
	
	const [ tmpDistricts, setTmpDistricts ] = useState(districts?.value ?? []);
	useEffect(() => {
		// if (tmpDistricts.some((d, i) => districts?.value?.[i] !== d)) {
		// 	setTmpDistricts(districts?.value ?? []);
		// }
		if (!deepEqual(tmpDistricts, districts?.value)) {
			setTmpDistricts(districts?.value ?? []);
		}
	}, [ districts, open ]);

	const pendingDistricts: FormBinder<string[]> = useMemo(() => ({
		value: tmpDistricts,
		change: (updated) => setTmpDistricts(updated ?? []),
		blur: () => {},
	}), [ tmpDistricts, setTmpDistricts ]);

	const usedDistricts = confirmRequired ? pendingDistricts : districts;

	useEffect(() => {   // TEMP_FIX: switching the language of district options(locale bundles) requires a re-rendering
		switch(districtSelect) {
			case 'HKI': setDistrictOptions(districtHkiOptions); break;
			case 'KLN': setDistrictOptions(districtKltOptions); break;
			case 'NT': setDistrictOptions(districtNtOptions); break;
			default: break;
		}
	}, [ locale ]);

  //--- search district
  const { districtHkiOptions: districtHkiOptionsEn, districtNtOptions: districtNtOptionsEn, districtKltOptions: districtKltOptionsEn } = useSelector((state: IRootState) => state.locale._bundle['en']);
  const { districtHkiOptions: districtHkiOptionsZhHK, districtNtOptions: districtNtOptionsZhHK, districtKltOptions: districtKltOptionsZhHK } = useSelector((state: IRootState) => state.locale._bundle['zh_HK']);

  let searchDistrictHkiOptions: { [key: string]: string; } = {};
  let searchDistrictKltOptions: { [key: string]: string; } = {};
  let searchDistrictNtOptions: { [key: string]: string; } = {};

  for (const [key, value] of Object.entries(districtHkiOptionsEn)) {
    searchDistrictHkiOptions[value.toLowerCase()] = key;
  }
  for (const [key, value] of Object.entries(districtHkiOptionsZhHK)) {
    searchDistrictHkiOptions[value.toLowerCase()] = key;
  }
  for (const [key, value] of Object.entries(districtKltOptionsEn)) {
    searchDistrictKltOptions[value.toLowerCase()] = key;
  }
  for (const [key, value] of Object.entries(districtKltOptionsZhHK)) {
    searchDistrictKltOptions[value.toLowerCase()] = key;
  }
  for (const [key, value] of Object.entries(districtNtOptionsEn)) {
    searchDistrictNtOptions[value.toLowerCase()] = key;
  }
  for (const [key, value] of Object.entries(districtNtOptionsZhHK)) {
    searchDistrictNtOptions[value.toLowerCase()] = key;
  }

  const [keyword, setKeyword] = useState('');

  //----- districts that contain the keyword
  const districtHkiOptionsResultKey = Object.keys(searchDistrictHkiOptions)
    .filter(districtName => districtName.search(keyword) > -1)
    .map(districtName => searchDistrictHkiOptions[districtName]);
  const districtKltOptionsResultKey = Object.keys(searchDistrictKltOptions)
    .filter(districtName => districtName.search(keyword) > -1)
    .map(districtName => searchDistrictKltOptions[districtName]);
  const districtNtOptionsResultKey = Object.keys(searchDistrictNtOptions)
    .filter(districtName => districtName.search(keyword) > -1)
    .map(districtName => searchDistrictNtOptions[districtName]);

  //----- display districts === matched keyword || selected
  const displayDistrictHkiOptionsKey = Object.keys(districtHkiOptions)
    .filter(v => (isNonEmpty(keyword) ? districtHkiOptionsResultKey.includes(v) : true) || tmpDistricts.includes(v));

  const displayDistrictKltOptionsKey = Object.keys(districtKltOptions)
    .filter(v => (isNonEmpty(keyword) ? districtKltOptionsResultKey.includes(v) : true) || tmpDistricts.includes(v));

  const displayDistrictNtOptionsKey = Object.keys(districtNtOptions)
    .filter(v => (isNonEmpty(keyword) ? districtNtOptionsResultKey.includes(v) : true) || tmpDistricts.includes(v));

	const toggleDistrictByRegion = (region: keyof typeof regionMap) => {
		// if (disabled){
		// 	return;
		// }

		const all = Object.keys(regionMap[region]);
		const current = Array.from(usedDistricts?.value ?? []);
		const selected = current.filter(n => all.indexOf(n) > -1);
		if (selected.length === all.length) {
			// all selected, clear all
			usedDistricts?.change(current.filter(n => selected.indexOf(n) < 0).filter(v => isNonEmpty(v)));
		} else if (selected.length < all.length) {
			current.push(...all.filter(n => selected.indexOf(n) < 0));
			usedDistricts?.change(current.filter(v => isNonEmpty(v)));
		}
	};

	const toggleChip = (key: string) =>{
    // if (disabled){
    //   return;
    // }

		if (usedDistricts?.value?.includes(key)){
			usedDistricts?.change(usedDistricts?.value?.filter(d=> d !== key))
		} else {
		 	usedDistricts?.change([...usedDistricts?.value ?? [], key]);
		}
    // if (tmpDistricts.includes(key)){
    //   setTmpDistricts(tmpDistricts.filter(v => v !== key).filter(v => isNonEmpty(v)));
    // }else{
    //   setTmpDistricts([...tmpDistricts, key].filter(v => isNonEmpty(v)));
    // }
	}
	
	const regionAllSelected = (region: keyof typeof regionMap) => {
    // if (disabled){
    //   return;
    // }
    
    const all = Object.keys(regionMap[region]);
    const current = Array.from(usedDistricts?.value ?? []);
    const selected = current.filter(n => all.indexOf(n) > -1);
    return selected.length === all.length;
	};
	
  return <ModalDialog size={'lg'}show={open} onHide={onHide} title={langDistrictSelectDialog.captionDistrict}>
		<div>
			<div className={classes['dialog-content']}>
				<div className={classes['search']}>
					<div className={classes['search-icon']}>
						<i className="fas fa-search" />
					</div>
					<div className={classes['search-input']}>
						<input
							value={keyword}
							onChange={(ev) => {
								setKeyword(ev.target.value);
							}}
							// className={classes['search-input']}
							placeholder={lang.captionSearch}
						/>

						<div className={classes['clear-icon']} onClick={() => setKeyword('')}>
							<i className="fas fa-times-circle"></i>
						</div>
					</div>
				</div>
				{
					displayDistrictHkiOptionsKey.length > 0 &&
					<div style={{ cursor: 'pointer', userSelect: 'none', fontWeight: regionAllSelected('HKI') ? 'bold' : 'normal' }} onClick={(e) => {
						setTimeout(() => toggleDistrictByRegion('HKI'), 0);
					}}>{langDistrictSelectDialog.captionHKI}</div>
				}
				
				<div className={classes['chip-panel']}>
					{displayDistrictHkiOptionsKey.map((key: string) => (
						<div className={classes['chip-container']}>
							<div className={clsx(classes['chip'], usedDistricts?.value?.includes(key) && classes['selected'])}
								key={key}
								// label={districtHkiOptions[key]}
								onClick={(e) => {
									toggleChip(key);
								}} 
							>{districtHkiOptions[key]}</div>
						</div>
					))}
				</div>

				{
					displayDistrictKltOptionsKey.length > 0 && 
					<div style={{ cursor: 'pointer', userSelect: 'none', fontWeight: regionAllSelected('KLN') ? 'bold' : 'normal' }} onClick={(e) => {
						setTimeout(() => toggleDistrictByRegion('KLN'), 0);
					}}>{langDistrictSelectDialog.captionKLN}</div>
				}

				<div className={classes['chip-panel']}>
					{displayDistrictKltOptionsKey.map((key: string) => (
						<div className={classes['chip-container']}>
							<div className={clsx(classes['chip'], usedDistricts?.value?.includes(key) && classes['selected'])}
								key={key}
								// label={districtKltOptions[key]}
								onClick={(e) => {
									toggleChip(key);
								}}
							>{districtKltOptions[key]}</div>
						</div>
					))}
				</div>

				{
					displayDistrictNtOptionsKey.length > 0 &&
					<div style={{ cursor: 'pointer', userSelect: 'none', fontWeight: regionAllSelected('NT') ? 'bold' : 'normal' }} onClick={(e) => {
						setTimeout(() => toggleDistrictByRegion('NT'), 0);
					}}>{langDistrictSelectDialog.captionNT}</div>
				}
				<div className={classes['chip-panel']}>
					{displayDistrictNtOptionsKey.map((key: string) => (
						<div className={classes['chip-container']}>
							<div className={clsx(classes['chip'], usedDistricts?.value?.includes(key) && classes['selected'])}
								key={key}
								// label={districtNtOptions[key]}
								onClick={(e) => {
									toggleChip(key);
								}}
							>{districtNtOptions[key]}</div>
						</div>
					))}
				</div>
			</div>
			
			<ModalDialogActions>
				<Button color="primary" onClick={() => {
					usedDistricts?.change([])
				}}>
					{lang.actionReset}
				</Button>
				<Button color="primary" onClick={() => {
					if (confirmRequired) {
						districts?.change(tmpDistricts);
					}
					onHide?.();
				}}>
					{lang.actionConfirm}
				</Button>
			</ModalDialogActions>
		</div>
		
	</ModalDialog>
}
