import useLocale from 'common/hooks/useLocale';
import { addTouchableHoveringSupport } from 'common/polyfills/touch-polyfill';
import React from 'react';
import Form from 'react-bootstrap/esm/Form';
import InputGroup from 'react-bootstrap/esm/InputGroup';
import classes from './TopSearchBar.module.scss';



export interface TopSearchBarProps {
  // bindKeyword?: FormBinder<string>;
  onClick?: AnyFn;
}

addTouchableHoveringSupport(
  `.${classes['back-btn']}`, [], classes['no-hover'],
);

export default function TopSearchBar(props: TopSearchBarProps) {
  const { onClick } = props;
  const { langHome } = useLocale();

  return <Form.Group className={classes['search-input']} onClick={onClick}>
    <InputGroup>
      <Form.Control
        // isInvalid={!!bindKeyword?.errorMessage}
        // {...baseTextFieldProps(bindKeyword)}
        className={classes['gray-input']}
        placeholder={langHome.captionSearchPlaceholder}
      />
      <InputGroup.Append>
        <InputGroup.Text className={classes['gray-input-append']}>
          <i className={`fa fa-search`} />
        </InputGroup.Text>
      </InputGroup.Append>
    </InputGroup>
  </Form.Group>;
}