import { Capacitor } from '@capacitor/core';
import { Share } from '@capacitor/share';

export const systemShareAvailable = Boolean((Capacitor.isNativePlatform() && Share) || navigator.share);

export function systemShare(text: string, url: string) {
  if (Capacitor.isNativePlatform() && Share) {
      Share.share({
      text, url
    });
  } else if (navigator.share) {
    navigator.share({ text, url });
  } else {
    console.warn('System share is not available!');
  }
}