import { FormBinder } from "components/form-controls/IFormBinder";
import { useDispatch, useSelector } from 'react-redux';
import { AnyAction } from "redux";
import { createAction, PayloadAction } from "typesafe-actions";

export interface FormPresenter<T> {
  field: <K extends keyof T>(key: K) => FormBinder<T[K]>;
  validateAll: () => Promise<boolean>;
}

export interface CommonFormState<T> {
  mode: 'ADD' | 'EDIT';
  /** The pending for edit contents */
  contents: Partial<T>;
  /** The unchanged contents fetched from backend. */
  originalContents?: T;
  /** Field errors object */
  fieldErrors: FormErrorsState<T>;
  /** Indicates whether any content is edited */
  edited: boolean;
}

export const commonFormInitialState = <T> (): CommonFormState<T> => ({
  mode: 'ADD',
  contents: {},
  fieldErrors: {},
  edited: false,
});

export type FormErrorsState<_T> = { [k: string]: string | null | undefined };

export interface FormStateSelector<S, T> {
  (state: S): CommonFormState<T>;
}

export interface FormContentSelector<S, T> {
  (state: S): Partial<T>;
}

export interface FormValidator<T> {
  (values: Partial<T>): PromiseLike<FormErrorsState<T>> | FormErrorsState<T>;
}

export interface FormErrorsSelector<S, T> {
  (state: S): FormErrorsState<T>
}

export interface AddFormErrorAction<T> {
  (errors: FormErrorsState<T>, replace: boolean): AnyAction;
}

export interface FormEditAction<T> {
  (key: keyof T, value: any): AnyAction;
}

export interface FormSpec<T, EditActionTypeKey extends string, AddFormErrorActionTypeKey extends string> {
  actions: {
    edit: (key: keyof T, value: any) => PayloadAction<EditActionTypeKey, { [x: string]: any; }>,
    addFormError: (errors: FormErrorsState<T>, replace?: boolean | undefined) => PayloadAction<AddFormErrorActionTypeKey, {
        errors: FormErrorsState<T>;
        replace: boolean | undefined;
    }>,
  };
  reducer: {
    [action: string]: AnyFn
  };
  initialState: CommonFormState<T>;
  validators: (...args: any[]) => FormValidator<T>[];
  options?: FormReducerOptions;
}

export const createFormSpec = <EditActionTypeKey extends string, AddFormErrorActionTypeKey extends string>(
  editActionType: EditActionTypeKey, addFormErrorActionType: AddFormErrorActionTypeKey,
  options?: FormReducerOptions,
) => {
  return <T>(validators: (...args: any[]) => FormValidator<T>[]): FormSpec<T, EditActionTypeKey, AddFormErrorActionTypeKey> => ({
    actions: createFormActions(editActionType, addFormErrorActionType)<T>(),
    reducer: formInitialReducerHandlers(editActionType, addFormErrorActionType)<T>(),
    initialState: commonFormInitialState(),
    validators,
    options,
  });
}

export const createFormActions = <EditActionTypeKey extends string, AddFormErrorActionTypeKey extends string>(
  editActionType: EditActionTypeKey, addFormErrorActionType: AddFormErrorActionTypeKey,
) => {
  return <T>() => ({
    edit: createAction(editActionType, (key: keyof T, value: any) => ({ [key]: value }))(),
    addFormError: createAction(addFormErrorActionType, (errors: FormErrorsState<T>, replace?: boolean) => ({ errors, replace }))(),
  });
};

export const formInitialReducerHandlers = <EditActionTypeKey extends string, AddFormErrorActionTypeKey extends string>(
  editActionType: EditActionTypeKey, addFormErrorActionType: AddFormErrorActionTypeKey,
) => {
  return <T>() => ({
    [editActionType]: (state: CommonFormState<T>, action: PayloadAction<EditActionTypeKey, Partial<T>>) => 
      ({
        ...state,
        contents: {
          ...state.contents,
          ...action.payload
        }
      }),
    [addFormErrorActionType]: (state: CommonFormState<T>, action: PayloadAction<AddFormErrorActionTypeKey, {
      errors: FormErrorsState<T>,
      replace: boolean | undefined
    }>) => ({
      ...state,
      fieldErrors: action.payload.replace ?
        action.payload.errors :
        { ...action.payload.errors, ...state.fieldErrors }
      }),
  });
};

const emptyErrorSelector: (s: any) => any = (_) => ({});

export function useFormSpec<S, T>(
  selectFormState: FormStateSelector<S, T>,
  spec: FormSpec<T, any, any>,
  ...validatorArgs: any[]
): FormPresenter<T> {
  return useCommonFormReducer(
    selectFormState,
    spec.actions.edit,
    spec.actions.addFormError,
    spec.validators(...validatorArgs),
    spec.options,
  );
}

export function useCommonFormReducer<S, T>(
  selectFormState: FormStateSelector<S, T>,
  editAction: FormEditAction<T>,
  addFormErrorAction?: AddFormErrorAction<T>,
  validators: FormValidator<T>[] = [],
  options: FormReducerOptions = {},
) {
  return useFormReducer(
    (state: S) => selectFormState(state).contents,
    editAction, addFormErrorAction,
    (state: S) => selectFormState(state).fieldErrors,
    validators,
    options,
  );
}

export interface FormReducerOptions {
  disableValidateOnBlur?: boolean;
}

export function useFormReducer<S, T>(selector: FormContentSelector<S, T>,
  editAction: FormEditAction<T>,
  addFormErrorAction?: AddFormErrorAction<T>,
  formErrorsSelector: FormErrorsSelector<S, T> = emptyErrorSelector,
  validators: FormValidator<T>[] = [],
  options: FormReducerOptions = {},
) {
  const contents = useSelector(selector);
  const currentErrors = useSelector(formErrorsSelector);
  const dispatch = useDispatch();
  return {
    field: <K extends keyof T>(key: K): FormBinder<T[K]> => {
      return {
        value: contents[key],
        change: (updated) => dispatch(editAction(key, updated)),
        blur: async () => {
          if (options.disableValidateOnBlur) {
            return;
          }
          const validationTasks = validators.map(func => func(contents));
          const errors = (await Promise.all(validationTasks))
            .reduce((a, b) => ({ ...a, ...b }), {});
          if (errors.hasOwnProperty(key as string)) {
            // Show only error of this field
            const action = addFormErrorAction?.({ [key]: errors[key as string] }, false);
            action && dispatch(action);
          } else {
            // Remove error
            const updatedErrors: FormErrorsState<T> = {};
            Object.keys(currentErrors).filter(k => k!== key).forEach(k => updatedErrors[k] = currentErrors[k]);
            const action = addFormErrorAction?.(updatedErrors, true);
            action && dispatch(action);
          }
        },
        errorMessage: currentErrors[key as string] ?? undefined,
      }
    },
    validateAll: async () => {
      const validationTasks = validators.map(func => func(contents));
      const errors = (await Promise.all(validationTasks))
        .reduce((a, b) => ({ ...a, ...b }), {});
      const action = addFormErrorAction?.(errors, true);
      action && dispatch(action);
      return Object.keys(errors).filter(k => errors[k]).length === 0;
    }
  }
}