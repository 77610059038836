import useLocale from 'common/hooks/useLocale';
import { useMobileLayoutHeight } from 'common/hooks/useMobileLayoutHeight';
import { registerTouchableClassNames } from 'common/polyfills/touch-polyfill';
import ClientScreenTopBar from 'components/navigation/ClientScreenTopBar';
import MainLayoutMobile from 'components/scaffold/MainLayoutMobile';
import React from 'react';
import RegisterLayout from './RegisterLayout';
import classes from './RegisterScreenLayoutMobile.module.scss';
import { RegisterViewProps } from './RegisterViewProps';




registerTouchableClassNames(
	classes['register-button'],
	classes['login-button'],
)

export default function RegisterScreenLayoutMobile(props: RegisterViewProps) {
  const { layoutProps, bindEmail, bindPassword, bindConfirmPassword, bindPhoneNumber, bindName,
    onBackClick, onRegisterClick, onGoToLoginClick,
    showPasswordComplexityRequirement, passwordComplexityRequirement} = props;
  const mobileLayoutHeight = useMobileLayoutHeight();

  const { langRegister } = useLocale();

  return <MainLayoutMobile {...layoutProps}>
    <div className={classes['register-screen-mobile']}>
      <div 
        className={classes['inner']} style={{  }}
      >
        <ClientScreenTopBar
          title={langRegister.title}
          onBackClick={onBackClick}
        />
        <RegisterLayout {...props}/>
      </div> 
    </div></MainLayoutMobile>
  ;
}