import React, { useEffect, useState } from 'react';
import './App.css';
import { Capacitor } from '@capacitor/core';
import { Router, Route, Redirect, Switch, useLocation } from 'react-router-dom';
import { browserHistory } from 'store';
import AgentListPage from 'containers/agent/AgentListPage';
import HomePage from 'containers/home/HomePage';
import LoginPage from 'containers/login/LoginPage';
import PropertyDetailPage from 'containers/property/PropertyDetailPage';
import GlobalEnquiryDialog from 'containers/enquiry/GlobalEnquiryDialog';
import { useSelector, useDispatch } from 'react-redux';
import { IRootState } from 'reducers';
import { layoutActions } from 'reducers/layout';
import { HomeScreenLayoutDemo } from 'stories/8.Screen-Home.stories';
import useLayoutProps from 'common/hooks/useLayoutProps';
import GlobalAgentDetailDialog from 'containers/agent/GlobalAgentDetailDialog';
import GlobalAgentContactDialog from 'containers/agent/GlobalAgentContactDialog';
import GlobalAgentRatingCommentDialog from 'containers/agent/GlobalAgentRatingCommentDialog';
import RegisterPage from 'containers/register/RegisterPage';
import RegisterMoreInfoPage from 'containers/register/RegisterMoreInfoPage';
import CustomerProfilePage from 'containers/customer/CustomerProfilePage';
import GlobalFacebookEmailConfirmDialog from 'containers/login/GlobalFacebookEmailConfirmDialog';
import GlobalLoginDialog from 'containers/login/GlobalLoginDialog';
import GlobalForgotPasswordDialog from 'containers/login/GlobalForgotPasswordDialog';
import GlobalRegisterDialog from 'containers/register/GlobalRegisterDialog';
import ResetPasswordPage from 'containers/resetPassword/ResetPasswordPage';
import GlobalProgressMask from 'containers/layout/GlobalProgressMask';
import AdvancedSearchPage from 'containers/home/AdvancedSearchPage';
import PropertySearchListingPage from 'containers/property/PropertySearchListingPage';
import PropertyMapPage from 'containers/property/PropertyMapPage';
import InlinePropertyListingPage from 'containers/property/InlinePropertyListingPage';
import BookmarkPage from 'containers/property/BookmarkPage';
import SettingPage from 'containers/setting/SettingPage';
import AboutPage from 'containers/setting/AboutPage';
import TCPage from 'containers/setting/TCPage';
import PrivacyPage from 'containers/setting/PrivacyPage';
import LanguagePage from 'containers/setting/LanguagePage';
import QAPage from 'containers/setting/QAPage';
import NotificationListPage from 'containers/notification/NotificationListPage';
import NewsListingPage from 'containers/news/NewsListingPage';
import NewsDetailPage from 'containers/news/NewsDetailPage';

import GlobalNotificationListOverLay from 'containers/notification/GlobalNotificationListOverLay';
import GlobalSavedSearchDialog from 'containers/property/GlobalSavedSearchDialog';
import GlobalAlertConfirmDialogs from 'containers/layout/GlobalAlertConfirmDialogs';
import PropertySearchDesktopPage from 'containers/property/PropertySearchDesktopPage';
import {SplashScreen } from '@capacitor/splash-screen';
// @ts-ignore
// import { AnimatedSwitch, AnimatedRoute } from 'react-router-transition';

const BLANK: any = {};

export function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  const { initialized } = useSelector((state: IRootState) => state.layout);
  // const { GIT, ENV } = useSelector((state: IRootState) => state.systemSettings?.BuildInfo) ?? BLANK;
  const dispatch = useDispatch();
  const { GIT, ENV } = useSelector((state: IRootState) => state.systemSettings?.BuildInfo) ?? BLANK;
  const DEFAULT_TITLE = useSelector((root: IRootState) => root.systemSettings?.ClientApp?.HOME?.PAGE_TITLE) ?? 'Property Mavin 邁房';

  useEffect(() => {
    document.title = DEFAULT_TITLE;
  }, [ DEFAULT_TITLE ]);

  // const { langUser } = useSelector((state: IRootState) => state.locale);
  
  const [ logoAppear, setLogoAppear ] = useState(false);
  const [ logoAppeared, setLogoAppeared ] = useState(false);

  useEffect(() => {
    dispatch(layoutActions.initialize());
    setTimeout(() => setLogoAppear(true), 100);
    setTimeout(() => setLogoAppeared(true), 900);
  }, [ dispatch ]);

  useEffect(() => {
    if (Capacitor.isNativePlatform() && initialized) {
      SplashScreen.hide({
        fadeOutDuration: 0,
      });
    }
  }, [ initialized ]);

  return initialized && logoAppeared ? <Router history={browserHistory}>
    <ScrollToTop />
      <Switch>
        <Redirect exact push from="/" to="/home" />
        <Route exact path="/login" component={LoginPage} />
        <Route exact path="/reset-password" component={ResetPasswordPage} />
        <Route exact path="/home" component={HomePage} />
        <Route exact path="/advanced-search" component={AdvancedSearchPage} />
        <Route exact path="/agents" component={AgentListPage} />
        <Route exact path="/register" component={RegisterPage} />
        <Route exact path="/register-more" component={RegisterMoreInfoPage} />
        <Route exact path="/customer-profile" component={CustomerProfilePage} />
        <Route exact path="/map" component={PropertyMapPage} />
        <Route exact path="/properties" component={PropertySearchListingPage} />
        <Route exact path="/properties/:propertyNo" component={PropertyDetailPage} />
        <Route exact path="/properties/:propertyNo/relatives/:type" component={InlinePropertyListingPage} />
        <Route exact path="/properties/:propertyNo/relatives/:type/:district" component={InlinePropertyListingPage} />
        <Route exact path="/bookmarks" component={BookmarkPage} />
        <Route exact path="/map-list" component={PropertySearchDesktopPage} />
        <Route exact path="/settings" component={SettingPage} />
        <Route exact path="/settings/about" component={AboutPage} /> 
        <Route exact path="/about" component={AboutPage} /> 
        <Route exact path="/settings/T&C" component={TCPage} />
        <Route exact path="/settings/privacy" component={PrivacyPage} />
        <Route exact path="/settings/language" component={LanguagePage} />
        <Route exact path="/settings/Q&A" component={QAPage} />
        <Route exact path="/notifications" component={NotificationListPage} />
        {/* <Route exact path="/news" component={NewsListingPage} /> */}
        {/* <Route exact path="/news/:id" component={NewsDetailPage} /> */}
      </Switch>

      {/* Global Overlays */}
      <GlobalAlertConfirmDialogs />
      <GlobalEnquiryDialog />
      <GlobalAgentDetailDialog />
      <GlobalAgentContactDialog />
      <GlobalAgentRatingCommentDialog />
      <GlobalFacebookEmailConfirmDialog />
      <GlobalLoginDialog />
      <GlobalForgotPasswordDialog />
      <GlobalRegisterDialog />
      <GlobalSavedSearchDialog />
      <GlobalProgressMask />
      <GlobalNotificationListOverLay />
      {/* DEV_ENV */}
      {window.PASClientConfig?.noDevWatermark ? null : <div style={{ color: 'rgba(0,0,0,0.3)', userSelect: 'none', pointerEvents: 'none', fontWeight: 'bolder', position: 'fixed', bottom: '5%', left: '5%', zIndex: 10000 }}>
        {(!ENV?.PROFILE || ENV?.PROFILE === 'prd') ? '' :
          `${ENV?.PROFILE?.toUpperCase()} (ver: ${GIT?.DESCRIPTION})`
        }
      </div>}
    </Router>
  : null;
}

export default App;
