import { ApiError, ApiResult } from 'api';
import { customerApi } from "api/customerApi";
import CACustomerDTO from "common/dto/CACustomerDTO";
import { selectLocale } from "common/hooks/useLocale";
import { IRootState } from 'reducers';
import { changePasswordFormActions } from "reducers/change-pw";
import { customerActions } from "reducers/customer";
import { layoutActions } from "reducers/layout";
import { loginActions } from "reducers/login";
import { call, put, select, takeLeading } from "redux-saga/effects";
import { PASSagaContext } from 'sagas';
import { ActionType, getType } from "typesafe-actions";
import { apiTaskWrapper } from './saga-commons';
import { format } from 'util';

export default [ watchCustomerFetchRequested, watchCustomerUpdateRequested, watchChangePasswordRequested ];

/////////////////////////////////////////////////////////////
/////////////////// Fetch Customer //////////////////////////
/////////////////////////////////////////////////////////////

export function* watchCustomerFetchRequested(context: PASSagaContext) {
  yield takeLeading(
    getType(customerActions.doFetchCustomer),
    apiTaskWrapper(fetchCustomer),
    context,
  );
}

export function* fetchCustomer(_context: PASSagaContext, action: ActionType<typeof customerActions['doFetchCustomer']>) {
  const { token } = yield select((state: IRootState) => state.login);
  const { data, error }: ApiResult<CACustomerDTO> = yield call(customerApi.get, token);

  if (error) {
    throw ApiError.of(error!);
  }
  if (data) {
    yield put(customerActions.customerLoaded(data));
    yield put(loginActions.updatePhotoFilename(data.photoFilename));
  }
}

/////////////////////////////////////////////////////////////
/////////////////// Update Customer /////////////////////////
/////////////////////////////////////////////////////////////

export function* watchCustomerUpdateRequested(context: PASSagaContext) {
  yield takeLeading(
    getType(customerActions.doUpdateCustomer),
    apiTaskWrapper(updateCustomer),
    context,
  );
}

export function* updateCustomer(_context: PASSagaContext, action: ActionType<typeof customerActions['doUpdateCustomer']>) {
  const { token } = yield select((state: IRootState) => state.login);
  const { data, error }: ApiResult<boolean> = yield call(customerApi.update, action.payload, token);
  const { lang, langClientProfile} = yield select(selectLocale());
  
  if (error) {
    throw ApiError.of(error!);
  }
  if (data) {
    yield put(layoutActions.alert(langClientProfile.msgSaveSuccess, 'success'));
    yield put(customerActions.doFetchCustomer());
    yield put(layoutActions.reloadData());
  }
}

/////////////////////////////////////////////////////////////
/////////////////// change password /////////////////////////
/////////////////////////////////////////////////////////////

export function* watchChangePasswordRequested(context: PASSagaContext) {
  yield takeLeading(
    getType(changePasswordFormActions.doChangePassword),
    apiTaskWrapper(changePassword),
    context,
  );
}

export function* changePassword(_context: PASSagaContext, action: ActionType<typeof changePasswordFormActions['doChangePassword']>) {
  const { token } = yield select((state: IRootState) => state.login);
  const { PASSWORD_HISTORY } = yield select((state: IRootState) =>
    state.systemSettings?.ClientApp?.PASSWORD) ?? {};
  const { data, error }: ApiResult<boolean> = yield call(customerApi.changePassword, action.payload.oldPassword, action.payload.newPassword, token);
  const { lang, langClientProfile} = yield select(selectLocale());

  if (error) {
    if (error === 'ERR_PASSWORD_MISMATCHED'){
      yield put(layoutActions.alert(langClientProfile.msgPasswordMismatched, 'error'));
    }
    else if (error === 'ERR_PW_FAIL_COMPLEXITY_REQUIREMENT'){
      yield put(layoutActions.alert(langClientProfile.msgPasswordFailComplexityRequirement, 'error'));
    }
    else if (error === 'ERR_PREVIOUS_PW_USED') {
      yield put(layoutActions.alert(langClientProfile.msgPreviousPasswordUsed, "error"));
    }
    else{
      throw ApiError.of(error!);
    }
    yield put(changePasswordFormActions.clearInput());
  }
  
  if (data) {
    yield put(layoutActions.alert(langClientProfile.msgChangePasswordSuccess, 'success'));
    yield put(changePasswordFormActions.closeDialog());
    yield put(changePasswordFormActions.reset());
    yield put(customerActions.doFetchCustomer());
  }
}

