import clsx from 'clsx';
import React from 'react';
import Container from 'react-bootstrap/esm/Container';
import classes from './SectionTitleDesktop.module.scss';


export interface SectionTitleDesktopProps {
  title: string;
  subheader?: string;
}

export default function SectionTitleDesktop(props: SectionTitleDesktopProps) {
  return <Container className={classes['section-container']}>
    <div className={classes['header']}>{props.title}</div>
    <div className={classes['subheader']}>{props.subheader}</div>
    <hr className={clsx(classes['divider'], classes['bg-saffron'])}/>
  </Container>
}