import { isMobileUI } from 'common/utils/utils';
import { History } from 'history';
import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { loginFormActions } from 'reducers/login-form';
import { call, put } from 'redux-saga/effects';

export type Jumper = () => { type: 'DISPATCH' | 'PUSH' | 'REPLACE', payload: string | object };
const willPush = (path: string) => ({ type: 'PUSH' as const, payload: path });
const willReplace = (path: string) => ({ type: 'REPLACE' as const, payload: path });
const willDispatch = (action: any) => ({ type: 'DISPATCH' as const, payload: action });

export function sagaJump(jumper: Jumper, history: History) {
  let action = jumper();
  switch (action.type) {
    case 'DISPATCH':
      return put(action.payload as any);
    case 'PUSH':
      return call(history.push, action.payload as string);
    case 'REPLACE':
      return call(history.replace, action.payload as string);
  }
}

export function useJump() {
  const dispatch = useDispatch();
  const history = useHistory();

  return useMemo(() => (jumper: Jumper) => {
    let action = jumper();
    switch (action.type) {
      case 'DISPATCH':
        dispatch(action.payload as any);
        break;
      case 'PUSH':
        history.push(action.payload as string, { fromPath: history.location.pathname });
        break;
      case 'REPLACE':
        history.replace(action.payload as string);
        break;
    }
  }, [dispatch, history]);
}

export const jumpers = {
  toLogin: (): Jumper => () => isMobileUI() ?
    willPush('/login') : willDispatch(loginFormActions.openDialog()),
  toHome: (): Jumper => () => willReplace('/home')
}

