import { ChangePasswordDTO } from 'common/dto/CACustomerDTO';
import { ActionType, createAction, createReducer } from 'typesafe-actions';
import { commonFormInitialState, CommonFormState, createFormActions, formInitialReducerHandlers, FormValidator } from './common/form';

export interface ChangePasswordFormState extends CommonFormState<ChangePasswordDTO> {
  open: boolean;
}

export type ChangePasswordFormActions = ActionType<typeof changePasswordFormActions>;

export const changePasswordFormActions = {
  ...createFormActions('ChangePasswordForm.Edit', 'ChangePasswordForm.AddError')<ChangePasswordDTO>(),
  reset: createAction('ChangePasswordForm.Reset')(),
  doChangePassword: createAction('ChangePasswordForm.ChangePasswordRequested')<ChangePasswordDTO>(),
  openDialog: createAction('ChangePasswordForm.Open')(),
  closeDialog: createAction('ChangePasswordForm.Close')(),
  clearInput: createAction('ChangePasswordForm.ClearInput')(),
};

const initialState = (): ChangePasswordFormState => ({
  ...commonFormInitialState<ChangePasswordDTO>(),
  open: false,
});

export const changePasswordFormReducer = createReducer<ChangePasswordFormState, ChangePasswordFormActions>(initialState(), formInitialReducerHandlers('ChangePasswordForm.Edit', 'ChangePasswordForm.AddError')())
  .handleAction(changePasswordFormActions.reset, (_) => 
    initialState() 
  )
  .handleAction(changePasswordFormActions.openDialog, (state) => 
    ({ ...state, open: true })
  )
  .handleAction(changePasswordFormActions.closeDialog, (state) => 
    ({ ...state, open: false })
  )
  .handleAction(changePasswordFormActions.clearInput, (state) =>
    ({ ...state, contents: { oldPassword: '', newPassword: '', confirmNewPassword: '' } })
  )
;

const WHITE_SPACE = ' ';

export const changePasswordFormValidations = (langClientProfile: LocaleOptions, socialLogin = false): FormValidator<ChangePasswordDTO>[] => [
  (values) => !socialLogin && !values.oldPassword ? { oldPassword: langClientProfile.errOldPassword } : {},
  (values) => !values.newPassword ? { newPassword: langClientProfile.errNewPassword } : {},
  (values) => values.newPassword && (values.newPassword.charAt(0) === WHITE_SPACE || values.newPassword.charAt(values.newPassword.length - 1) === WHITE_SPACE) ? { newPassword: langClientProfile.errPasswordLeadingTailingSpace } : {},
  (values) => !values.confirmNewPassword ? { confirmNewPassword: langClientProfile.errConfirmNewPassword } : {},
  (values) => (values.newPassword && values.confirmNewPassword && !(values.newPassword === values.confirmNewPassword) ) ? { confirmNewPassword: langClientProfile.errPasswordMismatch } : {},
];
