import useLocale from 'common/hooks/useLocale';
import { isMobileUI } from 'common/utils/utils';
import DialogAgentView, { DialogAgentViewProps } from 'components/display-cards/DialogAgentView';
import FormTextField from 'components/form-controls/FormTextField';
import { FormBinder } from 'components/form-controls/IFormBinder';
import DialogPropertyInfo, { DialogPropertyInfoProps } from 'components/property/detail/DialogPropertyInfo';
import React from 'react';
import classes from './ContactAgentDialog.module.scss';
import ModalDialog from './ModalDialog';
import ModalDialogActions, { ModalDialogActionButton } from './ModalDialogActions';
import { useMediaQuery } from 'react-responsive';
import breakpoints from 'common/theme/breakpoints.module.scss';



export interface ContactAgentDialogProps {
  open: boolean;
  onHide?: AnyFn;

  agentViewProps: DialogAgentViewProps;
  dialogPropertyInfoProps?: DialogPropertyInfoProps;
  propertySpecific?: boolean;

  bindEmail?: FormBinder<string>;
  bindContact?: FormBinder<string>;
  bindName?: FormBinder<string>;

  onSubmitClick?: AnyFn;
}

export default function ContactAgentDialog(props: ContactAgentDialogProps) {
  const { agentViewProps, dialogPropertyInfoProps, propertySpecific, 
    open, onHide, bindEmail, bindContact, bindName, onSubmitClick,
  } = props;
  const { lang, langEnquiryDialog, langAgent } = useLocale();
  const isMobile = useMediaQuery({ maxWidth: breakpoints.sm });


  return <ModalDialog size={isMobile ? 'sm' : undefined} title={langAgent.actionContactAgent} show={open} onHide={onHide}>
    <div className={classes['contact-agent-dialog']}>
      <DialogAgentView {...agentViewProps} />
      {propertySpecific ? <div className={classes['property-info']}>
        <DialogPropertyInfo {...dialogPropertyInfoProps as DialogPropertyInfoProps} />
        </div> : null }
      <div className={classes['form']}>
        {/* Message */}
        <div className={classes['contact-message']}>{langAgent.msgProvideInfo}</div>
        {/* Forms */}
        <div className={classes['form-row']}>
          <FormTextField label={langEnquiryDialog.captionName} bind={bindName} />
        </div>
        <div className={classes['form-row']}>
          <FormTextField label={langEnquiryDialog.captionContact} bind={bindContact} />
        </div>
        <div className={classes['form-row']}>
          <FormTextField label={langEnquiryDialog.captionEmail} bind={bindEmail} />
        </div>
      </div>

      <ModalDialogActions>
        <ModalDialogActionButton onClick={onSubmitClick}>
          {lang.actionSubmit}
        </ModalDialogActionButton>
      </ModalDialogActions>
    </div>
  </ModalDialog>
}