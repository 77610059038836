import useLocale from 'common/hooks/useLocale';
import React from 'react';
import classes from './About.module.scss';

interface AboutIntroProps {
  content: string;
}

export function AboutIntro(props: AboutIntroProps) {
  const { content } = props;

  const { langSetting } = useLocale();

  return <div className={classes['about-card']}>
    <div className={classes['title']}>{langSetting.titleAboutCompany}</div>
    <div className={classes['intro']} dangerouslySetInnerHTML={{
              __html: props.content ?? '',
            }}>
    </div>
  </div>
}

interface AboutContactProps {
  email: string;
  tel: string;
  address: string;
}

export function AboutContact(props: AboutContactProps) {
  const { email, tel, address } = props;

  const { langSetting } = useLocale();

  return <div className={classes['about-card']}>
    <div className={classes['title']}>{langSetting.captionContactUs}</div>
    <div className={classes['contact']}>
      <div className={classes['item']}>
        <i className="fas fa-at" />
        <div>{email}</div>
      </div>
      <div className={classes['item']}>
        <i className="fas fa-phone" />
        <div>{tel}</div>
      </div>
      <div className={classes['item']}>
        <i className="fas fa-map-marker-alt" />
        <div>{address}</div>
      </div>
    </div>
  </div>
}