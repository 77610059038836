import CAResetPasswordFormDTO from 'common/dto/CAResetPasswordFormDTO';
import { IRootState } from 'reducers';
import { ActionType, createAction, createReducer } from 'typesafe-actions';
import { commonFormInitialState, CommonFormState, createFormActions, formInitialReducerHandlers, FormValidator } from './common/form';

export interface ResetPasswordFormState extends CommonFormState<CAResetPasswordFormDTO> {
    errorMessage: string;
}

export type ResetPasswordFormActions = ActionType<typeof resetPasswordFormActions>;

export const resetPasswordFormActions = {
  ...createFormActions('ResetPasswordForm.Edit', 'ResetPasswordForm.AddError')<CAResetPasswordFormDTO>(),
  reset: createAction('ResetPasswordForm.Reset')(),
  doResetPassword: createAction('ResetPasswordForm.ResetPasswordRequested')<CAResetPasswordFormDTO>(),
  updateErrorMessage: createAction('ResetPasswordForm.updateErrorMessage')<string>(),
  clearInput: createAction('ResetPasswordForm.ClearContent')(),
};

const initialState = (): ResetPasswordFormState => ({
  ...commonFormInitialState<CAResetPasswordFormDTO>(),
  errorMessage: ''
});

export const resetPasswordFormReducer = createReducer<ResetPasswordFormState, ResetPasswordFormActions>(initialState(), formInitialReducerHandlers('ResetPasswordForm.Edit', 'ResetPasswordForm.AddError')())
  .handleAction(resetPasswordFormActions.reset, (_) => 
    initialState()
  )
  .handleAction(resetPasswordFormActions.updateErrorMessage, 
    (state, action) => ({ ...state, errorMessage: action.payload })
  )
  .handleAction(resetPasswordFormActions.clearInput,
    (state, _) => ({ ...state, contents: { password: '', passwordConfirm: '' } })
  )

;

const WHITE_SPACE = ' ';

export const resetPasswordFormValidations = (langLogin: LocaleOptions): FormValidator<CAResetPasswordFormDTO>[] => [
  (values) => !values.password ? { password: langLogin.errPassword } : {},
  (values) => values.password && (values.password.charAt(0) === WHITE_SPACE || values.password.charAt(values.password.length - 1) === WHITE_SPACE) ? { password: langLogin.errPasswordLeadingTailingSpace } : {},
  (values) => (!values.passwordConfirm || values.password !== values.passwordConfirm) ? { passwordConfirm: langLogin.errPasswordMismatch } : {}
];

export const resetPasswordFormSelectors = {
	selectForm: () => (state: IRootState) => state.resetPasswordForm
};