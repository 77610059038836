import React from 'react';

export interface RatingStarsProps {
  maxRating: number;
  rating: number;
  color?: string;
}

export default function RatingStars({ maxRating, rating, color }: RatingStarsProps) {
  const numFullStar = Math.floor(rating);
  const numHalfStar = numFullStar < rating ? 1 : 0;

  return <span>
    {(new Array(numFullStar)).fill(true).map((_, i) => <i style={{ color }} key={i} className="fas fa-star"></i>)}
    {numHalfStar ? <i style={{ color }} className="fas fa-star-half"></i> : null}
  </span>
}