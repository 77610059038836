import CARegisterFormDTO from 'common/dto/CARegisterFormDTO';
import { checkValidEmail, checkValidPhoneNumber, isNonEmpty } from 'common/utils/utils';
import { ActionType, createAction, createReducer } from 'typesafe-actions';
import { commonFormInitialState, CommonFormState, createFormActions, formInitialReducerHandlers, FormValidator } from './common/form';

export interface RegisterFormState extends CommonFormState<CARegisterFormDTO> {
  showPasswordComplexityRequirement: boolean;
}

export type RegisterFormActions = ActionType<typeof registerFormActions>;

export const registerFormActions = {
	...createFormActions('RegisterForm.Edit', 'RegisterForm.AddError')<CARegisterFormDTO>(),
	reset: createAction('RegisterForm.Reset')(),
  showRequirement: createAction('RegisterForm.ShowPwRequirement')(),
  hideRequirement: createAction('RegisterForm.HidePwRequirement')(),
  clearInput: createAction('RegisterForm.ClearInput')(),
};

const initialState = (): RegisterFormState => ({
  // ==> Form: extends the initial state formInitialState
  ...commonFormInitialState<CARegisterFormDTO>(),
  showPasswordComplexityRequirement: false,
});

export const registerFormReducer = createReducer<RegisterFormState, RegisterFormActions>(initialState(),
    // ==> Form: extends the reducer from FormInitialReducerHandler
    formInitialReducerHandlers('RegisterForm.Edit', 'RegisterForm.AddError')()
  )
  .handleAction(registerFormActions.reset, (_) => 
    initialState()
  )
  .handleAction(registerFormActions.showRequirement, (state) => 
    ({ ...state, showPasswordComplexityRequirement: true })
  )
  .handleAction(registerFormActions.hideRequirement, (state) => 
    ({ ...state, showPasswordComplexityRequirement: false })
  )
  .handleAction(registerFormActions.clearInput, (state) =>
    ({ ...state, contents: { email: "", phoneNumber: "", password: "", confirmPassword: ""}})
  )
;

const WHITE_SPACE = ' ';

export const registerFormValidations = (langRegister: LocaleOptions): FormValidator<CARegisterFormDTO>[] => [
  (values) => (!values.email || !checkValidEmail(values.email)) ? { email: langRegister.errEmail } : {},
  (values) => (isNonEmpty(values.phoneNumber) && !checkValidPhoneNumber(values.phoneNumber ?? '')) ? { phoneNumber: langRegister.errInvalidPhoneNumber } : {},
  (values) => !values.password ? { password: langRegister.errPassword } : {},
  (values) => values.password && (values.password.charAt(0) === WHITE_SPACE || values.password.charAt(values.password.length - 1) === WHITE_SPACE) ? { password: langRegister.errPasswordLeadingTailingSpace } : {},
  (values) => !values.confirmPassword ? { confirmPassword: langRegister.errConfirmPassword } : {},
  (values) => (values.confirmPassword && values.confirmPassword && !(values.password === values.confirmPassword) ) ? { confirmPassword: langRegister.errPasswordMismatch } : {},
];
