import CALoginFormDTO from 'common/dto/CALoginFormDTO';
import { checkValidEmail } from 'common/utils/utils';
import { ActionType, createAction, createReducer } from 'typesafe-actions';
import { commonFormInitialState, CommonFormState, createFormActions, formInitialReducerHandlers, FormValidator } from './common/form';

export interface LoginFormState extends CommonFormState<CALoginFormDTO> {
  email?: string;
  password?: string;
  dialogOpened: boolean;
}

export type LoginFormActions = ActionType<typeof loginFormActions>;

export const loginFormActions = {
  ...createFormActions('LoginForm.Edit', 'LoginForm.AddError')<CALoginFormDTO>(),
  reset: createAction('LoginForm.Reset')(),
  doLogin: createAction('LoginForm.LoginRequested')(),
  openDialog: createAction('LoginForm.OpenDialog')(),
  closeDialog: createAction('LoginForm.CloseDialog')(),
  clearInput: createAction('LoginForm.ClearInput')(),
};

const initialState = (): LoginFormState => ({
  ...commonFormInitialState<CALoginFormDTO>(),
  email: '',
  password: '',
  dialogOpened: false
});

export const loginFormReducer = createReducer<LoginFormState, LoginFormActions>(initialState(), formInitialReducerHandlers('LoginForm.Edit', 'LoginForm.AddError')())
  .handleAction(loginFormActions.reset, (_) => 
    initialState()
  )
  .handleAction(loginFormActions.openDialog, 
    (state, _) => ({ ...state, dialogOpened: true })
  )
  .handleAction(loginFormActions.closeDialog, 
    (state, _) => ({ ...state, dialogOpened: false })
  )
  .handleAction(loginFormActions.clearInput, 
    (state, _) => ({ ...state, contents: { email: "", password: ""}})
  )

;

export const loginFormValidations = (langLogin: LocaleOptions): FormValidator<CALoginFormDTO>[] => [
  (values) => (!values.email || !checkValidEmail(values.email)) ? { email: langLogin.errEmail } : {},
  (values) => !values.password ? { password: langLogin.errPassword } : {},
];
