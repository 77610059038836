import clsx from 'clsx';
import useLocale from 'common/hooks/useLocale';
import { registerTouchableClassNames } from 'common/polyfills/touch-polyfill';
import VerticalScrollView from 'components/scaffold/VerticalScrollView';
import React from 'react';
import classes from './SharePanel.module.scss';

registerTouchableClassNames(
	classes['btn'],
)

export interface SharePanelProps {
  onFacebookClick?: AnyFn;
  onWhatsAppClick?: AnyFn;
  onWeChatClick?: AnyFn;
  onInstagramClick?: AnyFn;
  onTwitterClick?: AnyFn;
  onSinaClick?: AnyFn;
  onEmailClick?: AnyFn;
  onMoreClick?: AnyFn;
  hideCaption?: boolean;
}

export default function SharePanel(props: SharePanelProps) {
  const { onEmailClick, onFacebookClick, onInstagramClick, onSinaClick,
    onTwitterClick, onWeChatClick, onWhatsAppClick, onMoreClick, hideCaption } = props;

  const { lang } = useLocale();

  return <div className={classes['share-panel']}>
    <div className={classes['inner']}>
    { !hideCaption ? <div className={classes['caption']}>{lang.captionShare}</div> : null }
    <VerticalScrollView>
      <div className={classes['btn-list']}>
        <div className={classes['btn']} onClick={onFacebookClick}><img src="images/share-icons/facebook@3x.png"/></div>
        <div className={classes['btn']} onClick={onWhatsAppClick}><img src="images/share-icons/whatsapp@3x.png"/></div>
        {!!onWeChatClick && <div className={classes['btn']} onClick={onWeChatClick}><img src="images/share-icons/wechat@3x.png"/></div>}
        {!!onInstagramClick && <div className={classes['btn']} onClick={onInstagramClick}><img src="images/share-icons/instagram@3x.png"/></div>}
        <div className={classes['btn']} onClick={onTwitterClick}><img src="images/share-icons/twitter@3x.png"/></div>
        {!!onSinaClick && <div className={classes['btn']} onClick={onSinaClick}><img src="images/share-icons/sina@3x.png"/></div>}
        <div className={classes['btn']} onClick={onEmailClick}><img src="images/share-icons/email@3x.png"/></div>
        {!!onMoreClick && <div className={clsx([classes['btn'], classes['fa']])} onClick={onMoreClick}>
          <i className="fas fa-ellipsis-v"></i>
        </div>}
      </div>
    </VerticalScrollView>
    </div>
  </div>
}