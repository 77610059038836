import clsx from 'clsx';
import useNativeStatusBarHeight from 'common/hooks/useNativeStatusBarHeight';
import 'common/polyfills/viewport-height';
import { getIOSInsectBottom, isMobileUI } from 'common/utils/utils';
import React from 'react';
import { CSSTransition } from 'react-transition-group';
import classes from './PopupOverlay.module.scss';



export interface PopupOverlayProps {
  show: boolean;
  onHide?: AnyFn;
  children: React.ReactChild;
  animation?: 'right-left' | 'fade' | 'none';
  noIOSInsect?: boolean;
}

export default function PopupOverlay(props: PopupOverlayProps) {
  const { show, children, onHide, animation, noIOSInsect } = props;

  // useOverlayNavigationBlocking(show, onHide);
  const statusBarHeight = useNativeStatusBarHeight();

  return <CSSTransition in={show} timeout={300} unmountOnExit classNames={{
    appear: classes['popup'],
    // appearActive: 'my-active-appear',
    // appearDone: 'my-done-appear',
    enter: classes['enter'],
    enterActive: classes['active-enter'],
    // enterDone: 'my-done-enter',
    exit: classes['exit'],
    exitActive: classes['active-exit'],
    // exitDone: 'my-done-exit',
  }}>
    <div className={clsx([
      classes['popup'],
      animation !== 'none' && classes[animation ?? 'right-left'],
      statusBarHeight && isMobileUI() && classes['darkblue-bg'],
      !isMobileUI() && classes['mask-bg'],
    ])} style={{
      paddingTop: `${statusBarHeight}px`,
    }}>
      <div style={{ flexBasis: '100%', overflow: 'auto' }}>{children}</div>
      {!noIOSInsect ? <div style={{ flexBasis: getIOSInsectBottom(), backgroundColor: 'var(--white)', flexShrink: 0 }}>&nbsp;</div> : null}
    </div>
  </CSSTransition>
}