import useSimpleFormBinder from 'common/hooks/useFormBinder';
import useLayoutProps from 'common/hooks/useLayoutProps';
import useLocale from 'common/hooks/useLocale';
import usePrevious from 'common/hooks/usePrevious';
import { jumpers, useJump } from 'common/theme/jumper';
import { handlePriceDisplay, handlePriceDisplayUnit, isNonEmpty, priceFromView, priceToView, rangeFriendlyDisplay } from 'common/utils/utils';
import SearchScreenMobile from 'components/property/search/SearchScreenLayoutMobile';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { IRootState } from 'reducers';
import { useCommonFormReducer } from 'reducers/common/form';
import { LocaleState } from 'reducers/locale';
import { loginSelectors } from 'reducers/login';
import { propertyListActions, PropertyStockSearchForm } from 'reducers/property-list';
import { saveSearchActions } from 'reducers/save-search';
import { format } from 'util';

// Change the description rendering logic.
// Pick the first 4 non-empty occurance of the following:
// 1. Usage
// 2. Buy/Rent
// 3. Districts
// 4. Price/Rent Range
// 5. Size
// 6. Building
// 7. Street
// 8. No. of Rooms
// 9. Facing
// 10. Clubhouse Facilities
// 11. No. of Suites
// 12. No. of Bathroom
// 13. No. of Balcony
// 14. No. of Helper Rooms
// 15. View
// 16. Deco
// 17. Features
// 18. Others
// 19. Keyword
// 20. Primary School Net
// 21.  Secondary School Net

const searchFormKeys: (keyof PropertyStockSearchForm)[] = [
  'usage',
  'buyOrRent',
  'district',
  'priceDisplay',
  'rent',
  'net',
  'buildingName',
  'street',
  'room',
  'facing',
  'clubHouseFacilities',
  'suite',
  'bathroom',
  'balcony',
  'hasHelperRoom',
  'view',
  'deco',
  'otherFeatures',
  'others',
  'freeTextSearch',
  'primarySchoolNet',
  'secondarySchoolNet'
];

const keys_fieldRange: (keyof PropertyStockSearchForm)[] = [
  'priceDisplay',
  'rent',
  'net'
];

const keys_fieldEnabled: Record<keyof PropertyStockSearchForm, keyof PropertyStockSearchForm> = {
  // 'room': 'roomEnabled',
  'suite': 'suiteEnabled',
  'bathroom': 'bathroomEnabled',
  'balcony': 'balconyEnabled',
  'hasHelperRoom': 'helperRoomEnabled'
} as Record<keyof PropertyStockSearchForm, keyof PropertyStockSearchForm>;

const optionsConcat = (fieldValues: string[], options: LocaleOptions, limit?: number) => 
  fieldValues!.slice(0, limit).map((v: string) => options[v]).join(",");

const districtDisplayConcat = (fieldValues: string[], displayQuota: number | undefined, 
  districtOptions: LocaleOptions, districtHkiOptions: LocaleOptions, districtKltOptions: LocaleOptions, districtNtOptions: LocaleOptions,
  captionHKI: string, captionKLN: string, captionNT: string) => {
    let concatValues: string[] = [];
    let remainingQuota = displayQuota ?? Infinity;
    let remainingFieldValues = [ ...fieldValues ];
    // group into regions, if any, in the order (HK Island > Kowloon > N.T.)
    // assume that displayQuota >= 3
    // HKI
    if (Object.keys(districtHkiOptions).filter(d => remainingFieldValues.includes(d)).length 
      === Object.keys(districtHkiOptions).length) {
        concatValues.push(captionHKI);
        remainingFieldValues = remainingFieldValues.filter(v => !Object.keys(districtHkiOptions).includes(v));
        remainingQuota = remainingQuota - 1;
      }
    // KL
    if (Object.keys(districtKltOptions).filter(d => remainingFieldValues.includes(d)).length 
      === Object.keys(districtKltOptions).length) {
        concatValues.push(captionKLN);
        remainingFieldValues = remainingFieldValues.filter(v => !Object.keys(districtKltOptions).includes(v));
        remainingQuota = remainingQuota - 1;
      }
    // NT
    if (Object.keys(districtNtOptions).filter(d => remainingFieldValues.includes(d)).length 
      === Object.keys(districtNtOptions).length) {
        concatValues.push(captionNT);
        remainingFieldValues = remainingFieldValues.filter(v => !Object.keys(districtNtOptions).includes(v));
        remainingQuota = remainingQuota - 1;
      }

    // sort remaining districts alphabetically
    remainingFieldValues = remainingFieldValues.sort();

    // append individual districts 
    for (let district of remainingFieldValues) {
      if (remainingQuota === 0) {
        concatValues.push("...")
        break;
      }
      concatValues.push(districtOptions[district]);
      remainingQuota = remainingQuota - 1;
    }

    return concatValues.join(",");
  }

export const getSearchTitle = (searchCriteria: Partial<PropertyStockSearchForm>, localeState: LocaleState) => {
  const { locale, lang: langCommon, langProperty, usageOptions, langDistrictSelectDialog,
    primarySchoolNetOptions, secondarySchoolNetOptions, otherFeatureOptions, facingOptions,
    decoOptions, viewOptions, clubHouseFacilitiesOptions,otherOptions,
    districtHkiOptions, districtKltOptions, districtNtOptions
  } = localeState;

  const { captionHKI, captionKLN, captionNT } = langDistrictSelectDialog;

  const districtOptions = { ...districtHkiOptions, ...districtKltOptions, ...districtNtOptions };

  let searchTitle = ''; // e.g. 買樓/$200-500萬/2房/大埔
  let descriptionQuota = 4;

  for (let key of searchFormKeys) {
    if (descriptionQuota === 0) return searchTitle;
    
    /**************** Empty occurance checking STARTS*******************/
    /* Skip for the following combo */
    if (searchCriteria['buyOrRent'] === 'RENT' && key === 'priceDisplay') continue;
    if (searchCriteria['buyOrRent'] === 'BUY' && key === 'rent') continue;

    /* for input number ranges */
    if (keys_fieldRange.includes(key)) {
      if ((searchCriteria[key] as Array<number>)?.[0] === 0 
        && (searchCriteria[key] as Array<number>)?.[1] === Infinity) {
          continue;
      }
    }

    /* for xxx-enabled fields */
    if (Object.keys(keys_fieldEnabled).includes(key) && 
      !(isNonEmpty(searchCriteria[key]) && isNonEmpty(searchCriteria[keys_fieldEnabled[key]]))
      ) {
      continue;
    }

    /* for room */
    if (key === 'room' && !isNonEmpty(searchCriteria['roomEnabled'])) {
      continue;
    }

    if (!isNonEmpty(searchCriteria[key]) && key !== 'room') continue;
    /**************** Empty occurance checking ENDS *******************/

    let concatValue = '';

    switch(key) {
      case 'usage':
        concatValue = optionsConcat(searchCriteria['usage']!, usageOptions);
        break;
      case 'buyOrRent':
        concatValue = searchCriteria['buyOrRent'] === 'BUY' ? langProperty.actionBuy : langProperty.actionRent;
        break;
      case 'district':
        concatValue = districtDisplayConcat(searchCriteria['district']!, undefined, 
        districtOptions, districtHkiOptions, districtKltOptions, districtNtOptions,
        captionHKI, captionKLN, captionNT);
        break;
      case 'priceDisplay':
        concatValue = rangeFriendlyDisplay(searchCriteria['priceDisplay']!, locale, num => `$${handlePriceDisplay(priceFromView(num, locale), locale)}${handlePriceDisplayUnit(priceFromView(num, locale), locale, langCommon)}`);
        break;
      case 'rent':
        concatValue = rangeFriendlyDisplay(searchCriteria['rent']!, locale, num => `$${num}`);
        break;
      case 'net':
        concatValue = rangeFriendlyDisplay(searchCriteria['net']!, locale, num => `${num}${langCommon.uFt}`);
        break;
      case 'buildingName':
        concatValue = searchCriteria['buildingName']!;
        break;
      case 'street':
        concatValue = searchCriteria['street']!;
        break;
      case 'room':
        if (searchCriteria['room'] === 0) {          
          concatValue = langProperty.captionOpenType;
        } else {
          concatValue = `${searchCriteria['room']}${searchCriteria['room'] === 1 ? langProperty.captionRoom : langProperty.captionRooms}`;
        }
        break;
      case 'facing':
        concatValue = optionsConcat(searchCriteria['facing']!, facingOptions);
        break;
      case 'clubHouseFacilities':
        concatValue = optionsConcat(searchCriteria['clubHouseFacilities']!, clubHouseFacilitiesOptions);
        break;
      case 'suite':
        concatValue = `${searchCriteria['suite']}${searchCriteria['suite'] === 1 ? langProperty.captionSuite : langProperty.captionSuites}`;
        break;
      case 'bathroom':
        concatValue = `${searchCriteria['bathroom']}${langProperty.captionBathroom}`;
        break;
      case 'balcony':
        concatValue = `${searchCriteria['balcony']}${langProperty.captionBalcony}`;
        break;
      case 'hasHelperRoom':
        concatValue = `${langProperty.captionHelperRoom}`;
        break;
      case 'view':
        concatValue = optionsConcat(searchCriteria['view']!, viewOptions);
        break;
      case 'deco':
        concatValue = optionsConcat(searchCriteria['deco']!, decoOptions);
        break;
      case 'otherFeatures':
        concatValue = optionsConcat(searchCriteria['otherFeatures']!, otherFeatureOptions);
        break;
      case 'others':
        concatValue = optionsConcat(searchCriteria['others']!, otherOptions);
        break;
      case 'freeTextSearch':
        concatValue = searchCriteria['freeTextSearch']!;
        break;
      case 'primarySchoolNet':
        concatValue = optionsConcat(searchCriteria['primarySchoolNet']!, primarySchoolNetOptions);
        break;
      case 'secondarySchoolNet':
        concatValue = optionsConcat(searchCriteria['secondarySchoolNet']!, secondarySchoolNetOptions);
        break;
      default:
        break;
    }

    if (descriptionQuota !== 4) searchTitle = searchTitle.concat("/");
    searchTitle = searchTitle.concat(concatValue);
    descriptionQuota = descriptionQuota - 1;
  }
  return searchTitle;
}


const AdvancedSearchPage = () => {
  const dispatch = useDispatch();
  const layoutProps = useLayoutProps();
  const history = useHistory();
  const { langProperty, locale, lang: langCommon, districtHkiOptions, districtKltOptions, districtNtOptions, 
    usageOptions, facingOptions, decoOptions, viewOptions, otherFeatureOptions, primarySchoolNetOptions, secondarySchoolNetOptions, 
    clubHouseFacilitiesOptions, otherOptions,
  } = useLocale();
  const localeState = useLocale();

  const jump = useJump();
  const loggedIn = useSelector(loginSelectors.loggedIn());
  
  const { contents: searchCriteria} = useSelector((state: IRootState) => state.propertyList);
  const { saveSearchTitle, saveSearchTitleErrorMsg } = useSelector((state: IRootState) => state.saveSearch);
  // ALERT: keep in sync with PropertySearchDesktopPage
  const maxTitleLength = 16777215;

	const bindSearchTitle = useSimpleFormBinder(
		saveSearchTitle,
		(updated) => dispatch(saveSearchActions.updateSaveSearchTitle(updated ?? '')),
		() => { dispatch(saveSearchActions.updateSaveSearchTitleError(isNonEmpty(saveSearchTitle) ? '' : langProperty.errPleaseEnterTitle)); },
		saveSearchTitleErrorMsg
  );
  
  const form = useCommonFormReducer(
    (state: IRootState) => state.propertyList,
    propertyListActions.edit,
    propertyListActions.addFormError,
    []
  );

  const prevLocale = usePrevious<string>(locale);

  useEffect(() => {
    if (prevLocale !== locale) {
      dispatch(propertyListActions.edit("priceDisplay", 
        form.field("priceDisplay").value?.map(v => priceToView(priceFromView(v, prevLocale ?? locale), locale)))
      );
    }
  }, [ locale ]);

  return <SearchScreenMobile
    layoutProps={layoutProps}
    form={form}
    usageOptions={usageOptions}
    facingOptions={facingOptions}
    decoOptions={decoOptions}
    viewOptions={viewOptions}
    otherFeatureOptions={otherFeatureOptions}
    primarySchoolNetOptions={primarySchoolNetOptions}
    secondarySchoolNetOptions={secondarySchoolNetOptions}
    districtHkiOptions={districtHkiOptions}
    districtKltOptions={districtKltOptions}
    districtNtOptions={districtNtOptions}
    clubHouseFacilitiesOptions={clubHouseFacilitiesOptions}
    otherOptions={otherOptions}
    onResetClick={()=>dispatch(propertyListActions.reset())}
    onSearchClick={()=> {
      dispatch(propertyListActions.fetch({ isSwitchingPage: false }));
      history.push('/properties');
    }}
    // ALERT: keep in sync with PropertySearchDesktopPage
    onSaveClick={() => {
      if (!loggedIn) {
        jump(jumpers.toLogin());
      } else {
        dispatch(saveSearchActions.updateSaveSearchTitle(getSearchTitle(searchCriteria, localeState)));
        dispatch(saveSearchActions.openSaveSearchDialog());
      }
    }}
    // ALERT: keep in sync with PropertySearchDesktopPage
    onSaveSubmit={() => {
      if (!isNonEmpty(bindSearchTitle.value)) {
        dispatch(saveSearchActions.updateSaveSearchTitleError(langProperty.errPleaseEnterTitle));
      }
      else if (bindSearchTitle.value!.length > maxTitleLength) {
        dispatch(saveSearchActions.updateSaveSearchTitleError(format(langCommon.msgMaxLength, maxTitleLength)));
      }
      else {
        dispatch(saveSearchActions.addSearchCriteria());
        dispatch(saveSearchActions.closeSaveSearchDialog());
      }
    }}
    bindSearchTitle={bindSearchTitle}
    onBackClick={() => history.goBack()}
  />;

}

export default AdvancedSearchPage;