import { PropertyStockSearchForm } from 'reducers/property-list';
import { ActionType, createAction, createReducer } from 'typesafe-actions';

export interface SaveSearchState {
  saveSearchTitle: string;
  saveSearchTitleErrorMsg?: string;
  savedCriterias: SavedCriteriaForm[];
  openSaveDialog: boolean;
  openSaveSuccessDialog: boolean;

  // For desktop
  openSavedSearchCriteriasDialog: boolean;
}

export interface SavedCriteriaForm {
  criteriaId: number;
  title: string;
  searchDate: string;
  contents: PropertyStockSearchForm;
}

const saveSearchInitialState = (): SaveSearchState => ({
  saveSearchTitle: '',
  savedCriterias: [],
  openSaveDialog: false,
  openSaveSuccessDialog: false,
  openSavedSearchCriteriasDialog: false,
});

export const saveSearchActions = {
  updateSaveSearchTitle: createAction('SaveSearch.TitleUpdated')<string>(),
  updateSaveSearchTitleError: createAction('SaveSearch.TitleErrorUpdated')<string>(),
  fetchSavedSearchCriterias: createAction('SaveSearch.FetchRequested')(),
  updateSavedSearchCriterias: createAction('SaveSearch.Updated')<{ savedCriterias: SavedCriteriaForm[] }>(),
  addSearchCriteria: createAction('SaveSearch.AddRequested')(),
  deleteSavedSearchCriteria: createAction('SaveSearch.DeleteRequested')<{ criteriaId: number}>(),
  openSaveSearchDialog: createAction('SaveSearch.OpenDialog')(),
  closeSaveSearchDialog: createAction('SaveSearch.CloseDialog')(),
  openSaveSearchSuccessDialog: createAction('SaveSearch.Success.OpenDialog')(),
  closeSaveSearchSuccessDialog: createAction('SaveSearch.Success.CloseDialog')(),

  openSavedSearchCriteriasDialog: createAction('SavedSearchCriterias.OpenDialog')(),
  closeSavedSearchCriteriasDialog: createAction('SavedSearchCriterias.CloseDialog')()
};

export type SaveSearchActions = ActionType<typeof saveSearchActions>;

export const saveSearchReducer = createReducer<SaveSearchState, SaveSearchActions>(saveSearchInitialState())
  .handleAction(saveSearchActions.updateSaveSearchTitle,
    (state, action) => ({ ...state, saveSearchTitle: action.payload }),
  )
  .handleAction(saveSearchActions.updateSaveSearchTitleError,
    (state, action) => ({ ...state, saveSearchTitleErrorMsg: action.payload })
  )
  .handleAction(saveSearchActions.updateSavedSearchCriterias,
    (state, action) => ({ ...state, savedCriterias: action.payload.savedCriterias })
  )
  .handleAction(saveSearchActions.deleteSavedSearchCriteria, (state, { payload }) => ({
    ...state, savedCriterias: state.savedCriterias.filter(c => c.criteriaId !== payload.criteriaId)
  }))
  .handleAction(saveSearchActions.openSaveSearchDialog, (state, _) => ({
    ...state, openSaveDialog: true,
  }))
  .handleAction(saveSearchActions.closeSaveSearchDialog, (state, _) => ({
    ...state, saveSearchTitleErrorMsg: '', openSaveDialog: false,
  }))
  .handleAction(saveSearchActions.openSaveSearchSuccessDialog, (state, _) => ({
    ...state, openSaveSuccessDialog: true,
  }))
  .handleAction(saveSearchActions.closeSaveSearchSuccessDialog, (state, _) => ({
    ...state, openSaveSuccessDialog: false,
  }))
  .handleAction(saveSearchActions.openSavedSearchCriteriasDialog, (state, _) => ({
    ...state, openSavedSearchCriteriasDialog: true
  }))
  .handleAction(saveSearchActions.closeSavedSearchCriteriasDialog, (state, _) => ({
    ...state, openSavedSearchCriteriasDialog: false
  }))
;