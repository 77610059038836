import clsx from 'clsx';
import useLocale from 'common/hooks/useLocale';
import { registerTouchableClassNames } from 'common/polyfills/touch-polyfill';
import { handlePriceDisplay, handlePriceDisplayUnit, isNonEmpty, numberWithCommas } from 'common/utils/utils';
import { ImageBg } from 'components/data-display/Image';
import React from 'react';
import { isNullOrUndefined } from 'util';
import classes from './PropertyResultListItem.module.scss';


registerTouchableClassNames(
  classes['property-result-list-item'],
  classes['bookmark'],
)

export interface PropertyResultListItemProps {
  id: number;
  propertyNo?: string;
  street: string;
  buildingName: string;
  level: string;
  image: string;
  net: number;
  gross?: number;
  district: string;
  price?: number;
  rent?: number;

  status?: string;

  bookmarked?: boolean;
  isHotpick?: boolean;

  punchline?: string;

  onBookmarkClick?: AnyFn;

  onClick?: AnyFn;

  // For bookmark only
  unpublished?: boolean;
  showBookmark?: boolean;
}

export default function PropertyResultListItem(props: PropertyResultListItemProps) {
  const { street, buildingName, level, image, net, gross, district, price, rent, status, bookmarked,
    isHotpick, onBookmarkClick, onClick } = props;

  const { lang: langCommon, langProperty, propertyStockStatusOptions, locale } = useLocale();

  const onlyRent = isNullOrUndefined(price);

  const isSoldOrLeased = status === 'SOLD' || status === 'LEASED';

  const grossDisplay = () => {
    const grossDisplay = !(gross) ? '' : (langProperty.grossAbbr + String(gross) + langCommon.uFt);

    return isNonEmpty(grossDisplay) ? <div className={classes['gross']}>{grossDisplay}</div> : null;
  }

  const netDisplay = () => {
    const netDisplay = !(net) ? '' : (langProperty.netAbbr + String(net) + langCommon.uFt);

    return isNonEmpty(netDisplay) ? <div className={classes['net']}>{netDisplay}</div> : null;
  }

  const priceSection = () => {
    const priceDisplay = (isNullOrUndefined(price) || !isNullOrUndefined(price) && price === 0) ? '' :
      numberWithCommas(handlePriceDisplay(price ?? 0, locale)) + handlePriceDisplayUnit(price, locale, langCommon);

    return isNonEmpty(priceDisplay) ? <div className={classes['price-section']} style={{ minWidth: priceDisplay.length > 10 ? '' : '7.5rem' }}>
      <div className={classes['logo']}>{langProperty.sale}</div>
      <div className={classes['price']}>{`$${priceDisplay}`}</div>
    </div> : null;
  }

  const rentSection = () => {
    const rentDisplay = (isNullOrUndefined(rent) || !isNullOrUndefined(rent) && rent === 0) ? '' : numberWithCommas(rent);

    return isNonEmpty(rentDisplay) ? <div className={classes['rent-section']} style={{ minWidth: rentDisplay.length > 10 ? '' : '7.5rem' }}>
      <div className={classes['logo']}>{langProperty.rent}</div>
      <div className={classes['rent']}>{`$${rentDisplay}`}</div>
    </div> : null;
  }

  return <div className={clsx(classes['property-result-list-item'], props.unpublished && classes['unpublished'])} onClick={onClick}>
    <div className={classes['main-container']}>
      <div className={classes['property-image']}>
        {/* <div style={{ backgroundImage: `url(${image})`}}/> */}
        {
          !props.unpublished && isSoldOrLeased ?
            <div className={clsx({
              [classes['status-label']]: true,
              [classes['sold']]: status === 'SOLD',
              [classes['leased']]: status === 'LEASED',
            })} >
              {propertyStockStatusOptions?.[status ?? ''] ?? status}
            </div>
            : null
        }
        {
          props.unpublished ? <div className={clsx({
            [classes['status-label']]: true,
            [classes['unpublished']]: props.unpublished,
          })} style={{ fontSize: langProperty.metaAppUnpublishedLabelFontSize }}>
            {langProperty.captionUnpublished}
          </div> : null
        }
        <ImageBg src={image} overrideClassName={classes['image']} />
      </div>
      <div className={classes['property-description']}>
        <div>
          <div className={classes['header']}>
            <div>
              {street?.trim().length ? <div className={classes['label']}>{street}</div> : null}
              <div className={classes['label']}>{buildingName} {level}</div>
            </div>
            <div className={classes['hotpick-bookmark']}>
              {isHotpick && <div className={classes['hotpick']}>{langProperty.captionHotpick}</div>}
              {!isSoldOrLeased ? <img className={classes['bookmark']}
                src={bookmarked ? "images/bookmark-active.png" : "images/bookmark-inactive.png"}
                onClick={(evt) => {
                  evt.stopPropagation();
                  onBookmarkClick?.();
                }}
              /> : null
              }
            </div>
          </div>
          <div className={classes['area']}>
            {netDisplay()}
            {
              !!(net) && !!(gross) &&
              <div className={classes['divider']}>/</div>
            }
            {grossDisplay()}
          </div>
        </div>
        <div className={classes['district-price-rent']}>
          <div className={classes['district']}>{district}</div>
          <div className={classes['price-rent']}>
            {priceSection()}
            {rentSection()}
          </div>
        </div>
      </div>
      {/* <div className={classes['property-description']}>
        <div className={classes['general']}>
          <div className={classes['label-area']}>
            <div className={classes['label']}>{label}</div>
            <div className={classes['area']}>
              {netDisplay()}
              {
               !!(net) && !!(gross) && 
                <div className={classes['divider']}>/</div>
              }
              {grossDisplay()}
            </div>
          </div>
          <div className={classes['district']}>{district}</div>
        </div>
        <div className={classes['other']}>
          <div className={classes['hotpick-bookmark']}>
            { isHotpick && <div className={classes['hotpick']}>{langProperty.captionHotpick}</div> }
            { !isSoldOrLeased ? <img className={classes['bookmark']}
                src={bookmarked ? "images/bookmark-active.png" : "images/bookmark-inactive.png"}
                onClick={(evt) => {
                  evt.stopPropagation();
                  onBookmarkClick?.();
                }}
              /> : null
            }
          </div>
          <div className={classes['price-rent']}>
            {rentSection()}
				    {priceSection()}
          </div>
        </div>
      </div> */}
    </div>

  </div>
}