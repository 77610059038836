import clsx from 'clsx';
import 'common/general-imports';
import useLocale from 'common/hooks/useLocale';
import Image from 'components/data-display/Image';
import React, { useMemo, useState } from 'react';
import { AgentCardSmallProps } from './AgentCardSmall';
import classes from './AgentCardSmallDesktop.module.scss';
import AgentDistrictDialog from './AgentDistrictDialog';



export interface AgentCardSmallDesktopProps extends AgentCardSmallProps {

}

export default function AgentCardSmallDesktop(props: AgentCardSmallDesktopProps) {
	const { id, chineseName, englishName, image, districts, fullWidth, noShadow, onClick } = props;

	const regionBundles = useLocale();

	const allDistrictOptions = useMemo(() => {
		return {
		  ...regionBundles.districtHkiOptions,
		  ...regionBundles.districtKltOptions,
		  ...regionBundles.districtNtOptions,
		};
	  }, [ regionBundles ]);

	const districtDisplay = useMemo(() => {
		return districts?.map(d => allDistrictOptions[d] ?? d)?.join(", ");
	}, [districts, allDistrictOptions]);

	const [showAllDistricts, setShowAllDistricts] = useState(false);
	const handleShowAllDistrictsClose = () => {
		setShowAllDistricts(false);
	}

	const handleDistrictOnClick = () => {
		setShowAllDistricts(true);
	}

	return <div id={`agent-card-small-${id}`} className={clsx([
		classes['agent-card'],
		fullWidth ? classes['full-width'] : classes['fixed-size'],
		noShadow ? undefined : classes['shadow'],
	])}>
		<div onClick={onClick} style={{ width: '100%', cursor: 'pointer' }}><Image src={image} altImage="images/user-icon.svg" ratio={1} style={{ width: '100%' }}/></div>
		<div className={clsx([classes['agent-name'], classes['top-line']])} onClick={onClick}><b>{englishName}</b> <b>{chineseName}</b></div>
		{/* <div className={classes['agent-name']}></div> */}
		<AgentDistrictDialog allDistricts={districtDisplay ?? ""} open={showAllDistricts} onHide={handleShowAllDistrictsClose} />
		{districtDisplay && districtDisplay.trim().length && <div className={classes['agent-district']} onClick={handleDistrictOnClick}><b>{districtDisplay}</b></div>}
	</div>
}
   