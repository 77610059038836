import clsx from 'clsx';
import 'common/general-imports';
import React, { useRef, useState } from 'react';
import SwipeableViews from 'react-swipeable-views';
import ArrowIcon from './ArrowIcon';
import classes from './HorizontalList.module.scss';



export interface HorizontalListProps {
  items: JSX.Element[];
  arrowColor: 'dark' | 'light';
}

const springConfig = {
  duration: '.4s',
  easeFunction: 'cubic-bezier(0.15, 0.3, 0.25, 1)',
  delay: '.0s',
};

export default function HorizontalList(props: HorizontalListProps) {
  const rootRef = useRef<HTMLDivElement | null>(null);

  const [ pointerEvents, setPointerEvents ] = useState<any>(undefined);
  const [ index, setIndex ] = useState(0);
  const swipeViewRef = useRef<HTMLDivElement & SwipeableViews>(null);

  const [ shouldEnableTransition, setShouldEnableTransition ] = useState(true);
  // useLayoutEffect(() => {
  //   if (pointerEvents === undefined) {
  //     setTimeout(() => {
  //       setShouldEnableTransition(true);
  //     }, 160);
  //   } else {
  //     setTimeout(() => {
  //       setShouldEnableTransition(false);
  //     }, 160);
  //   }
  // }, [ pointerEvents, setShouldEnableTransition ]);

  return <div ref={rootRef} className={clsx(shouldEnableTransition && classes['swiping'], classes['root'])} style={{ width: '100%', position: 'relative' }}>
    <SwipeableViews axis="x"
      ref={swipeViewRef}
      springConfig={springConfig} resistance enableMouseEvents
      onSwitching={(idx, type) => setPointerEvents(type === 'move' ? 'none' : undefined)}
      index={index}
      onChangeIndex={(idx) => setIndex(idx)}
      containerStyle={{
        // transition: 'transform 0.35s cubic-bezier(0.15, 0.3, 0.25, 1) 0s',
        transitionProperty: 'transform',
        transitionDuration: '0.4s',
        transitionTimingFunction: 'cubic-bezier(0.15, 0.3, 0.25, 1)',
      }}
    >
      {props.items.map(item => <div key={item.key ?? undefined} style={{ width: '100%', pointerEvents: pointerEvents, padding: "24px" }}>{item}</div>)}
    </SwipeableViews>

    {index !== 0 ? <div className={classes['icon-outer']} style={{ position: 'absolute', height: '100%',
      display: 'flex', justifyContent: 'center', zIndex: 10,
      top: 0, left: 0, marginLeft: '0.4rem',
    }} onClick={() => setIndex(index - 1)}>
      <div className={classes['icon-btn']} onClick={() => setIndex(index + 1)}>
        <ArrowIcon color={'var(--pas-light-orange)'} direction="left" size={20} />
      </div>
    </div> : null}

    {index !== props.items.length - 1 ? <div className={classes['icon-outer']} style={{ position: 'absolute', height: '100%',
      display: 'flex', justifyContent: 'center', zIndex: 10,
      top: 0, right: 0, marginRight: '0.4rem',
    }} >
      <div className={classes['icon-btn']} onClick={() => setIndex(index + 1)}>
        <ArrowIcon color={'var(--pas-light-orange)'} direction="right" size={20} />
      </div>
    </div> : null}
  </div>
}