import { ApiError, ApiPageResult, ApiResult } from 'api';
import { notificationApi } from 'api/notificationApi';
import CANotificationDTO from 'common/dto/CANotificationDTO';
import { isMobileUI } from 'common/utils/utils';
import { IRootState } from 'reducers';
import { notificationActions, NotificationState } from 'reducers/notification';
import { call, delay, put, select, takeLeading } from "redux-saga/effects";
import { PASSagaContext } from 'sagas';
import { ActionType, getType } from "typesafe-actions";
import { apiTaskWrapper } from './saga-commons';

export function* watchNotificationListFetched(context: PASSagaContext) {
	yield takeLeading(
		getType(notificationActions.fetch),
		apiTaskWrapper(fetchNotificationList, { noMask: !isMobileUI() }),
		context
	);
}

export function* fetchNotificationList(_context: PASSagaContext, action: ActionType<typeof notificationActions['fetch']>) {
	const { isSwitchingPage } = action.payload;

	const criteria: NotificationState['criteria'] = yield select((state: IRootState) => state.notification.criteria);
	const { token } = yield select((state: IRootState) => state.login);

  const { data, error }: ApiPageResult<CANotificationDTO> = yield call(notificationApi.getList,
    { ...criteria, page: isSwitchingPage ? criteria.page + 1 : 0 },
    token
  );

  if (error) {
    throw ApiError.of(error!);
  }

	const notifications = data!.content;

  if (isSwitchingPage) {
    yield put(notificationActions.updateCriteria({ page: criteria.page + 1 }));
    yield put(notificationActions.appendNextPage({ notifications, hasMore: notifications.length > 0 }));
  } else {
    yield put(notificationActions.updateCriteria({ page: 0 }));
    yield put(notificationActions.update({ notifications, hasMore: notifications.length > 0 }));
  }
}

export function* watchNotificationReadRequested(context: PASSagaContext) {
	yield takeLeading(
		getType(notificationActions.read),
		apiTaskWrapper(readNotification),
		context
	);
}

export function* readNotification(_context: PASSagaContext, action: ActionType<typeof notificationActions['read']>) {
	const { token } = yield select((state: IRootState) => state.login);

  const { data, error }: ApiResult<number> = yield call(notificationApi.read,
    action.payload.id,
    token
  );

  if (error) {
    throw ApiError.of(error!);
  } 
}


export function* watchNotificationReceiveEvent(context: PASSagaContext) {
	yield takeLeading(
		getType(notificationActions._onNotificationReceiveEvent),
		apiTaskWrapper(onNotificationReceiveEvent),
		context
	);
}

export function* onNotificationReceiveEvent(_: PASSagaContext) {
  const { token, loggedIn } = yield select((state: IRootState) => state.login);

  if (loggedIn && token) {
    const { data } : ApiResult<number> = yield call(notificationApi.getTotalCount, token);

    yield put(notificationActions.updateTotalCount({ totalCount: data! }));
  }
}

export function* notificationPolling() {
  while(true) {
    const { token, loggedIn } = yield select((state: IRootState) => state.login);

    if (loggedIn && token) {
      const { data, error } : ApiResult<number> = yield call(notificationApi.getTotalCount, token);

      yield put(notificationActions.updateTotalCount({ totalCount: data! }));
    }
    yield delay(1000 * 100);
    
  }
}

export function* watchConfirmClientClaimProcess(context: PASSagaContext) {
	yield takeLeading(
		getType(notificationActions.confirmClientClaimProcess),
		apiTaskWrapper(confirmClientClaimProcess),
		context
	);
}

export function* confirmClientClaimProcess(_context: PASSagaContext, action: ActionType<typeof notificationActions['confirmClientClaimProcess']>) {
  const { token } = yield select((state: IRootState) => state.login);
  const { nid, cid } = action.payload;

  const { error: error }: ApiResult<number> = yield call(notificationApi.confirmClientClaimProcess,
    cid,
    token
  );

  if (error) {
    throw ApiError.of(error!);
  } 

  yield put(notificationActions.read({ id: nid }));
}

export default [ 
  watchNotificationListFetched,
  watchNotificationReadRequested,
  notificationPolling,
  watchConfirmClientClaimProcess,
  watchNotificationReceiveEvent,
];