import CAPropertyStockListItemDTO from 'common/dto/CAPropertyStockListItemDTO';
import CAPropertyStockMapItemDTO from 'common/dto/CAPropertyStockMapItemDTO';
import { ActionType, createAction, createReducer } from 'typesafe-actions';
import { propertyListActions, PropertyListActions } from './property-list';

export interface PropertyMapState {
  points: CAPropertyStockMapItemDTO[];

  caPropertyStockIdList?: number[];
  filtered: CAPropertyStockListItemDTO[];
  filteredLoading: boolean;
  filteredHasMore: boolean;

  page: number;
}

export const propertyMapActions = {
  fetchPoints: createAction('PropertyMap.PointsFetchRequested')(),
  fetchFiltered: createAction('PropertyMap.FilteredFetchRequested')<{ pidList: number[], isSwitchingPage: boolean }>(),

  pointsUpdated: createAction('PropertyMap.PointsUpdated')<{ points: CAPropertyStockMapItemDTO[] }>(),
  filteredUpdated: createAction('PropertyMap.FilteredUpdated')<{ filtered: CAPropertyStockListItemDTO[], hasMore: boolean, append: boolean, }>(),
};

export type PropertyMapActions = ActionType<typeof propertyMapActions>;

const initial: PropertyMapState = {
  points: [],
  filtered: [],
  filteredLoading: false,
  filteredHasMore: false,
  page: 0,
};

export const propertyMapReducer = createReducer<PropertyMapState, PropertyMapActions | PropertyListActions>(initial)
  .handleAction(propertyMapActions.filteredUpdated, (state, { payload }) => ({
    ...state, filtered: payload.append ?
      [ ...state.filtered, ...payload.filtered ] : payload.filtered,
    filteredLoading: false, filteredHasMore: payload.hasMore,
    page: payload.append ? state.page + 1 : 0,
  }))
  .handleAction(propertyMapActions.pointsUpdated, (state, { payload }) => ({
    ...state, points: payload.points,
  }))
  .handleAction(propertyListActions.addBookmark, (state, { payload }) => ({
    ...state, filtered: state.filtered.map(p => p.caPropertyStockId === payload.caPropertyStockId ?
      { ...p, isBookmarked: true } : p,
    )
  }))
  .handleAction(propertyListActions.deleteBookmark, (state, { payload }) => ({
    ...state, filtered: state.filtered.map(p => p.caPropertyStockId === payload.caPropertyStockId ?
      { ...p, isBookmarked: false } : p,
    )
  }))
  .handleAction(propertyMapActions.fetchFiltered, (state, { payload }) => ({
    ...state,
    caPropertyStockIdList: payload.isSwitchingPage ? state.caPropertyStockIdList : payload.pidList,
    filteredLoading: !payload.isSwitchingPage,
  }))
;