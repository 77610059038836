import useLocale from 'common/hooks/useLocale';
import MainLayoutMobile from 'components/scaffold/MainLayoutMobile';
import React from 'react';
import InfoScreenMobile from './InfoScreenMobile';
import { TCScreenViewProps } from './TCScreenViewProps';
import TermsAndConditions from './TermsAndConditions';

export default function TCScreenMobile(props: TCScreenViewProps) {
  const { layoutProps, tcProps, onBackClick } = props;

  const { langSetting } = useLocale();

  return <MainLayoutMobile {...layoutProps}>
    <InfoScreenMobile title={langSetting.captionTermsAndConditions}
      children={{
        tc: <TermsAndConditions 
          items={tcProps}
        />
      }}
      onBackClick={onBackClick}
    />
  </MainLayoutMobile>
}