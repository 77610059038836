import 'common/general-imports';
import React from 'react';

// import classes from './ArrowIcon.module.scss';

export interface ArrowIconProps {
  color: 'dark' | 'light' | string;
  direction: 'left' | 'right';
  size?: number;
}

export default function ArrowIcon(props: ArrowIconProps) {
  const color = ({
    'dark': 'var(--dark)',
    'light': 'var(--white)',
  } as any)[props.color] ?? props.color;
  return <>
    {/* {{
      'left': <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 34.65 36.06"><defs></defs><rect fill={color} x="1.06" y="17.02" width="33.58" height="1.5"/><polygon fill={color} points="18.03 36.06 0 18.03 18.03 0 19.09 1.06 2.12 18.03 19.09 35 18.03 36.06"/></svg>,
      'right': <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 34.65 36.06"><defs></defs><rect fill={color} y="17.02" width="33.58" height="1.5"/><polygon fill={color} points="16.61 36.06 15.55 35 32.52 18.03 15.55 1.06 16.61 0 34.65 18.03 16.61 36.06"/></svg>
    }[props.direction]} */}
    {{
      'left': <i style={{ color, fontSize: `${props.size}px` }} className="fas fa-chevron-left" />,
      'right': <i style={{ color, fontSize: `${props.size}px` }} className="fas fa-chevron-right" />
    }[props.direction]}
  </>;
}