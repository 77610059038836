import 'bootstrap-slider';
import 'bootstrap-slider/dist/css/bootstrap-slider.min.css';
import $ from 'jquery';
import React, { useLayoutEffect, useRef } from 'react';
import { FormBinder } from './IFormBinder';
import './RangeInput.css';


export interface RangeInputProps {
  bind: FormBinder<number[]> | undefined;
  min: number;
  max: number;
  unlimited?: boolean;
  step: number;
  /** List of available values, overrides unlimited & min & max & step */
  valueMapping?: number[];
}

const RANGE_INPUT_DEBUG = false;

const debug = (...args: any[]) => {
  RANGE_INPUT_DEBUG && console.debug('RangeInput', ...args);
}

const spy = function spy<T>(msg: string, val: T) {
  RANGE_INPUT_DEBUG && debug(msg, val);
  return val;
}


function b2v(bindVal: number[], minDisplay: number, maxDisplay: number, unlimited: boolean | undefined, valueMapping: number[] | undefined) {
  debug('b2v', 'bindVal', bindVal, 'minDisplay', minDisplay, 'maxDisplay', maxDisplay, 'unlimited', unlimited);
  if (valueMapping) {
    return bindVal.map(b => valueMapping.findIndex(val => val === b));
  }
  if (!unlimited) return bindVal;
  return spy('new view val', [
    bindVal[0] === 0 ? minDisplay : bindVal[0],
    bindVal[1] === Infinity ? maxDisplay : bindVal[1],
  ]);
}

function v2b(viewVal: number[], minDisplay: number, maxDisplay: number, unlimited: boolean | undefined, valueMapping: number[] | undefined) {
  debug('v2b', 'viewVal', viewVal, 'minDisplay', minDisplay, 'maxDisplay', maxDisplay, 'unlimited', unlimited);
  if (valueMapping) {
    return viewVal.map(v => valueMapping[v]);
  }
  if (!unlimited) return viewVal;
  return spy('new bind val', [
    viewVal[0] === minDisplay ? 0 : viewVal[0],
    viewVal[1] === maxDisplay ? Infinity : viewVal[1],
  ]);
}

export default function RangeInput(props: RangeInputProps) {
  const { min, max, bind, step, unlimited, valueMapping } = props;
  const inputRef = useRef<HTMLInputElement>(null);

  useLayoutEffect(() => {
    if (!inputRef.current) {
      return;
    }

    const input = inputRef.current;

    let maxDisplay = unlimited ? max + step : max;
    let minDisplay = unlimited ? min - step : min;

    if (valueMapping) {
      maxDisplay = valueMapping.length - 1;
      minDisplay = 0;

    }

    const slider: Slider = $(input).bootstrapSlider({
      min: minDisplay, max: maxDisplay,
      value: bind?.value ? b2v(bind?.value, minDisplay, maxDisplay, unlimited, valueMapping) : (
        valueMapping ? [ Math.min(...valueMapping), Math.max(...valueMapping) ] : [ min, max ]
      ),
      tooltip: 'hide',
      step: valueMapping ? 1 : step,
    }).data('bootstrapSlider');

    const currentValue = slider.getValue() as unknown as number[];
    if (bind?.value !== undefined && currentValue.some((n, i) => n !== bind?.value![i])) {
      slider.setValue(b2v(bind?.value, minDisplay, maxDisplay, unlimited, valueMapping) as any);
    }

    const onSlide = () => {
      const updatedVal = slider.getValue() as unknown as number[];
      bind?.change(v2b(updatedVal, minDisplay, maxDisplay, unlimited, valueMapping));
    };

    $(input).on('change', onSlide);

    return () => {
      $(input).off('change');
    };
  }, [ min, max, step, bind, unlimited, valueMapping ]);

  // useLayoutEffect(() => {
  //   if (!inputRef.current) {
  //     return;
  //   }

  //   const maxDisplay = unlimited ? max + step : max;
  //   const minDisplay = unlimited ? min - step : min;

  //   const slider: Slider = $(inputRef.current).bootstrapSlider({
  //     min: minDisplay, max: maxDisplay,
  //     value: bind?.value ? b2v(bind?.value, minDisplay, maxDisplay, unlimited) : [ min, max ],
  //     tooltip: 'hide',
  //     step,
  //   }).data('bootstrapSlider');

  //   return () => {
  //     slider.destroy();
  //   };
  // }, [ min, max, step, unlimited ]);

  return <div className={'range-input'}>
    <input
      ref={inputRef}
      type="text"
    />
  </div>;
}