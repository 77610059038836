import { ApiPageResult, ApiResult, BASE_URL } from 'api';
import CACustomerDTO from 'common/dto/CACustomerDTO';
import CACustomerSavedSearchDTO from 'common/dto/CACustomerSavedSearchDTO';
import CAPropertyStockListItemDTO from 'common/dto/CAPropertyStockListItemDTO';
import { objectToQuery } from 'common/utils/utils';

export const customerApi = {

  get: async (token: string): Promise<ApiPageResult<CACustomerDTO>> => {
    try {
      const response = await fetch(`${BASE_URL}/ca/customer/current`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        }
      });
      return await response.json();
    } catch (e) {
      return { error: String(e) };
    }
  },

  update: async (customerDetail: CACustomerDTO, token: string): Promise<ApiResult<boolean>> => {
    try {
      const response = await fetch(`${BASE_URL}/ca/customer/current`, {
        method: 'POST', body: JSON.stringify(customerDetail),
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},
      });
      return await response.json();
    } catch (e) {
      return { error: String(e) };
    }
  },

  changePassword: async(oldPassword: string, newPassword: string, token: string): Promise<ApiResult<boolean>> => {
    const dto = { oldPassword, newPassword };
    try {
      const response = await fetch(`${BASE_URL}/ca/customer/current/change-password`, {
        method: 'POST', body: JSON.stringify(dto),
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
      });
      return await response.json();
    } catch (e) {
      return { error: String(e) };
    }
  },

  addBookmark: async (propertyStockId: number, token: string) : Promise<ApiResult<boolean>> => {
    try {
      const response = await fetch(`${BASE_URL}/ca/customer/current/bookmarks/${propertyStockId}`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        // body: JSON.stringify(criteria),
      });
      return await response.json();
    } catch (e) {
      return { error: String(e) };
    }
  },

  deleteBookmark: async (propertyStockId: number, token: string) : Promise<ApiResult<boolean>> => {
    try {
      const response = await fetch(`${BASE_URL}/ca/customer/current/bookmarks/${propertyStockId}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        // body: JSON.stringify(criteria),
      });
      return await response.json();
    } catch (e) {
      return { error: String(e) };
    }
  },

  getBookmarks: async (criteria: object, token: string): Promise<ApiPageResult<CAPropertyStockListItemDTO>> => {
    try {
      const response = await fetch(`${BASE_URL}/ca/customer/current/bookmarks?${objectToQuery(criteria)}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        // body: JSON.stringify(criteria),
      });
      return await response.json();
    } catch (e) {
      return { error: String(e) };
    }
  },

  visitBookmark: async (propertyStockId: number, token: string): Promise<ApiResult<boolean>> => {
    try {
      const response = await fetch(`${BASE_URL}/ca/customer/current/bookmarks/${propertyStockId}/visit`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        // body: JSON.stringify(criteria),
      });
      return await response.json();
    } catch (e) {
      return { error: String(e) };
    }
  },

  getSavedSearchCriteria: async (token: string): Promise<ApiResult<CACustomerSavedSearchDTO[]>> => {
    try {
      const response = await fetch(`${BASE_URL}/ca/customer/current/savedSearchCriteria`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      return await response.json();
    } catch (e) {
      return { error: String(e) };
    }
  },

  saveSearchCriteria: async (title: string, criteria: object, token: string): Promise<ApiResult<boolean>> => {
    try {
      const response = await fetch(`${BASE_URL}/ca/customer/current/savedSearchCriteria`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ ...criteria, title }),
      });
      return await response.json();
    } catch (e) {
      return { error: String(e) };
    }
  },

  switchCustomerLocale: async (locale: string, token: string): Promise<ApiResult<boolean>> => {
    try {
      const response = await fetch(`${BASE_URL}/ca/customer/current/locale?locale=${locale}`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      return await response.json();
    } catch (e) {
      return { error: String(e) };
    }
  },

  deleteSavedSearchCriteria: async (criteriaId: number, token: string): Promise<ApiResult<boolean>> => {
    try {
      const response = await fetch(`${BASE_URL}/ca/customer/current/savedSearchCriteria/${criteriaId}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      return await response.json();
    } catch (e) {
      return { error: String(e) };
    }
  },
};