import clsx from 'clsx';
import 'common/general-imports';
import useLocale from 'common/hooks/useLocale';
import breakpoints from 'common/theme/breakpoints.module.scss';
import { handlePriceDisplay, handlePriceDisplayUnit, isMobileDevice, isMobileUI, isNonEmpty, numberWithCommas } from 'common/utils/utils';
import ModalDialog from 'components/overlay/ModalDialog';
import { toggleDirection } from 'components/property/search/PropertySearchResultScreenMobile';
import $ from 'jquery';
import React, { useRef, useState } from 'react';
import Button from 'react-bootstrap/esm/Button';
import Overlay from 'react-bootstrap/esm/Overlay';
import Popover from 'react-bootstrap/esm/Popover';
import { useMediaQuery } from 'react-responsive';
import TransactionListItem from './TransactionListItem';
import classes from './TransactionTable.module.scss';




interface _SortingCommons {
  sorting: string;
  direction: 'DESC' | 'ASC';
  onSortSelect?: (sorting: string, direction: 'DESC' | 'ASC') => any;
}

export interface TransactionTableProps extends _SortingCommons {
  transactions: Array<{
    address: string;
    date: string;
    gross: number;
    net: number;
    /** Note: Full price, not price display */
    price: number;
    pricePerNet: number;
    pricePerGross: number;
  }>;
}

const tableDirectionSym = (currentSort: string, expectedSort: string, direction: 'DESC' | 'ASC') => {
  if (currentSort !== expectedSort) {
    return null;
  }
  return direction === 'DESC' ? <>&#9660;</> : <>&#9650;</>;
}

export default function TransactionTable(props: TransactionTableProps) {
  const { locale, lang: langCommon, langProperty, } = useLocale();
  const columnHeader = (expectedSort: string, display: string) => {
    return <th className={clsx(!!props.onSortSelect && classes['clickable'])} scope="col" onClick={() => {
      const newDirection = props.sorting === expectedSort ? toggleDirection(props.direction) : props.direction;
      props.onSortSelect?.(expectedSort, newDirection);
    }}>
      {display}
      {tableDirectionSym(props.sorting, expectedSort, props.direction)}
    </th>
  };

  return <div className={classes['root']}>
    <div className="table-responsive">
      <table className="table">
        <thead>
          <tr>

            <th scope="col">{langProperty.captionAddress}</th>

            {columnHeader('transactionDate', langProperty.captionTransactionDate)}
            {columnHeader('price', langProperty.captionPrice)}
            {columnHeader('property.net', langProperty.captionArea)}
            {columnHeader('pricePerNet', langProperty.captionPricePerSize)}
          </tr>
        </thead>
        <tbody>
          {
            props.transactions.map((transaction, i) => {
              const grossDisplay = () => {
                const grossDisplay = !(transaction.gross) ? '' : (langProperty.grossAbbr + String(transaction.gross) + langCommon.uFt);

                return isNonEmpty(grossDisplay) ? <span className={classes['gross']}>{grossDisplay}</span> : null;
              }

              const netDisplay = () => {
                const netDisplay = !(transaction.net) ? '' : (langProperty.netAbbr + String(transaction.net) + langCommon.uFt);

                return isNonEmpty(netDisplay) ? <span className={classes['net']}>{netDisplay}</span> : null;
              }

              const pricePerNetDisplay = () => {
                const display = !(transaction.pricePerNet) ? '' : (langProperty.netAbbr + langCommon.uCurr + String(numberWithCommas(Math.floor(transaction.pricePerNet))));

                return isNonEmpty(display) ? <span className={classes['price-per-net']}>{display}</span> : null;
              };

              const pricePerGrossDisplay = () => {
                const display = !(transaction.pricePerGross) ? '' : (langProperty.grossAbbr + langCommon.uCurr + String(numberWithCommas(Math.floor(transaction.pricePerGross))));

                return isNonEmpty(display) ? <span className={classes['price-per-gross']}>{display}</span> : null;
              };

              return <tr key={i}>
                <td className={classes['address']}>{transaction.address}</td>
                <th className={classes['date']}>{transaction.date}</th>
                {/* <td className={classes['floor-plan']}>
                  <i className="fas fa-puzzle-piece" />
                </td> */}
                <td className={classes['price']}>
                  {langCommon.uCurr + numberWithCommas(handlePriceDisplay(transaction.price ?? 0, locale)) + handlePriceDisplayUnit(transaction.price, locale, langCommon)}
                </td>
                <td className={classes['area']}>
                  {netDisplay()}
                  {transaction.gross && transaction.net ? <>&nbsp;/&nbsp;</> : null}
                  {grossDisplay()}
                </td>
                <td className={classes['price-per-area']}>
                  {pricePerNetDisplay()}
                  {transaction.gross && transaction.net ? <>&nbsp;/&nbsp;</> : null}
                  {pricePerGrossDisplay()}
                </td>
              </tr>
            })
          }
        </tbody>
      </table>
    </div>
  </div>
}

export interface TransactionFullListProps extends _SortingCommons {
  transactions: TransactionTableProps['transactions'];
  currentPage: number;
  totalPages: number;
  onNext?: AnyFn;
  onPrev?: AnyFn;
}

export interface TransactionTableDialogProps extends TransactionFullListProps {
  show: boolean;
  onHide?: AnyFn;
}

export function TransactionTableDialog(props: TransactionTableDialogProps) {
  const isSmallScreen = useMediaQuery({ maxWidth: breakpoints.md });
  const [sortingOpen, setSortingOpen] = useState(false);
  const sortingBtnRef = useRef<HTMLButtonElement | null>(null);
  const { langProperty, lang, locale } = useLocale();

  const sortingItem = (display: string, sort: any) => {
    return <div key={String(sort)} className={classes['sorting-option']} onClick={() => {
      const newDirection = props.sorting === sort ? toggleDirection(props.direction) : props.direction;
      props.onSortSelect?.(sort, newDirection);
      setSortingOpen(false);
    }}>
      {props.sorting === sort && props.direction === 'DESC' ? <i className="fas fa-sort-amount-down"></i> : null}
      &nbsp;{display}&nbsp;
      {props.sorting === sort && props.direction === 'ASC' ? <i className="fas fa-sort-amount-up"></i> : null}
    </div>
  };

  const sortingPopover = <Popover id={'Popover-Sorting'}>
    <Popover.Content style={{ padding: '0', minWidth: '8.25rem' }}>
      {sortingItem(langProperty.captionTransactionDate, 'transactionDate')}
      {sortingItem(langProperty.captionPrice, 'price')}
      {sortingItem(langProperty.captionArea, 'property.net')}
      {sortingItem(langProperty.captionPricePerSize, 'pricePerNet')}
    </Popover.Content>
  </Popover>;

  return <ModalDialog hasStyle={isSmallScreen ? {height:"90%"}: {height:"100%"} } overrideClassName={{
    overrideModal: classes['trnx-modal'],
  }} isMobile={isSmallScreen} size="xl" show={props.show} title={langProperty.captionFullTransactions} onHide={props.onHide} closeButtonWhenCustomizedHeader headerChildren={(header) => <div className={classes['trnx-table-header']}>
    {isSmallScreen ? <Button variant={'outline-pas-blue'} ref={sortingBtnRef} onClick={() => setSortingOpen(true)}>{langProperty.captionSorting}</Button> : null}
    {header}
  </div>}>
    <div>
      <Overlay rootClose placement="bottom-start" show={sortingOpen} onHide={() => setSortingOpen(false)} target={sortingBtnRef.current}>{sortingPopover}</Overlay>

      {!isSmallScreen ? <div style={{ padding: '1rem 0 0 0' }}>
        <TransactionTable transactions={props.transactions}
          sorting={props.sorting}
          direction={props.direction}
          onSortSelect={props.onSortSelect}
        />
      </div> : <div className={classes['transaction-list-container']}>
        {props.transactions.map((transaction, i) => <div key={i} className={classes['list-item-outer']}>
          <TransactionListItem {...transaction} />
        </div>)}
      </div>}

      {/* Pagination */}
      <div className={classes['pagination-bar']}>
        <button disabled={props.currentPage === 1} className={clsx('btn btn-text', classes['prev-btn'])} onClick={(ev) => {
          props.onPrev?.();
          ($(ev.target).parents('.pm-modal-dialog')[0] as HTMLDivElement)?.scrollTo(0, 0);
        }}>
          &lt; {lang.captionPrevPage}
        </button>
        <div className={classes['page-number']}>{props.currentPage} / {props.totalPages}</div>
        <button disabled={props.currentPage === props.totalPages} className={clsx('btn btn-text', classes['next-btn'])} onClick={(ev) => {
          props.onNext?.();
          ($(ev.target).parents('.pm-modal-dialog')[0] as HTMLDivElement)?.scrollTo(0, 0);
        }}>
          {lang.captionNextPage} &gt;
        </button>
      </div>
    </div>
  </ModalDialog >
}