import CAAgentRatingCommentDTO from 'common/dto/CAAgentRatingCommentDTO';
import { ActionType, createAction, createReducer } from 'typesafe-actions';

export interface AgentDetailInfo {
  id: number;
  image: string;
  chineseName: string;
  englishName: string;
  maxRating: number;
  rating: number;
  district?: string;
}

export interface AgentDetailState {
  open: boolean;
  comments: CAAgentRatingCommentDTO[];
  hasMore: boolean;
  currentAgent: AgentDetailInfo;
}

export const agentDetailActions = {
  openDialog: createAction('AgentDetail.Open')<AgentDetailInfo>(),
  closeDialog: createAction('AgentDetail.Close')(),
  reset: createAction('AgentDetail.Reset')(),
  doFetchComments: createAction('AgentDetail.CommentsFetchRequested')<{ uid: number }>(), // saga
  updateComments: createAction('AgentDetail.CommentsUpdated')<{ comments: CAAgentRatingCommentDTO[], hasMore: boolean }>(),
};

export type AgentDetailActions = ActionType<typeof agentDetailActions>;

const initialState = (): AgentDetailState => ({
  open: false,
  comments: [],
  hasMore: false,
  currentAgent: {
    id: 0,
    image: '',
    chineseName: '',
    englishName: '',
    rating: 0,
    maxRating: 0,
    district: "",
  },
});


export const agentDetailReducer = createReducer<AgentDetailState, AgentDetailActions>(initialState())
  .handleAction(agentDetailActions.openDialog, (state, action) => 
    ({ ...state, open: true, currentAgent: action.payload })
  )
  .handleAction(agentDetailActions.closeDialog, (state) => 
    ({ ...state, open: false })
  )
  .handleAction(agentDetailActions.updateComments, (state, action) => 
    ({ ...state, comments: action.payload.comments })
  )
  .handleAction(agentDetailActions.reset, (_) => 
    initialState()
  )
;
