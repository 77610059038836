import clsx from 'clsx';
import useLocale from 'common/hooks/useLocale';
import  { multiLang } from 'common/utils/utils'
import { useMobileLayoutHeight } from 'common/hooks/useMobileLayoutHeight';
import breakpoints from 'common/theme/breakpoints.module.scss';
import AgentCardSmall from 'components/display-cards/home/AgentCardSmall';
import AgentCardSmallDesktop from 'components/display-cards/home/AgentCardSmallDestop';
import HomeSectionHeader from 'components/display-cards/home/HomeSectionHeader';
import PropertyCardDesktop from 'components/display-cards/home/PropertyCardDesktop';
import TagPanel from 'components/display-cards/home/TagPanel';
import FormDistrictSelectField from 'components/form-controls/FormDistrictSelectField';
import FormTextField from 'components/form-controls/FormTextField';
import ScrollToTopButton from 'components/misc/ScrollToTopButton';
import HorizontalList from 'components/scaffold/HorizontalList';
import MainLayoutDesktop from 'components/scaffold/MainLayoutDesktop';
// import SwipeableViews from 'react-swipeable-views';
// import { autoPlay } from 'react-swipeable-views-utils';
import { NavigationKeys } from 'components/scaffold/MainLayoutProps';
import VerticalScrollView from 'components/scaffold/VerticalScrollView';
import React, { useState } from 'react';
import Button from 'react-bootstrap/esm/Button';
import Container from 'react-bootstrap/esm/Container';
import Spinner from 'react-bootstrap/esm/Spinner';
import { useMediaQuery } from 'react-responsive';
import AgentCommentItem from './AgentCommentItem';
import classes from './HomeScreenLayoutDesktop.module.scss';
import HomeScreenProps from './HomeScreenProps';
// import classes from './HomeScreenLayoutMobile.module.scss';
import HomeScreenSearchPanel from './HomeScreenSearchPanel';
import PropertyResultListItemDesktop from 'components/property/search/PropertyResultListItemDesktop';
import { Swiper, SwiperSlide } from 'swiper/swiper-react';
import { Pagination } from 'swiper/modules';

import 'swiper/swiper.scss'; // core Swiper
import 'swiper/modules/navigation.scss'; // Navigation module
import 'swiper/modules/pagination.scss'; // Pagination module


// const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

export default function HomeScreenLayoutDesktop(props: HomeScreenProps) {
	const { layoutProps, searchPanelProps, backgroundImage, 
    latestPropertiesForSale, latestPropertiesForLeasing, latestNews, topAgents,
		topAgentComments, tags, onTagClick, getInTouchProps, homeContentLoading,
		onClickMoreSell, onClickMoreLease, onClickMoreNews, onClickMoreAgent,
	} = props;
	
	const { langHome, locale, districtHkiOptions, districtKltOptions, districtNtOptions } = useLocale();

	const districtOptions = { ...districtHkiOptions, ...districtKltOptions, ...districtNtOptions };
	
	const [ ] = useState<NavigationKeys>('/home');

	// const dummyNameBind = useDummyBinder<string>('');
	// const dummyPhoneBind = useDummyBinder<string>('');
	// const dummyEmailBind = useDummyBinder<string>('');
	// const dummyDistrictBind = useDummyBinder<string[]>([]);
	// const dummyMessageBind = useDummyBinder<string>('');

	const isSmallScreen = useMediaQuery({ maxWidth: breakpoints.sm });
	const isMediumScreen = useMediaQuery({ maxWidth: breakpoints.md });

	const largeScreenLayout = () => {
		return <div>
			<Container fluid className={classes['tag-panel-container']}>
				<div className="d-none d-md-block d-lg-block d-xl-block">
					<TagPanel tags={tags} rows={1} onTagClick={onTagClick} />
				</div>

				<div className="d-xs-block d-sm-block d-md-none">
					<TagPanel tags={tags} rows={2} onTagClick={onTagClick} />
				</div>
			</Container>

			
			<div className={classes['section-container']}>
				<div className={classes['header']}>{langHome.titleLatestPropertiesSell}</div>
				<hr className={clsx(classes['divider'], classes['bg-saffron'])}/>
				{/* <HomeSectionHeader title={langHome.titleLatestPropertiesSell} onClickMore={undefined} /> */}
			</div>
			<Container id="sale-property-container" className={classes['property-container']}>
				<div className="row">
					{
						!isSmallScreen ? latestPropertiesForSale.map(
							property => <div className={`${classes['slot']} col-sm-6 col-md-4 col-lg-4 col-xl-4`}>
								{/* <PropertyCardDesktop {...property} fullWidth={true} noShadow /> */}
								<PropertyResultListItemDesktop 
									street={property.street}
									buildingName={property.buildingName}
									level={property.level}
									id={Number(property.id)} 
									propertyNo={property.propertyNo}
									image={property.image} 
									district={districtOptions[property.district]}
									net={property.net}
									gross={property.gross}
									price={property.price}
									rent={0}
									status={'SALE'}
									punchline={property.punchline}
									onClick={property.onClick}
									showBookmark={false}
								/>
							</div>
						) 
						: <HorizontalList arrowColor="dark"
							items={latestPropertiesForSale.map(property => 
								<PropertyResultListItemDesktop 
									key={property.id}
									street={property.street}
									buildingName={property.buildingName}
									level={property.level}
									id={Number(property.id)}
									propertyNo={property.propertyNo} 
									image={property.image} 
									district={districtOptions[property.district]}
									net={property.net}
									gross={property.gross}
									price={property.price}
									rent={0}
									status={'SALE'}
									punchline={property.punchline}
									onClick={property.onClick}
									showBookmark={false}
								/>
							)}
						/>
					}
				</div>
			</Container>
			<Container className={classes['btn-container']}>
				<Button variant="pas-light-orange" 
					className={classes['more-btn']}
					onClick={onClickMoreSell}
				>
					{langHome.actionMoreProperties}
				</Button>
			</Container>
			
				
			<div className={classes['gray-section']}>
				<Container className={classes['section-container']}>
					<div className={classes['header']}>{langHome.titleLatestPropertiesLease}</div>
					<hr className={clsx(classes['divider'], classes['bg-saffron'])}/>
					{/* <HomeSectionHeader title={langHome.titleLatestPropertiesLease} onClickMore={undefined} /> */}
				</Container>
				<Container id="lease-property-container" className={classes['property-container']}>
					<div className="row">
						{
							!isSmallScreen ? latestPropertiesForLeasing.map(property => <div className={`${classes['slot']} col-sm-6 col-md-4 col-lg-4 col-xl-4`}>
								{/* <PropertyCardDesktop {...property} fullWidth={true} noShadow /> */}
								<PropertyResultListItemDesktop 
									street={property.street}
									buildingName={property.buildingName}
									level={property.level}
									id={Number(property.id)} 
									propertyNo={property.propertyNo}
									image={property.image} 
									district={districtOptions[property.district]}
									net={property.net}
									gross={property.gross}
									price={0}
									rent={property.rent}
									status={'RENT'}
									punchline={property.punchline}
									onClick={property.onClick}
									showBookmark={false}
								/>
							</div>) : <HorizontalList arrowColor="dark"
								items={latestPropertiesForLeasing.map(property => 
								<PropertyResultListItemDesktop 
									key={property.id}
									street={property.street}
									buildingName={property.buildingName}
									level={property.level}
									id={Number(property.id)}
									propertyNo={property.propertyNo}
									image={property.image} 
									district={districtOptions[property.district]}
									net={property.net}
									gross={property.gross}
									price={0}
									rent={property.rent}
									status={'RENT'}
									punchline={property.punchline}
									onClick={property.onClick}
									showBookmark={false}
								/>
								)}
							/>
						}
					</div>
				</Container>
				<Container className={classes['btn-container']}>
					<Button variant="pas-light-orange" 
						className={classes['more-btn']}
						onClick={onClickMoreLease}
					>
						{langHome.actionMoreProperties}
					</Button>
				</Container>
			</div>
			{/* <div className={classes['gray-section']}>
				<Container className={classes['section-container']}>
					<div className={classes['header']}>{langHome.titleNews}</div>
					<hr className={clsx(classes['divider'], classes['bg-saffron'])}/>
				</Container>
				<Container className={classes['news-container']}>
					<div className="row">
						{
							latestNews.map(news => <div className={`${classes['slot']} col-sm-6 col-md-4 col-lg-4 col-xl-4`}>
								<NewsCard {...news} fullWidth={true}/>
							</div>)
						}
					</div>
				</Container>
				<Container className={classes['btn-container']}>
					<Button variant="pas-light-orange" 
						className={classes['more-btn']}
						onClick={onClickMoreNews}
					>
						{langHome.actionMoreNews}
					</Button>
				</Container>	
			</div> */}
			
			<Container className={classes['section-container']}>
				<div className={classes['header']}>{langHome.titleTopAgents}</div>
				<hr className={clsx(classes['divider'], classes['bg-saffron'])}/>
				{/* <HomeSectionHeader title={langHome.titleTopAgents} onClickMore={undefined} /> */}
			</Container>
			<Container className={classes['agent-container']}>
				<div className="row">
					{
						!isSmallScreen ? topAgents.slice(0, 4).map(agent => <div className={`${classes['slot']} col-sm-6 col-md-3 col-lg-3 col-xl-3`}>
							<AgentCardSmallDesktop
								{...agent}
								fullWidth
								noShadow
								// id={agent?.id ?? 0}
								// image={agent?.photoFilename ? `${agent.photoFilename}` : ''}
								// name={`${agent?.name ?? ''}`}
								// maxRating={agent?.maxRating ?? 0}
								// rating={agent?.rating ?? 0}
								// comment={agent?.comment}
								// onClickMore={() => doDisplayDetail?.(agent?.id ?? 0)}
								// onClickContact={() => doDisplayContact?.(agent?.id ?? 0)}
								// onClickRating={() => doDisplayRatingForm?.(agent?.id ?? 0)}
							/>
						</div>) : <HorizontalList arrowColor="dark"
								items={topAgents.slice(0, 4).map(agent => <AgentCardSmallDesktop key={agent.id} {...agent} fullWidth noShadow />)}
							/>
					}
				</div>
			</Container>
			<Container className={classes['btn-container']} style={{ paddingBottom: 'calc(110rem / 16' }}>
				<Button variant="pas-light-orange" 
					className={classes['more-btn']}
					onClick={onClickMoreAgent}
				>
					{langHome.actionMoreAgents}
				</Button>
			</Container>	
			
			<div className={classes['agent-comment-section']}>
				<Container className={classes['section-container']}>
					<div className={classes['header']}>{langHome.titleTopAgentComments}</div>
					<hr className={clsx(classes['divider'], classes['bg-saffron'])}/>
					{/* <HomeSectionHeader title={langHome.titleTopAgents} onClickMore={undefined} /> */}
				</Container>
				<Container className={classes['comment-container']}>
					<div className="row" style={{ width: '100%', minHeight: 200 }}>
						<Swiper spaceBetween={30}
							slidesPerView={1}
							pagination={{
								clickable: true,
							}}
							slidesPerGroup={1}
							modules={[Pagination]}
							className={classes['agent-comment-swiper']}
							updateOnWindowResize
							breakpoints={{
								576: {
									slidesPerView: 2,
									slidesPerGroup: 2,
								},
								768: {
									slidesPerView: 3,
									slidesPerGroup: 3,
								},
							}}
						>
							{topAgentComments.map(c =>
								<SwiperSlide>
									<div className={`${classes['slot']} col-sm-6 col-md-4 col-lg-4 col-xl-4`}>
										<AgentCommentItem
											comment={c.comment}
											name={c.name}
											image={c.image}
											smallScreen={isSmallScreen}
										/>
									</div>
								</SwiperSlide>
							)}

							{/*add empty slides for medium screen (group of 2) */}
							{!isSmallScreen && isMediumScreen && (topAgentComments.length % 2 === 1) &&
								<SwiperSlide></SwiperSlide>
							}

							{/*add empty slides for large screen (group of 3) */}
							{!isMediumScreen && !isSmallScreen && (topAgentComments.length % 3 === 1) && <>
								<SwiperSlide></SwiperSlide>
								<SwiperSlide></SwiperSlide>
							</>
							}

							{!isMediumScreen && !isSmallScreen && (topAgentComments.length % 3 === 2) &&
								<SwiperSlide></SwiperSlide>
							}
						</Swiper>
					</div>
				</Container>
			</div>
			
			{/* <div style={{ width: '100%' }}>
					<img src={demoTestimonial} style={{ width: '100%' }} />
			</div>	 */}
			<ScrollToTopButton />
		</div>
	}

	const smallScreenLayout = () => {
		return <div className={classes['home-screen-desktop-small']} style={{ height: 'auto' }}>
			<div className={classes['content']}>
				<div className={classes['scroll-inner']}>

					<div className={classes['tag-panel-container']}>
						{tags ? <TagPanel tags={tags} rows={2} onTagClick={onTagClick} /> : null}
					</div>

					<div className={classes['section-container']}>
						<HomeSectionHeader title={langHome.titleLatestPropertiesSell} onClickMore={onClickMoreSell} />
					</div>
					<VerticalScrollView>
						<div className={classes['card-list']}>
							{latestPropertiesForSale.map(propertyProps => <div key={propertyProps.id} className={classes['item']}>
								{/* <PropertyCardDesktop {...propertyProps} noShadow/> */}
								<PropertyResultListItemDesktop 
									street={propertyProps.street}
									buildingName={propertyProps.buildingName}
									level={propertyProps.level}
									id={Number(propertyProps.id)} 
									propertyNo={propertyProps.propertyNo}
									image={propertyProps.image} 
									district={districtOptions[propertyProps.district]}
									net={propertyProps.net}
									gross={propertyProps.gross}
									price={propertyProps.price}
									rent={0}
									status={'SALE'}
									punchline={propertyProps.punchline}
									onClick={propertyProps.onClick}
									showBookmark={false}
								/>
							</div>)}
						</div>
					</VerticalScrollView>

					<div className={classes['gray-section']}>
						<div className={classes['section-container']}>
							<HomeSectionHeader title={langHome.titleLatestPropertiesLease} onClickMore={onClickMoreLease} />
						</div>
						<VerticalScrollView>
							<div className={classes['card-list']}>
								{latestPropertiesForLeasing.map(propertyProps => <div key={propertyProps.id} className={classes['item']}>
									{/* <PropertyCardDesktop {...propertyProps} noShadow/> */}
									<PropertyResultListItemDesktop 
										street={propertyProps.street}
										buildingName={propertyProps.buildingName}
										level={propertyProps.level}	
										id={Number(propertyProps.id)} 
										propertyNo={propertyProps.propertyNo}
										image={propertyProps.image} 
										district={districtOptions[propertyProps.district]}
										net={propertyProps.net}
										gross={propertyProps.gross}
										price={0}
										rent={propertyProps.rent}
										status={'RENT'}
										punchline={propertyProps.punchline}
										onClick={propertyProps.onClick}
										showBookmark={false}
									/>
								</div>)}
							</div>
						</VerticalScrollView>
					</div>

					{/* <div className={classes['section-container']}>
						<HomeSectionHeader title={langHome.titleNews} onClickMore={onClickMoreNews} />
					</div>
					<VerticalScrollView>
						<div className={classes['card-list']}>
							{latestNews.map(newsProps => <div key={newsProps.id} className={classes['item']}>
								<NewsCard {...newsProps} />
							</div>)}
						</div>
					</VerticalScrollView> */}

					<div className={classes['section-container']}>
						<HomeSectionHeader title={langHome.titleTopAgents} onClickMore={onClickMoreAgent} />
					</div>
					<VerticalScrollView>
						<div className={classes['card-list']}>
							{topAgents.map(agentProps => <div key={agentProps.id} className={classes['item']}>
								<AgentCardSmall {...agentProps} />
							</div>)}
						</div>
					</VerticalScrollView>

					<div className={classes['gray-section']}>
						<div className={classes['section-container']}>
							<HomeSectionHeader title={langHome.titleTopAgentComments} />
						</div>
						<VerticalScrollView>
							<div className={classes['card-list']}>
								{topAgentComments.map((c, i) => <div key={i} className={classes['item']}>
								<AgentCommentItem comment={c.comment} name={c.name} image={c.image} />
								</div>)}
							</div>
						</VerticalScrollView>
					</div>
					
				</div>
			</div>
		</div>
	}

	const height = useMobileLayoutHeight(true); // By including tab height, MobileLayoutHeight will equal screenHeight

	return <MainLayoutDesktop 
		{...layoutProps}
	>
		<div className={classes['home-screen-desktop']}>
			{/* height: `calc(${height} - 4rem)` */}
			<div className={clsx(classes['search-panel-container'])} style={{ backgroundImage: `url("${backgroundImage}")` }}>
				<HomeScreenSearchPanel variant="desktop" {...searchPanelProps} />
			</div>
			{homeContentLoading ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '1rem 0' }}>
				<Spinner animation="grow" variant="pas-blue" /> 
			</div>: null}
			{
				// isSmallScreen ? smallScreenLayout() : largeScreenLayout()
				largeScreenLayout()
			}	
			<div className={classes['get-in-touch-section']} style={{ backgroundImage: `url("images/bg-03-1920x1022.jpg")` }}>
				<Container className={classes['section-container']}>
					<div className={classes['header']} style={{ color: '#FFFFFF'}}>{langHome.titleGetInTouch}</div>
					<hr className={clsx(classes['divider'], classes['bg-saffron'])}/>
				</Container>
				<Container>
					<div className={`row ${classes['form-container']}`}>
						<div className={`col-sm-12 col-md-9 col-lg-5 col-xl-5`}>
							<FormTextField errorMessagePlaceHeld label={langHome.captionName} bind={getInTouchProps?.bindName} overrideClassName={classes['form-input']} />
						</div>
						<div className={`col-sm-12 col-md-9 col-lg-5 col-xl-5`}>
							<FormTextField errorMessagePlaceHeld label={langHome.captionPhoneNumber} bind={getInTouchProps?.bindPhone} overrideClassName={classes['form-input']} />
						</div>
						<div className={`col-sm-12 col-md-9 col-lg-5 col-xl-5`}>
							<FormTextField errorMessagePlaceHeld label={langHome.captionEmail} bind={getInTouchProps?.bindEmail} overrideClassName={classes['form-input']} />
						</div>
						<div className={`col-sm-12 col-md-9 col-lg-5 col-xl-5`}>
							<FormDistrictSelectField errorMessagePlaceHeld label={langHome.captionDistrict} bind={getInTouchProps?.bindDistricts} regionBundles={searchPanelProps?.regionBundles!} overrideClassName={classes['form-input']} />
						</div>
						<div className={`col-sm-12 col-md-9 col-lg-10 col-xl-10`}>
							<FormTextField errorMessagePlaceHeld label={langHome.captionMessage} bind={getInTouchProps?.bindMessage} multiline rows={6} overrideClassName={classes['form-input']} hideClearButton/>
						</div>
					</div>
				</Container>
				<Container className={classes['btn-container']} style={{ paddingTop: '0rem' }}>
					<Button variant="pas-light-orange" 
						className={classes['more-btn']}
						onClick={getInTouchProps?.onSubmitClick}
					>
						{langHome.actionSendMessage}
					</Button>
				</Container>
			</div>
		</div>
	</MainLayoutDesktop>
}