import 'common/general-imports';
import useLocale from 'common/hooks/useLocale';
import { addTouchableHoveringSupport } from 'common/polyfills/touch-polyfill';
import { isMobileUI, isNonEmpty } from 'common/utils/utils';
import { FormBinder } from 'components/form-controls/IFormBinder';
import DistrictSelectDesktopDialog from 'components/overlay/DistrictSelectDesktopDialog';
import React, { useEffect, useMemo, useState } from 'react';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import { useMediaQuery } from 'react-responsive';
import classes from './DistrictSelectDialog.module.scss';
import ModalDialog from './ModalDialog';
import './ModalDialog.css';
import ModalDialogActions, { ModalDialogActionButton } from './ModalDialogActions';
import deepEqual from 'deep-equal';


export interface DistrictSelectDialogProps {

  // delegate to bootstrap
  open?: boolean;
	onHide?: () => void;
	districtHkiOptions: LocaleOptions;
	districtKltOptions: LocaleOptions;
	districtNtOptions: LocaleOptions;
	districts?: FormBinder<string[]>;

	confirmRequired?: boolean;
}

addTouchableHoveringSupport(
	`.${classes['finish-select-action']}, .${classes['district-option']}`,
	classes['touched'],
	classes['no-hover'],
);


export default function DistrictSelectDialog(props: DistrictSelectDialogProps) {
  const { open, onHide, confirmRequired, districtHkiOptions, districtKltOptions, districtNtOptions, districts } = props;
	const { langDistrictSelectDialog, locale, lang } = useLocale();
	const [ districtSelect, setDistrictSelected ] = useState<'HKI' | 'KLN' | 'NT'>('HKI'); 
	const [ districtOptions, setDistrictOptions ] = useState<LocaleOptions>(districtHkiOptions);
	const regionMap = {
		HKI: districtHkiOptions,
		KLN: districtKltOptions,
		NT: districtNtOptions,
		};
	
	const toggleDistrictByRegion = (region: keyof typeof regionMap) => {

	const all = Object.keys(regionMap[region]);
	const current = Array.from(usedDistricts?.value ?? []);
	const selected = current.filter(n => all.indexOf(n) > -1);
	if (selected.length === all.length) {
		// all selected, clear all
		usedDistricts?.change(current.filter(n => selected.indexOf(n) < 0).filter(v => isNonEmpty(v)));
	} else if (selected.length < all.length) {
		current.push(...all.filter(n => selected.indexOf(n) < 0));
		usedDistricts?.change(current.filter(v => isNonEmpty(v)));
	}
};

	// Small Screen Handling
	const isSmallScreen = useMediaQuery({ maxWidth: 576 });
	
	const [ tmpDistricts, setTmpDistricts ] = useState(districts?.value ?? []);
	useEffect(() => {
		if (!deepEqual(tmpDistricts, districts?.value)) {
			setTmpDistricts(districts?.value ?? []);
		}
		// if (tmpDistricts.some((d, i) => districts?.value?.[i] !== d)) {
		// 	setTmpDistricts(districts?.value ?? []);
		// }
	}, [districts, open]);

	const pendingDistricts: FormBinder<string[]> = useMemo(() => ({
		value: tmpDistricts,
		change: (updated) => setTmpDistricts(updated ?? []),
		blur: () => {},
	}), [ tmpDistricts, setTmpDistricts ]);

	const usedDistricts = confirmRequired ? pendingDistricts : districts;

	const changeDistrictOptions = (value: 'HKI' |  'KLN' | 'NT') =>{
		setDistrictSelected(value)
		setDistrictOptions(
			value === 'HKI' ? districtHkiOptions :
			value === 'KLN' ? districtKltOptions :
			value === 'NT' ? districtNtOptions : {}
		)
	}

	useEffect(() => {   // TEMP_FIX: switching the language of district options(locale bundles) requires a re-rendering
		switch(districtSelect) {
			case 'HKI': setDistrictOptions(districtHkiOptions); break;
			case 'KLN': setDistrictOptions(districtKltOptions); break;
			case 'NT': setDistrictOptions(districtNtOptions); break;
			default: break;
		}
	}, [ locale ]);

	return (!isMobileUI() && isSmallScreen || isMobileUI()) ? <ModalDialog size={'sm'} title={langDistrictSelectDialog.captionDistrict} show={open} onHide={onHide}>
		<div className={classes['district-select-dialog-body']}>
			<div className={classes['district-select-dialog-content']}>
				<ButtonGroup className={classes['district-group-btn']}>
					<Button
						size="sm"
						className={classes['district-select-btn']}
						variant={districtSelect === 'HKI' ? "pas-blue" : "outline-pas-gray"}
						onClick={() => { changeDistrictOptions('HKI');}}>{langDistrictSelectDialog.captionHKI}
					</Button>
					<Button
						size="sm"
						className={classes['district-select-btn']}
						variant={districtSelect === 'KLN' ? "pas-blue" : "outline-pas-gray"}
						onClick={() => { changeDistrictOptions('KLN')}}>{langDistrictSelectDialog.captionKLN}
					</Button>
					<Button
						size="sm"
						className={classes['district-select-btn']}
						variant={districtSelect === 'NT' ? "pas-blue" : "outline-pas-gray"}
						onClick={() => { changeDistrictOptions('NT')}}>{langDistrictSelectDialog.captionNT}
					</Button>
				</ButtonGroup>
				<Button size="sm"
						className={classes['district-select-dialog-body']}
						style={{width:"100%"}}
						variant={"pas-blue" }
						onClick={() => toggleDistrictByRegion(districtSelect)}>{langDistrictSelectDialog.selectAll}</Button>
				
				<div className={classes['district-options-wrapper']}>
					{Object.keys(districtOptions)?.map((key: string)=>(
						<div key={key} className={classes['district-option']} 
							onClick={()=>{
								if (usedDistricts?.value?.includes(key)){
									usedDistricts?.change(usedDistricts?.value?.filter(d=> d !== key))
								  }else{
									usedDistricts?.change([...usedDistricts?.value ?? [], key]);
								  }
							}}>
							<span className={classes['district-option-text']}>{districtOptions[key]}</span>
							{usedDistricts?.value?.includes(key) &&
								<i className={"fas fa-check " + classes['tick-color'] }></i> }
						</div>
					))}
					
				</div>
				</div>

				<ModalDialogActions>
					<ModalDialogActionButton onClick={() => {
						if (confirmRequired) {
							setTmpDistricts([]);
						} else {
							districts?.change([]);
						}
					}}>
						{lang.actionReset}
					</ModalDialogActionButton>

					<ModalDialogActionButton onClick={() => {
						if (confirmRequired) {
							districts?.change(tmpDistricts);
						}
						onHide?.();
					}}>{langDistrictSelectDialog.actionOK}</ModalDialogActionButton>
				</ModalDialogActions>
		
		</div>
		
	</ModalDialog> 
	: <DistrictSelectDesktopDialog 
		districtHkiOptions={districtHkiOptions} 
		districtKltOptions={districtKltOptions}
		districtNtOptions={districtNtOptions}
		open={open}
		onHide={onHide}
		districts={districts}
		confirmRequired={confirmRequired}
	/>
	;
} 
