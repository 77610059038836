import CAPropertyStockListItemDTO from 'common/dto/CAPropertyStockListItemDTO';
import { CAPropertyStockSearchObjectCommons } from 'common/dto/CAPropertyStockSearchDTO';
import { SortKey } from 'components/property/search/PropertySearchResultViewProps';
import { ActionType, createAction, createReducer } from 'typesafe-actions';
import { CommonFormState, createFormSpec } from './common/form';

export interface InlinePropertyListState extends CommonFormState<InlinePropertyStockSearchForm> {
  /** Current fetched properties */
  properties: CAPropertyStockListItemDTO[];
  /** Whether having more properties to fetch (mobile only) */
  hasMore: boolean;
  totalCount: number;
}

export const inlinePropertySearchFormSpec = createFormSpec(
  'InlinePropertyList.CriteriaUpdated',
  'InlinePropertyList.CriteriaErrorAdded'
)<InlinePropertyStockSearchForm>(
  () => [] // no validation required
);

export interface InlinePropertyStockSearchForm extends CAPropertyStockSearchObjectCommons {
  priceDisplay: number[];
  roomEnabled: boolean;
  suiteEnabled: boolean;
  bathroomEnabled: boolean;
  balconyEnabled: boolean;
  helperRoomEnabled: boolean;
  sortKey: SortKey,
  direction: 'DESC' | 'ASC';
}

const inlinePropertyListInitialState = (): InlinePropertyListState => ({
  ...inlinePropertySearchFormSpec.initialState,
  properties: [],
  hasMore: true,
  totalCount: 0,
  contents: {
    limit: 20,
    page: 0,
    sortKey: null,
    direction: 'DESC',
    
    status: [],
    freeTextSearch: '', // Free Text Search Keyword
    buyOrRent: undefined, // BUY or RENT (CACustomerSearch.BuyOrRentOptions)
    district: [], // Districts (Multi-value)
    usage: [], // Usages (Multi-value)
    
    // gross: [ 0, Infinity ],
    net: [ 0, Infinity ],
    priceDisplay: [ 0, Infinity ],
    rent: [ 0, Infinity ],
    
    room: 0,
    suite: 0,
    bathroom: 0,
    balcony: 0,
    hasHelperRoom: false,
    
    facing: [],
    deco: [],
    view: [],
    otherFeatures: [],
    primarySchoolNet: [],
    secondarySchoolNet: [],
    
    saveSearchCriteria: false,
    
    //-------- for keyword search--------//
    clubHouseFacilities: [],
    others: [],
  },
});

export const inlinePropertyListActions = {
  ...inlinePropertySearchFormSpec.actions,
  reset: createAction('InlinePropertyList.Reset')(),
  fetch: createAction('InlinePropertyList.FetchRequested')<{ isSwitchingPage: boolean }>(),
  update: createAction('InlinePropertyList.Updated')<{ properties: CAPropertyStockListItemDTO[], hasMore: boolean }>(),
  appendNextPage: createAction('InlinePropertyList.AppendNextPage')<{ properties: CAPropertyStockListItemDTO[], hasMore: boolean }>(),
  // updateCriteria: createAction('InlinePropertyList.CriteriaUpdated')<Partial<InlinePropertyListState['contents']>>(),
  addBookmark: createAction('InlinePropertyList.AddBookmark')<{ caPropertyStockId: number }>(),
  deleteBookmark: createAction('InlinePropertyList.DeleteBookmark')<{ caPropertyStockId: number }>(),
};

export type InlinePropertyListActions = ActionType<typeof inlinePropertyListActions>;

export const inlinePropertyListReducer = createReducer<InlinePropertyListState, InlinePropertyListActions>(inlinePropertyListInitialState(), inlinePropertySearchFormSpec.reducer)
  .handleAction(inlinePropertyListActions.reset, () => inlinePropertyListInitialState())
  .handleAction(inlinePropertyListActions.fetch, (state, { payload }) => ({
    ...state,
    properties: payload.isSwitchingPage ? state.properties : [],
    hasMore: payload.isSwitchingPage ? state.hasMore : true,
  }))
  .handleAction(inlinePropertyListActions.appendNextPage, (state, { payload }) => ({
    ...state, properties: [ ...state.properties, ...payload.properties ], hasMore: payload.hasMore,
  }))
  .handleAction(inlinePropertyListActions.update, (state, { payload }) => ({
    ...state, properties: payload.properties, hasMore: payload.hasMore,
  }))
  .handleAction(inlinePropertyListActions.addBookmark, (state, { payload }) => ({
    ...state, properties: state.properties.map(p => p.caPropertyStockId === payload.caPropertyStockId ?
      { ...p, isBookmarked: true } : p,
    )
  }))
  .handleAction(inlinePropertyListActions.deleteBookmark, (state, { payload }) => ({
    ...state, properties: state.properties.map(p => p.caPropertyStockId === payload.caPropertyStockId ?
      { ...p, isBookmarked: false } : p,
    )
  }))
;