import useLayoutProps from 'common/hooks/useLayoutProps';
import useLocale from 'common/hooks/useLocale';
import { isMobileUI, multiLang } from 'common/utils/utils';
import TCScreenDesktop from 'components/setting/TCScreenDesktop';
import TCScreenMobile from 'components/setting/TCScreenMobile';
import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { IRootState } from 'reducers';


const TCScreen = isMobileUI() ? TCScreenMobile : TCScreenDesktop;

const TCPage = () => {
  const history = useHistory();
  const layoutProps = useLayoutProps();
  const tcItems = useSelector((state: IRootState) => state.systemSettings['T&C']);
  const { locale } = useLocale();

  return <TCScreen
    layoutProps={layoutProps}
    tcProps={Object.entries(tcItems).sort((a, b) => parseInt(a[1]['seq'] ?? 0) - parseInt(b[1]['seq'] ?? 0))
      .map(([_, item]) => ({ title: multiLang(locale, item['TITLE_TC'], item['TITLE_EN'], item['TITLE_SC'])!, content: multiLang(locale, item['CONTENT_TC'], item['CONTENT_EN'], item['CONTENT_SC'])! }))}
    onBackClick={() => history.goBack()}
  />

}

export default TCPage;