import React, { useRef } from 'react';
import { ScrollContext, useCreateScrollContext } from './ScrollContext';
import classes from './VerticalScrollView.module.css';


export interface VerticalScrollViewProps {
  children: React.ReactChild;
}

export default function VerticalScrollView(props: VerticalScrollViewProps) {
  const scrollRef = useRef<HTMLDivElement>(null);
  const scrollContext = useCreateScrollContext(scrollRef);
  return <ScrollContext.Provider value={scrollContext}>
    <div className={classes['vertical-scroll']} ref={scrollRef}>
      <div className={classes['inner']}>
        {props.children}
      </div>
    </div>
  </ScrollContext.Provider>;
}