import 'common/general-imports';
import useLocale from 'common/hooks/useLocale';
import ScreenTopBarMobile from 'components/navigation/ScreenTopBarMobile';
import ListView, { ListViewItem } from 'components/scaffold/ListView';
import MainLayoutMobile from 'components/scaffold/MainLayoutMobile';
import React from 'react';
import NotificationListItem from './NotificationListItem';
import classes from './NotificationListScreenLayoutMobile.module.scss';
import NotificationListViewProps from './NotificationListViewProps';

export interface NotificationListScreenLayoutMobileProps extends NotificationListViewProps {};

export function NotificationListView(props: Omit<NotificationListViewProps, 'layoutProps'>) {
  const { onBackClick, onRequestNextPage, onRefresh, hasMore, notifications } = props;
  const { langNotification } = useLocale();

  return <div className={classes['root']}>
    <ScreenTopBarMobile onBackClick={onBackClick} shadow>
      <div className={classes['title']}>{langNotification.titleNotifications}</div>
    </ScreenTopBarMobile>
    {
      notifications.length > 0 ? <ListView next={onRequestNextPage} refresh={onRefresh} hasMore={hasMore}>
        {notifications.map(notification => <ListViewItem key={notification.id} overrideClassName={classes['list-item-outer']}>
          <NotificationListItem {...notification} />
        </ListViewItem>)}
      </ListView> : <div className={classes['no-record-view']}>{langNotification.msgNoRecord}</div>
    }    
  </div>
}

export default function NotificationListScreenLayoutMobile(props:NotificationListScreenLayoutMobileProps) {
  const { layoutProps, ...rest } = props;

  return <MainLayoutMobile {...layoutProps}>
    <NotificationListView {...rest} />
  </MainLayoutMobile>
}