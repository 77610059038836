import useLocale from 'common/hooks/useLocale';
import { useMobileLayoutHeight } from 'common/hooks/useMobileLayoutHeight';
import { registerTouchableClassNames } from 'common/polyfills/touch-polyfill';
import ClientScreenTopBar from 'components/navigation/ClientScreenTopBar';
import MainLayoutMobile from 'components/scaffold/MainLayoutMobile';
import { ScrollContext, useCreateScrollContext } from 'components/scaffold/ScrollContext';
import React, { useMemo, useRef, useState } from 'react';
import { RegistrationMoreInfoViewProps } from './RegisterViewProps';
import RegisterMoreInfo from './RegistrationMoreInfo';
import classes from './RegistrationMoreInfoScreenMobile.module.scss';


registerTouchableClassNames(
	classes['select-district-row'],
	classes['save-btn'],
)

export default function RegistrationMoreInfoScreenMobile(props: RegistrationMoreInfoViewProps) {
  const { layoutProps, name, bindDistrict, bindPhoneNumber, bindReceiveEmail, bindSubscribeNewsletter,
    onSaveClick, onSkipClick } = props;
  
  const { lang, langClientProfile } = useLocale();
  const regionBundles = useLocale();

  const mobileLayoutHeight = useMobileLayoutHeight();

  const allDistrictOptions = useMemo(() => {
    return {
      ...regionBundles.districtHkiOptions,
      ...regionBundles.districtKltOptions,
      ...regionBundles.districtNtOptions,
    };
  }, [ regionBundles ]);

  const [ districtDialogOpen, setDistrictDialogOpen ] = useState(false);

  // Scroll Context
  const scrollRef = useRef<HTMLDivElement>(null);
  const scrollContext = useCreateScrollContext(scrollRef);

  return <MainLayoutMobile navigationHidden {...layoutProps}>
    {/* <DistrictSelectDialog {...regionBundles} open={districtDialogOpen} onHide={() => setDistrictDialogOpen(false)} districts={bindDistrict} /> */}
    <ScrollContext.Provider value={scrollContext}>
      <div className={classes['registration-more-info-mobile']} ref={scrollRef}>
        <div className={classes['inner']} style={{ height: mobileLayoutHeight }}>

          <div>
            <ClientScreenTopBar
              title={name}
              onSkipClick={onSkipClick}
              hideBackBtn={true}
            />
          </div>
          <RegisterMoreInfo {...props} />
        </div>
    </div>
    </ScrollContext.Provider>
  </MainLayoutMobile>
}