import useLayoutProps from 'common/hooks/useLayoutProps';
import useLocale from 'common/hooks/useLocale';
import usePageTitle from 'common/hooks/usePageTitle';
import { isMobileUI, multiLang } from 'common/utils/utils';
import AboutScreenDesktop from 'components/setting/AboutScreenDesktop';
import AboutScreenMobile from 'components/setting/AboutScreenMobile';
import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { IRootState } from 'reducers';


const AboutPage = () => {
  const { COMPANY_EMAIL, COMPANY_TEL, COMPANY_ADDRESS_EN, COMPANY_ADDRESS_SC, COMPANY_ADDRESS_TC, INTRO_EN, INTRO_SC, INTRO_TC } = useSelector((state: IRootState) => state.systemSettings.ClientApp.ABOUT);

  const history = useHistory();
  const { locale, lang } = useLocale();
  const layoutProps = useLayoutProps();

  usePageTitle(lang.navAbout);
  
  return isMobileUI() ? <AboutScreenMobile
    layoutProps={layoutProps}
    intro={multiLang(locale, INTRO_TC, INTRO_EN, INTRO_SC)!}
    email={COMPANY_EMAIL}
    tel={COMPANY_TEL}
    address={multiLang(locale, COMPANY_ADDRESS_TC, COMPANY_ADDRESS_EN, COMPANY_ADDRESS_SC)!}
    onBackClick={() => history.goBack()}
  /> : <AboutScreenDesktop
    layoutProps={layoutProps}
    content={multiLang(locale, INTRO_TC, INTRO_EN, INTRO_SC)!}
    email={COMPANY_EMAIL}
    tel={COMPANY_TEL}
    address={multiLang(locale, COMPANY_ADDRESS_TC, COMPANY_ADDRESS_EN, COMPANY_ADDRESS_SC)!}
  />

}

export default AboutPage;